import React, { useContext, useState } from 'react';
import { Box, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import CampaignActivity from './CampaignActivity';
import CampaignStatusBadge from './CampaignStatusBadge';
import RequestPurchaseOrderButton from '../purchaseOrders/RequestPurchaseOrderButton';
import POList from './CampaignPurchaseOrder/POList';
import { CurrencyContext } from '../../context/currency/CurrencyContext';
import UploadedFile from '../vendors/UploadedFile';
import CampaignApprovalStatusBadge from './CampaignApprovalStatusBadge';
import ScrollToTopOnMount from '../../util/scrollToTopOnMount';
import downloadFile from '../../util/downloadFile';
import { HttpContext } from '../../context/HttpContext';
import FilePreviewModal from '../../util/renderFilePreviewModal';
import renderFileDownload from '../../util/renderFileDownload';

export default function CampaignDetailsContent({
  campaign,
  updatedCampaignCode,
  updatePOList,
  refreshActivity,
  isCampaignManager,
}) {
  const { authAxios } = useContext(HttpContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const [contractPreview, setContractPreview] = useState({
    isOpen: false,
    url: '',
    downloading: false,
  });

  const [contract2Preview, setContract2Preview] = useState({
    isOpen: false,
    url: '',
    downloading: false,
    fileType: '',
  });

  const [contract3Preview, setContract3Preview] = useState({
    isOpen: false,
    url: '',
    downloading: false,
    fileType: '',
  });

  const [contract4Preview, setContract4Preview] = useState({
    isOpen: false,
    url: '',
    downloading: false,
    fileType: '',
  });

  const [contract5Preview, setContract5Preview] = useState({
    isOpen: false,
    url: '',
    downloading: false,
    fileType: '',
  });

  const [attachmentPreview, setAttachmentPreview] = useState({
    isOpen: false,
    url: '',
    downloading: false,
  });

  const [attachment2Preview, setAttachment2Preview] = useState({
    isOpen: false,
    url: '',
    downloading: false,
  });

  const [attachment3Preview, setAttachment3Preview] = useState({
    isOpen: false,
    url: '',
    downloading: false,
  });

  const [attachment4Preview, setAttachment4Preview] = useState({
    isOpen: false,
    url: '',
    downloading: false,
  });

  const [attachment5Preview, setAttachment5Preview] = useState({
    isOpen: false,
    url: '',
    downloading: false,
  });

  const downloadContractFile = (e) => {
    e.preventDefault();
    downloadFile('campaignContract', setContractPreview, authAxios, campaign.id);
  };

  const downloadContract2File = (e) => {
    e.preventDefault();
    downloadFile('campaignContract2', setContract2Preview, authAxios, campaign.id);
  };

  const downloadContract3File = (e) => {
    e.preventDefault();
    downloadFile('campaignContract3', setContract3Preview, authAxios, campaign.id);
  };

  const downloadContract4File = (e) => {
    e.preventDefault();
    downloadFile('campaignContract4', setContract4Preview, authAxios, campaign.id);
  };

  const downloadContract5File = (e) => {
    e.preventDefault();
    downloadFile('campaignContract5', setContract5Preview, authAxios, campaign.id);
  };

  const downloadAttachmentFile = (e) => {
    e.preventDefault();
    downloadFile('campaign', setAttachmentPreview, authAxios, campaign.id);
  };

  const downloadAttachment2File = (e) => {
    e.preventDefault();
    downloadFile('campaignFile2', setAttachment2Preview, authAxios, campaign.id);
  };

  const downloadAttachment3File = (e) => {
    e.preventDefault();
    downloadFile('campaignFile3', setAttachment3Preview, authAxios, campaign.id);
  };

  const downloadAttachment4File = (e) => {
    e.preventDefault();
    downloadFile('campaignFile4', setAttachment4Preview, authAxios, campaign.id);
  };

  const downloadAttachment5File = (e) => {
    e.preventDefault();
    downloadFile('campaignFile5', setAttachment5Preview, authAxios, campaign.id);
  };

  let code = campaign.campaignCode;
  if (code === null) {
    code = updatedCampaignCode;
  }

  return (
    <>
      <ScrollToTopOnMount />
      {/* Contracts */}
      <FilePreviewModal
        isOpen={contractPreview.isOpen}
        onClose={() => {
          setContractPreview((prev) => ({ ...prev, isOpen: false }));
          window.URL.revokeObjectURL(contractPreview.url);
        }}
        fileUrl={contractPreview.url}
        fileType={contractPreview.fileType}
      />
      <FilePreviewModal
        isOpen={contract2Preview.isOpen}
        onClose={() => {
          setContract2Preview((prev) => ({ ...prev, isOpen: false }));
          window.URL.revokeObjectURL(contract2Preview.url);
        }}
        fileUrl={contract2Preview.url}
        fileType={contract2Preview.fileType}
      />
      <FilePreviewModal
        isOpen={contract3Preview.isOpen}
        onClose={() => {
          setContract3Preview((prev) => ({ ...prev, isOpen: false }));
          window.URL.revokeObjectURL(contract3Preview.url);
        }}
        fileUrl={contract3Preview.url}
        fileType={contract3Preview.fileType}
      />
      <FilePreviewModal
        isOpen={contract4Preview.isOpen}
        onClose={() => {
          setContract4Preview((prev) => ({ ...prev, isOpen: false }));
          window.URL.revokeObjectURL(contract4Preview.url);
        }}
        fileUrl={contract4Preview.url}
        fileType={contract4Preview.fileType}
      />
      <FilePreviewModal
        isOpen={contract5Preview.isOpen}
        onClose={() => {
          setContract5Preview((prev) => ({ ...prev, isOpen: false }));
          window.URL.revokeObjectURL(contract5Preview.url);
        }}
        fileUrl={contract5Preview.url}
        fileType={contract5Preview.fileType}
      />
      {/* Campaign attachments */}
      <FilePreviewModal
        isOpen={attachmentPreview.isOpen}
        onClose={() => {
          setAttachmentPreview((prev) => ({ ...prev, isOpen: false }));
          window.URL.revokeObjectURL(attachmentPreview.url);
        }}
        fileUrl={attachmentPreview.url}
        fileType={attachmentPreview.fileType}
      />
      <FilePreviewModal
        isOpen={attachment2Preview.isOpen}
        onClose={() => {
          setAttachment2Preview((prev) => ({ ...prev, isOpen: false }));
          window.URL.revokeObjectURL(attachment2Preview.url);
        }}
        fileUrl={attachment2Preview.url}
        fileType={attachment2Preview.fileType}
      />
      <FilePreviewModal
        isOpen={attachment3Preview.isOpen}
        onClose={() => {
          setAttachment3Preview((prev) => ({ ...prev, isOpen: false }));
          window.URL.revokeObjectURL(attachment3Preview.url);
        }}
        fileUrl={attachment3Preview.url}
        fileType={attachment3Preview.fileType}
      />
      <FilePreviewModal
        isOpen={attachment4Preview.isOpen}
        onClose={() => {
          setAttachment4Preview((prev) => ({ ...prev, isOpen: false }));
          window.URL.revokeObjectURL(attachment4Preview.url);
        }}
        fileUrl={attachment4Preview.url}
        fileType={attachment4Preview.fileType}
      />
      <FilePreviewModal
        isOpen={attachment5Preview.isOpen}
        onClose={() => {
          setAttachment5Preview((prev) => ({ ...prev, isOpen: false }));
          window.URL.revokeObjectURL(attachment5Preview.url);
        }}
        fileUrl={attachment5Preview.url}
        fileType={attachment5Preview.fileType}
      />
      <Box
        bg="#fff"
        borderRadius="md"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
        mt="4"
        p="10"
      >
        <VStack alignItems="flex-start" w={'full'} gap={'10px'}>
          <HStack w={'full'} justifyContent={'space-between'}>
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Campaign code
              </Box>
              {campaign.campaignCode ? (
                <Box h="24px" fontSize="20px" fontWeight="bold" lineHeight="20px">
                  {campaign.campaignCode}
                </Box>
              ) : (
                <Box h="24px" fontSize="14px" color="#a9b1b4" lineHeight="20px">
                  pending
                </Box>
              )}
            </Box>
            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Client
              </Box>{' '}
              <Box>{campaign.clientName} </Box>
            </Box>
            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Currency
              </Box>{' '}
              <Box>{`${campaign.currency.code} - ${campaign.currency.name}`}</Box>
            </Box>
            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Budget
              </Box>{' '}
              <Box>{formatCurrency(campaign.budget, campaign.currency.code)}</Box>
            </Box>
            {campaign.approvalStatus && campaign.approvalStatus !== '' && (
              <>
                <Box h="45px" w="1px" bg="#e8e9e9" />
                <Box flex="1">
                  <CampaignApprovalStatusBadge status={campaign.approvalStatus} />
                </Box>
              </>
            )}
            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <CampaignStatusBadge status={campaign.status} />
            </Box>
          </HStack>

          <HStack w={'full'} justifyContent={'space-between'}>
            {/* Attachment files */}
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Attachment (1)
              </Box>
              {campaign.file ? (
                <>
                  {renderFileDownload(
                    'View/Download',
                    downloadAttachmentFile,
                    contractPreview.downloading
                  )}
                </>
              ) : (
                <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                  No files.
                </Box>
              )}
            </Box>
            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Attachment (2)
              </Box>
              {campaign.file2 ? (
                <>
                  {renderFileDownload(
                    'View/Download',
                    downloadAttachment2File,
                    contract2Preview.downloading
                  )}
                </>
              ) : (
                <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                  No files.
                </Box>
              )}
            </Box>
            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Attachment (3)
              </Box>
              {campaign.file3 ? (
                <>
                  {renderFileDownload(
                    'View/Download',
                    downloadAttachment3File,
                    contract3Preview.downloading
                  )}
                </>
              ) : (
                <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                  No files.
                </Box>
              )}
            </Box>
            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Attachment (4)
              </Box>
              {campaign.file4 ? (
                <>
                  {renderFileDownload(
                    'View/Download',
                    downloadAttachment4File,
                    contract4Preview.downloading
                  )}
                </>
              ) : (
                <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                  No files.
                </Box>
              )}
            </Box>

            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Attachment (5)
              </Box>
              {campaign.file5 ? (
                <>
                  {renderFileDownload(
                    'View/Download',
                    downloadAttachment5File,
                    contract5Preview.downloading
                  )}
                </>
              ) : (
                <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                  No files.
                </Box>
              )}
            </Box>
            <Box h="45px" w="1px" bg="#e8e9e9" visibility={'hidden'} />
            <Box flex="1" visibility={'hidden'}></Box>
          </HStack>

          <HStack w={'full'} justifyContent={'space-between'}>
            {/* Contract files */}
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Contract (1)
              </Box>
              {campaign.contractFile ? (
                <>
                  {renderFileDownload(
                    'View/Download',
                    downloadContractFile,
                    contractPreview.downloading
                  )}
                </>
              ) : (
                <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                  No files.
                </Box>
              )}
            </Box>

            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Contract (2)
              </Box>
              {campaign?.contractFile2 ? (
                <>
                  {renderFileDownload(
                    'View/Download',
                    downloadContract2File,
                    contract2Preview.downloading
                  )}
                </>
              ) : (
                <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                  No files.
                </Box>
              )}
            </Box>

            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Contract (3)
              </Box>
              {campaign?.contractFile3 ? (
                <>
                  {renderFileDownload(
                    'View/Download',
                    downloadContract3File,
                    contract3Preview.downloading
                  )}
                </>
              ) : (
                <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                  No files.
                </Box>
              )}
            </Box>

            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Contract (4)
              </Box>
              {campaign?.contractFile3 ? (
                <>
                  {renderFileDownload(
                    'View/Download',
                    downloadContract4File,
                    contract4Preview.downloading
                  )}
                </>
              ) : (
                <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                  No files.
                </Box>
              )}
            </Box>

            <Box h="45px" w="1px" bg="#e8e9e9" />
            <Box flex="1">
              <Box color="#868789" fontSize="14px">
                Contract (5)
              </Box>
              {campaign?.contractFile3 ? (
                <>
                  {renderFileDownload(
                    'View/Download',
                    downloadContract5File,
                    contract5Preview.downloading
                  )}
                </>
              ) : (
                <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                  No files.
                </Box>
              )}
            </Box>

            <Box h="45px" w="1px" bg="#e8e9e9" visibility={'hidden'} />
            <Box flex="1" visibility={'hidden'}></Box>
          </HStack>
        </VStack>
        <CampaignActivity
          id={campaign.id}
          campaign={campaign}
          code={code}
          refreshActivity={refreshActivity}
        />
      </Box>
      <Box>
        <Heading variant="h4" fontSize="22px" fontWeight="bold" mt="35px">
          Purchase Orders ({campaign.numberOfPurchaseOrders})
        </Heading>
        {!campaign.campaignCode && isCampaignManager && (
          <Text fontSize="14px" color="#a9b1b4" mt="18px">
            You can request a Purchase order for this campaign after the finance team
            approve the campaign.
            {/*No purchase orders yet.*/}
          </Text>
        )}
        <Box mt="25px">
          {campaign.campaignCode &&
            campaign.status === 'active' &&
            ['approved', 'initial'].includes(campaign.approvalStatus) &&
            isCampaignManager && (
              <RequestPurchaseOrderButton fromCampaign updatePOList={updatePOList} />
            )}
        </Box>
        {campaign.campaignCode && <POList pageSize={campaign.numberOfPurchaseOrders} />}
      </Box>
    </>
  );
}
