import React, {
  useContext,
  // useEffect
} from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Textarea,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../context/HttpContext';
import { getMessage } from '../../util';

const InvoiceChangesForm = ({ onClose, updateActivityLog, invoice }) => {
  const { id } = useParams();
  const history = useHistory();
  const [done, setDone] = React.useState(false);
  const validationSchema = Yup.object().shape({
    comment: Yup.string()
      .required('This is mandatory field')
      .test('len', 'Please enter 1000 characters or less', (val) => val.length < 1000),
  });

  const {
    register,
    handleSubmit,
    formState,
    // reset,
    // setError, watch
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { errors, isSubmitting, isDirty, isValid } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const onSubmit = async (values) => {
    try {
      const { data } = await authAxios.post(`invoices/${id}/comments`, values);
      toast({
        position: 'top',
        description:
          'You have successfully sent your comment. You will be notified when the supplier responds.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      history.push(`/orders/${invoice.purchaseOrder.id}`);
      setDone(true);
      onClose();
      updateActivityLog(data);
      // reset();
    } catch (onError) {
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
      setDone(true);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.comment} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Comment</FormLabel>
          <Textarea
            isInvalid
            name="comment"
            id="comment"
            size="lg"
            border="1px solid  transparent"
            bg="#ebecf0"
            _focus={{ border: '1px solid black' }}
            _hover={{ border: '1px solid black' }}
            {...register('comment')}
            placeholder="Enter your comment"
          />
          <FormErrorMessage>{errors.comment?.message || errors.comment}</FormErrorMessage>
        </FormControl>
        <>
          <Box d="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              isLoading={isSubmitting && !done}
              disabled={!isDirty || !isValid || isSubmitting}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
            >
              Submit
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
};
export default InvoiceChangesForm;
