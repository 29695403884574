import {
  Center,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

const FilePreviewModal = ({ isOpen, onClose, fileUrl, fileType = '' }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        sx={{
          maxWidth: '700px',
        }}
      >
        <ModalCloseButton
          sx={{ outline: 'none !important', boxShadow: 'none !important' }}
        />
        <ModalBody>
          <Center mt="10%">
            {fileUrl && fileType === 'pdf' && (
              <iframe
                src={fileUrl}
                style={{ width: '100%', height: '80vh', border: 'none' }}
                title="File Preview"
              />
            )}
            {fileUrl &&
              (fileType === 'docx' ||
                fileType === 'doc' ||
                fileType === 'msword' ||
                fileType ===
                  'vnd.openxmlformats-officedocument.wordprocessingml.document') && (
                <Text textAlign={'center'} mb="5%">
                  Word documents cannot be previewed directly in this modal. <br /> Please
                  view the downloaded file instead.
                </Text>
              )}
            {fileUrl &&
              (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') && (
                <Image
                  src={fileUrl}
                  alt="File Preview"
                  sx={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
                />
              )}
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FilePreviewModal;
