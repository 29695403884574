import React from 'react';
export const FilterIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="461px"
    height="512px"
    viewBox="488.5 50.5 461 512"
    {...props}
  >
    <path
      d="M936.883,51.609H503.117c-6.759,0-12.288,5.53-12.288,12.288c0,2.458,0.614,4.916,2.457,7.373l151.143,216.269v217.498
   c0,4.915,3.072,9.83,7.987,11.059l127.795,46.08c6.145,2.458,13.518-1.229,15.975-7.373c0.614-1.229,0.614-2.457,0.614-4.301
   V286.925L947.328,70.656c3.687-5.53,2.457-13.517-3.072-17.204C942.413,52.224,939.955,51.609,936.883,51.609L936.883,51.609z
    M774.067,276.48c-1.229,1.843-2.458,4.301-2.458,6.758v250.061l-103.219-37.478V283.238c0-2.458-0.614-4.915-2.458-6.758
   L526.464,76.186h387.072L774.067,276.48z"
    />
  </svg>
);
