import React, { useContext } from 'react';
import { Checkbox, Button, Box, useToast, Text, HStack } from '@chakra-ui/react';

import { HttpContext } from '../../../../context/HttpContext';
import { hasBankAccount, hasPayPal } from '../../../../util/supplier/helper';
import { getErrors, getMessage } from '../../../../util';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const CodeInput = (props) => {
  const [isChecked, setIsChecked] = React.useState(false);
  const {
    navigation,
    // name,
    // value,
    onClose,
    steps,
    index,
    // id,
    // supplierId,

    updateSelectedSupplierId,
    updateStepFormState,
    stepFormState,
    suppliers,
    updateSuppliers,
    // updateUser,
  } = props;

  const { suppliersForApproving, suppliersForApprovingCount, status } = stepFormState;

  const supplier = suppliersForApproving[index];
  const supplierId = suppliersForApproving[index].id;

  const {
    next,
    // go,
    previous,
  } = navigation;

  const validationSchema = Yup.object().shape({
    paymentDetailsApproved: Yup.boolean()
      .oneOf([true], 'Must Accept Terms and Conditions')
      .required('This is mandatory field'),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };
  const {
    // register, handleSubmit, reset, formState,
    setError,
  } = useForm(formOptions);
  // const { errors, isSubmitting, isDirty, isValid } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleClick = async () => {
    try {
      updateStepFormState({
        ...stepFormState,
        status: 'pending',
      });

      const { data } = await authAxios.post(
        `users/suppliers/${supplierId}/approve-payment-details-changes`,
        { paymentDetailsApproved: true }
      );

      setIsChecked(false);

      const recordIndex = suppliers.findIndex((x) => x.id === data.id);
      if (recordIndex > -1) {
        suppliers[recordIndex] = {
          ...suppliers[recordIndex],
          approvedAt: data.approvedAt,
          paymentDetailsApproved: data.paymentDetailsApproved,
          sageSyncFailed: data.sageSyncFailed,
        };
        updateSuppliers(suppliers);
        updateSelectedSupplierId(supplier.id);
        updateStepFormState({
          ...stepFormState,
          status: 'resolved',
        });
      }

      toast({
        position: 'top',
        description:
          'You have confirmed that you had changed the supplier records in Sage and the supplier has been notified.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });

      const newCount =
        suppliersForApprovingCount > 0 ? suppliersForApprovingCount - 1 : 0;
      if (newCount === 0) {
        onClose();
      } else {
        if (index === suppliersForApprovingCount - 1) {
          previous();
        }
        const newSuppliersForApproving = suppliersForApproving.filter(
          (supp) => supp.id !== data.id
        );

        updateStepFormState({
          ...stepFormState,
          suppliersForApproving: newSuppliersForApproving,
          suppliersForApprovingCount: newCount,
          status: 'resolved',
        });
      }
    } catch (onError) {
      console.log(onError);
      updateStepFormState({
        ...stepFormState,
        status: 'rejected',
      });
      const errors = getErrors(onError);
      if (errors && errors.code && errors.code.errors) {
        let message = errors.code.errors[0];
        if (message === 'This value is too long. It should have 8 characters or less.') {
          message = 'Please enter 8 characters or less.';
        }
        setError('code', { type: 'server', message });
      } else {
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top',
            description: message,
            // "This code is already in use. Please choose a different code.",
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
      }
    }
  };

  return (
    <>
      {/*<form onSubmit={handleSubmit(onSubmit)}>*/}
      <Box d="flex" justifyContent="center" mt="-12px" mb="10px" zIndex="1000">
        {index + 1}
        {' of '}
        {suppliersForApprovingCount}
        {' currencies'}
      </Box>
      <Box>
        <b>Please update the supplier records manually in Sage</b> with the payment
        details below and then click “Confirm changes”.
      </Box>

      <>
        <Box bg="#f5f6fa" p="30px" mt="27px">
          <Box>
            <Text mb="17px">
              <b>Supplier code</b>: {supplier.code}
            </Text>

            <Text>
              <b>Currency</b>: {`${supplier.currency.code} - ${supplier.currency.name}`}
            </Text>
            <Box w="100%" h="1px" bg="#e8e9e9" mb="20px" mt="20px" />
            <HStack alignItems="flex-start">
              {hasBankAccount(supplier) && (
                <>
                  <Box color="#868789" fontSize="14px" mr="35px">
                    <Text mb="5px">Payment method:</Text>
                    <Text mb="5px">Bank name:</Text>
                    {supplier.bankAddress && <Text mb="5px">Bank address:</Text>}
                    <Text mb="5px">Country:</Text>
                    {supplier.accountName && <Text mb="5px">Account name:</Text>}
                    {supplier.accountNumber && <Text mb="5px">Account number:</Text>}
                    {supplier.accountNumberOrIban && (
                      <Text mb="5px">Account number or IBAN:</Text>
                    )}
                    {supplier.bankCode && <Text mb="5px">Bank code:</Text>}
                    {supplier.bsbCode && <Text mb="5px">BSB code:</Text>}
                    {supplier.iban && <Text mb="5px">IBAN:</Text>}
                    {supplier.ifscCode && <Text mb="5px">IFSC code:</Text>}
                    {supplier.routingNumber && <Text mb="5px">Routing number:</Text>}
                    {supplier.sortCode && <Text mb="5px">Sort code:</Text>}
                    {supplier.swift && <Text mb="5px">SWIFT code:</Text>}
                    {supplier.additionalReference && (
                      <Text mb="5px">Additional reference:</Text>
                    )}
                  </Box>
                  <Box alignItems="flex-start" fontSize="14px">
                    <Text mb="5px">Bank transfer</Text>
                    <Text mb="5px">{supplier.bankName}</Text>
                    {supplier.bankAddress && <Text mb="5px">{supplier.bankAddress}</Text>}
                    <Text mb="5px">{supplier.country?.name}</Text>
                    {supplier.accountName && <Text mb="5px">{supplier.accountName}</Text>}
                    {supplier.accountNumber && (
                      <Text mb="5px">{supplier.accountNumber}</Text>
                    )}
                    {supplier.accountNumberOrIban && (
                      <Text mb="5px">{supplier.accountNumberOrIban}</Text>
                    )}
                    {supplier.bankCode && <Text mb="5px">{supplier.bankCode}</Text>}
                    {supplier.bsbCode && <Text mb="5px">{supplier.bsbCode}</Text>}
                    {supplier.iban && <Text mb="5px">{supplier.iban}</Text>}
                    {supplier.ifscCode && <Text mb="5px">{supplier.ifscCode}</Text>}
                    {supplier.routingNumber && (
                      <Text mb="5px">{supplier.routingNumber}</Text>
                    )}
                    {supplier.sortCode && <Text mb="5px">{supplier.sortCode}</Text>}
                    {supplier.swift && <Text mb="5px">{supplier.swift}</Text>}
                    {supplier.additionalReference && (
                      <Text mb="5px">{supplier.additionalReference}</Text>
                    )}
                  </Box>
                </>
              )}
              {supplier && hasPayPal(supplier) && (
                <>
                  <Box color="#868789" fontSize="14px" mr="35px">
                    <Text mb="5px">Payment method:</Text>
                    <Text mb="5px">Email address:</Text>
                  </Box>
                  <Box alignItems="flex-start" fontSize="14px">
                    <Text mb="5px">PayPal</Text>
                    <Text mb="5px">{supplier.payPalAccount}</Text>
                  </Box>
                </>
              )}
            </HStack>
          </Box>
        </Box>
        <Checkbox
          size="lg"
          colorScheme="orange"
          isChecked={isChecked}
          onChange={(e) => handleCheckboxChange(e)}
        >
          <Box fontSize="16px" pt="20px">
            I confirm that I have manually added the above payment data to the supplier
            records in Sage.
          </Box>
        </Checkbox>
        <Box d="flex" justifyContent="space-between" mt={10} mb={20}>
          <Box>
            {index !== 0 && (
              <Button
                variant="outline"
                size="lg"
                color="brand.400"
                //colorScheme="brand"
                textTransform="initial"
                borderRadius="10px"
                onClick={previous}
                disabled={status === 'pending'}
              >
                Go back
              </Button>
            )}
          </Box>
          <Box>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
              disabled={!isChecked || status === 'pending'}
              isLoading={status === 'pending'}
              onClick={handleClick}
            >
              Confirm changes
            </Button>
            {steps.length - 1 !== index && (
              <Button
                variant="ghost"
                size="lg"
                colorScheme="brand"
                textTransform="initial"
                borderRadius="10px"
                onClick={next}
                disabled={status === 'pending'}
              >
                Skip
              </Button>
            )}
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={onClose}
              disabled={status === 'pending'}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </>
      {/*</form>*/}
    </>
  );
};
export default CodeInput;
