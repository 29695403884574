import React from 'react';
export const CampaignIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    // width="24px"
    // height="24px"
    viewBox="0 0 24 24"
    {...props}
  >
    <g>
      <path
        d="M19.084,3.54h-1.381c-0.158-1.058-1.058-1.841-2.138-1.841h-0.738c-0.227,0-0.426-0.123-0.507-0.313
		c-0.394-0.917-1.292-1.51-2.289-1.51c-0.998,0-1.896,0.593-2.289,1.51c-0.082,0.19-0.281,0.313-0.507,0.313H8.496
		c-1.08,0-1.979,0.784-2.136,1.841H4.979c-0.957,0-1.735,0.778-1.735,1.735v16.961c0,0.956,0.778,1.734,1.735,1.734h14.105
		c0.957,0,1.735-0.778,1.735-1.734V5.274C20.819,4.318,20.041,3.54,19.084,3.54z M8.496,2.545h0.739
		c0.564,0,1.068-0.324,1.286-0.826c0.26-0.606,0.853-0.997,1.51-0.997c0.659,0,1.252,0.391,1.511,0.998
		c0.217,0.501,0.722,0.826,1.285,0.826h0.738c0.726,0,1.315,0.59,1.315,1.314v0.895H7.182V3.859
		C7.182,3.135,7.771,2.545,8.496,2.545z M19.084,23.126H4.979c-0.491,0-0.89-0.399-0.89-0.891V5.274c0-0.49,0.399-0.889,0.89-0.889
		h1.357v0.792C6.336,5.41,6.525,5.6,6.759,5.6h10.546c0.232,0,0.422-0.189,0.422-0.423V4.385h1.357c0.49,0,0.89,0.399,0.89,0.889
		v16.961C19.974,22.727,19.574,23.126,19.084,23.126z"
      />
    </g>
    <path
      d="M17.51,17.947c0,0.032,0.026,0.059,0.059,0.059l0,0c0.031,0,0.058-0.026,0.058-0.059v-0.088
	c0-0.032-0.026-0.059-0.058-0.059l0,0c-0.032,0-0.059,0.026-0.059,0.059V17.947z"
    />
    <path
      d="M6.147,18.375c0-0.267,0.216-0.482,0.482-0.482h10.982c0.267,0,0.482,0.216,0.482,0.482l0,0
	c0,0.266-0.216,0.481-0.482,0.481H6.629C6.363,18.856,6.147,18.641,6.147,18.375L6.147,18.375z"
    />
    <path
      d="M6.147,20.924c0-0.267,0.216-0.482,0.482-0.482h10.982c0.267,0,0.482,0.216,0.482,0.482l0,0
	c0,0.266-0.216,0.481-0.482,0.481H6.629C6.363,21.405,6.147,21.189,6.147,20.924L6.147,20.924z"
    />
    <g>
      <path
        d="M12.234,14.049c-0.01,0-0.021,0-0.032-0.002c-0.203-0.008-0.394-0.098-0.529-0.25l-2.157-2.426
		c-0.275-0.31-0.248-0.784,0.062-1.059c0.31-0.275,0.784-0.247,1.059,0.062l1.642,1.847l5.094-4.84
		c0.301-0.286,0.775-0.273,1.061,0.027s0.273,0.775-0.027,1.06l-5.656,5.375C12.611,13.975,12.426,14.049,12.234,14.049z"
      />
    </g>
    <g>
      <path
        d="M11.912,16.461c-2.635,0-4.779-2.144-4.779-4.779s2.144-4.779,4.779-4.779s4.779,2.144,4.779,4.779
		S14.547,16.461,11.912,16.461z M11.912,7.903c-2.083,0-3.779,1.695-3.779,3.779s1.695,3.779,3.779,3.779s3.779-1.695,3.779-3.779
		S13.995,7.903,11.912,7.903z"
      />
    </g>
  </svg>
);
