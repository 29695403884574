import React from 'react';
export const TeamIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    {...props}
  >
    <path
      d="M7.593,10.512c2.351,0,4.257-1.904,4.257-4.256C11.85,3.907,9.943,2,7.593,2c-2.35,0-4.257,1.907-4.257,4.256l0,0
	C3.34,8.607,5.243,10.509,7.593,10.512z M7.593,2.736c1.943,0,3.521,1.577,3.521,3.521c0,1.944-1.578,3.52-3.521,3.52
	s-3.52-1.575-3.52-3.52c0.006-1.939,1.58-3.512,3.52-3.515V2.736z"
    />
    <g>
      <rect x="13.898" y="14.136" width="2.5" height="0.852" />
    </g>
    <g>
      <rect x="13.898" y="16.566" width="2.5" height="0.851" />
    </g>
    <g>
      <rect x="13.898" y="19.188" width="2.5" height="0.851" />
    </g>
    <g>
      <path
        d="M22.29,23.948h-4.384c-0.942,0-1.709-0.855-1.709-1.907v-7.45c0-1.051,0.767-1.907,1.709-1.907h4.384
		c0.942,0,1.71,0.856,1.71,1.907v7.45C24,23.093,23.232,23.948,22.29,23.948z M17.906,13.535c-0.521,0-0.945,0.474-0.945,1.056v7.45
		c0,0.583,0.424,1.057,0.945,1.057h4.384c0.521,0,0.945-0.474,0.945-1.057v-7.45c0-0.582-0.424-1.056-0.945-1.056H17.906z"
      />
    </g>
    <g>
      <rect x="18.148" y="16.08" width="3.994" height="0.852" />
    </g>
    <g>
      <rect x="18.148" y="18.04" width="3.994" height="0.852" />
    </g>
    <g>
      <rect x="18.148" y="19.974" width="3.994" height="0.851" />
    </g>
    <path
      d="M10.248,21.782H0.803c0.332-4.436,2.436-8.937,7.852-8.647c0.556,0.029,1.082,0.191,1.593,0.372v-0.89
	c-0.715-0.229-1.467-0.373-2.251-0.365C2.316,12.307,0.003,17.393,0,22.161v0.636h10.248V21.782z"
    />
    <path
      d="M21.033,12.164c-0.148-1.065-1.027-1.89-2.096-1.89h-5.702c-1.171,0-2.125,0.988-2.125,2.203v9.008
	c0,1.215,0.954,2.202,2.125,2.202h4.419l-0.372-0.829h-4.047c-0.729,0-1.324-0.616-1.324-1.373v-9.008
	c0-0.756,0.594-1.373,1.324-1.373h5.702c0.624,0,1.123,0.458,1.264,1.06v0.555h0.846v-0.555H21.033z"
    />
  </svg>
);
