import React from 'react';
// import { useHistory } from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Spinner,
  Link,
  Tooltip,
} from '@chakra-ui/react';

import map from 'lodash/map';
import Pagination from '../../common/UIcomponents/Pagination';
import ScrollToTop from '../../util/scrollToTop';
import { POIcon } from '../../assets/iconsComponents/poIcon';
import SortArrows from '../../common/UIcomponents/SortArrowsTable';
import SatusBadge from './PaymentsStatusBadge';
import { formatAmount } from '../../util';
import SyncFailIcon from '../../assets/icons/sync_fail_icon.svg';
import moment from 'moment';

function PaymentList({
  invoices,
  isSupplier,
  isFinance,
  isCampaign,
  handlePageSizeChange,
  handlePageChange,
  currentPage,
  pagesQuantity,
  orderBy,
  updateOrderBy,
  loading,
}) {
  const {
    totalGross,
    totalNet,
    totalVat,
    status,
    number,
    referenceNumber,
    fromName,
    date,
    dueDate,
    paymentDate,
  } = orderBy;

  function handleOnClick(e, key, value) {
    updateOrderBy(key, value);
  }

  return (
    <>
      <ScrollToTop />
      <Table
        variant={`simple`}
        bg="#fff"
        borderRadius="md"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
        mt="7"
      >
        {invoices.length > 0 && !loading && (
          <Thead>
            <Tr key={'head-members'}>
              <Th pr="10px">
                <Box
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Gross
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={totalGross}
                      b="totalGross"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>

              <Th pl="10px" pr="10px">
                <Box
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Net
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={totalNet}
                      b="totalNet"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>

              <Th pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Vat
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={totalVat}
                      b="totalVat"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Status
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={status}
                      b="status"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th maxWidth="90px" pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  PO number
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={number}
                      b="number"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="100px" pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Ref. Number
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={referenceNumber}
                      b="referenceNumber"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              {!isSupplier && (
                <Th minWidth="100px" p="5px">
                  <Box
                    as="p"
                    d="flex"
                    flexDirection="row"
                    alignItems="center"
                    color="#bdc1d0"
                    fontSize="12px"
                    fontFamily="Roboto"
                    letterSpacing="0"
                  >
                    Supplier
                    <Box d="flex" flexDirection="column" ml="0px">
                      <SortArrows
                        i={fromName}
                        b="fromName"
                        desc="desc"
                        asc="asc"
                        handleOnClick={handleOnClick}
                      />
                    </Box>
                  </Box>
                </Th>
              )}
              <Th minWidth="100px" p="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Invoice date
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={date}
                      b="date"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="100px" p="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Due date
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={dueDate}
                      b="dueDate"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="110px" p="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Payment date
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={paymentDate}
                      b="paymentDate"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              {!isCampaign && (
                <Th maxWidth="70px" p="10px">
                  <Box
                    as="p"
                    d="flex"
                    flexDirection="row"
                    alignItems="center"
                    color="#bdc1d0"
                    fontSize="12px"
                    fontFamily="Roboto"
                    letterSpacing="0"
                  >
                    Action
                  </Box>
                </Th>
              )}
            </Tr>
          </Thead>
        )}
        <>
          {loading && (
            <Box p={12} d="flex" alignItems="center" justifyContent="center" h="400px">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="brand.500"
                size="xl"
              />
            </Box>
          )}
          {invoices.length > 0 && !loading ? (
            <Tbody>
              <>
                {map(invoices, (invoice) => {
                  const isRed = (isFinance && invoice.sageSyncFailed) || false;
                  return (
                    <Tr key={invoice.id}>
                      <Td maxWidth="250px" position="relative">
                        {isRed && (
                          <Box position="absolute" top="10px" left="10px" as="span">
                            <Tooltip
                              label="Sync with Sage failed. Restart synchronisation manually."
                              fontSize="xs"
                              maxWidth="180px"
                              color="white"
                              borderRadius="8px"
                              p={2}
                              bg="red"
                              textAlign="center"
                              lineHeight="1"
                            >
                              <img
                                src={SyncFailIcon}
                                style={{ width: '25px' }}
                                alt="syncfailed"
                              />
                            </Tooltip>
                          </Box>
                        )}
                        <Box
                          as="a"
                          fontSize="14px"
                          fontFamily="Roboto"
                          fontWeight="700"
                          color={isRed && `red`}
                        >
                          {invoice.purchaseOrder.supplier.currency.symbol}
                          {formatAmount(invoice.purchaseOrder.totalGross)}
                        </Box>
                      </Td>

                      <Td maxWidth="250px" pl="10px">
                        <Box
                          as="a"
                          fontSize="14px"
                          fontFamily="Roboto"
                          color={isRed && `red`}
                        >
                          {' '}
                          <Box
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="1"
                            color={isRed && `red`}
                          >
                            {invoice.purchaseOrder.supplier.currency.symbol}
                            {formatAmount(invoice.purchaseOrder.totalNet)}
                          </Box>
                        </Box>
                      </Td>
                      <Td maxWidth="250px" pl="10px">
                        <Box
                          as="a"
                          fontSize="14px"
                          fontFamily="Roboto"
                          color={isRed && `red`}
                        >
                          {' '}
                          <Box fontSize="14px" fontWeight="400" lineHeight="1">
                            {invoice.purchaseOrder.supplier.currency.symbol}
                            {formatAmount(invoice.purchaseOrder.totalVat)}
                          </Box>
                        </Box>
                      </Td>
                      <Td pl="10px">
                        <Box fontWeight="bold" fontSize="16px" color={isRed && `red`}>
                          <SatusBadge status={invoice.purchaseOrder?.status} />
                        </Box>
                      </Td>
                      <Td pl="10px" minWidth="100px">
                        {invoice.purchaseOrder?.number ? (
                          <Box color={isRed && `red`}>{invoice.purchaseOrder.number}</Box>
                        ) : (
                          <Box color={isRed ? `red` : '#a9b1b4'}>pending</Box>
                        )}
                      </Td>
                      <Td pl="10px" pr="10px">
                        <Box color={isRed && `red`}>{invoice.referenceNumber}</Box>
                      </Td>
                      {!isSupplier && (
                        <Td pl="0">
                          <Box fontSize="14px" pl="0px" color={isRed && `red`}>
                            {invoice.fromName}
                            <Box
                              color={isRed ? `red` : '#a9b1b4'}
                              fontSize="14px"
                              fontWeight="400"
                              lineHeight="1"
                            >
                              {invoice.purchaseOrder?.supplier.code}
                            </Box>
                          </Box>
                        </Td>
                      )}

                      <Td pl="10px">
                        <Box color={isRed && `red`}>
                          {moment(invoice.date).format('DD MMM y')}
                        </Box>
                      </Td>

                      <Td pl="10px" color={isRed && `red`}>
                        {moment(invoice.dueDate).format('DD MMM y')}
                      </Td>
                      <Td pl="10px" color={isRed && `red`}>
                        {invoice.paymentDate
                          ? moment(invoice.paymentDate).format('DD MMM y')
                          : null}
                      </Td>
                      {!isCampaign && (
                        <Td pl="10px" pr="10px">
                          <Link
                            fontSize="14px"
                            color="brand.500"
                            as={RouterLink}
                            to={`/invoices/${invoice.id}`}
                          >
                            Invoice
                          </Link>
                        </Td>
                      )}
                    </Tr>
                  );
                })}
              </>
            </Tbody>
          ) : invoices.length === 0 && !loading ? (
            <Box
              d="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              p="80px"
            >
              <POIcon width="60px" height="55px" color="#d1dbdf" />
              <Box fontSize="18px" fontWeight="bold" mt="20px" mb="20px">
                No results.
              </Box>
              <Box color="#a9b1b4" fontSize="14px">
                We could not find any invoices.
                {/*We could not find any invoices that matched your search criteria.*/}
              </Box>
            </Box>
          ) : null}
        </>
      </Table>
      <Box margin="20px" />
      {invoices.length > 0 && (
        <Pagination
          handlePageSizeChange={handlePageSizeChange}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          pagesQuantity={pagesQuantity}
        />
      )}
    </>
  );
}

export default PaymentList;
