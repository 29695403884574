import React, { createContext, useState } from 'react';

export const PaymentContext = createContext();

const PaymentContextProvider = (props) => {
  const [filterStatus, setFilterStatus] = useState('none');
  const [filterDueDateFrom, setFilterDueDateFrom] = useState('');
  const [filterDueDateTo, setFilterDueDateTo] = useState('');
  const [filterPaymentDateFrom, setFilterPaymentDateFrom] = useState('');
  const [filterPaymentDateTo, setFilterPaymentDateTo] = useState('');
  return (
    <PaymentContext.Provider
      value={{
        filterStatus,
        setFilterStatus,
        filterDueDateFrom,
        setFilterDueDateFrom,
        filterDueDateTo,
        setFilterDueDateTo,
        filterPaymentDateFrom,
        setFilterPaymentDateFrom,
        filterPaymentDateTo,
        setFilterPaymentDateTo,
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  );
};

export default PaymentContextProvider;
