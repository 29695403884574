import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import DrawerComponentCustom from '../../../common/UIcomponents/CustomDrawer';
import PurchaseEditForm from './PurchaseEditForm';

export default function EditPOButton({ order, updatePurchaseOrders }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        type="submit"
        colorScheme="brand"
        size="xs"
        onClick={onOpen}
        pt="20px"
        pb="20px"
        variant="ghost"
        fontSize="14px"
      >
        Edit
      </Button>

      <DrawerComponentCustom
        title="Edit a Purchase order"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        po
      >
        <PurchaseEditForm
          onClose={onClose}
          order={order}
          updatePurchaseOrders={updatePurchaseOrders}
        />
      </DrawerComponentCustom>
    </>
  );
}
