import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import DrawerComponentCustom from '../../../common/UIcomponents/CustomDrawer';
import Step from './PaymentStep/PaymentStep';
import { CheckIcon } from '@chakra-ui/icons';

export default function ReviewPaymentButton({
  suppliers,
  updateSuppliers,
  updateSupplierCode,
  supplierId,
  user,
  updateUser,
  withCheckmark,
  updateSelectedSupplierId,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {withCheckmark ? (
        <Button
          type="submit"
          colorScheme="brand"
          onClick={onOpen}
          size="sm"
          pt="20px"
          pb="20px"
          mt="20px"
          // _
          _hover={{ bg: '#000' }}
        >
          <CheckIcon mr="5px" />
          Confirm the payment data changes
        </Button>
      ) : (
        <Button
          type="submit"
          colorScheme="brand"
          size="sm"
          onClick={onOpen}
          pt="20px"
          pb="20px"
          // _
          _hover={{ bg: '#000' }}
        >
          Review the supplier payment data
        </Button>
      )}

      <DrawerComponentCustom
        title="Review supplier payment data"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        payment
      >
        {' '}
        <Step
          suppliers={suppliers}
          updateSuppliers={updateSuppliers}
          onClose={onClose}
          updateSupplierCode={updateSupplierCode}
          supplierId={supplierId}
          user={user}
          updateUser={updateUser}
          updateSelectedSupplierId={updateSelectedSupplierId}
        />
      </DrawerComponentCustom>
    </>
  );
}
