import React from 'react';
import UseCurrency from '../../custom-hooks/useCurrency';
import POContextProvider from '../../context/PurchaseOrder/FilterPO';
import { CurrencyProvider } from '../../context/currency/CurrencyContext';
import OrderDetails from '../OrderDetails';
import { PodLeadersProvider } from '../../context/podLeader/PodleadersContext';
import UsePodLeaders from '../../custom-hooks/usePodLeaders';

function SingleOrderPage() {
  return (
    <POContextProvider>
      <CurrencyProvider>
        <UseCurrency>
          <PodLeadersProvider>
            <UsePodLeaders>
              <OrderDetails />
            </UsePodLeaders>
          </PodLeadersProvider>
        </UseCurrency>
      </CurrencyProvider>
    </POContextProvider>
  );
}

export default SingleOrderPage;
