export function hasInvoicePayPal(invoice) {
  const { payPalAccount } = invoice;
  return payPalAccount !== null;
}

export function hasInvoiceBankAccount(invoice) {
  const { bankName } = invoice;
  return bankName !== null;
}

export function getPaymentDetailsFromInvoice(invoice) {
  if (invoice) {
    const {
      accountName,
      accountNumber,
      accountNumberOrIban,
      additionalReference,
      bankAddress,
      bankCode,
      bankName,
      bsbCode,
      country,
      iban,
      ifscCode,
      numberOfPendingInvoices,
      payPalAccount,
      paymentDetailsApproved,
      routingNumber,
      sortCode,
      swift,
      purchaseOrder,
    } = invoice;

    let data = {
      accountName,
      accountNumber,
      accountNumberOrIban,
      additionalReference,
      bankAddress,
      bankCode,
      bankName,
      bsbCode,
      country,
      iban,
      ifscCode,
      numberOfPendingInvoices,
      payPalAccount,
      paymentDetailsApproved,
      routingNumber,
      sortCode,
      swift,
      purchaseOrder,
    };
    if (
      invoice.paymentDetailsApproved === undefined ||
      invoice.paymentDetailsApproved === null
    ) {
      data = {
        ...data,
        paymentDetailsApproved: invoice.purchaseOrder.supplier.paymentDetailsApproved,
      };
    }

    data = {
      ...data,
      currency: invoice.purchaseOrder.supplier.currency,
    };

    return data;
  }

  return null;
}
