import React, { useContext, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import map from 'lodash/map';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  useToast,
} from '@chakra-ui/react';
import {
  formatAmount,
  getMessage,
  getUserFullAddress,
  getUserFullName,
} from '../../util';
import logo from '../../assets/images/Goat-logo-emails.png';
import { hasBankAccount, hasPayPal } from '../../util/supplier/helper';
import { HttpContext } from '../../context/HttpContext';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AddPaymentDetails from '../account/PaymentDetailsForm/AddPaymentDetailsButton';

export default function InvoiceCreate({ order, supplierPo, me }) {
  const [supplier, setSupplier] = useState(supplierPo);
  function updateSupplier(supplier) {
    setSupplier(supplier);
  }
  const currencySymbol =
    (order && order.supplier.currency && order.supplier.currency.symbol) || null;
  const descriptionOfWork = (order && order.descriptionOfWork) || null;
  const totalGross = (order && order.totalGross) || null;
  const totalNet = (order && order.totalNet) || null;
  const totalVat = (order && order.totalVat) || null;
  const number = (order && order.number) || null;
  const purchaseOrderItems = (order && order.purchaseOrderItems) || [];

  const toast = useToast();
  const history = useHistory();
  const { authAxios } = useContext(HttpContext);

  const validationSchema = Yup.object().shape({
    referenceNumber: Yup.string().max(8, 'Please enter 8 characters or less'),
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { errors, isSubmitting, isValid } = formState;

  const onSubmit = async (values) => {
    try {
      const submittedData = {
        ...values,
        purchaseOrder: order.id,
      };

      const { data } = await authAxios.post(`/invoices`, submittedData);
      if (data) {
        history.push(`/orders/${order.id}`);
        toast({
          position: 'top',
          description:
            'You have successfully submitted an invoice. You will be notified when the Goat team approves it.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      }
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
        setTimeout(function () {
          history.push('/orders');
        }, 3000);
      }
    }
  };

  return (
    <>
      <Box
        bg="#fff"
        borderRadius="md"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
        mt="7"
        p="10"
        pt="8"
      >
        <HStack justifyContent="space-between" alignItems="center" pb="10px">
          <Box display="flex" alignItems="center">
            <img style={{ height: '76px', marginRight: '20px' }} src={logo} alt="" />

            <Box>
              <Text fontSize="40px" fontWeight="bold" mb="0">
                Invoice
              </Text>
              {/*<Text color=" #868789" fontSize="14px">*/}
              {/*  Invoice number will be auto generated*/}
              {/*</Text>*/}
            </Box>
          </Box>
          <form>
            <Box display="flex" alignItems="center">
              {' '}
              <FormControl isInvalid={errors.referenceNumber} mb={0}>
                <FormLabel htmlFor="invoiceEditHtmlFor">
                  Reference number (optional)
                </FormLabel>
                <Input
                  isInvalid
                  name="referenceNumber"
                  id="referenceNumber"
                  focusBorderColor="black"
                  errorBorderColor="error.500"
                  placeholder="Type here"
                  {...register('referenceNumber')}
                />
                <FormErrorMessage>
                  {errors.referenceNumber?.message || errors.referenceNumber}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </form>
        </HStack>
        <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="30px" />
        <HStack spacing={10} mb={7} alignItems="flex-start">
          <Box width="550px">
            <Text fontSize="20px" mb="10px" fontWeight="bold">
              To
            </Text>
            <HStack alignItems="flex-start">
              <Box fontSize="14px">
                <Text mb="5px">Goat Solutions Ltd</Text>
                <Text mb="5px">Sea Containers</Text>
                <Text mb="5px">18 Upper Ground, London, SE1 9GL, UK</Text>
                <Text mb="5px">VAT no: GB 512 5421 86</Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <Text fontSize="20px" mb="10px" fontWeight="bold">
              From
            </Text>
            <HStack alignItems="flex-start">
              <Box fontSize="14px" mr="35px">
                <Text mb="5px">{getUserFullName(me)}</Text>
                <Text mb="5px">{getUserFullAddress(me)}</Text>
                <Text mb="5px">
                  VAT no:
                  {me?.vatNumber ? (
                    <Text as="span"> {me?.vatNumber}</Text>
                  ) : (
                    <Text as="span" color="#868789">
                      {' '}
                      No VAT registered
                    </Text>
                  )}
                </Text>
                <Link
                  color="brand.500"
                  as={RouterLink}
                  to={`/account?redirect=create-invoice/${order.id}&edit=personal-information`}
                  fontSize="14px"
                  display="block"
                  mb="6px"
                >
                  Edit
                </Link>
              </Box>
            </HStack>
          </Box>
        </HStack>

        <HStack spacing={10} mb={14} alignItems="flex-start">
          <Box width="550px">
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Description of work
                </Text>
                <Text color="#333" fontSize="14px" maxWidth="100%">
                  {descriptionOfWork}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Invoice date
                </Text>
                <Text color="#868789" fontSize="14px" maxWidth="100%">
                  will be auto generated
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Due date
                </Text>
                <Text color="#868789" fontSize="14px" maxWidth="100%">
                  will be auto generated
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Purchase order number
                </Text>
                <Text color="#868789" fontSize="14px" maxWidth="100%">
                  {number ? number : <Box color="#a9b1b4">pending</Box>}
                </Text>
              </Box>
            </HStack>
          </Box>
        </HStack>

        <Box>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th minWidth="500px" pl="0" pb="0" pr="0">
                  <Box color="#bdc1d0" fontSize="12px" fontWeight="700">
                    item title
                  </Box>
                </Th>
                <Th pl="0" pb="0">
                  <Box color="#bdc1d0" pb="0" fontSize="12px" fontWeight="700">
                    amount(net)
                  </Box>
                </Th>
                <Th pb="0" pl="0">
                  <Box
                    color="#bdc1d0"
                    pb="0"
                    pl="0"
                    fontSize="12px"
                    fontWeight="700"
                    lineHeight="1"
                    textAlign="left"
                  >
                    vat
                  </Box>
                </Th>
                <Th pl="10px" pb="0" pr="0">
                  <Box
                    color="#bdc1d0"
                    pb="0"
                    fontSize="12px"
                    fontWeight="700"
                    textAlign="left"
                  >
                    amount(gross)
                  </Box>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {order.purchaseOrderItems.length &&
                map(purchaseOrderItems, (purchase, index) => {
                  return (
                    <Tr key={index}>
                      <Td pl="0" p="10px">
                        {purchase.title}
                      </Td>
                      <Td p="10px" pl="0">
                        <Box>
                          {' '}
                          {currencySymbol}
                          {formatAmount(purchase.amount)}
                        </Box>
                      </Td>
                      <Td isNumeric p="10px" pl="0">
                        <Box>
                          {purchase.vatAmount ? (
                            <>
                              {' '}
                              {currencySymbol}
                              {formatAmount(purchase.vatAmount)}
                            </>
                          ) : (
                            <>{`${currencySymbol}0`}</>
                          )}
                        </Box>
                      </Td>
                      <Td isNumeric p="10px">
                        <Box>
                          {' '}
                          {currencySymbol}
                          {formatAmount(purchase.grossAmount)}
                        </Box>
                      </Td>
                    </Tr>
                  );
                })}
              <Tr>
                <Td pl="0" p="10px" />
                <Td pl="0" p="10px" />
                <Td p="10px" isNumeric>
                  <Box color="#868789">Sub-total (net) </Box>
                  <Box color="#868789">VAT sub-total</Box>
                  <Box color="#868789" mt="20px">
                    TOTAL (gross){' '}
                  </Box>
                </Td>

                <Td isNumeric p="10px">
                  {' '}
                  <Box textAlign="right">
                    {currencySymbol}
                    {formatAmount(totalNet)}
                  </Box>
                  <Box textAlign="right">
                    {currencySymbol}
                    {formatAmount(totalVat)}
                  </Box>
                  <Box fontSize="20px" fontWeight="bold" textAlign="right" mt="20px">
                    {currencySymbol}
                    {formatAmount(totalGross)}
                  </Box>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>

        <Box mt="30px">
          <Text fontSize="20px" mb="10px" fontWeight="bold">
            Payment details:
            {!supplier.paymentDetailsApproved && (
              <Text
                fontStyle="italic"
                fontSize="16px"
                color="#868789"
                fontWeight="400"
                display="inline-block"
                ml="3"
              >
                Processing
              </Text>
            )}
          </Text>
          <HStack alignItems="flex-start">
            {supplier && hasBankAccount(supplier) && (
              <>
                <Box color="#868789" fontSize="14px" mr="35px">
                  <Text mb="5px">Payment method:</Text>
                  <Text mb="5px">Bank name:</Text>
                  {supplier.bankAddress && <Text mb="5px">Bank address:</Text>}
                  <Text mb="5px">Country:</Text>
                  {supplier?.accountName && <Text mb="5px">Account name:</Text>}
                  {supplier.accountNumber && <Text mb="5px">Account number:</Text>}
                  {supplier.accountNumberOrIban && (
                    <Text mb="5px">Account number or IBAN:</Text>
                  )}
                  {supplier.bankCode && <Text mb="5px">Bank code:</Text>}
                  {supplier.bsbCode && <Text mb="5px">BSB code:</Text>}
                  {supplier.iban && <Text mb="5px">IBAN:</Text>}
                  {supplier.ifscCode && <Text mb="5px">IFSC code:</Text>}
                  {supplier.routingNumber && <Text mb="5px">Routing number:</Text>}
                  {supplier.sortCode && <Text mb="5px">Sort code:</Text>}
                  {supplier.swift && <Text mb="5px">SWIFT code:</Text>}
                  {supplier.additionalReference && (
                    <Text mb="5px">Additional reference:</Text>
                  )}
                  {/*<Link*/}
                  {/*  color="brand.500"*/}
                  {/*  as={RouterLink}*/}
                  {/*  to={`/account`}*/}
                  {/*  fontSize="14px"*/}
                  {/*  d="block"*/}
                  {/*  mb="6px"*/}
                  {/*>*/}
                  {/*  Edit*/}
                  {/*</Link>*/}
                  <Box marginLeft="-18px">
                    {supplier.paymentDetailsApproved && (
                      <AddPaymentDetails
                        edit
                        supplier={supplier}
                        updateSupplier={updateSupplier}
                      />
                    )}
                  </Box>
                </Box>
                <Box alignItems="flex-start" fontSize="14px">
                  <Text mb="5px">Bank transfer</Text>
                  <Text mb="5px">{supplier.bankName}</Text>
                  {supplier.bankAddress && <Text mb="5px">{supplier.bankAddress}</Text>}
                  <Text mb="5px">{supplier.country?.name}</Text>
                  {supplier?.accountName && <Text mb="5px">{supplier.accountName}</Text>}
                  {supplier.accountNumber && (
                    <Text mb="5px">{supplier.accountNumber}</Text>
                  )}
                  {supplier.accountNumberOrIban && (
                    <Text mb="5px">{supplier.accountNumberOrIban}</Text>
                  )}
                  {supplier.bankCode && <Text mb="5px">{supplier.bankCode}</Text>}
                  {supplier.bsbCode && <Text mb="5px">{supplier.bsbCode}</Text>}
                  {supplier.iban && <Text mb="5px">{supplier.iban}</Text>}
                  {supplier.ifscCode && <Text mb="5px">{supplier.ifscCode}</Text>}
                  {supplier.routingNumber && (
                    <Text mb="5px">{supplier.routingNumber}</Text>
                  )}
                  {supplier.sortCode && <Text mb="5px">{supplier.sortCode}</Text>}
                  {supplier.swift && <Text mb="5px">{supplier.swift}</Text>}
                  {supplier.additionalReference && (
                    <Text mb="5px">{supplier.additionalReference}</Text>
                  )}
                </Box>
              </>
            )}
            {supplier && hasPayPal(supplier) && (
              <>
                <Box color="#868789" fontSize="14px" mr="35px">
                  <Text mb="5px">Payment method:</Text>
                  <Text mb="5px">Email address:</Text>
                  {/*<Link*/}
                  {/*  color="brand.500"*/}
                  {/*  as={RouterLink}*/}
                  {/*  to={`/account`}*/}
                  {/*  fontSize="14px"*/}
                  {/*  d="block"*/}
                  {/*  mb="6px"*/}
                  {/*>*/}
                  {/*  Edit*/}
                  {/*</Link>*/}
                  <Box marginLeft="-18px">
                    {supplier.paymentDetailsApproved && (
                      <AddPaymentDetails
                        edit
                        supplier={supplier}
                        updateSupplier={updateSupplier}
                      />
                    )}
                  </Box>
                </Box>
                <Box alignItems="flex-start" fontSize="14px">
                  <Text mb="5px">PayPal</Text>
                  <Text mb="5px">{supplier.payPalAccount}</Text>
                </Box>
              </>
            )}
            {supplier && !hasPayPal(supplier) && !hasBankAccount(supplier) && (
              <>
                <Text color="#868789">No information yet.</Text>
              </>
            )}
          </HStack>
        </Box>
      </Box>
      <Box mt="20px" mb="30px" float="right">
        <Button
          type="submit"
          colorScheme="brand"
          size="lg"
          fontSize="16px"
          fontWeight="500"
          isFullWidth
          isLoading={isSubmitting}
          textTransform="initial"
          _hover={{ bg: '#000' }}
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Send invoice
        </Button>
      </Box>
    </>
  );
}
