import React, { useContext, useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { HttpContext } from '../../context/HttpContext';
import first from 'lodash/first';
import { isFunction } from 'lodash';

export default function WFile({ userId, label, updateUploadedWFile }) {
  const [href, setHref] = useState('');
  const { authAxios } = useContext(HttpContext);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await authAxios.get(`files/suppliers/${userId}?fileType=wFile`);
        const wFilePath = first(res.data)?.wFilePath ?? null;
        if (wFilePath) {
          if (isFunction(updateUploadedWFile)) {
            updateUploadedWFile(wFilePath);
          }
          setHref(wFilePath);
        }
      } catch (onError) {
        console.log(onError);
      }
    };

    getData();
  }, [authAxios, userId]);

  return (
    <Text mb="5px" color="brand.400">
      {href && (
        <a href={href} target="_blank" rel="noreferrer">
          {label ? label : 'Download file'}
        </a>
      )}
    </Text>
  );
}
