import React, { useContext, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Button,
  FormLabel,
  FormControl,
  Select,
  HStack,
  MenuGroup,
  useDisclosure,
  Badge,
  Box,
  Input,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import map from 'lodash/map';
import { statusOptionsForFilterPO } from '../../../util';
import { FilterIcon } from '../../../assets/iconsComponents/filterIcon';
import { POContext } from '../../../context/PurchaseOrder/FilterPO';
import FilterDateApproved from './POFilterDateApproved';
import moment from 'moment';
import FilterPOSelectCurrency from '../../../common/UIcomponents/FilterSelectPOCurrency';
import { REGEX_IS_CURRENCY_NUMBER } from '../../../util/regExr';
import FilterSelectPodLeader from '../../../common/UIcomponents/FilterSelectPodLeader';

function getFromTo(fromAmount, toAmount) {
  let from = parseFloat(fromAmount);
  let to = parseFloat(toAmount);

  if (from > to) {
    const temp = from;
    from = to;
    to = temp;
  }

  if (from < 0.01) {
    from = 0.01;
  }
  if (to < 0.01) {
    to = 0.01;
  }

  return { from, to };
}

export default function FilterOrders({
  clearAllFilters,
  updateFilterStatus,
  updateFilterPodLeader,
  updateFilterCurrency,
  updateFilterStartBetween,
  updateFilterEndBetween,
  updateGrossAmountBetween,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { filterStatus, setFilterStatus } = useContext(POContext);
  const { filterCurrency, setFilterCurrency } = useContext(POContext);
  const { filterStartFrom, setFilterStartFrom } = useContext(POContext);
  const { filterStartTo, setFilterStartTo } = useContext(POContext);
  const { filterEndFrom, setFilterEndFrom } = useContext(POContext);
  const { filterEndTo, setFilterEndTo } = useContext(POContext);
  const { filterStartFromAmount, setFilterStartFromAmount } = useContext(POContext);
  const { filterStartToAmount, setFilterStartToAmount } = useContext(POContext);
  const { filterPodLeader, setFilterPodLeader } = useContext(POContext);

  const [errors, setErrors] = useState([]);
  const [values, setValues] = useState([]);

  const numberOfFilters =
    (filterStartFrom && filterStartTo ? 1 : 0) +
    (filterStatus !== 'none') +
    (filterPodLeader && filterPodLeader !== 0 ? 1 : 0) +
    (filterCurrency && filterCurrency !== 0 ? 1 : 0) +
    (filterEndFrom && filterEndTo ? 1 : 0) +
    (filterStartFromAmount &&
    filterStartToAmount &&
    !(errors?.grossAmountFrom || errors?.grossAmountTo)
      ? 1
      : 0);

  const handleChangeStatus = (e) => {
    setFilterStatus(e.target.value);
  };
  const updateCurrencyId = (id) => {
    setFilterCurrency(id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (filterEndFrom && filterEndTo) {
      const dateApprovedFromUTC = moment(filterEndFrom).format('YYYY-MM-DDT00:00:00Z');
      const dateApprovedToUTC = moment(filterEndTo).format('YYYY-MM-DDT23:59:59Z');
      const uri = `&dateApprovedFrom=${encodeURIComponent(
        dateApprovedFromUTC
      )}&dateApprovedTo=${encodeURIComponent(dateApprovedToUTC)}`;
      // const dateEndFrom = moment(filterEndFrom).format("YYYY-MM-DD");
      // const dateEndTo = moment(filterEndTo).format("YYYY-MM-DD");
      updateFilterEndBetween(uri);
    }

    if (filterStatus) {
      if (filterStatus === 'none') {
        updateFilterStatus('');
      } else {
        updateFilterStatus(`&status=${filterStatus}`);
      }
    }

    if (filterCurrency) {
      updateFilterCurrency(`&currency=${filterCurrency}`);
    } else if (filterCurrency === 0) {
      updateFilterCurrency('');
    }

    if (filterStartFromAmount && filterStartToAmount) {
      const { from, to } = getFromTo(filterStartFromAmount, filterStartToAmount);

      updateGrossAmountBetween(`&grossFrom=${from}&grossTo=${to}`);
    }

    if (filterPodLeader) {
      updateFilterPodLeader(`&podLeader=${filterPodLeader}`);
    } else if (filterPodLeader === 0) {
      updateFilterPodLeader('');
    }

    onClose();
  };

  function handleClearGrossAmount(e) {
    e.preventDefault();
    setFilterStartFromAmount('');
    setFilterStartToAmount('');
    setErrors([]);
  }
  const handleClearAll = (e) => {
    e.preventDefault();
    setFilterStatus('none');
    setFilterPodLeader(0);
    setFilterCurrency(0);
    setFilterStartFrom('');
    setFilterStartTo('');
    setFilterEndFrom('');
    setFilterEndTo('');
    updateFilterStatus('');
    updateFilterCurrency('');
    updateFilterStartBetween('');
    updateFilterEndBetween('');
    setFilterStartFromAmount('');
    setFilterStartToAmount('');
    clearAllFilters();
    setErrors([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('grossAmountFrom' in fieldValues) {
      // const regExp1 = /[0-9]|\./;
      // const regExp2 = /^[a-zA-Z.,;:|\\\/~!@#$%^&*_-{}\[\]()`"'<>?\s]+$/;
      // const regExp3 = /^\s*|[0-9][0-9]*/;
      // const regExp4 = /^\d+\.\d{0,2}$/;
      // const regExp5 = /^(\s*|\d+)$/; //positive numbers and allow empty string
      // https://forums.asp.net/t/1909870.aspx?Regular+expression+to+allow+a+number+upto+2+decimal+places
      // const regExp6 = /^\d+(\.\d{1,2})?$/; //positive numbers and allow empty string
      // temp.grossAmountFrom = regExp6.test(fieldValues.grossAmountFrom)
      //   ? ""
      //   : "Only numerical input is allowed, if decimal number is needed please use . to separate numbers (e.g. 3.14).";
      if (fieldValues.grossAmountFrom === '') {
        if (filterStartToAmount === '') {
          temp.grossAmountTo = '';
          temp.grossAmountFrom = '';
        } else {
          temp.grossAmountFrom = 'This is mandatory field';
        }
      } else if (REGEX_IS_CURRENCY_NUMBER.test(fieldValues.grossAmountFrom)) {
        temp.grossAmountFrom = '';
      } else {
        temp.grossAmountFrom =
          'Only numerical input is allowed, if decimal number is needed please use . to separate numbers (e.g. 3.14).';
      }
    }

    if ('grossAmountTo' in fieldValues) {
      // const regExp6 = /^\d+(\.\d{1,2})?$/;
      if (fieldValues.grossAmountTo === '') {
        if (filterStartFromAmount === '') {
          temp.grossAmountTo = '';
          temp.grossAmountFrom = '';
        } else {
          temp.grossAmountTo = 'This is mandatory field'; // This is mandatory field
        }
      } else if (REGEX_IS_CURRENCY_NUMBER.test(fieldValues.grossAmountTo)) {
        temp.grossAmountTo = '';
      } else {
        temp.grossAmountTo =
          'Only numerical input is allowed, if decimal number is needed please use . to separate numbers (e.g. 3.14).';
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x.toString() === '');
  };
  function handleChangeGrossAmountFrom(e) {
    const { name, value } = e.target;

    validate({ [name]: value });
    setFilterStartFromAmount(value);
  }

  function handleChangeGrossAmountTo(e) {
    const { name, value } = e.target;

    validate({ [name]: value });
    setFilterStartToAmount(value);
  }

  const updatePodLeaderId = (id) => {
    setFilterPodLeader(id);
  };

  return (
    <Menu>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            as={Button}
            bg="white"
            borderRadius="8px"
            fontSize="14px"
            fontWeight="500"
            border="1px solid #e8e9e9"
            pt="18px"
            pb="18px"
            onClick={onOpen}
            _expanded={{ bg: 'black', color: 'white' }}
            _focus={{ boxShadow: 'none' }}
            leftIcon={
              numberOfFilters ? (
                <Badge
                  bg="#eb7f24"
                  borderRadius="50%"
                  color="white"
                  h="23px"
                  w="24px"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {numberOfFilters}
                </Badge>
              ) : (
                <FilterIcon width="17px" color="#eb7f24" height="19px" />
              )
            }
          >
            Filters
          </MenuButton>
          <MenuList
            onClose={onClose}
            minWidth="360px"
            p="23px"
            boxShadow=" 0 2px 10px rgba(29, 44, 45, 0.18)"
            boundary="preventOverflow"
            flip="true"
            matchWidth
          >
            <MenuOptionGroup title="" type="checkbox" matchWidth closeOnSelect={true}>
              <FormControl mb={[4, 4, 8]}>
                <FormLabel htmlFor="emailHtmlFor" fontSize="14px">
                  Status
                </FormLabel>
                <Select
                  bg="#ebecf0"
                  h="48px"
                  onChange={handleChangeStatus}
                  value={filterStatus}
                >
                  {map(statusOptionsForFilterPO(), (option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mb={[4, 4, 8]}>
                <FormLabel htmlFor="emailHtmlFor" fontSize="14px">
                  Pod Leader
                </FormLabel>
                <FilterSelectPodLeader
                  updatePodLeaderId={updatePodLeaderId}
                  podLeaderId={filterPodLeader}
                />
              </FormControl>

              <FormControl mb={[4, 4, 8]}>
                <FormLabel htmlFor="emailHtmlFor" fontSize="14px">
                  Currency
                </FormLabel>
                <FilterPOSelectCurrency updateCurrencyId={updateCurrencyId} />
              </FormControl>
              <Box fontSize="16px" fontWeight="700" mb="10px">
                Date approved is between
              </Box>
              <FilterDateApproved />
              <Box fontSize="16px" fontWeight="700" mb="10px" mt="30px">
                Gross amount is between
              </Box>
              {(errors?.grossAmountFrom || errors?.grossAmountTo) && (
                <Box mb="4px">
                  <Button
                    onClick={(event) => {
                      handleClearGrossAmount(event);
                      // onClose();
                    }}
                    variant="ghost"
                    size="sm"
                    fontSize="14px"
                  >
                    Clear Gross amounts
                  </Button>
                </Box>
              )}
              <HStack spacing={0}>
                <FormControl maxWidth="140px">
                  <Input
                    isInvalid
                    value={filterStartFromAmount}
                    name="grossAmountFrom"
                    id="grossAmountFrom"
                    placeholder=""
                    focusBorderColor="black"
                    errorBorderColor="error.500"
                    onChange={handleChangeGrossAmountFrom}
                  />
                  {errors?.grossAmountFrom ? (
                    <Box color="error.500" fontSize="13px">
                      <Tooltip
                        bg="error.500"
                        // color="brand.50"
                        // color="white"
                        label={errors.grossAmountFrom}
                        aria-label="Err 1 tooltip"
                      >
                        <Text isTruncated maxWidth="120px">
                          {errors.grossAmountFrom}
                        </Text>
                      </Tooltip>
                    </Box>
                  ) : null}
                </FormControl>
                <Box as="span" pl="10px" pr="14px" color="#868789">
                  and
                </Box>
                <FormControl maxWidth="140px" ml="0">
                  <Input
                    isInvalid
                    value={filterStartToAmount}
                    name="grossAmountTo"
                    id="grossAmountTo"
                    placeholder=""
                    focusBorderColor="black"
                    errorBorderColor="error.500"
                    onChange={handleChangeGrossAmountTo}
                    m="0"
                  />
                  {errors?.grossAmountTo ? (
                    <Box color="error.500" fontSize="13px">
                      <Tooltip
                        bg="error.500"
                        // color="brand.50"
                        // color="white"
                        label={errors.grossAmountTo}
                        aria-label="Err 1 tooltip"
                      >
                        <Text isTruncated maxWidth="120px">
                          {errors.grossAmountTo}
                        </Text>
                      </Tooltip>
                    </Box>
                  ) : null}
                </FormControl>
              </HStack>
            </MenuOptionGroup>
            <MenuGroup closeOnSelect={true} onClose>
              <HStack justifyContent="space-between" mt="30px">
                <Button
                  onClick={(event) => {
                    handleClearAll(event);
                    onClose();
                  }}
                  variant="ghost"
                  size="sm"
                  fontSize="14px"
                >
                  Clear all
                </Button>{' '}
                <Button
                  size="sm"
                  fontSize="14px"
                  colorScheme="brand"
                  textTransform="initial"
                  mr="10px"
                  disabled={errors?.grossAmountFrom || errors?.grossAmountTo}
                  onClick={(event) => {
                    handleSubmit(event);
                    onClose();
                  }}
                >
                  Apply and close
                </Button>
              </HStack>
            </MenuGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
}
