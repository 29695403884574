import React, { createContext, useState } from 'react';

export const CampaignContext = createContext();

const CampaignContextProvider = (props) => {
  const [filterStatus, setFilterStatus] = useState('none');
  const [filterApprovalStatus, setFilterApprovalStatus] = useState('none');
  const [filterCurrency, setFilterCurrency] = useState('');
  const [filterPodLeader, setFilterPodLeader] = useState('');
  const [filterStartFrom, setFilterStartFrom] = useState('');
  const [filterStartTo, setFilterStartTo] = useState('');
  const [filterEndFrom, setFilterEndFrom] = useState('');
  const [filterEndTo, setFilterEndTo] = useState('');

  const [filterTo, setFilterTo] = useState('');
  const [filterFrom, setFilterFrom] = useState('');

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const [fromStart, setFromStart] = useState('');
  const [toStart, setToStart] = useState('');

  const [fromEnd, setFromEnd] = useState('');
  const [toEnd, setToEnd] = useState('');
  return (
    <CampaignContext.Provider
      value={{
        filterStatus,
        setFilterStatus,
        filterApprovalStatus,
        setFilterApprovalStatus,
        filterCurrency,
        setFilterCurrency,
        filterPodLeader,
        setFilterPodLeader,
        filterStartFrom,
        setFilterStartFrom,
        filterStartTo,
        setFilterStartTo,
        filterEndFrom,
        setFilterEndFrom,
        filterEndTo,
        setFilterEndTo,
        filterFrom,
        setFilterFrom,
        filterTo,
        setFilterTo,
        from,
        setFrom,
        to,
        setTo,
        fromStart,
        setFromStart,
        toStart,
        setToStart,
        fromEnd,
        setFromEnd,
        toEnd,
        setToEnd,
      }}
    >
      {props.children}
    </CampaignContext.Provider>
  );
};

export default CampaignContextProvider;
