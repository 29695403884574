import React from 'react';
export const InfluencerIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    {...props}
  >
    <path
      d="M12.004,16.828c1.711,0,3.099-1.387,3.099-3.1c0-1.711-1.388-3.099-3.099-3.099c-1.712,0-3.1,1.388-3.1,3.099l0,0
   C8.907,15.44,10.293,16.826,12.004,16.828z M12.004,11.461c1.251,0,2.268,1.015,2.268,2.267c0,1.253-1.017,2.268-2.268,2.268
   c-1.252,0-2.268-1.015-2.268-2.268c0.004-1.249,1.018-2.261,2.268-2.263V11.461z"
    />
    <path
      d="M12.12,17.942c-3.116,0-5.644,2.524-5.646,5.642V24h11.288v-0.416C17.76,20.469,15.234,17.944,12.12,17.942z M7.324,23.168
   c0.219-2.647,2.542-4.616,5.189-4.397c2.344,0.193,4.206,2.053,4.399,4.397H7.324z"
    />
    <rect x="15.7" y="3.877" width="6.288" height="0.601" />
    <rect x="18.435" y="5.968" width="3.554" height="0.6" />
    <path
      d="M23.743,8.702V1.74H13.31v6.962h1.132v2.658l3.837-2.658H23.743z M15.063,10.214V8.102h-1.149V2.344h9.229v5.758H18.08
   L15.063,10.214z"
    />
    <path d="M15.621,17.703l0.558,0.601c2.126-1.935,2.645-5.078,1.25-7.594l-0.724,0.408C17.92,13.299,17.471,16.028,15.621,17.703z" />
    <path
      d="M12.004,8.373V7.542C8.581,7.543,5.807,10.32,5.81,13.744c0.001,1.792,0.778,3.496,2.129,4.673l0.546-0.63
   c-2.229-1.954-2.452-5.345-0.499-7.574c1.015-1.158,2.478-1.824,4.018-1.829V8.373z"
    />
    <path
      d="M21.096,13.736c0-0.058,0-0.116,0-0.17s0-0.025,0-0.036c-0.025-1.281-0.323-2.541-0.874-3.696l-0.753,0.362
   c0.137,0.278,0.258,0.564,0.361,0.857l0,0c0.277,0.804,0.427,1.647,0.445,2.499v0.032c0,0.051,0,0.105,0,0.156
   c0.002,2.306-0.962,4.507-2.658,6.068l0.535,0.607c1.866-1.719,2.929-4.142,2.926-6.68H21.096z"
    />
    <path
      d="M4.503,17.193C2.596,13.05,4.409,8.144,8.553,6.236c1.082-0.498,2.259-0.756,3.451-0.756V4.648
   c-5.02-0.003-9.091,4.064-9.093,9.083c-0.001,2.614,1.123,5.103,3.086,6.829l0.549-0.625C5.681,19.175,4.985,18.24,4.503,17.193z"
    />
    <path
      d="M2.16,18.98c-2.896-5.433-0.841-12.184,4.591-15.08c1.616-0.862,3.421-1.312,5.253-1.311V1.758
   C5.385,1.76,0.02,7.128,0.022,13.747c0,1.961,0.482,3.893,1.403,5.623c0.897,1.676,2.176,3.115,3.733,4.203l0.477-0.683
   C4.186,21.878,2.995,20.538,2.16,18.98z"
    />
    <path
      d="M23.638,16.618c0.224-0.883,0.345-1.787,0.362-2.697c0-0.061,0-0.121,0-0.181c0-0.061,0-0.134,0-0.221
   c0-0.025,0-0.048,0-0.072c0-0.026,0-0.102,0-0.151c0-0.051,0-0.055,0-0.08s0-0.108,0-0.167c0-0.057,0-0.076,0-0.112
   c0-0.037,0-0.094,0-0.142v-0.111v-0.102v-0.119v-0.087c0-0.077-0.01-0.155-0.029-0.234v-0.072l-0.018-0.13v-0.08l-0.021-0.13v-0.076
   c-0.013-0.074-0.025-0.148-0.04-0.22v-0.091l-0.024-0.115l-0.022-0.101l-0.024-0.109l-0.03-0.119
   c-0.085-0.343-0.183-0.681-0.297-1.017l0,0l-0.788,0.268c0.096,0.287,0.183,0.576,0.258,0.868l0,0v0.054l0.031,0.141l0.019,0.087
   l0.024,0.108l0.02,0.094l0.021,0.115l0.029,0.16v0.097v0.109v0.087v0.112v0.087c0,0.062,0.007,0.126,0.021,0.191v0.094v0.105v0.101
   v0.098c0,0.047,0,0.094,0,0.141s0,0.065,0,0.099c0,0.032,0,0.104,0,0.154v0.072c0,0.049,0,0.096,0,0.142c0,0.022,0,0.044,0,0.065
   s0,0.138,0,0.206c0.006,1.783-0.422,3.542-1.244,5.125c-0.789,1.527-1.918,2.854-3.299,3.877l0.492,0.673l0,0
   c1.493-1.098,2.715-2.522,3.569-4.167C23.08,18.411,23.413,17.53,23.638,16.618z"
    />
  </svg>
);
