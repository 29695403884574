import React, {
  useContext,
  useEffect,
  useState,
  // useRef,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import map from 'lodash/map';

import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
  SimpleGrid,
  Container,
  Radio,
  RadioGroup,
  Stack,
  Heading,
  Select,
  Text,
} from '@chakra-ui/react';

import { HttpContext } from '../../context/HttpContext';
import { AuthContext } from '../../context/AuthContext';
import { getBase64Promise, getMessage } from '../../util';
import UploadFiles from './uploadFiles';
import PaymentDetails from './PaymentDetails';

import { CountriesContext } from '../../context/country/CountriesContext';
import {
  statesUS,
  firstStateUS,
  getUSStateCountryName,
  getUSStateCountryCode,
} from '../../util/country';
import ScrollToTopOnMount from '../../util/scrollToTopOnMount';
import { useHistory } from 'react-router-dom';
import UploadedFile from '../vendors/UploadedFile';

function getUsFile(user) {
  if (user?.wFile) {
    if (user.entityType === 'us_person') {
      return user.wFile;
    }
  }

  return null;
}

function getForeignFile(user) {
  if (user?.wFile) {
    if (user.entityType === 'foreign_person') {
      return user.wFile;
    }
  }

  return null;
}

const SupplierAccountForm = ({ redirect }) => {
  const toast = useToast();
  const history = useHistory();
  const countriesContext = useContext(CountriesContext);

  const countyIdForUS = countriesContext.getCountyIdForUS();
  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);
  const user = auth.getUser();

  const {
    addressLine1,
    addressLine2,
    city,
    country,
    email,
    entityType,
    firstName,
    lastName,
    state,
    vatNumber,
    vatRegistered,
    zipCode,
  } = user;

  useEffect(() => {
    if (
      (addressLine1 === null || city === null || zipCode === null || country === null) &&
      !redirect
    ) {
      toast({
        position: 'top',
        description:
          'Please fill in your account information before submitting an invoice.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [addressLine1, city, country, toast, zipCode]);

  const [usFiles, setUsFiles] = useState([]);
  const [foreignFiles, setForeignFiles] = useState([]);
  const [usUploadedFile, setUsUploadedFile] = useState(getUsFile(user));
  const [uploadedNewUsFile, setUploadedNewUsFile] = useState(null);
  const [foreignUploadedFile, setForeignUploadedFile] = useState(getForeignFile(user));
  const [uploadedNewForeignFile, setUploadedNewForeignFile] = useState(null);

  const valueVat = vatRegistered ? '1' : '2';
  const valueAttachment = entityType === 'us_person' || entityType === null ? '1' : '2';
  const [countryId, setCountryId] = useState(country?.countryId);

  const [radioValueVat, setRadioValueVat] = useState(valueVat);
  const [radioValueAttachment, setRadioValueAttachment] = useState(valueAttachment);
  const [stateUS, setStateUS] = useState(getUSStateCountryCode(state));

  const isVatRegister = radioValueVat === '1';
  const isSelectedCountryUS = countyIdForUS === countryId;

  let validationSchema = Yup.object().shape({
    firstName: Yup.string().required('This is mandatory field'),
    lastName: Yup.string().required('This is mandatory field'),
    addressLine1: Yup.string().required('This is mandatory field'),
    addressLine2: Yup.string(),
    city: Yup.string().required('This is mandatory field'),
    zipCode: Yup.string().required('This is mandatory field'),
  });

  if (isVatRegister) {
    validationSchema = Yup.object().shape({
      firstName: Yup.string().required('This is mandatory field'),
      lastName: Yup.string().required('This is mandatory field'),
      vatNumber: Yup.string().required('This is mandatory field'),
      addressLine1: Yup.string().required('This is mandatory field'),
      addressLine2: Yup.string(),
      city: Yup.string().required('This is mandatory field'),
      zipCode: Yup.string().required('This is mandatory field'),
    });
  }
  const { register, handleSubmit, formState, watch, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { errors, isSubmitting, isDirty, isValid } = formState;

  const watchAddressLine1 = watch('addressLine1');

  const handleChangeCountry = (e) => {
    setValue('addressLine1', watchAddressLine1, { shouldDirty: true });

    const countryNum = parseInt(e.target.value, 10);
    if (countyIdForUS === countryNum) {
      setStateUS(firstStateUS);
    }
    setCountryId(countryNum);
  };

  const handleChangeStateUS = (e) => {
    setStateUS(e.target.value);
    setValue('addressLine1', watchAddressLine1, { shouldDirty: true });
  };

  const handleChangeValueAttachment = () => {
    setValue('addressLine1', watchAddressLine1, { shouldDirty: true });
    setRadioValueAttachment(radioValueAttachment === '1' ? '2' : '1');
  };

  const watchVatNumber = watch('vatNumber');

  async function getBase64(file) {
    return await getBase64Promise(file);
  }

  const onSubmit = async (values) => {
    try {
      let data = {
        firstName: values.firstName,
        lastName: values.lastName,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        zipCode: values.zipCode,
        country: countryId,
      };

      if (isVatRegister) {
        data = { ...data, vatRegistered: true, vatNumber: watchVatNumber };
      } else {
        data = { ...data, vatRegistered: false };
      }

      if (!isSelectedCountryUS) {
        const res = await authAxios.patch(`accounts/suppliers/me`, data);
        auth.setAuthUser(res.data);
        toast({
          position: 'top',
          description: 'Changes saved.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });

        setUploadedNewUsFile(null);
        setForeignFiles([]);
        setUsFiles([]);
        setUploadedNewForeignFile(null);
        setUsUploadedFile(null);
        setForeignUploadedFile(null);
        setRadioValueAttachment('1');

        reset();
        if (redirect) {
          history.push(redirect);
        }
        return;
      }

      data = {
        ...data,
        state: getUSStateCountryName(stateUS),
        entityType: radioValueAttachment === '1' ? 'us_person' : 'foreign_person',
      };

      if (uploadedNewUsFile && radioValueAttachment === '1') {
        const base64 = await getBase64(uploadedNewUsFile);
        const wFile = base64.split(',')[1];
        data = {
          ...data,
          wFile,
        };
      }

      if (uploadedNewForeignFile && radioValueAttachment === '2') {
        const base64 = await getBase64(uploadedNewForeignFile);
        const wFile = base64.split(',')[1];
        data = {
          ...data,
          wFile,
        };
      }

      const res = await authAxios.patch(`accounts/suppliers/me`, data);
      auth.setAuthUser(res.data);

      if (uploadedNewUsFile && radioValueAttachment === '1') {
        setUploadedNewUsFile(null);
        setForeignFiles([]);
        setUsFiles([]);
        setUploadedNewForeignFile(null);
        setUsUploadedFile(getUsFile(res.data));
        setForeignUploadedFile(null);
      }

      if (uploadedNewForeignFile && radioValueAttachment === '2') {
        setUploadedNewUsFile(null);
        setForeignFiles([]);
        setUsFiles([]);
        setUploadedNewForeignFile(null);
        setUsUploadedFile(null);
        setForeignUploadedFile(getForeignFile(res.data));
      }

      toast({
        position: 'top',
        description: 'Changes saved.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      reset();
      if (redirect) {
        history.push(redirect);
      }
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };

  function handleChangeRadioVat(e) {
    setValue('addressLine1', watchAddressLine1, { shouldDirty: true });
    setRadioValueVat(e);
  }

  function updateUsPersonUploadedFile(file) {
    setValue('addressLine1', watchAddressLine1, { shouldDirty: true });
    setUploadedNewUsFile(file);
  }

  function updateForeignPersonUploadedFile(file) {
    setValue('addressLine1', watchAddressLine1, { shouldDirty: true });
    setUploadedNewForeignFile(file);
  }

  function isExistUSFile() {
    // if (radioValueAttachment === '1' && usFiles.length > 0) {
    //   return true;
    // }
    if (radioValueAttachment === '1' && uploadedNewUsFile) {
      return true;
    }
    if (radioValueAttachment === '1' && usUploadedFile) {
      return true;
    }

    // if (radioValueAttachment === '2' && foreignFiles.length > 0) {
    //   return true;
    // }
    if (radioValueAttachment === '2' && uploadedNewForeignFile) {
      return true;
    }
    if (radioValueAttachment === '2' && foreignUploadedFile) {
      return true;
    }

    return false;
  }

  return (
    <>
      <ScrollToTopOnMount />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          bg="white"
          borderRadius="8px"
          boxShadow="0 1px 13px rgba(29, 44, 45, 0.12)"
          pt="30px"
          pb={4}
        >
          {/*<Box pr="30px" pl="30px" ref={refPersonalInformation}>*/}
          <Box pr="30px" pl="30px">
            <Heading variant="h4" fontSize="22px" fontWeight="bold" mt="0">
              Personal information
            </Heading>
            <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="40px" />
          </Box>
          <Container maxW="container.md">
            <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
              <Box>
                <FormControl isInvalid={errors.firstName} mb={[4, 4, 8]}>
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                  <Input
                    id="firstName"
                    defaultValue={firstName}
                    {...register('firstName')}
                  />
                  <FormErrorMessage>
                    {errors.firstName?.message || errors.firstName}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl isInvalid={errors.lastName} mb={[4, 4, 8]}>
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <Input
                    id="lastName"
                    defaultValue={lastName}
                    {...register('lastName')}
                  />
                  <FormErrorMessage>
                    {errors.lastName?.message || errors.lastName}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl isInvalid={errors.email} mb={[4, 4, 8]}>
                  <FormLabel htmlFor="email">Email address</FormLabel>
                  <Input disabled color="#c3c1c1" id="email" defaultValue={email} />
                  <FormErrorMessage>
                    {errors.email?.message || errors.email}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
            <Box fontWeight="700" mb={5}>
              VAT registered?
            </Box>
            <RadioGroup
              name="1"
              onChange={handleChangeRadioVat}
              value={radioValueVat}
              mb={8}
            >
              <Stack direction="column">
                <Radio
                  size="lg"
                  colorScheme="black"
                  _focus={{ outline: '0' }}
                  value="1"
                  alignItems="flex-start"
                >
                  <Box pl="0" fontSize="16px">
                    Yes
                  </Box>
                </Radio>
                <Radio
                  size="lg"
                  colorScheme="black"
                  _focus={{ outline: '0' }}
                  value="2"
                  alignItems="flex-start"
                >
                  <Box pl="0" fontSize="16px">
                    No
                  </Box>
                </Radio>
              </Stack>
            </RadioGroup>
            {radioValueVat === '1' && (
              <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
                <Box>
                  <FormControl isInvalid={errors.vatNumber} mb={[4, 4, 8]}>
                    <FormLabel htmlFor="vatNumber">VAT number</FormLabel>
                    <Input
                      id="vatNumber"
                      defaultValue={vatNumber}
                      placeholder="Enter VAT number"
                      textTransform="uppercase"
                      _placeholder={{ textTransform: 'initial' }}
                      {...register('vatNumber')}
                    />
                    <FormErrorMessage>
                      {errors.vatNumber?.message || errors.vatNumber}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </SimpleGrid>
            )}
          </Container>
          <Box pr="30px" pl="30px">
            <Heading variant="h4" fontSize="22px" fontWeight="bold" mt="0">
              Billing address
            </Heading>
            <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="40px" />
          </Box>
          <Container maxW="container.md">
            <Box>
              <FormControl isInvalid={errors.addressLine1} mb={[4, 4, 8]} mt={8}>
                <FormLabel htmlFor="addressLine1">Address line 1</FormLabel>
                <Input
                  id="addressLine1"
                  defaultValue={addressLine1}
                  placeholder="Enter address line 1"
                  {...register('addressLine1')}
                />
                <FormErrorMessage>
                  {errors.addressLine1?.message || errors.addressLine1}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box>
              <FormControl isInvalid={errors.addressLine2} mb={[4, 4, 8]} mt={8}>
                <FormLabel htmlFor="addressLine2">Address line 2 (optional)</FormLabel>
                <Input
                  id="addressLine2"
                  defaultValue={addressLine2}
                  placeholder="Enter address line 2"
                  {...register('addressLine2')}
                />
                <FormErrorMessage>
                  {errors.addressLine2?.message || errors.addressLine2}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
              <Box>
                <FormControl isInvalid={errors.city} mb={[4, 4, 8]}>
                  <FormLabel htmlFor="city">City</FormLabel>
                  <Input
                    id="city"
                    defaultValue={city}
                    placeholder="Enter city"
                    {...register('city')}
                  />
                  <FormErrorMessage>
                    {errors.city?.message || errors.city}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl isInvalid={errors.zipCode} mb={[4, 4, 8]}>
                  <FormLabel htmlFor="zipCode">ZIP / Post code</FormLabel>
                  <Input
                    id="zipCode"
                    defaultValue={zipCode}
                    placeholder="Enter ZIP / Post code"
                    {...register('zipCode')}
                  />
                  <FormErrorMessage>
                    {errors.zipCode?.message || errors.zipCode}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl mb={[4, 4, 8]}>
                  <FormLabel htmlFor="emailHtmlFor">Country</FormLabel>
                  <Select
                    bg="#ebecf0"
                    h="48px"
                    onChange={handleChangeCountry}
                    value={countryId}
                  >
                    {map(countriesContext.gatAllCountriesForSelect(), (country) => (
                      <option key={country.countryId} value={country.countryId}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {isSelectedCountryUS && (
                <Box>
                  <FormControl mb={[4, 4, 8]}>
                    <FormLabel htmlFor="emailHtmlFor">State</FormLabel>
                    <Select
                      bg="#ebecf0"
                      h="48px"
                      onChange={handleChangeStateUS}
                      value={stateUS}
                    >
                      {map(statesUS, (state) => (
                        <option key={state.code} value={state.code}>
                          {state.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </SimpleGrid>
            {isSelectedCountryUS && (
              <>
                <Box fontWeight="700" mb={5}>
                  Entity type
                </Box>
                <RadioGroup
                  onChange={handleChangeValueAttachment}
                  value={radioValueAttachment}
                  name="2"
                >
                  <Stack direction="column">
                    <Radio
                      size="lg"
                      x
                      colorScheme="black"
                      _focus={{ outline: '0' }}
                      value="1"
                      alignItems="flex-start"
                    >
                      <Box pl="0" fontSize="16px">
                        US Person (Sole Trader, LLC)
                      </Box>
                    </Radio>
                    <Radio
                      size="lg"
                      colorScheme="black"
                      _focus={{ outline: '0' }}
                      value="2"
                      alignItems="flex-start"
                    >
                      <Box pl="0" fontSize="16px">
                        Foreign Person (Individual - Non-resident in the United States of
                        America)
                      </Box>
                    </Radio>
                  </Stack>
                </RadioGroup>
                <Text fontWeight="bold" fontSize="16px" mt="40px">
                  {radioValueAttachment === '1' ? 'Attach W9 form' : 'Attach W8-BEN form'}
                </Text>
                {usUploadedFile && radioValueAttachment === '1' && !uploadedNewUsFile && (
                  <>
                    <UploadedFile
                      type="supplierWFile"
                      id={user.id}
                      label="Uploaded file"
                    />
                  </>
                )}
                {foreignUploadedFile &&
                  radioValueAttachment === '2' &&
                  !uploadedNewForeignFile && (
                    <>
                      <UploadedFile
                        type="supplierWFile"
                        id={user.id}
                        label="Uploaded file"
                      />
                    </>
                  )}
                {radioValueAttachment === '1' && (
                  <UploadFiles
                    updateUploadedFile={updateUsPersonUploadedFile}
                    files={usFiles}
                    setFiles={setUsFiles}
                  />
                )}
                {radioValueAttachment === '2' && (
                  <UploadFiles
                    updateUploadedFile={updateForeignPersonUploadedFile}
                    files={foreignFiles}
                    setFiles={setForeignFiles}
                  />
                )}
              </>
            )}
          </Container>
        </Box>
        <Box textAlign="center">
          <Button
            type="submit"
            size="lg"
            colorScheme="brand"
            disabled={
              isSubmitting ||
              !isDirty ||
              !isValid ||
              countryId === 0 ||
              countryId === undefined ||
              (isSelectedCountryUS && !isExistUSFile())
            }
            isLoading={isSubmitting}
            textTransform="initial"
            _hover={{ bg: '#000' }}
            borderRadius="10px"
            //disabled={disableForm}
            mt={12}
          >
            Save Personal information and Billing address
          </Button>
        </Box>
      </form>
      <Box
        bg="white"
        borderRadius="8px"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12)"
        pt="30px"
        pb="100px"
        mt={12}
      >
        {/*<Box pr="30px" pl="30px" ref={refPayments}>*/}
        <Box pr="30px" pl="30px">
          <Heading variant="h4" fontSize="22px" fontWeight="bold" mt="0">
            Payment details
          </Heading>
          <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="40px" />
        </Box>
        <Container maxW="container.md">
          <PaymentDetails user={user} />
        </Container>
      </Box>
    </>
  );
};
export default SupplierAccountForm;
