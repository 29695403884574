import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function Dashboard() {
  const auth = useContext(AuthContext);
  if (auth.isAdmin()) {
    return <Redirect to="/team" />;
  }
  return <Redirect to="/orders" />;
}

export default Dashboard;
