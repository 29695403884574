import first from 'lodash/first';
import {
  REGEX_HAS_AT_LEAST_ONE_NUMBER,
  REGEX_HAS_CONTAINS_BOTH_LOWER_AND_UPPERCASE_LETTERS,
  REGEX_HAS_SYMBOL,
  REGEX_LETTERS_AND_NUMBERS,
} from './regExr';
import moment from 'moment';
import { useLocation as ReactRouterLocation } from 'react-router-dom';

export function getMessage(onError) {
  const data = (onError.response && onError.response.data) || null;
  return (data && data.message) || null;
}

export function getMessageStatus(onError) {
  return (onError.response && onError.response.status) || null;
}

export function getErrors(onError) {
  return (
    (onError.response &&
      onError.response.data &&
      onError.response.data.errors &&
      onError.response.data.errors.children) ||
    null
  );
}

export function getErrorsFromArray(onError) {
  return (
    (onError.response &&
      onError.response.data &&
      onError.response.data.errors &&
      onError.response.data.errors.errors) ||
    []
  );
}

export const formatCurrency = (num, code) => {
  const symbol = getCurrencySymbol(code);
  const numberFormatted = addZerosToDecimalNumber(num);
  return `${symbol}${numberFormatted
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const formatAmount = (num) => {
  const numberFormatted = addZerosToDecimalNumber(num);
  return `${numberFormatted.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export function addZerosToDecimalNumber(value) {
  let newValue = value * 1; //removes trailing zeros
  newValue = newValue + ''; //casts it to string

  const pos = newValue.indexOf('.');
  if (pos === -1) {
    return newValue; // newValue = newValue + '.00';
  } else {
    let integer = newValue.substring(0, pos);
    let decimals = newValue.substring(pos + 1);
    while (decimals.length < 2) decimals = decimals + '0';
    newValue = integer + '.' + decimals;
  }
  return newValue;
}

export function hasLowerAndUpperCase(str) {
  const pattern = new RegExp(REGEX_HAS_CONTAINS_BOTH_LOWER_AND_UPPERCASE_LETTERS);

  return pattern.test(str);
}

export function hasDigit(str) {
  const pattern = new RegExp(REGEX_HAS_AT_LEAST_ONE_NUMBER);

  return pattern.test(str);
}

export function hasSymbol(str) {
  const pattern = new RegExp(REGEX_HAS_SYMBOL);

  return pattern.test(str);
}

export function hasLettersAndNumbers(str) {
  const pattern = new RegExp(REGEX_LETTERS_AND_NUMBERS);

  return pattern.test(str);
}

export function lengthAtLeast(str, num) {
  return str === undefined || num === undefined ? false : str.trim().length >= num;
}

export function capitalizeTheFirstLetter(str) {
  return str === undefined ? str : str.charAt(0).toUpperCase() + str.slice(1);
}

export function getCurrencyName(code) {
  if (code === undefined) return code;
  switch (code.toUpperCase()) {
    case 'GBP':
      return 'GBP - Pound Sterling';
    case 'USD':
      return 'USD - US Dollar';
    case 'EUR':
      return 'EUR - Euro';
    case 'ARS':
      return 'ARS - Argentinean Peso';
    case 'AUD':
      return 'AUD - Australian Dollar';
    case 'BRL':
      return 'BRL - Brazilian Real';
    case 'CAD':
      return 'CAD - Canadian Dollar';
    case 'DKK':
      return 'DKK - Danish Krone';
    case 'HKD':
      return 'HKD - Hong Kong Dollar';
    case 'INR':
      return 'INR - Indian Rupee';
    case 'IDR':
      return 'IDR - Indonesian Rupiah';
    case 'IRR':
      return 'IRR - Iranian Rial';
    case 'ILS':
      return 'ILS - Israeli Shekel';
    case 'JPY':
      return 'JPY - Japanese Yen';
    case 'MYR':
      return 'MYR - Malaysian Ringgit';
    case 'MXN':
      return 'MXN - Mexican Peso';
    case 'NZD':
      return 'NZD - New Zealand Dollar';
    case 'NOK':
      return 'NOK - Norwegian Krone';
    case 'PHP':
      return 'PHP - Philippine Peso';
    case 'RUB':
      return 'RUB - Russian Rouble';
    case 'SAR':
      return 'SAR - Saudi Arabian Riyal';
    case 'SEK':
      return 'SEK - Swedish Krona';
    case 'SGD':
      return 'SGD - Singapore Dollar';
    case 'ZAR':
      return 'ZAR - South African Rand';
    case 'KRW':
      return 'KRW - South Korean Won';
    case 'CHF':
      return 'CHF - Swiss Franc';
    case 'TWD':
      return 'TWD - Taiwanese Dollar';
    case 'THB':
      return 'THB - Thai Baht';
    case 'PLN':
      return 'PLN - Zloty';
    case 'QAT':
      return 'QAT - Qatari Rial';
    default:
      return code;
  }
}

export function getCurrencySymbol(code) {
  if (code === undefined) return code;
  switch (code.toUpperCase()) {
    case 'GBP':
      return '£';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    // case "ARS":
    //   return "ARS - Argentinean Peso";
    // case "AUD":
    //   return "AUD - Australian Dollar";
    // case "BRL":
    //   return "BRL - Brazilian Real";
    // case "CAD":
    //   return "CAD - Canadian Dollar";
    // case "DKK":
    //   return "DKK - Danish Krone";
    // case "HKD":
    //   return "HKD - Hong Kong Dollar";
    // case "INR":
    //   return "INR - Indian Rupee";
    // case "IDR":
    //   return "IDR - Indonesian Rupiah";
    // case "IRR":
    //   return "IRR - Iranian Rial";
    // case "ILS":
    //   return "ILS - Israeli Shekel";
    // case "JPY":
    //   return "JPY - Japanese Yen";
    // case "MYR":
    //   return "MYR - Malaysian Ringgit";
    // case "MXN":
    //   return "MXN - Mexican Peso";
    // case "NZD":
    //   return "NZD - New Zealand Dollar";
    // case "NOK":
    //   return "NOK - Norwegian Krone";
    // case "PHP":
    //   return "PHP - Philippine Peso";
    // case "RUB":
    //   return "RUB - Russian Rouble";
    // case "SAR":
    //   return "SAR - Saudi Arabian Riyal";
    // case "SEK":
    //   return "SEK - Swedish Krona";
    // case "SGD":
    //   return "SGD - Singapore Dollar";
    // case "ZAR":
    //   return "ZAR - South African Rand";
    // case "KRW":
    //   return "KRW - South Korean Won";
    // case "CHF":
    //   return "CHF - Swiss Franc";
    // case "TWD":
    //   return "TWD - Taiwanese Dollar";
    // case "THB":
    //   return "THB - Thai Baht";
    // case "PLN":
    //   return "PLN - Zloty";
    // case "QAT":
    //   return "QAT - Qatari Rial";
    default:
      return code;
  }
}

export function currenciesOptions() {
  return [
    { value: 'GBP', label: 'GBP - Pound Sterling' },
    { value: 'USD', label: 'USD - US Dollar' },
    { value: 'EUR', label: 'EUR - Euro' },
    { value: 'ARS', label: 'ARS - Argentinean Peso' },
    { value: 'AUD', label: 'AUD - Australian Dollar' },
    { value: 'BRL', label: 'BRL - Brazilian Real' },
    { value: 'CAD', label: 'CAD - Canadian Dollar' },
    { value: 'DKK', label: 'DKK - Danish Krone' },
    { value: 'HKD', label: 'HKD - Hong Kong Dollar' },
    { value: 'INR', label: 'INR - Indian Rupee' },
    { value: 'IDR', label: 'IDR - Indonesian Rupiah' },
    { value: 'IRR', label: 'IRR - Iranian Rial' },
    { value: 'ILS', label: 'ILS - Israeli Shekel' },
    { value: 'JPY', label: 'JPY - Japanese Yen' },
    { value: 'MYR', label: 'MYR - Malaysian Ringgit' },
    { value: 'MXN', label: 'MXN - Mexican Peso' },
    { value: 'NZD', label: 'NZD - New Zealand Dollar' },
    { value: 'NOK', label: 'NOK - Norwegian Krone' },
    { value: 'PHP', label: 'PHP - Philippine Peso' },
    { value: 'RUB', label: 'RUB - Russian Rouble' },
    { value: 'SAR', label: 'SAR - Saudi Arabian Riyal' },
    { value: 'SEK', label: 'SEK - Swedish Krona' },
    { value: 'SGD', label: 'SGD - Singapore Dollar' },
    { value: 'ZAR', label: 'ZAR - South African Rand' },
    { value: 'KRW', label: 'KRW - South Korean Won' },
    { value: 'CHF', label: 'CHF - Swiss Franc' },
    { value: 'TWD', label: 'TWD - Taiwanese Dollar' },
    { value: 'THB', label: 'THB - Thai Baht' },
    { value: 'PLN', label: 'PLN - Zloty' },
    { value: 'QAT', label: 'QAT - Qatari Rial' },
  ];
}

export function firstCurrencyValue() {
  const option = first(currenciesOptions());
  return option.value || null;
}

export function typeOptions() {
  return [
    { value: 'company', label: 'Company' },
    { value: 'individual', label: 'Individual' },
  ];
}

export function Countries() {
  return [
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ];
}

export function firstTypeValue() {
  const option = first(typeOptions());
  return option.value || null;
}

export function statusOptionsForEdit() {
  return [
    // { value: "initial", label: "Initial" },
    { value: 'active', label: 'Active' },
    { value: 'completed', label: 'Completed' },
    { value: 'suspend', label: 'Suspend' },
    { value: 'snag', label: 'Snag' },
  ];
}

export function statusOptionsForFilterPO() {
  return [
    { value: 'none', label: 'Select status' },
    { value: 'pending_approval', label: 'PO is pending approval (Pod Leader)' },
    { value: 'pending_approval_finance', label: 'PO is pending approval (Finance)' },
    { value: 'rejected', label: 'Purchase order is rejected' },
    { value: 'pending_repair', label: 'Purchase order is pending repair' },
    { value: 'awaiting_invoice', label: 'Awaiting invoice' },
    { value: 'invoice_approved', label: 'Invoice approved' },
    { value: 'invoice_paid', label: 'Invoice paid' },
    { value: 'invoice_pending_approval', label: 'Invoice is pending approval' },
    { value: 'invoice_pending_repair', label: 'Invoice is pending repair' },
    { value: 'invoice_rejected', label: 'Invoice rejected' },
  ];
}

export function firstStatusValueForEdit() {
  const option = first(statusOptionsForEdit());
  return option.value || null;
}

export function statusOptions() {
  return [
    { value: 'initial', label: 'Initial' },
    { value: 'active', label: 'Active' },
    { value: 'completed', label: 'Completed' },
    { value: 'suspend', label: 'Suspend' },
    { value: 'snag', label: 'Snag' },
  ];
}

export function firstStatusValue() {
  const option = first(statusOptions());
  return option.value || null;
}

export function statusOptionsForFilter() {
  return [
    { value: 'none', label: 'Select status' },
    { value: 'initial', label: 'Initial' },
    { value: 'active', label: 'Active' },
    { value: 'completed', label: 'Completed' },
    { value: 'suspend', label: 'Suspend' },
    { value: 'snag', label: 'Snag' },
    { value: 'rejected', label: 'Rejected' },
    // { value: 'finance_repair', label: 'Finance repair' },
    // { value: 'finance_rejected', label: 'Finance rejected' },
    // { value: 'finance_pending_approval', label: 'Finance pending approval' },
    // { value: 'pod_leader_repair', label: 'Pod Leader repair' },
    // { value: 'pod_leader_rejected', label: 'Pod Leader rejected' },
  ];
}

export function needToResetFilterApprovalStatus(statusOld, statusNew) {
  let main = ['active', 'completed', 'suspend', 'snag'];
  return !(main.includes(statusOld) && main.includes(statusNew));
}
export function approvalStatusOptionsForFilter(campaignStatus) {
  // return [
  //   { value: 'none', label: 'Select status' },
  //   { value: 'approved', label: 'Approved' },
  //   { value: 'pending_to_pod_leader', label: 'Pending to Pod Leader' },
  //   { value: 'pending_to_finance', label: 'Pending to Finance' }, //'Approved by Pod Leader'},
  //   { value: 'pod_leader_pending_repair', label: 'Pending Repair (Pod Leader)' },
  //   { value: 'finance_pending_repair', label: 'Pending Repair (Finance)' },
  //   { value: 'pod_leader_rejected', label: 'Rejected by Pod Leader' },
  //   { value: 'finance_rejected', label: 'Rejected by Finance' },
  //   { value: 'initial', label: 'Initial' },
  // ];
  // console.log({ campaignStatus })
  // if (!campaignStatus || campaignStatus === 'none'){
  //   return [
  //     { value: 'none', label: 'Select status' },
  //     { value: 'approved', label: 'Approved' },
  //     { value: 'pending_to_pod_leader', label: 'Pending to Pod Leader' },
  //     { value: 'pending_to_finance', label: 'Pending to Finance' }, //'Approved by Pod Leader'},
  //     { value: 'pod_leader_pending_repair', label: 'Pending Repair (Pod Leader)' },
  //     { value: 'finance_pending_repair', label: 'Pending Repair (Finance)' },
  //     { value: 'pod_leader_rejected', label: 'Rejected by Pod Leader' },
  //     { value: 'finance_rejected', label: 'Rejected by Finance' },
  //     { value: 'initial', label: 'Initial' },
  //   ];
  // }

  if (['active', 'completed', 'suspend', 'snag'].includes(campaignStatus)) {
    return [
      { value: 'none', label: 'Select status' },
      { value: 'approved', label: 'Approved' },
      { value: 'pending_to_pod_leader', label: 'Pending Pod Leader' },
      { value: 'pending_to_finance', label: 'Pending Finance' }, //'Approved by Pod Leader'},
      { value: 'pod_leader_pending_repair', label: 'Pending Repair (Pod Leader)' },
      { value: 'finance_pending_repair', label: 'Pending Repair (Finance)' },
      { value: 'initial', label: 'Initial' },
    ];
  }

  if (campaignStatus === 'rejected') {
    return [
      { value: 'none', label: 'Select status' },
      { value: 'pod_leader_rejected', label: 'Rejected by Pod Leader' },
      { value: 'finance_rejected', label: 'Rejected by Finance' },
    ];
  }
  if (campaignStatus === 'initial') {
    return [
      { value: 'none', label: 'Select status' },
      { value: 'pending_to_pod_leader', label: 'Pending Pod Leader' },
      { value: 'pending_to_finance', label: 'Pending Finance' }, //'Approved by Pod Leader'},
      { value: 'pod_leader_pending_repair', label: 'Pending Repair (Pod Leader)' },
      { value: 'finance_pending_repair', label: 'Pending Repair (Finance)' },
      { value: 'initial', label: 'Initial' },
    ];
  }

  return [
    { value: 'none', label: 'Select status' },
    { value: 'approved', label: 'Approved' },
    { value: 'pending_to_pod_leader', label: 'Pending Pod Leader' },
    { value: 'pending_to_finance', label: 'Pending Finance' }, //'Approved by Pod Leader'},
    { value: 'pod_leader_pending_repair', label: 'Pending Repair (Pod Leader)' },
    { value: 'finance_pending_repair', label: 'Pending Repair (Finance)' },
    { value: 'pod_leader_rejected', label: 'Rejected by Pod Leader' },
    { value: 'finance_rejected', label: 'Rejected by Finance' },
    { value: 'initial', label: 'Initial' },
  ];
}

export function purchaseOrderStatus() {
  return [
    // { value: "none", label: "Select status" },
    { value: 'pending_approval', label: 'Pending approval' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'pending_repair', label: 'Pending repair' },
    { value: 'awaiting_invoice', label: 'Awaiting invoice' },
    { value: 'invoice_pending_approval', label: 'Invoice is pending approval' },
    { value: 'invoice_paid', label: 'Invoice paid' },
  ];
}

export function filterStatusPaymentDetailsApproved() {
  return [
    { value: 'none', label: 'Select status' },
    { value: 'true', label: 'Approved' },
    { value: 'false', label: 'Not approved' },
  ];
}

export function statusOptionsForPaymentsFilter() {
  return [
    { value: 'none', label: 'Select status' },
    { value: 'invoice_approved', label: 'Invoice approved' },
    { value: 'invoice_paid', label: 'Invoice paid' },
  ];
}

export function getPOLabel(value) {
  if (value === undefined) {
    return value;
  }
  switch (value.toLowerCase()) {
    case 'pending_approval':
      return 'PO pending approval (Pod Leader)';
    case 'pending_approval_finance':
      return 'PO pending approval (Finance)';
    case 'rejected':
      return 'Purchase order rejected';
    case 'pending_repair':
      return 'Purchase order pending repair';
    case 'awaiting_invoice':
      return 'Awaiting invoice';
    case 'invoice_pending_approval':
      return 'Invoice pending approval';
    case 'invoice_approved':
      return 'Invoice approved';
    case 'invoice_paid':
      return 'Invoice paid';
    case 'invoice_rejected':
      return 'Invoice rejected';
    case 'invoice_pending_repair':
      return 'Invoice pending repair';
    default:
      return value;
  }
}

export function getTeamLabel(value) {
  if (value === undefined) {
    return value;
  }
  switch (value) {
    case true:
      return 'Active';
    case false:
      return 'Pending';
    default:
      return value;
  }
}

export function firstStatusValueForFilter() {
  const option = first(statusOptionsForFilter());
  return option.value || null;
}

export function getName(user) {
  if (user === undefined) return user;
  return user.officialRegisteredName
    ? user.officialRegisteredName
    : `${user.firstName} ${user.lastName}`;
}

export function getActivityDetail(activity) {
  const date = moment(activity.createdAt).format('DD MMM y,  HH:mm');
  const { author } = activity;
  return `${getAuthorName(author)} (${author.type} team), ${date}`;
}

export function getPOCommentContent(data) {
  const { comment } = data;
  return `"${comment}"`;
}

export function getPOCommentAuthor(data) {
  const { author } = data;
  return `${getAuthorName(author)} (${author.type} team)`;
}

export function getPOCommentDate(data) {
  const { createdAt } = data;
  const date = moment(createdAt).format('DD MMM y,  HH:mm');
  return `${date}`;
}
// export function getPOCommentAuthor(comment) {
//   const { author } = comment;
//   return `${getAuthorName(author)} (${author.type} team)`;
// }

export function getAuthorName(user) {
  return user.officialRegisteredName
    ? user.officialRegisteredName
    : `${user.firstName} ${user.lastName}`;

  // return name
}

export function getSupplierNameFromUser(user) {
  if (user) {
    return user.officialRegisteredName
      ? user.officialRegisteredName
      : `${user.firstName} ${user.lastName}`;
  }

  return null;
}

export function getFullNameFromUser(user) {
  if (user) {
    return user.officialRegisteredName
      ? user.officialRegisteredName
      : `${user.firstName} ${user.lastName}`;
  }

  return null;
}

export function getUserFullName(user) {
  if (user) {
    return user.officialRegisteredName
      ? user.officialRegisteredName
      : `${user.firstName} ${user.lastName}`;
  }

  return null;
}
export function getUserFullAddress(user) {
  if (user) {
    let address = '';
    if (user.addressLine1) {
      address = address + user.addressLine1;
      if (user.city) {
        address = address + ', ' + user.city;
      }
      if (user.zipCode) {
        address = address + ', ' + user.zipCode;
      }
      if (user.country) {
        address = address + ', ' + user.country.name;
      }
      if (user.state) {
        address = address + ', ' + user.state;
      }
      return address;
    }
  }

  return null;
}

export function getSupplierName(supplier) {
  if (supplier?.user) {
    return supplier.user.officialRegisteredName
      ? supplier.user.officialRegisteredName
      : `${supplier.user.firstName} ${supplier.user.lastName}`;
  }

  return '';
}

export function getSupplierEmail(supplier) {
  if (supplier?.user) {
    return supplier.user.email;
  }

  return '';
}

export function getSupplierType(supplier) {
  if (supplier?.user) {
    return supplier.user.subType;
  }

  return null;
}

export function getSupplierCurrencyName(supplier) {
  if (supplier?.currency) {
    return getCurrencyTitle(supplier.currency);
  }
  return '';
}
export function getCurrencyTitle(currency) {
  return `${currency.code} - ${currency.name}`;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export async function getBase64(file) {
  return await getBase64Promise(file);
}

export function getBase64Promise(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function getFilenameOrNull(str) {
  const index = str.lastIndexOf('/');
  if (index) {
    return str.substring(index + 1, str.length);
  }

  return null;
}

export function NewlineText(props) {
  const text = props.text;
  return text.split('\n').map((str) => <p>"{str}"</p>);
}

export function getUrlSearchParams() {
  return new URLSearchParams(ReactRouterLocation().search);
}

export function getDueDate(invoice) {
  switch (invoice.purchaseOrder.status) {
    case 'invoice_pending_approval':
    case 'invoice_pending_repair':
      return `will be auto generated`;
    case 'invoice_rejected':
      return `no due date`;
    default:
      return <>{moment(invoice.dueDate).format('DD MMM y')}</>;
  }
}
