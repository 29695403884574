import React, { useContext } from 'react';
import { Select } from '@chakra-ui/react';
import map from 'lodash/map';
import { PodLeadersContext } from '../../context/podLeader/PodleadersContext';

function FilterSelectPodLeader({ podLeaderId, updatePodLeaderId }) {
  const ctx = useContext(PodLeadersContext);
  if (!ctx) {
    return null;
  }
  function handleChangeOption(e) {
    updatePodLeaderId(parseInt(e.target.value, 10));
  }

  const items = map(ctx.getAll(), (item) => ({
    ...item,
    title: `${item.firstName} ${item.lastName}`,
  }));
  const filtersItems = [{ id: 0, title: 'Select pod leader' }, ...items];

  return (
    <Select bg="#ebecf0" h="48px" onChange={handleChangeOption} value={podLeaderId}>
      {map(filtersItems, ({ id, title }) => (
        <option key={id} value={id}>
          {title}
        </option>
      ))}
    </Select>
  );
}

export default FilterSelectPodLeader;
