import React from 'react';
import { useParams } from 'react-router-dom';

import PasswordSetupForm from '../components/auth/PasswordSetup/PaswordSetupForm';

const PasswordSetup = ({ location }) => {
  const { token } = useParams();
  const redirect = location.state?.previous;

  return <PasswordSetupForm token={token} redirect={redirect} />;
};

export default PasswordSetup;
