import React, { useContext, useEffect, useState } from 'react';
import { Heading, Container, HStack, Box } from '@chakra-ui/react';
import { HttpContext } from '../context/HttpContext';
import PaymentList from '../components/payments/PaymentList';
import Search from '../common/UIcomponents/SearchList';
import { usePaginator } from 'chakra-paginator';
import { AuthContext } from '../context/AuthContext';
import PaymentsTotalAndPending from '../components/payments/PaymentsTotalAndPending';
import SageRestartSync from '../components/vendors/SageRestartSync';
import FilterPayments from '../components/payments/FilterPaymentDropdown';
import { INITIAL_PAGE_SIZE } from '../util/consts';

export default function PortalPayments() {
  const clearedOrderBy = {
    totalGross: null,
    totalNet: null,
    totalVat: null,
    status: null,
    number: null,
    referenceNumber: null,
    fromName: null,
    date: null,
    dueDate: null,
    paymentDate: null,
  };
  const initialOrderBy = {
    totalGross: null,
    totalNet: null,
    totalVat: null,
    status: null,
    number: null,
    referenceNumber: null,
    fromName: null,
    date: null,
    dueDate: null,
    paymentDate: null,
  };

  const [state, setState] = useState({
    loading: true,
    status: 'idle',
    total: 0,
    paidPayments: [],
    pendingPayments: [],
    invoices: [],
    error: null,
    // orderBy: initialOrderBy
  });
  const { invoices, total, paidPayments, pendingPayments } = state;

  const [order, setOrder] = useState(initialOrderBy);
  const [term, setTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterDueDateBetween, setFilterDueDateBetween] = useState('');
  const [filterPaymentDateBetween, setFilterPaymentDateBetween] = useState('');

  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  const {
    pagesQuantity,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    offset, // you may not need this most of the times, but it's returned for you anyway
  } = usePaginator({
    total: total,
    initialState: {
      pageSize: INITIAL_PAGE_SIZE,
      currentPage: 1,
      isDisabled: false,
    },
  });

  function getSortingQuery(order) {
    if (order.totalGross) {
      return `&sort=totalGross&direction=${order.totalGross}`;
    }
    if (order.totalNet) {
      return `&sort=totalNet&direction=${order.totalNet}`;
    }
    if (order.totalVat) {
      return `&sort=totalVat&direction=${order.totalVat}`;
    }
    if (order.status) {
      return `&sort=status&direction=${order.status}`;
    }
    if (order.number) {
      return `&sort=number&direction=${order.number}`;
    }
    if (order.referenceNumber) {
      return `&sort=referenceNumber&direction=${order.referenceNumber}`;
    }
    if (order.fromName) {
      return `&sort=fromName&direction=${order.fromName}`;
    }
    if (order.date) {
      return `&sort=date&direction=${order.date}`;
    }
    if (order.dueDate) {
      return `&sort=dueDate&direction=${order.dueDate}`;
    }
    if (order.paymentDate) {
      return `&sort=paymentDate&direction=${order.paymentDate}`;
    }

    return '';
  }

  function getSearchQuery(term) {
    const trimmed = term.trim();
    if (trimmed.length > 0) {
      return `&search=${encodeURIComponent(term)}`;
    }
    return '';
  }

  useEffect(() => {
    const getPortalData = async () => {
      try {
        setState({
          ...state,
          status: 'pending',
        });
        const sort = getSortingQuery(order);
        const search = getSearchQuery(term);
        const res = await authAxios.get(
          `invoices?limit=${pageSize}&offset=${offset}${sort}${search}${filterStatus}${filterDueDateBetween}${filterPaymentDateBetween}`
        );
        setState({
          loading: false,
          invoices: res.data?.data ?? [],
          total: res.data?.meta?.total ?? 0,
          paidPayments: res.data?.meta?.paidPayments ?? [],
          pendingPayments: res.data?.meta?.pendingPayments ?? [],
          status: 'resolved',
        });
      } catch (onError) {
        console.log(onError);
        setState({
          ...state,
          status: 'rejected',
          loading: false,
          error: 'Something went wrong',
        });
      }
    };

    getPortalData();
  }, [
    authAxios,
    currentPage,
    pageSize,
    offset,
    order,
    term,
    filterStatus,
    filterDueDateBetween,
    filterPaymentDateBetween,
  ]);

  // TODO CLEANUP
  // function addPurchaseOrders(data) {
  //   const old = state.purchaseOrders;
  //   setCurrentPage(1);
  //   setState({
  //     ...state,
  //     purchaseOrders: [data, ...old],
  //   });
  //   toast({
  //     position: "top",
  //     description:
  //       "You have successfully requested the purchase order. You will be notified when the finance team approves it.",
  //     status: "success",
  //     duration: 6000,
  //     isClosable: true,
  //   });
  // }

  // function updateSupplier(supplier) {
  //   const newSuppliers = state.purchaseOrders;
  //   let recordIndex = newSuppliers.findIndex((x) => x.id === supplier.id);
  //   newSuppliers[recordIndex] = { ...supplier };
  //
  //   setState({
  //     ...state,
  //     purchaseOrders: newSuppliers,
  //   });
  //
  //   toast({
  //     position: "top",
  //     description: "You have successfully edit a purchase order.",
  //     status: "success",
  //     duration: 6000,
  //     isClosable: true,
  //   });
  // }

  function updateOrderBy(key, value) {
    setOrder({ ...clearedOrderBy, [key]: value });
    setCurrentPage(1);
  }

  function updateFilterStatus(status) {
    setFilterStatus(status);
    setCurrentPage(1);
  }

  function updateFilterDueDateBetween(between) {
    setFilterDueDateBetween(between);
    setCurrentPage(1);
  }

  function updateFilterPaymentDateBetween(between) {
    setFilterPaymentDateBetween(between);
    setCurrentPage(1);
  }

  function clearAllFilters() {
    setFilterStatus('');
    setFilterDueDateBetween('');
    setFilterPaymentDateBetween('');
    setCurrentPage(1);
  }

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);
  };

  const handlePageSizeChange = (event) => {
    const pageSize = Number(event.target.value);
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  function updateTerm(term) {
    setCurrentPage(1);
    setTerm(term);
  }

  return (
    <Container w="100%" maxWidth="100%" pt={4} pr={10} pl={10} pb={14}>
      {auth.isFinance() && auth.getAccessToken() && <SageRestartSync />}
      <Box mt="30px">
        <PaymentsTotalAndPending
          paidPayments={paidPayments}
          pendingPayments={pendingPayments}
        />
      </Box>

      <HStack justifyContent="space-between">
        <Heading as="h2" size="lg" textAlign="left" fontWeight="700" pt={2} mb={0}>
          Payments
        </Heading>
        <Box display="flex" flexDirection="row" pt={1}>
          <Search updateTerm={updateTerm} />
          <Box width="15px" />
          <FilterPayments
            clearAllFilters={clearAllFilters}
            updateFilterStatus={updateFilterStatus}
            updateFilterDueDateBetween={updateFilterDueDateBetween}
            updateFilterPaymentDateBetween={updateFilterPaymentDateBetween}
          />
        </Box>
      </HStack>
      <PaymentList
        loading={state.loading}
        invoices={invoices}
        handlePageSizeChange={handlePageSizeChange}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        pagesQuantity={pagesQuantity}
        isCampaign={auth.isCampaignManager()}
        isSupplier={auth.isSupplier()}
        isFinance={auth.isFinanceManager()}
        orderBy={order}
        updateOrderBy={updateOrderBy}
      />
    </Container>
  );
}
