import React, { useContext } from 'react';
import { Select } from '@chakra-ui/react';
import map from 'lodash/map';
import { CurrencyContext } from '../../context/currency/CurrencyContext';
import { CampaignContext } from '../../context/campaign/FilterCampaigns';

function FilterSelectCurrency({ updateCurrencyId }) {
  const currencyContext = useContext(CurrencyContext);
  const { filterCurrency } = useContext(CampaignContext);
  function handleChangeOption(e) {
    updateCurrencyId(parseInt(e.target.value, 10));
  }

  const currencies = map(currencyContext.gateAllCurrencies(), (curr) => ({
    ...curr,
    title: `${curr.code} - ${curr.name}`,
  }));
  const currencies2 = [{ id: 0, title: 'Select currency' }, ...currencies];

  return (
    <Select bg="#ebecf0" h="48px" onChange={handleChangeOption} value={filterCurrency}>
      {map(currencies2, ({ id, title }) => (
        <option key={id} value={id}>
          {title}
        </option>
      ))}
    </Select>
  );
}

export default FilterSelectCurrency;
