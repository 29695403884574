import React, { useContext } from 'react';
import moment from 'moment';
import {
  Menu,
  useDisclosure,
  MenuButton,
  Button,
  Badge,
  MenuList,
  MenuOptionGroup,
  FormControl,
  FormLabel,
  Select,
  MenuGroup,
  HStack,
  Box,
} from '@chakra-ui/react';
import { FilterIcon } from '../../assets/iconsComponents/filterIcon';
import { PaymentContext } from '../../context/payment/FilterPayments';
import { statusOptionsForPaymentsFilter } from '../../util';
import map from 'lodash/map';
import FilterDueDate from './PaymentFilterDueDate';
import FilterPaymentDate from './PaymentFilterPaymentDate';

export default function FilterPayments({
  clearAllFilters,
  updateFilterStatus,
  updateFilterDueDateBetween,
  updateFilterPaymentDateBetween,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { filterStatus, setFilterStatus } = useContext(PaymentContext);
  const { filterDueDateFrom, setFilterDueDateFrom } = useContext(PaymentContext);
  const { filterDueDateTo, setFilterDueDateTo } = useContext(PaymentContext);
  const { filterPaymentDateFrom, setFilterPaymentDateFrom } = useContext(PaymentContext);
  const { filterPaymentDateTo, setFilterPaymentDateTo } = useContext(PaymentContext);

  const numberOfFilters =
    (filterStatus !== 'none' ? 1 : 0) +
    (filterDueDateFrom && filterDueDateTo ? 1 : 0) +
    (filterPaymentDateFrom && filterPaymentDateTo ? 1 : 0);

  const handleChangeStatus = (e) => {
    setFilterStatus(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (filterStatus) {
      if (filterStatus === 'none') {
        updateFilterStatus('');
      } else {
        updateFilterStatus(`&status=${filterStatus}`);
      }
    }

    if (filterDueDateFrom && filterDueDateTo) {
      const dateDueDateFrom = moment(filterDueDateFrom).format('YYYY-MM-DD');
      const dateDueDateTo = moment(filterDueDateTo).format('YYYY-MM-DD');
      updateFilterDueDateBetween(
        `&paymentDueDateFrom=${dateDueDateFrom}&paymentDueDateTo=${dateDueDateTo}`
      );
    }

    if (filterPaymentDateFrom && filterPaymentDateTo) {
      const datePaymentDateFrom = moment(filterPaymentDateFrom).format('YYYY-MM-DD');
      const datePaymentDateTo = moment(filterPaymentDateTo).format('YYYY-MM-DD');
      updateFilterPaymentDateBetween(
        `&paymentDateFrom=${datePaymentDateFrom}&paymentDateTo=${datePaymentDateTo}`
      );
    }
  };

  const handleClearAll = (e) => {
    e.preventDefault();
    setFilterStatus('none');
    updateFilterStatus('');
    setFilterDueDateFrom('');
    setFilterDueDateTo('');
    updateFilterDueDateBetween('');
    setFilterPaymentDateFrom('');
    setFilterPaymentDateTo('');
    updateFilterPaymentDateBetween('');
    clearAllFilters();
  };

  return (
    <Menu>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            as={Button}
            bg="white"
            borderRadius="8px"
            fontSize="14px"
            fontWeight="500"
            border="1px solid #e8e9e9"
            pt="18px"
            pb="18px"
            onClick={onOpen}
            _expanded={{ bg: 'black', color: 'white' }}
            _focus={{ boxShadow: 'none' }}
            leftIcon={
              numberOfFilters ? (
                <Badge
                  bg="#eb7f24"
                  borderRadius="50%"
                  color="white"
                  h="23px"
                  w="24px"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {numberOfFilters}
                </Badge>
              ) : (
                <FilterIcon width="17px" color="#eb7f24" height="19px" />
              )
            }
          >
            Filters
          </MenuButton>
          <MenuList
            onClose={onClose}
            minWidth="360px"
            p="23px"
            boxShadow=" 0 2px 10px rgba(29, 44, 45, 0.18)"
            boundary="preventOverflow"
            flip="true"
            matchWidth
          >
            <MenuOptionGroup title="" type="checkbox" matchWidth closeOnSelect={true}>
              <FormControl mb={[4, 4, 8]}>
                <FormLabel htmlFor="statusHtmlFor" fontSize="14px">
                  Status
                </FormLabel>
                <Select
                  bg="#ebecf0"
                  h="48px"
                  onChange={handleChangeStatus}
                  value={filterStatus}
                >
                  {map(statusOptionsForPaymentsFilter(), (option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Box fontSize="16px" fontWeight="700" mb="10px">
                Due date is between
              </Box>
              <FilterDueDate />
              <Box fontSize="16px" fontWeight="700" mb="10px" mt="30px">
                Payment date is between
              </Box>
              <FilterPaymentDate />
            </MenuOptionGroup>
            <MenuGroup closeOnSelect={true} onClose>
              <HStack justifyContent="space-between" mt="30px">
                <Button
                  onClick={(event) => {
                    handleClearAll(event);
                    onClose();
                  }}
                  variant="ghost"
                  size="sm"
                  fontSize="14px"
                >
                  Clear all
                </Button>
                <Button
                  size="sm"
                  fontSize="14px"
                  colorScheme="brand"
                  textTransform="initial"
                  mr="10px"
                  onClick={(event) => {
                    handleSubmit(event);
                    onClose();
                  }}
                >
                  Apply and close
                </Button>
              </HStack>
            </MenuGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
}
