import React from 'react';
import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { hasBankAccount, hasPayPal } from '../../util/supplier/helper';

export default function PaymentDetailsViewAsFinance({ supplier }) {
  function getBoxOpacity(supplier) {
    return supplier?.paymentDetailsApproved ? '1' : '0.3';
  }

  return (
    <>
      <Heading as="h4" size="md" mt="60px" fontWeight="bold">
        Payment details
      </Heading>
      <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="30px" />
      {!supplier.paymentDetailsApproved && (
        <Box
          bg="#f7c7ad"
          border="1px solid #ff5a00"
          p="20px"
          d="flex"
          flexDirection="row"
          mb="25px"
        >
          <Box>
            <WarningIcon color="brand.400" w="16px" mr="5px" />
          </Box>
          <Box>
            <Text>
              {`The supplier (${supplier.code}) has submitted new payment data for ${supplier.currency.code} - ${supplier.currency.name}.`}
            </Text>
            <Text>
              <b>Please update the supplier records manually in Sage </b>
              with the payment details below and then click “Confirm the payment data
              changes”.
            </Text>
          </Box>
        </Box>
      )}
      <HStack alignItems="flex-start" opacity={getBoxOpacity(supplier)}>
        {supplier && hasBankAccount(supplier) && (
          <>
            <Box color="#868789" fontSize="14px" mr="35px">
              <Text mb="5px">Payment method:</Text>
              <Text mb="5px">Bank name:</Text>
              {supplier.bankAddress && <Text mb="5px">Bank address:</Text>}
              <Text mb="5px">Country:</Text>
              {supplier?.accountName && <Text mb="5px">Account name:</Text>}
              {supplier.accountNumber && <Text mb="5px">Account number:</Text>}
              {supplier.accountNumberOrIban && (
                <Text mb="5px">Account number or IBAN:</Text>
              )}
              {supplier.bankCode && <Text mb="5px">Bank code:</Text>}
              {supplier.bsbCode && <Text mb="5px">BSB code:</Text>}
              {supplier.iban && <Text mb="5px">IBAN:</Text>}
              {supplier.ifscCode && <Text mb="5px">IFSC code:</Text>}
              {supplier.routingNumber && <Text mb="5px">Routing number:</Text>}
              {supplier.sortCode && <Text mb="5px">Sort code:</Text>}
              {supplier.swift && <Text mb="5px">SWIFT code:</Text>}
              {supplier.additionalReference && (
                <Text mb="5px">Additional reference:</Text>
              )}
            </Box>
            <Box alignItems="flex-start" fontSize="14px">
              <Text mb="5px">Bank transfer</Text>
              <Text mb="5px">{supplier.bankName}</Text>
              {supplier.bankAddress && <Text mb="5px">{supplier.bankAddress}</Text>}
              <Text mb="5px">{supplier.country?.name}</Text>
              {supplier?.accountName && <Text mb="5px">{supplier.accountName}</Text>}
              {supplier.accountNumber && <Text mb="5px">{supplier.accountNumber}</Text>}
              {supplier.accountNumberOrIban && (
                <Text mb="5px">{supplier.accountNumberOrIban}</Text>
              )}
              {supplier.bankCode && <Text mb="5px">{supplier.bankCode}</Text>}
              {supplier.bsbCode && <Text mb="5px">{supplier.bsbCode}</Text>}
              {supplier.iban && <Text mb="5px">{supplier.iban}</Text>}
              {supplier.ifscCode && <Text mb="5px">{supplier.ifscCode}</Text>}
              {supplier.routingNumber && <Text mb="5px">{supplier.routingNumber}</Text>}
              {supplier.sortCode && <Text mb="5px">{supplier.sortCode}</Text>}
              {supplier.swift && <Text mb="5px">{supplier.swift}</Text>}
              {supplier.additionalReference && (
                <Text mb="5px">{supplier.additionalReference}</Text>
              )}
            </Box>
          </>
        )}
        {supplier && hasPayPal(supplier) && (
          <>
            <Box color="#868789" fontSize="14px" mr="35px">
              <Text mb="5px">Payment method:</Text>
              <Text mb="5px">Email address:</Text>
            </Box>
            <Box alignItems="flex-start" fontSize="14px">
              <Text mb="5px">PayPal</Text>
              <Text mb="5px">{supplier.payPalAccount}</Text>
            </Box>
          </>
        )}
        {supplier && !hasPayPal(supplier) && !hasBankAccount(supplier) && (
          <>
            <Text color="#868789">No information yet.</Text>
          </>
        )}
      </HStack>
    </>
  );
}
