import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
  SimpleGrid,
  Container,
} from '@chakra-ui/react';

import { HttpContext } from '../../context/HttpContext';
import { AuthContext } from '../../context/AuthContext';
import { getMessage } from '../../util';

const AccountForm = () => {
  const toast = useToast();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('This is mandatory field'),
    lastName: Yup.string().required('This is mandatory field'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;

  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);
  const user = auth.getUser();

  const onSubmit = async (values) => {
    try {
      const { firstName, lastName } = values;
      const { data } = await authAxios.patch(`users/members/${user.id}`, {
        firstName,
        lastName,
      });
      if (data) {
        auth.setAuthUser(data);
        toast({
          position: 'top',
          description: 'You have successfully updated your account.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      }
    } catch (onError) {
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          bg="white"
          borderRadius="8px"
          boxShadow="0 1px 13px rgba(29, 44, 45, 0.12)"
          pt="80px"
          pb="100px"
        >
          <Container maxW="container.md">
            <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
              <Box>
                <FormControl isInvalid={errors.firstName} mb={[4, 4, 8]}>
                  <FormLabel htmlFor="firstName">First name</FormLabel>
                  <Input
                    id="firstName"
                    defaultValue={user.firstName}
                    {...register('firstName')}
                  />
                  <FormErrorMessage>
                    {errors.firstName?.message || errors.firstName}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl isInvalid={errors.lastName} mb={[4, 4, 8]}>
                  <FormLabel htmlFor="lastName">Last name</FormLabel>
                  <Input
                    id="lastName"
                    defaultValue={user.lastName}
                    {...register('lastName')}
                  />
                  <FormErrorMessage>
                    {errors.lastName?.message || errors.lastName}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl isInvalid={errors.email} mb={[4, 4, 8]}>
                  <FormLabel htmlFor="email">Email address</FormLabel>
                  <Input
                    disabled
                    color="#c3c1c1"
                    id="email"
                    defaultValue={user.email}
                    {...register('email')}
                  />
                  <FormErrorMessage>
                    {errors.email?.message || errors.email}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </SimpleGrid>
          </Container>
        </Box>
        <>
          <Box textAlign="right">
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              disabled={!isDirty || !isValid || isSubmitting}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mt="25px"
            >
              Save
            </Button>
          </Box>
        </>
      </form>
    </>
  );
};
export default AccountForm;
