import React, { useContext } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { SmallAddIcon } from '@chakra-ui/icons';
import DrawerComponentCustom from '../../common/UIcomponents/CustomDrawer';
import CampaignForm from './CreateCampaignForm';
import { CampaignContext } from '../../context/campaign/FilterCampaigns';

export default function CreateCampaignButton({ addCampaign, addFromPurchase }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { setFrom } = useContext(CampaignContext);
  const { setTo } = useContext(CampaignContext);
  function handleOnClose() {
    setFrom('');
    setTo('');
    onClose();
  }

  return (
    <>
      {addFromPurchase ? (
        <Button
          onClick={onOpen}
          color="brand.500"
          variant="ghost"
          fontSize="16px"
          py={2}
          px={2}
        >
          <SmallAddIcon h={22} w={7} /> Request a new campaign code
        </Button>
      ) : (
        <Button
          type="submit"
          colorScheme="brand"
          size="sm"
          onClick={onOpen}
          pt="20px"
          pb="20px"
          _hover={{ bg: '#000' }}
        >
          <SmallAddIcon h={22} w={7} />
          Create a campaign
        </Button>
      )}

      <DrawerComponentCustom
        title="Create a Campaign"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={handleOnClose}
        campaign
      >
        <CampaignForm onClose={onClose} addCampaign={addCampaign} />
      </DrawerComponentCustom>
    </>
  );
}
