import { Button, Text } from '@chakra-ui/react';

const renderFileDownload = (file, downloadHandler, isLoading, isDownloadable = true) => {
  if (!isDownloadable) return null;
  return file ? (
    <Button
      type="button"
      variant="ghost"
      sx={{ padding: 0, fontSize: '14px', mb: '8px', height: 'fit-content' }}
      onClick={downloadHandler}
      isLoading={isLoading}
    >
      {file}
    </Button>
  ) : (
    <Text mb="5px">No information yet.</Text>
  );
};

export default renderFileDownload;
