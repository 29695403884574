import React from 'react';
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import debounce from 'lodash/debounce';

export default function Search({ updateTerm }) {
  function handleChange(e) {
    updateTerm(e.target.value);
  }

  return (
    <InputGroup
      bg="white"
      h="40px"
      border="1px solid #e7e8ec"
      borderRadius="8px"
      w="350px"
    >
      <InputLeftAddon h="40px" children={<SearchIcon color="gray.300" />} />
      <Input
        variant="filled"
        borderRadius="8px"
        bg="white"
        h="36px"
        border="0"
        _focus={{ border: '0' }}
        _hover={{ border: '0' }}
        type="search"
        placeholder="Search"
        fontSize="15px"
        pl="0"
        onChange={debounce(handleChange, 350)}
      />
    </InputGroup>
  );
}
