import React, { useContext, useState, useRef, forwardRef } from 'react';
import moment from 'moment';
import Helmet from 'react-helmet';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { formatDate, parseDate } from 'react-day-picker/moment';
import { DateIcon } from '../../assets/iconsComponents/dateIcon';
import { Input, Box, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { CampaignContext } from '../../context/campaign/FilterCampaigns';

const FilterEndDate = forwardRef((props, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const ToRef = React.createRef();
  const { filterEndFrom: from, setFilterEndFrom: setFrom } = useContext(CampaignContext);
  const { filterEndTo: to, setFilterEndTo: setTo } = useContext(CampaignContext);

  const showFromMonth = () => {
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      to.getDayPicker().showMonth(from);
    }
  };

  //   const handleChange = (e) => {
  //     //setCurrency(e.target.value);
  //   };

  const handleFromChange = (from) => {
    // Change the from date and focus the "to" input field
    setFrom(from);
  };

  const handleToChange = (to) => {
    setTo(to, showFromMonth);
  };

  const modifiers = { start: from, end: to };

  return (
    <div className="InputFromTo">
      <DayPickerInput
        component={(props) => (
          <InputGroup
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            maxWidth="140px"
            {...props}
          >
            {!from && (
              <InputLeftElement
                pointerEvents="none"
                children={<DateIcon color="#a9b1b4" />}
              />
            )}
            <Input
              value={from}
              format="LL"
              formatDate={formatDate}
              parseDate={parseDate}
              placeholder=""
              fontSize="16px"
              //{...props}
            />
          </InputGroup>
        )}
        format="LL"
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          selectedDays: [from, { from, to }],
          disabledDays: { after: to },
          toMonth: to,
          modifiers,
          numberOfMonths: 1,
          onDayClick: (e) => {
            const diffForFromTo = moment(e).diff(moment(to));
            if (diffForFromTo && diffForFromTo > 0) {
              return;
            }
            ToRef.current.focus();
            setIsFocused(true);
          },
        }}
        onDayChange={handleFromChange}
      />{' '}
      <Box as="span" pl="10px" pr="10px" color="#868789">
        and
      </Box>
      <span className="InputFromTo-to">
        <DayPickerInput
          component={(props) => (
            <InputGroup
              keepFocus
              format="LL"
              formatDate={formatDate}
              parseDate={parseDate}
              maxWidth="140px"
              // ref={(el) => (this.to = el)}
              {...props}
            >
              {!to && (
                <InputLeftElement
                  pointerEvents="none"
                  children={<DateIcon color="#a9b1b4" />}
                />
              )}
              <Input
                ref={ToRef}
                value={to}
                format="LL"
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder=""
                //onFocus={isFocused}
                fontSize="16px"
                boxShadow={isFocused ? `0px 0px 1px 1px black}` : 'none'}
              />
            </InputGroup>
          )}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { before: from },
            modifiers,
            month: from,
            fromMonth: from,
            numberOfMonths: 1,
          }}
          onDayChange={handleToChange}
        />
      </span>
      <Helmet>
        <style>{`
  .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #eb7f24;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #eb7f24;
}
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #eb7f24;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 270px;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -198px;
  }
`}</style>
      </Helmet>
    </div>
  );
});
export default FilterEndDate;
