import React from 'react';
import UseCurrency from '../../custom-hooks/useCurrency';
import CampaignContextProvider from '../../context/campaign/FilterCampaigns';
import { CurrencyProvider } from '../../context/currency/CurrencyContext';
import CampaignDetails from '../CampaignDetails';
import { PodLeadersProvider } from '../../context/podLeader/PodleadersContext';
import UsePodLeaders from '../../custom-hooks/usePodLeaders';

export const SINGLE_CAMPAIGN_PAGE = 'campaign_details';
function SingleCampaignPage() {
  return (
    <CampaignContextProvider>
      <CurrencyProvider>
        <UseCurrency>
          <PodLeadersProvider>
            <UsePodLeaders>
              <CampaignDetails />
            </UsePodLeaders>
          </PodLeadersProvider>
        </UseCurrency>
      </CurrencyProvider>
    </CampaignContextProvider>
  );
}

export default SingleCampaignPage;
