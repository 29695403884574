import React from 'react';
import UseCurrency from '../../custom-hooks/useCurrency';
import POContextProvider from '../../context/PurchaseOrder/FilterPO';
import { CurrencyProvider } from '../../context/currency/CurrencyContext';
import CreateInvoice from '../CreateInvoice';
import { CountriesProvider } from '../../context/country/CountriesContext';
import UseCountries from '../../custom-hooks/useCountries';

export default function InvoiceCreatePage() {
  return (
    <POContextProvider>
      <CurrencyProvider>
        <UseCurrency>
          <CountriesProvider>
            <UseCountries>
              <CreateInvoice />
            </UseCountries>
          </CountriesProvider>
        </UseCurrency>
      </CurrencyProvider>
    </POContextProvider>
  );
}
