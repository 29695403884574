import React, {
  // lazy,
  Suspense,
  useContext,
} from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

/* Contexts */
import { AuthContext } from './context/AuthContext';

/* Layouts */
//import LayoutMain from "./layout/main";
import LayoutAuth from './common/layout/Auth.js';
import LayoutMain from './common/layout/Main';

/* Pages */
import Login from './pages/login';
// import Dashboard from "./pages/dashboard";
import Logout from './pages/logout';
import PasswordSetup from './pages/PasswordSetup';
import InfluencerPortalTeam from './pages/InfluencerPortalTeam';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Account from './pages/Account';
import SuppliersPage from './pages/supplier/SuppliersPage';
import SingleSupplierPage from './pages/supplier/SingleSupplierPage';
import CampaignPage from './pages/campaign/CompaignPage';
import SingleCampaignPage from './pages/campaign/SingleCampaignPage';
import PurchaseOrderPage from './pages/purchaseOrder/PurchaseOrderPage';
import PaymentPage from './pages/payments/PaymentsPage';
import SingleOrderPage from './pages/purchaseOrder/SingleOrderPage';
import InvoiceView from './pages/ViewInvoice';
import Dashboard from './pages/dashboard';
import InvoiceCreatePage from './pages/payments/InvoiceCreatePage';
import InvoiceEditPage from './pages/payments/InvoiceEditPage';
import CampaignRequestChangesPage from './pages/campaign/CampaignRequestChangesPage.js';

/* Lazy load */
//const Dashboard = lazy(() => import('./pages/dashboard'))
const LoadingFallback = () => <div>Loading...</div>;

const AuthRoute = ({
  component: Component,
  layout: Layout,
  title,
  withTitle,
  ...rest
}) => {
  const auth = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated() ? (
          auth.shouldSupplierConfirmAccountDetails() ? (
            <Redirect from="/account" to="/account" />
          ) : auth.isAdmin() ? (
            <Redirect to="/team" />
          ) : (
            <Redirect to="/orders" />
          )
        ) : (
          <Layout {...props} withTitle title={title}>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};
const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        return auth.isAuthenticated() ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { next: props.location },
            }}
          />
        );
      }}
    />
  );
};

const FinanceManagerAndAdminRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isFinanceManager() || auth.isAdmin() ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { next: props.location },
            }}
          />
        )
      }
    />
  );
};
// const FinanceManagerAndPodLeaderRoute = ({
//   component: Component,
//   layout: Layout,
//   ...rest
// }) => {
//   const auth = useContext(AuthContext);
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         auth.isFinanceManager() || auth.isPodLeader() ? (
//           <Layout {...props}>
//             <Component {...props} />
//           </Layout>
//         ) : (
//           <Redirect
//             to={{
//               pathname: '/login',
//               state: { next: props.location },
//             }}
//           />
//         )
//       }
//     />
//   );
// };

const SupplierRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isSupplier() ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { next: props.location },
            }}
          />
        )
      }
    />
  );
};

const FinanceAndSupplierRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isFinance() || auth.isSupplier() ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { next: props.location },
            }}
          />
        )
      }
    />
  );
};
const NonAdminPrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        !auth.isAuthenticated() ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { next: props.location },
            }}
          />
        ) : !auth.isAdmin() ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/team',
              state: { next: props.location },
            }}
          />
        )
      }
    />
  );
};

const FinanceAndCampaignRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isFinanceManager() ||
        auth.isCampaignManager() ||
        auth.isFinanceUser() ||
        auth.isPodLeader() ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { next: props.location },
            }}
          />
        )
      }
    />
  );
};

const FinanceAndCampaignAndSupplierRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isFinanceUser() ||
        auth.isPodLeader() ||
        auth.isFinanceManager() ||
        auth.isCampaignManager() ||
        auth.isSupplier() ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { next: props.location },
            }}
          />
        )
      }
    />
  );
};

export default function AppRoutes() {
  return (
    <Router>
      <Suspense fallback={<LoadingFallback />}>
        <Switch>
          <AuthRoute layout={LayoutAuth} component={Login} path="/login" title="Log in" />
          <AuthRoute
            layout={LayoutAuth}
            component={PasswordSetup}
            path="/password-setup/:token"
            title="Set your password"
          />
          <AuthRoute
            layout={LayoutAuth}
            component={ForgotPassword}
            path="/forgot-password"
            title="Forgot password?"
          />
          <AuthRoute
            layout={LayoutAuth}
            component={ResetPassword}
            path="/reset-password"
            title="Reset password"
          />
          <AuthRoute layout={LayoutAuth} component={Login} path="/login" />

          <FinanceManagerAndAdminRoute
            layout={LayoutMain}
            component={InfluencerPortalTeam}
            path="/team"
            title="Team"
          />

          <FinanceAndCampaignRoute
            layout={LayoutMain}
            component={SingleSupplierPage}
            exac
            path="/suppliers/:userId/:id"
          />
          <FinanceAndCampaignRoute
            layout={LayoutMain}
            component={SingleSupplierPage}
            exac
            path="/suppliers/:userId"
          />
          <FinanceAndCampaignRoute
            layout={LayoutMain}
            component={CampaignRequestChangesPage}
            exac
            path="/campaigns/:id/campaigns-request-changes"
          />
          <FinanceAndCampaignRoute
            layout={LayoutMain}
            component={SingleCampaignPage}
            exac
            path="/campaigns/:id"
          />

          <FinanceAndCampaignRoute
            layout={LayoutMain}
            component={CampaignPage}
            path="/campaigns"
          />
          <FinanceAndCampaignRoute
            layout={LayoutMain}
            component={SuppliersPage}
            path="/suppliers"
          />
          {/*
          <FinanceAndSupplierRoute
            layout={LayoutMain}
            component={PaymentPage}
            path="/payments"
          /> */}

          <FinanceAndCampaignAndSupplierRoute
            layout={LayoutMain}
            component={PaymentPage}
            path="/payments"
          />
          <FinanceAndSupplierRoute
            layout={LayoutMain}
            component={InvoiceView}
            exac
            path="/invoices/:id"
          />

          <SupplierRoute
            layout={LayoutMain}
            component={InvoiceCreatePage}
            exac
            path="/create-invoice/:id"
          />
          <SupplierRoute
            layout={LayoutMain}
            component={InvoiceEditPage}
            exac
            path="/edit-invoice/:id"
          />

          <NonAdminPrivateRoute
            layout={LayoutMain}
            component={SingleOrderPage}
            exac
            path="/orders/:id"
          />
          <NonAdminPrivateRoute
            layout={LayoutMain}
            component={PurchaseOrderPage}
            path="/orders"
          />
          <PrivateRoute layout={LayoutMain} component={Logout} exac path="/logout" />
          <PrivateRoute layout={LayoutMain} component={Account} path="/account" />
          <PrivateRoute layout={LayoutMain} component={Dashboard} path="/" />
        </Switch>
      </Suspense>
    </Router>
  );
}
