import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Button,
  Box,
  useToast,
  Textarea,
  Text,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../../context/HttpContext';
import { getBase64Promise, getErrors, getMessage, getMessageStatus } from '../../../util';

import AutocompleteSupplierInput from './AutocompleteSupplierInput';
import AutocompleteCampaignInput from './AutocompleteCampaignInput';
import PriceInputs from './PriceInputs';
import map from 'lodash/map';
import isFunction from 'lodash/isFunction';
import UploadFiles from '../../campaigns/UploadCampaignFile';
import SelectPodLeader from '../../../common/UIcomponents/SelectPodLeader';
import { PodLeadersContext } from '../../../context/podLeader/PodleadersContext';

const RequestForm = ({ onClose, addPurchaseOrders, updatePOList }) => {
  const { getFirstId: getFirstPodLeaderId } = useContext(PodLeadersContext);
  const [uploadedFile, setUploadedFile] = useState(undefined);
  const [uploadFileError, setUploadFileError] = useState('');
  const [supplierState, setSupplierState] = React.useState({
    supplier: null,
    supplierError: null,
    supplierTouched: false,
  });
  // const [supplierError, setSupplierError] = React.useState(null);
  const [campaign, setCampaign] = React.useState(null);
  const [campaignSelectedError, setCampaignSelectedError] = useState(null);
  const [prices, setPrices] = React.useState([]);
  // const [pricesTouched, setPricesTouched] = useState(false);
  const [pricesErrors, setPricesErrors] = useState(false);
  const [done, setDone] = React.useState(false);
  const [podLeaderId, setPodLeaderId] = React.useState(getFirstPodLeaderId || 0);
  const validationSchema = Yup.object().shape({
    descriptionOfWork: Yup.string()
      .required('This is mandatory field')
      .max(1000, 'Please enter 1000 characters or less'),
    // campaignName: Yup.string().required("This is mandatory field"),
    // budget: Yup.number().required('This is mandatory field'),
    // budget: Yup.number().test(
    //   "maxDigitsAfterDecimal",
    //   "Budget field it must be positive and must have two digits after decimal or less",
    //   (number) => Number.isInteger(number * 10 ** 2) && number > 0
    // ),
    // budget: Yup.number().test('is-decimal', 'invalid decimal', value => (value + "").match(/^\d*\.{1}\d*$/),),
    // startDate: Yup.date().default(function () {return new Date();}),
    // endDate: Yup.string().default(function () {return new Date();})
  });

  const { register, handleSubmit, formState, setError, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { errors, isSubmitting, isDirty, isValid } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  async function getBase64(file) {
    return await getBase64Promise(file);
  }

  function updateUploadedFile(file) {
    setUploadedFile(file);
  }

  const updatePodLeaderId = (id) => {
    setPodLeaderId(id);
  };

  const onSubmit = async (values) => {
    try {
      const { supplier } = supplierState;

      let purchaseOrderItemsArray = map(prices, ({ amount, title, vat }) => ({
        amount: amount.toString(),
        title,
        vat,
        id: null,
      }));

      let data = {
        ...values,
        supplier: supplier.id,
        campaign: campaign.id,
        purchaseOrderItemsArray,
        file: '',
        podLeader: podLeaderId,
      };

      if (uploadedFile) {
        const base64 = await getBase64(uploadedFile);
        const file = base64.split(',')[1];
        data = {
          ...data,
          file,
        };
      }

      const res = await authAxios.post(`purchase-orders`, data);

      toast({
        position: 'top',
        description:
          'You have successfully requested the purchase order. You will be notified when the finance team approves it.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });

      if (isFunction(addPurchaseOrders)) {
        addPurchaseOrders(res.data);
      }

      if (isFunction(updatePOList)) {
        updatePOList();
      }

      onClose();
      reset();
      setDone(true);
    } catch (onError) {
      console.log(onError);
      const status = getMessageStatus(onError);
      if (status === 500) {
        toast({
          position: 'top-left',
          description: 'Somethings went wrong',
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }

      const errors = getErrors(onError);
      console.log(errors);
      if (errors && errors.clientName && errors.clientName.errors) {
        const message = errors.clientName.errors[0];
        setError('clientName', { type: 'server', message });
      } else if (errors && errors.campaignName && errors.campaignName.errors) {
        const message = errors.campaignName.errors[0];
        setError('campaignName', { type: 'server', message });
      } else if (errors && errors.budget && errors.budget.errors) {
        const message = errors.budget.errors[0];
        setError('budget', { type: 'server', message });
      } else if (errors && errors.currency && errors.currency.errors) {
        const message = errors.currency.errors[0];
        setError('currency', { type: 'server', message });
      } else if (errors && errors.file && errors.file.errors) {
        // const message = errors.file.errors[0];
        setUploadFileError('This is mandatory field');
      }
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top-left',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };

  function handleOnClose() {
    onClose();
  }

  function updateSupplierState({ supplier, supplierError, supplierTouched }) {
    setSupplierState({
      supplier,
      supplierError,
      supplierTouched,
    });
  }

  function updateCampaign(campaign) {
    setCampaign(campaign);
  }

  function updateCampaignSelectedError(value) {
    setCampaignSelectedError(value);
  }

  function updatePrices(prices) {
    setPrices(prices);
  }

  function updatePricesErrors(errors) {
    setPricesErrors(errors);
  }

  function hasPricesErrors() {
    if (hasPricesEmptyField()) return true;
    if (pricesErrors.length > 0) {
      for (let i = 0; i < pricesErrors.length; i++) {
        if (pricesErrors[i]?.amount !== '' || pricesErrors[i]?.title !== '') {
          return true;
        }
      }
    }
    return false;
  }

  function hasPricesEmptyField() {
    if (prices.length > 0) {
      for (let i = 0; i < prices.length; i++) {
        if (prices[i]?.amount === '' || prices[i]?.title === '') {
          return true;
        }
      }
    }

    return false;
  }

  function hasPricesEmpty() {
    return prices.length <= 0;
  }

  function hasSupplierErrors() {
    if (supplierState?.supplier === null) {
      return true;
    }
    if (!supplierState?.supplier?.id) {
      return true;
    }
    return false;
  }

  function hasCampaignErrors() {
    return !!campaignSelectedError;
  }

  function hasUploadedFileEmpty() {
    if (uploadedFile === undefined) return true;
    return false;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AutocompleteSupplierInput
          close={handleOnClose}
          supplierState={supplierState}
          updateSupplierState={updateSupplierState}
        />
        <AutocompleteCampaignInput
          close={handleOnClose}
          updateCampaign={updateCampaign}
          updateCampaignSelectedError={updateCampaignSelectedError}
          campaignSelectedError={campaignSelectedError}
        />
        <FormControl isInvalid={errors.descriptionOfWork} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Description of the work</FormLabel>
          <Textarea
            mt="16px"
            isInvalid
            name="descriptionOfWork"
            id="descriptionOfWork"
            size="lg"
            border="1px solid  transparent"
            bg="#ebecf0"
            _focus={{ border: '1px solid black' }}
            _hover={{ border: '1px solid black' }}
            {...register('descriptionOfWork')}
            placeholder="Enter the details of the work"
          />
          <FormErrorMessage>
            {errors.descriptionOfWork?.message || errors.descriptionOfWork}
          </FormErrorMessage>
        </FormControl>
        <Text fontWeight="bold" fontSize="16px" mb="18px">
          Price
        </Text>
        <PriceInputs
          updatePrices={updatePrices}
          updatePricesErrors={updatePricesErrors}
        />
        <FormControl isInvalid={uploadFileError}>
          <FormLabel htmlFor="emailHtmlFor">Attach supplier contract</FormLabel>
          <UploadFiles
            updateUploadedFile={updateUploadedFile}
            setUploadFileError={setUploadFileError}
          />
          <FormErrorMessage>{uploadFileError}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.currency} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Pod Leader</FormLabel>
          <SelectPodLeader
            updatePodLeaderId={updatePodLeaderId}
            podLeaderId={podLeaderId}
          />
          <FormErrorMessage>
            {errors.currency?.message || errors.currency}
          </FormErrorMessage>
        </FormControl>
        <>
          <Box d="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              disabled={
                isSubmitting ||
                !isDirty ||
                !isValid ||
                isSubmitting ||
                hasSupplierErrors() ||
                hasCampaignErrors() ||
                hasPricesErrors() ||
                hasPricesEmpty() ||
                hasUploadedFileEmpty()
              }
              isLoading={isSubmitting && !done}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
              //disabled={disableForm}
            >
              Submit
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={handleOnClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
};
export default RequestForm;
