import React, { useContext } from 'react';
import { Link, Text } from '@chakra-ui/react';
import { HttpContext } from '../../context/HttpContext';
// import { saveAs, preview } from "file-saver";

export function getFilenameFromResponse(response) {
  let filename = 'download';
  const disposition = response.headers['content-disposition'];
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition);
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, '');
  }

  return filename;
}

export default function UploadedFile({ id, label, fileName, type }) {
  const { authAxios } = useContext(HttpContext);

  if (
    type !== 'campaign' &&
    type !== 'purchaseOrder' &&
    type !== 'supplierWFile' &&
    type !== 'campaignContract' &&
    type !== 'campaignContract2' &&
    type !== 'campaignContract3' &&
    type !== 'campaignContract4' &&
    type !== 'campaignContract5'
  ) {
    // throw new Error("Type is not a valid!");
    return null;
  }

  const handleDownload = async () => {
    try {
      const response = await authAxios.get(`/files/download?id=${id}&type=${type}`, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });

      const url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = fileName ?? getFilenameFromResponse(response);
      a.click();
    } catch (onError) {
      console.log(onError);
    }
  };

  return (
    <Text mb="3px" color="brand.400">
      {
        <Link onClick={handleDownload} color="brand.500" fontSize="14px" d="block">
          {label ? label : 'Download file'}
        </Link>
      }
    </Text>
  );
}
