import React from 'react';
import { HStack, Box } from '@chakra-ui/layout';
import Logo from '../../assets/vectors/Goat-Portal-logo.svg';
import { Image } from '@chakra-ui/react';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div style={{ padding: ' 0 15px', border: '1px solid #dedfdf' }}>
      <HStack h="64px" justify="space-between">
        <Box>
          <Image src={Logo} alt="Goat" w={149} />
        </Box>
        <Box color="#a0a0a0" fontSize="12px">
          © Goat Influencer Payment Portal {currentYear}
        </Box>
      </HStack>
    </div>
  );
}
