import React from 'react';
import { HStack, Box } from '@chakra-ui/layout';
import { HeaderStyles } from './styles';
import HeaderDropdown from './HeaderDropdown';
import Logo from '../../assets/vectors/Goat-Portal-logo.svg';
import { Image } from '@chakra-ui/react';

export default function Header() {
  return (
    <HeaderStyles>
      <HStack h="64px" justify="space-between">
        <Box>
          <Image src={Logo} alt="Goat" w={200} />
        </Box>
        <Box>
          <HeaderDropdown />
        </Box>
      </HStack>
    </HeaderStyles>
  );
}
