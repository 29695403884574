import React, { useContext } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box, Spinner } from '@chakra-ui/react';
import map from 'lodash/map';
import Pagination from '../../common/UIcomponents/Pagination';
import ScrollToTop from '../../util/scrollToTop';
import TeamEditMember from './TeamEdit';
import RemoveTeamMember from './TeamMemberRemove';
import SortArrows from '../../common/UIcomponents/SortArrowsTable';
import TeamStatusBadge from './TeamStatusBadge';
import { AuthContext } from '../../context/AuthContext';
import { TeamIcon } from '../../assets/iconsComponents/teamIcon';

function TeamMembersList({
  loading,
  members,
  handlePageSizeChange,
  handlePageChange,
  currentPage,
  pagesQuantity,
  updateMember,
  removeMember,
  orderBy,
  updateOrderBy,
}) {
  const auth = useContext(AuthContext);
  const { name, email, type, enabled } = orderBy;

  function handleOnClick(e, key, value) {
    updateOrderBy(key, value);
  }

  function getMemberTypeName(member) {
    switch (member?.type) {
      case 'pod_leader':
        return 'Pod Leader Team';
      case 'finance':
        return 'Finance Manager Team';
      case 'finance_user':
        return 'Finance User Team';
      case 'campaign':
        return 'Campaign Team';
      case 'admin':
        return 'Admin Team';
      default:
        return '';
    }
    // return ''
    // return "member.type.charAt(0).toUpperCase() + member.type.slice(1)"
  }

  return (
    <>
      <ScrollToTop />
      <Table
        variant="simple"
        bg="#fff"
        borderRadius="md"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
        mt="7"
      >
        {/*<TableCaption>Member list</TableCaption>*/}
        {members.length > 0 && !loading && (
          <Thead>
            <Tr key={'head-members'}>
              <Th maxWidth="150px">
                <Box
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Name
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={name}
                      b="name"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th>
                <Box
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Email Address
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={email}
                      b="email"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th>
                <Box
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Team
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={type}
                      b="type"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th>
                <Box
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Status
                  <Box d="flex" flexDirection="column" ml="0px">
                    <SortArrows
                      i={enabled}
                      b="enabled"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th>
                <Box as="p" color="#bdc1d0" fontSize="12px" fontFamily="Roboto">
                  Actions
                </Box>
              </Th>
            </Tr>
          </Thead>
        )}
        {loading && (
          <Box p={12} d="flex" alignItems="center" justifyContent="center" h="400px">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="xl"
            />
          </Box>
        )}
        {members.length > 0 && !loading ? (
          <Tbody>
            {map(members, (member) => {
              // console.log(member);
              return (
                <Tr key={member.id}>
                  <Td maxWidth="150px">
                    <Box
                      as="p"
                      fontWeight="700"
                      fontSize="16px"
                      fontFamily="Roboto"
                    >{`${member.firstName} ${member.lastName}`}</Box>
                  </Td>
                  <Td>{member.email}</Td>
                  <Td>{getMemberTypeName(member)}</Td>
                  <Td>
                    <TeamStatusBadge status={member.enabled} />
                  </Td>
                  <Td>
                    {(auth.isAdmin() || member.id === auth.getUser()?.id) && (
                      <TeamEditMember
                        member={member}
                        updateMember={updateMember}
                        isAdmin={auth.isAdmin()}
                      />
                    )}
                    {auth.isAdmin() && (
                      <RemoveTeamMember member={member} removeMember={removeMember} />
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        ) : members.length === 0 && !loading ? (
          <Box
            d="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            p="80px"
          >
            <TeamIcon width="60px" height="55px" color="#d1dbdf" />
            <Box fontSize="18px" fontWeight="bold" mt="20px" mb="20px">
              No results.
            </Box>
            <Box color="#a9b1b4" fontSize="14px">
              We could not find any members.
            </Box>
          </Box>
        ) : null}
      </Table>
      <Box margin="20px" />
      <Pagination
        handlePageSizeChange={handlePageSizeChange}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        pagesQuantity={pagesQuantity}
      />
    </>
  );
}

export default TeamMembersList;
