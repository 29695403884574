import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Box, Text, Tooltip } from '@chakra-ui/react';
import { AttachmentIcon, CheckIcon } from '@chakra-ui/icons';
// import { FIFTY_MB } from '../../util/consts';
import first from 'lodash/first';

function UploadFiles({ updateUploadedFile, files, setFiles }) {
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    multiple: false,
    maxFiles: 1,
    // maxSize: FIFTY_MB,
    accept: [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    validator: fileSizeValidator,
    onDrop: (acceptedFiles) => {
      const acc = acceptedFiles.map((accFile) =>
        Object.assign(accFile, {
          preview: URL.createObjectURL(accFile),
        })
      );
      setFiles([...acc]);
      const uploadedFile = first(acc);
      updateUploadedFile(uploadedFile);
    },
  });

  const thumbs = files.map((file) => (
    <Box key={file.path}>
      {/* {file.path} - {formatBytes(file.size)} */}
      {/* <Link color="brand.400" ml="5px">File uploaded</Link> */}
      <Box color="brand.400" ml="5px">
        <CheckIcon color="brand.400" mr={2} />{' '}
        <a href={file.preview} target="_blank" rel="noreferrer">
          {file.path}
        </a>{' '}
      </Box>
    </Box>
  ));

  function fileSizeValidator(file) {
    if (file.size > 20971520) {
      return {
        code: 'file-too-large',
        message: `Please upload a file not larger than 20 MB.`,
      };
    }

    return null;
  }

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={file.path}>
      {/*{file.path} -  {formatBytes(file.size)}*/}

      {errors.map((e) => (
        <Box color="error.500" fontSize="13px" key={e.code}>
          <Tooltip
            bg="error.500"
            color="brand.50"
            // color="white"
            label={e.code}
            aria-label={`Err ${e.code} tooltip`}
          >
            <Text isTruncated>{e.message}</Text>
          </Tooltip>
        </Box>
      ))}
    </div>
  ));
  return (
    <section className="container">
      <aside>
        <Box pr="10px" mt={thumbs && 2}>
          {thumbs}
        </Box>
      </aside>
      <Box
        d="flex"
        alignItems="center"
        mt={3}
        mb={5}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        <Button
          colorScheme="brand"
          textTransform="initial"
          //_hover={{ bg: "#000" }}
          fontWeight="400"
          mt="0"
          mr="20px"
          fontSize="14px"
        >
          <AttachmentIcon mr="5px" />
          Upload file
        </Button>
        <Box d="inline-block" color="#a9b1b4" fontSize="12px">
          <Text mb="0" lineHeight="1">
            File format: PDF, DOC, JPG, PNG
          </Text>{' '}
          <Text mt="1" lineHeight="1">
            Max file size: 20 MB
          </Text>
        </Box>
      </Box>
      <aside>
        <Box pr="10px" mt={fileRejectionItems && 2}>
          {fileRejectionItems}
        </Box>
      </aside>
    </section>
  );
}

export default UploadFiles;
