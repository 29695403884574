import React, { useContext, useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { Link as RouterLink } from 'react-router-dom';
import {
  Input,
  List,
  ListItem,
  Flex,
  Box,
  Link,
  FormControl,
  FormLabel,
  Text,
  IconButton,
  // Button,
} from '@chakra-ui/react';
import { SmallAddIcon, CloseIcon } from '@chakra-ui/icons';
import { useDebounce } from '../../../custom-hooks/useDebounce';
import { HttpContext } from '../../../context/HttpContext';
import map from 'lodash/map';

// const items = ["John Jones", "Joana Jasons", "Jenny Wiliams"];
// const campaigns = [
//   {
//     code: "XYMN12",
//     clientName: "Coca Cola",
//     campaignName: "Together Tastes better",
//     status: "completed",
//   },
//   {
//     code: "XYMN12",
//     clientName: "Dell",
//     campaignName: "Dell Optimizer",
//     status: "snag",
//   },
//   {
//     code: "XYMN12",
//     clientName: "Dell",
//     campaignName: "Dell Optimizer",
//     status: "suspended",
//   },
// ];
const ComboboxInput = React.forwardRef(({ ...props }, ref) => {
  return <Input {...props} ref={ref} />;
});

const ComboboxList = React.forwardRef(({ isOpen, ...props }, ref) => {
  return <List display={isOpen ? null : 'none'} py={2} {...props} ref={ref} />;
});

const ComboboxItem = React.forwardRef(
  ({ itemIndex, highlightedIndex, ...props }, ref) => {
    const isActive = itemIndex === highlightedIndex;

    return (
      <ListItem
        transition="background-color 220ms, color 220ms"
        bg={isActive ? '#ebecf0' : null}
        px={4}
        py={2}
        cursor="pointer"
        {...props}
        ref={ref}
      />
    );
  }
);

export default function AutocompleteCampaignInput({
  updateCampaign,
  updateCampaignSelectedError,
  campaignSelectedError,
}) {
  const [inputItems, setInputItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [clearAutocomplete, setClearAutocomplete] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 350);
  const { authAxios } = useContext(HttpContext);
  useEffect(() => {
    const getData = async () => {
      if (searchTerm === '') return;
      try {
        const { data } = await authAxios.get(
          `campaigns/search/autocomplete?search=${searchTerm}`
        );
        const items = map(data, (item) => ({
          ...item,
          code: item.campaignCode,
        }));
        setInputItems(items);
      } catch (onError) {
        console.log(onError);
      }
    };

    getData();
  }, [authAxios, debouncedSearchTerm]);

  function handleSelected(selectedItem) {
    if (selectedItem && selectedItem.status !== 'active') {
      updateCampaignSelectedError(
        'Purchase orders can not be created for completed, snag or suspend campaigns.'
      );
    } else {
      updateCampaignSelectedError(null);
    }
    updateCampaign(selectedItem);
    setClearAutocomplete(true);
  }

  function handleSelectedInputValueChange(inputValue) {
    setSearchTerm(inputValue.trim());
    if (inputValue.trim() === '') {
      updateCampaignSelectedError('This is a mandatory field.');
    }
  }

  const {
    isOpen,
    // selectedItem,
    // getToggleButtonProps,
    // getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    // itemToString,
  } = useCombobox({
    items: inputItems,
    itemToString: (item) => (item ? `${item.code}, ${item.campaignName}` : ''),
    onInputValueChange: ({ inputValue }) => {
      handleSelectedInputValueChange(inputValue);
    },
    onSelectedItemChange({ selectedItem }) {
      handleSelected(selectedItem);
    },
    onStateChange(changes, stateAndHelpers) {
      if (
        (changes.type === '__function_select_item__' || changes.type === 16) &&
        !changes.selectedItem
      ) {
        setClearAutocomplete(false);
      }
    },
  });
  return (
    <Flex direction="column">
      <Flex {...getComboboxProps()} direction="column" flex="1 1 auto" mb={[4, 4, 8]}>
        <FormControl>
          <FormLabel htmlFor="emailHtmlFor">Campaign</FormLabel>
          <ComboboxInput
            {...getInputProps({ disabled: clearAutocomplete })}
            placeholder="Start typing to find or add a campaign code"
            flex="0 0 auto"
            mt={3}
          />
          {clearAutocomplete && (
            <Box position="absolute" top="36px" right="-10px">
              <IconButton
                colorScheme="brand"
                size="xs"
                onClick={() => {
                  selectItem(null);
                }}
                aria-label="clear autocomplete icon"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </FormControl>
        <Box boxShadow="0 1px 13px rgba(29, 44, 45, 0.12)" borderRadius="6px">
          <ComboboxList
            isOpen={isOpen}
            {...getMenuProps()}
            flex={1}
            overflowY="auto"
            mt={0}
          >
            {inputItems.map((campaign, index) => (
              <ComboboxItem
                {...getItemProps({ campaign, index })}
                itemIndex={index}
                highlightedIndex={highlightedIndex}
                key={index}
              >
                <Text>
                  {campaign.code}, {campaign.campaignName}
                  <Box
                    as="span"
                    textTransform="uppercase"
                    fontSize="12px"
                    pl={2}
                    color={`${
                      campaign.status === 'completed'
                        ? '#5b9075'
                        : campaign.status === 'snag'
                        ? '#d18533'
                        : campaign.status === 'suspend'
                        ? '#c75c5c'
                        : null
                    }`}
                  >
                    {campaign.status !== 'active' ? campaign.status : null}
                  </Box>
                </Text>
                <Text fontSize="14px" color="gray">
                  {campaign.clientName}
                </Text>
              </ComboboxItem>
            ))}
            {isOpen && (
              <>
                {inputItems.length > 0 && (
                  <Box w="100%" h="1px" bg="#ebecf0" mt="5px" mb="5px" />
                )}
                <Link
                  color="brand.500"
                  as={RouterLink}
                  to="/campaigns"
                  fontSize="16px"
                  py={2}
                  px={2}
                >
                  <SmallAddIcon h={22} w={7} /> Create a new campaign
                </Link>
                {/* <CreateCampaignButton addFromPurchase onClose/> */}
              </>
            )}
          </ComboboxList>
        </Box>
        {campaignSelectedError ? (
          <Box color="error.500" fontSize="13px">
            {campaignSelectedError}
          </Box>
        ) : null}
      </Flex>
    </Flex>
  );
}
