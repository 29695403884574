import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Stack,
  Radio,
  RadioGroup,
  Box,
  useToast,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../context/HttpContext';
import { getErrors, getMessage } from '../../util';
import { useHistory } from 'react-router-dom';

const AddMemberForm = ({ onClose, addMember, addNewMember }) => {
  const history = useHistory();
  const [radioValue, setRadioValue] = React.useState('campaign');
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, 'Please enter 100 characters or less')
      .required('This is mandatory field'),
    // .min(4, 'First name must be at least 4 characters'),
    lastName: Yup.string()
      .max(100, 'Please enter 100 characters or less')
      .required('This is mandatory field'),
    // .min(4, 'Last name must be at least 4 characters'),
    email: Yup.string()
      .max(100, 'Please enter 100 characters or less')
      .required('This is mandatory field')
      .email('Please enter an email address'),
    // .matches(
    //   /(@goatagency.com|@etondigital.com|@gmail.com)$/,
    //   'Please enter an email address that ends with @goatagency.com'
    // )
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };
  const { register, handleSubmit, formState, setError, reset } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  const onSubmit = async (values) => {
    try {
      const type = radioValue;
      const data = {
        ...values,
        type,
      };

      const res = await authAxios.post(`users/members`, data);
      addMember(res.data);
      onClose();
      reset();
      if (addNewMember) {
        history.push('/team');
      }
    } catch (onError) {
      const errors = getErrors(onError);
      if (errors && errors.email) {
        const message = errors.email.errors[0];
        setError('email', { type: 'server', message });
      } else {
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top-left',
            description: message,
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
      }
    }
  };

  function handleCancel() {
    onClose();
    if (addNewMember) {
      history.push('/team');
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.firstName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">First Name</FormLabel>
          <Input
            isInvalid
            name="firstName"
            id="firstName"
            placeholder="Enter first name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('firstName')}
          />
          <FormErrorMessage>
            {errors.firstName?.message || errors.firstName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.lastName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Last Name</FormLabel>
          <Input
            isInvalid
            name="lastName"
            id="lastName"
            placeholder="Enter last name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('lastName')}
          />
          <FormErrorMessage>
            {errors.lastName?.message || errors.lastName}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.email} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Email Address</FormLabel>
          <Input
            isInvalid
            name="email"
            id="email"
            placeholder="Enter email address"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('email')}
          />
          <FormErrorMessage>{errors.email?.message || errors.email}</FormErrorMessage>
        </FormControl>

        <>
          <Box fontWeight="700" mb={5}>
            Assign Role
          </Box>
          <RadioGroup onChange={setRadioValue} value={radioValue}>
            <Stack direction="column">
              <Radio
                size="lg"
                name="campaign"
                colorScheme="black"
                _focus={{ outline: '0' }}
                value="campaign"
                alignItems="flex-start"
              >
                <Box pl="23px" fontSize="14px">
                  Campaign Team Member
                  <Box color="#b9c1c3" fontSize="14px">
                    <Box> - request new supplier</Box>
                    <Box>- perform checks and attach evidence on supplier profile</Box>
                    <Box>- request campaign code</Box>
                    <Box>- request purchase order</Box>
                    <Box>- view PO and payment statuses</Box>
                  </Box>
                </Box>
              </Radio>
              <Radio
                size="lg"
                name="pod_leader"
                colorScheme="black"
                _focus={{ outline: '0' }}
                value="pod_leader"
                alignItems="flex-start"
              >
                <Box pl="23px" fontSize="14px">
                  Pod Leader Member
                  <Box color="#b9c1c3" fontSize="14px">
                    <Box>
                      - approve sanctions / reputations checks on supplier profile
                    </Box>
                    <Box>- approve PO requests for campaigns allocated to them</Box>
                    <Box>- approve campaign code requests</Box>
                    <Box>- view PO and payment statuses</Box>
                  </Box>
                </Box>
              </Radio>
              <Radio
                size="lg"
                name="finance_user"
                colorScheme="black"
                _focus={{ outline: '0' }}
                value="finance_user"
                alignItems="flex-start"
              >
                <Box pl="23px" fontSize="14px">
                  Finance User Member
                  <Box color="#b9c1c3">
                    <Box>- approve initial new supplier request</Box>
                    <Box>- perform checks on supplier profile once submitted</Box>
                    <Box>- submit supplier profile for approval from finance manager</Box>
                    <Box>- approve and send POs</Box>
                    <Box>- approve invoices</Box>
                  </Box>
                </Box>
              </Radio>
              <Radio
                size="lg"
                name="finance"
                colorScheme="black"
                _focus={{ outline: '0' }}
                value="finance"
                alignItems="flex-start"
              >
                <Box pl="23px" fontSize="14px">
                  Finance Manager Member
                  <Box color="#b9c1c3">
                    <Box>
                      - approve new suppliers once profile is complete and checks
                      performed
                    </Box>
                    <Box>- approve and send POs</Box>
                    <Box>- approve invoices</Box>
                  </Box>
                </Box>
              </Radio>
            </Stack>
          </RadioGroup>
          <Box d="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              disabled={!isDirty || !isValid || isSubmitting}
              isLoading={isSubmitting}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
            >
              Submit
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              // isLoading={isSubmitting}
              disabled={isSubmitting}
              textTransform="initial"
              borderRadius="10px"
              onClick={handleCancel}
              // _hover={{ bg: "#000" }}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
};
export default AddMemberForm;
