import React from 'react';
export const DateIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    {...props}
  >
    <g>
      <path
        fill=""
        d="M13,0h2v16H0V0h2v1h2V0h7v1h2V0z M14,15V4H1v11H14z M2,11h2V9H2V11z M2,14h2v-2H2V14z M5,8h2V6H5V8z M5,11
            h2V9H5V11z M5,14h2v-2H5V14z M8,8h2V6H8V8z M8,11h2V9H8V11z M8,14h2v-2H8V14z M11,8h2V6h-2V8z M11,11h2V9h-2V11z"
      />
    </g>
  </svg>
);
