import React from 'react';
import UseCurrency from '../../custom-hooks/useCurrency';
import FilterSupplierContextProvider from '../../context/FilterSuppliers';
import { CurrencyProvider } from '../../context/currency/CurrencyContext';
import SupplierDetails from '../SupplierDetails';
import { SupplierProvider } from '../../context/supplier/SupplierContext';
import { CountriesProvider } from '../../context/country/CountriesContext';
import UseCountries from '../../custom-hooks/useCountries';

function SingleSupplierPage() {
  return (
    <FilterSupplierContextProvider>
      <SupplierProvider>
        <CurrencyProvider>
          <UseCurrency>
            <CountriesProvider>
              <UseCountries>
                <SupplierDetails />
              </UseCountries>
            </CountriesProvider>
          </UseCurrency>
        </CurrencyProvider>
      </SupplierProvider>
    </FilterSupplierContextProvider>
  );
}

export default SingleSupplierPage;
