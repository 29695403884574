import React, { useContext, useEffect, useState } from 'react';
import {
  Heading,
  Container,
  useToast,
  HStack,
  Box,
  Button,
  // Spinner,
  // filter,
} from '@chakra-ui/react';
import CreateCampaignButton from '../components/campaigns/CreateCampaignButton';
import { HttpContext } from '../context/HttpContext';
import CampaignList from '../components/campaigns/CampaignList';
import Search from '../common/UIcomponents/SearchList';
import { usePaginator } from 'chakra-paginator';
import { AuthContext } from '../context/AuthContext';
import FilterCampaigns from '../components/campaigns/FilterCampaignDropdown';
import SageRestartSync from '../components/vendors/SageRestartSync';
import { INITIAL_PAGE_SIZE } from '../util/consts';
import { DownloadIcon } from '../assets/iconsComponents/downloadIcon';

export default function InfluencerPortalCampaigns() {
  const clearedOrderBy = {
    name: null,
    campaignCode: null,
    budget: null,
    currency: null,
    podLeader: null,
    status: null,
    approvalStatus: null,
    startDate: null,
    endDate: null,
    numberOfPos: null,
  };
  const initialOrderBy = {
    name: null,
    campaignCode: null,
    budget: null,
    currency: null,
    podLeader: null,
    status: null,
    approvalStatus: null,
    startDate: null,
    endDate: null,
    numberOfPos: null,
  };
  const [campaignsState, setCampaignsState] = useState({
    campaigns: [],
    loading: true,
    total: 0,
    // orderBy: initialOrderBy
  });

  const [order, setOrder] = useState(initialOrderBy);
  const [term, setTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterApprovalStatus, setFilterApprovalStatus] = useState('');
  const [filterCurrency, setFilterCurrency] = useState('');
  const [filterPodLeader, setFilterPodLeader] = useState('');
  const [filterStartBetween, setFilterStartBetween] = useState('');
  const [filterEndBetween, setFilterEndBetween] = useState('');

  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);
  const toast = useToast();

  const {
    pagesQuantity,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    offset, // you may not need this most of the times, but it's returned for you anyway
  } = usePaginator({
    total: campaignsState.total,
    initialState: {
      pageSize: INITIAL_PAGE_SIZE,
      currentPage: 1,
      isDisabled: false,
    },
  });

  function getSortingQuery(order) {
    if (order.name) {
      return `&sort=campaignName&direction=${order.name}`;
    }
    if (order.campaignCode) {
      return `&sort=campaignCode&direction=${order.campaignCode}`;
    }
    if (order.budget) {
      return `&sort=budget&direction=${order.budget}`;
    }
    if (order.currency) {
      return `&sort=currency&direction=${order.currency}`;
    }
    if (order.podLeader) {
      return `&sort=podLeader&direction=${order.podLeader}`;
    }
    if (order.status) {
      return `&sort=status&direction=${order.status}`;
    }
    if (order.approvalStatus) {
      return `&sort=approvalStatus&direction=${order.approvalStatus}`;
    }
    if (order.startDate) {
      return `&sort=startDate&direction=${order.startDate}`;
    }
    if (order.endDate) {
      return `&sort=endDate&direction=${order.endDate}`;
    }
    if (order.numberOfPos) {
      return `&sort=numberOfPos&direction=${order.numberOfPos}`;
    }

    return '';
  }

  function getSearchQuery(term) {
    const trimmed = term.trim();
    if (trimmed.length > 0) {
      return `&search=${encodeURIComponent(term)}`;
    }
    return '';
  }

  useEffect(() => {
    const getPortalData = async () => {
      try {
        const sort = getSortingQuery(order);
        const search = getSearchQuery(term);
        const { data } = await authAxios.get(
          `campaigns?limit=${pageSize}&offset=${offset}${sort}${search}${filterStartBetween}${filterEndBetween}${filterStatus}${filterApprovalStatus}${filterCurrency}${filterPodLeader}`
        );
        const campaigns = (data && data.data) || [];
        const total = (data && data.meta && data.meta.total) || 0;
        setCampaignsState({
          campaigns,
          total,
          loading: false,
        });
      } catch (onError) {
        console.log(onError);
        setCampaignsState({
          ...campaignsState,
          loading: false,
        });
      }
    };

    getPortalData();
  }, [
    authAxios,
    currentPage,
    pageSize,
    offset,
    order,
    term,
    filterStatus,
    filterApprovalStatus,
    filterCurrency,
    filterStartBetween,
    filterEndBetween,
    filterPodLeader,
  ]);

  function addCampaign(campaign) {
    const old = campaignsState.campaigns;
    setCurrentPage(1);
    setCampaignsState({
      ...campaignsState,
      campaigns: [campaign, ...old],
    });
    toast({
      position: 'top',
      description:
        'You have successfully created the campaign. You will be notified when the finance team activates it.',
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  }

  function updateSupplier(supplier) {
    const newSuppliers = campaignsState.campaigns;
    let recordIndex = newSuppliers.findIndex((x) => x.id === supplier.id);
    newSuppliers[recordIndex] = { ...supplier };

    setCampaignsState({
      ...campaignsState,
      campaigns: newSuppliers,
    });

    toast({
      position: 'top',
      description: 'You have successfully updated a campaign.',
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  }

  function updateOrderBy(key, value) {
    setOrder({ ...clearedOrderBy, [key]: value });
    setCurrentPage(1);
  }

  function updateFilterStatus(status) {
    if (status === '&status=none') {
      setFilterStatus('');
    } else {
      setFilterStatus(status);
    }
    setCurrentPage(1);
  }

  function updateFilterApprovalStatus(status) {
    if (status === '&approvalStatus=none') {
      setFilterApprovalStatus('');
    } else {
      setFilterApprovalStatus(status);
    }
    setCurrentPage(1);
  }
  function updateFilterCurrency(currency) {
    if (currency === '&currency=0') {
      setFilterCurrency('');
    } else {
      setFilterCurrency(currency);
    }
    setCurrentPage(1);
  }

  function updateFilterStartBetween(between) {
    setFilterStartBetween(between);
    setCurrentPage(1);
  }
  function updateFilterEndBetween(between) {
    setFilterEndBetween(between);
    setCurrentPage(1);
  }

  function updateFilterPodLeader(podLeader) {
    if (podLeader === '&podLeader=0') {
      setFilterPodLeader('');
    } else {
      setFilterPodLeader(podLeader);
    }
    setCurrentPage(1);
  }

  function clearAllFilters() {
    setFilterStatus('');
    setFilterApprovalStatus('');
    setFilterCurrency('');
    setFilterPodLeader('');
    setFilterStartBetween('');
    setFilterEndBetween('');
    setCurrentPage(1);
  }

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);
  };

  const handlePageSizeChange = (event) => {
    const pageSize = Number(event.target.value);
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  function updateTerm(term) {
    setCurrentPage(1);
    setTerm(term);
  }

  const handleCsvDownload = async () => {
    try {
      const sort = getSortingQuery(order);
      const search = getSearchQuery(term);
      const csvExport = `&export=true`;
      const response = await authAxios.get(
        `campaigns?limit=${pageSize}&offset=${offset}${sort}${search}${filterStartBetween}${filterEndBetween}${filterStatus}${filterApprovalStatus}${filterCurrency}${filterPodLeader}${csvExport}`,
        {
          responseType: 'blob',
        }
      );
      const blob = new Blob([response.data], { type: 'text/csv; charset=UTF-8' });
      const url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = 'campaigns.csv';
      a.click();
    } catch (onError) {
      console.log(onError);
    }
  };

  return (
    <Container w="100%" maxWidth="100%" pt={4} pr={10} pl={10} pb={14}>
      {auth.isFinance() && auth.getAccessToken() && <SageRestartSync />}
      <Heading as="h2" size="lg" textAlign="left" fontWeight="700" pt={4} mb={[4, 4, 5]}>
        Campaigns
      </Heading>
      <HStack justifyContent="space-between">
        <Box>
          {auth.isCampaignManager() && <CreateCampaignButton addCampaign={addCampaign} />}
        </Box>
        <Box d="flex" flexDirection="row">
          {auth.isFinance() && (
            <Button
              leftIcon={<DownloadIcon width="17px" color="#fff" height="19px" />}
              colorScheme="brand"
              borderRadius="8px"
              fontSize="14px"
              fontWeight="500"
              pt="18px"
              pb="18px"
              _hover={campaignsState?.campaigns?.length === 0 ? null : { bg: '#000' }}
              _expanded={{ bg: 'black', color: 'white' }}
              _focus={{ boxShadow: 'none' }}
              onClick={handleCsvDownload}
              disabled={campaignsState?.campaigns?.length === 0}
              mr="1rem"
            >
              Download (.csv)
            </Button>
          )}
          <Search updateTerm={updateTerm} />
          <Box width="15px" />
          <FilterCampaigns
            clearAllFilters={clearAllFilters}
            updateFilterStatus={updateFilterStatus}
            updateFilterApprovalStatus={updateFilterApprovalStatus}
            updateFilterCurrency={updateFilterCurrency}
            updateFilterStartBetween={updateFilterStartBetween}
            updateFilterEndBetween={updateFilterEndBetween}
            updateFilterPodLeader={updateFilterPodLeader}
          />
        </Box>
      </HStack>
      <CampaignList
        campaigns={campaignsState.campaigns}
        handlePageSizeChange={handlePageSizeChange}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        pagesQuantity={pagesQuantity}
        updateSupplier={updateSupplier}
        isCampaign={auth.isCampaignManager()}
        isFinance={auth.isFinanceManager()}
        orderBy={order}
        updateOrderBy={updateOrderBy}
        updateFilterStartBetween={updateFilterStartBetween}
        updateFilterEndBetween={updateFilterEndBetween}
        loading={campaignsState.loading}
      />
    </Container>
  );
}
