import React, { useContext, useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import // Link as RouterLink,
// useHistory
'react-router-dom';
import {
  Input,
  List,
  ListItem,
  Flex,
  Box,
  // Link,
  FormControl,
  // FormLabel,
  Text,
  // useToast,
} from '@chakra-ui/react';
import { useDebounce } from '../../../custom-hooks/useDebounce';
import { HttpContext } from '../../../context/HttpContext';
// import map from "lodash/map";
// import filter from "lodash/filter";
// import { getFullNameFromUser } from "../../../util";
import { alreadyExistSupplierCode } from '../../../util/supplier/helper';
import { hasLettersAndNumbers } from '../../../util';

const ComboboxInput = React.forwardRef(({ ...props }, ref) => {
  return <Input {...props} ref={ref} _placeholder={{ textTransform: 'none' }} />;
});

const ComboboxList = React.forwardRef(({ isOpen, ...props }, ref) => {
  return <List display={isOpen ? null : 'none'} py={2} {...props} ref={ref} />;
});

const ComboboxItem = React.forwardRef(
  ({ itemIndex, highlightedIndex, ...props }, ref) => {
    const isActive = itemIndex === highlightedIndex;

    return (
      <ListItem
        transition="background-color 220ms, color 220ms"
        bg={isActive ? '#ebecf0' : null}
        px={4}
        py={2}
        cursor="pointer"
        {...props}
        ref={ref}
      />
    );
  }
);

export default function AutocompleteSupplierCodeInput({
  autocompleteState,
  updateAutocompleteState,
  inputValue,
}) {
  const { error } = autocompleteState;
  const [inputItems, setInputItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 350);
  const { authAxios } = useContext(HttpContext);
  // const history = useHistory();
  // const toast = useToast();
  useEffect(() => {
    const getData = async () => {
      if (debouncedSearchTerm === '') return;
      if (error) return;
      try {
        const { data } = await authAxios.get(
          `users/suppliers/search/autocomplete?type=code&search=${debouncedSearchTerm}`
        );
        const existed = alreadyExistSupplierCode(debouncedSearchTerm, data);
        if (existed) {
          // const message =
          //   "This code is already in use. Please choose a different code.";
          // setError("code", { type: "server", message });
          const message = 'This code is already in use. Please choose a different code.';
          updateAutocompleteState({
            ...autocompleteState,
            code: debouncedSearchTerm,
            error: message,
          });
          updateAutocompleteState({
            code: debouncedSearchTerm,
            error: message,
            touched: true,
          });

          // toast({
          //   position: "top",
          //   description: message,
          //   status: "warning",
          //   duration: 6000,
          //   isClosable: true,
          // });
        }
        setInputItems(data);
      } catch (onError) {
        console.log(onError);
      }
    };

    getData();
  }, [authAxios, debouncedSearchTerm]);

  function handleSelected(selectedItem) {}

  function handleSelectedInputValueChange(inputValue) {
    const trimmedInputValue = inputValue.trim();
    setSearchTerm(trimmedInputValue);
    if (trimmedInputValue === '') {
      updateAutocompleteState({
        code: null,
        error: 'This is a mandatory field.',
        touched: true,
      });

      return;
    }

    if (!hasLettersAndNumbers(trimmedInputValue)) {
      updateAutocompleteState({
        code: null,
        error: 'The code should it should be a mix of letters and numbers.',
        touched: true,
      });

      return;
    }

    if (trimmedInputValue.length > 8) {
      updateAutocompleteState({
        code: null,
        error: 'The code should have a maximum of 8 characters.',
        touched: true,
      });

      return;
    }

    updateAutocompleteState({
      code: trimmedInputValue,
      error: null,
      touched: true,
    });
  }

  const {
    isOpen,
    // selectedItem,
    // getToggleButtonProps,
    // getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    // selectItem,
    // itemToString,
  } = useCombobox({
    items: inputItems,
    initialInputValue: inputValue,
    // itemToString: (item) => (item ? `${item.code}, ${item.campaignName}` : ""),
    itemToString: (item) => (item ? item.code : ''),
    onInputValueChange: ({ inputValue }) => {
      // handleSelectedInputValueChange(inputValue.trim());
      handleSelectedInputValueChange(inputValue);
    },
    onSelectedItemChange({ selectedItem }) {
      handleSelected(selectedItem);
    },
  });
  return (
    <Flex direction="column">
      <Flex {...getComboboxProps()} direction="column" flex="1 1 auto" mb={[4, 4, 8]}>
        <FormControl>
          <ComboboxInput
            {...getInputProps()}
            placeholder="Start typing to add a supplier code"
            flex="0 0 auto"
            mt={3}
            textTransform="uppercase"
          />
        </FormControl>
        <Box boxShadow="0 1px 13px rgba(29, 44, 45, 0.12)" borderRadius="6px">
          {inputItems.length > 0 && (
            <ComboboxList
              isOpen={isOpen}
              {...getMenuProps()}
              flex={1}
              overflowY="auto"
              mt={0}
            >
              <Box color="#a0a0a0" w="100%" p="5px 15px" fontStyle="italic">
                These codes have already been used:
              </Box>
              {inputItems.map((supplier, index) => (
                <ComboboxItem
                  {...getItemProps({ supplier, index })}
                  itemIndex={index}
                  highlightedIndex={highlightedIndex}
                  key={index}
                >
                  <Text>{supplier.code}</Text>
                </ComboboxItem>
              ))}
            </ComboboxList>
          )}
        </Box>
        {error ? (
          <Box color="error.500" fontSize="13px">
            {error}
          </Box>
        ) : null}
      </Flex>
    </Flex>
  );
}
