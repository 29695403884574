import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Box,
  useToast,
  SimpleGrid,
  Text,
  Button,
  Link,
} from '@chakra-ui/react';

import { HttpContext } from '../../../context/HttpContext';
import { getMessage } from '../../../util';

const PayPalInputs = ({ onClose, supplier, updateSupplier, isChecked, isEdit }) => {
  const toast = useToast();
  const validationSchema = Yup.object().shape({
    payPalAccount: Yup.string()
      .email('Enter a valid email')
      .required('This is mandatory field'),
    payPalAccountConfirm: Yup.string()
      .required('This is mandatory field')
      .oneOf([Yup.ref('payPalAccount')], 'Emails do not match'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;
  const { authAxios } = useContext(HttpContext);

  const payPalAccount = supplier?.payPalAccount || '';
  const payPalAccountConfirm = '';

  const onSubmit = async (values) => {
    try {
      const { payPalAccount, payPalAccountConfirm } = values;
      let dataToSend = {
        payPalAccount,
        payPalAccountConfirm,
        type: 'paypal',
      };

      if (isChecked) {
        // if (isChecked && isEdit) {
        dataToSend = { ...dataToSend, agreeChangesPendingInvoices: true };
      }

      const { data } = await authAxios.patch(
        `accounts/suppliers/${supplier.id}/payment-details`,
        dataToSend
      );

      if (data) {
        updateSupplier(data);
        toast({
          position: 'top',
          description:
            'Payment details are now sent to Goat team for approval. You will be notified when the process is completed.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
        onClose();
      }
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
          <Box>
            <FormControl isInvalid={errors.payPalAccount} mb={[4, 4, 8]}>
              <FormLabel htmlFor="accountHtmlFor">Your PayPal email address</FormLabel>
              <Input
                isInvalid
                name="payPalAccount"
                id="payPalAccount"
                defaultValue={payPalAccount}
                focusBorderColor="black"
                errorBorderColor="error.500"
                placeholder="Enter PayPal email address"
                {...register('payPalAccount')}
              />
              <FormErrorMessage>
                {errors.payPalAccount?.message || errors.payPalAccount}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box>
            <FormControl isInvalid={errors.payPalAccountConfirm} mb={[4, 4, 8]}>
              <FormLabel htmlFor="accountHtmlFor">
                Confirm your PayPal email address
              </FormLabel>
              <Input
                isInvalid
                name="payPalAccountConfirm"
                id="payPalAccountConfirm"
                defaultValue={payPalAccountConfirm}
                focusBorderColor="black"
                errorBorderColor="error.500"
                placeholder="Re-type your PayPal email address"
                {...register('payPalAccountConfirm')}
              />
              <FormErrorMessage>
                {errors.payPalAccountConfirm?.message || errors.payPalAccountConfirm}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </SimpleGrid>
        <Box textAlign="right">
          <Text fontSize="14px" mb="30px">
            Don't have PayPal account?{' '}
            <Link
              color="brand.400"
              href="https://www.paypal.com/rs/webapps/mpp/account-selection"
              isExternal
            >
              Create PayPal account
            </Link>
          </Text>
          <Text fontSize="12px">
            You acknowledge that any deduction from the Fee made by your bank or third
            party service provider, including but not limited to PayPal, is outside of
            Goat's control and you shall be liable for paying such deductions and Goat
            shall in no event be liable to pay such deductions. We do not pay via 'Family
            and Friends'.
          </Text>
          <Button
            type="submit"
            size="lg"
            colorScheme="brand"
            disabled={
              !isDirty ||
              !isValid ||
              isSubmitting ||
              (supplier.numberOfPendingInvoices > 0 && !isChecked)
            }
            textTransform="initial"
            _hover={{ bg: '#000' }}
            borderRadius="10px"
            mt="25px"
          >
            Save
          </Button>
          <Button
            onClick={onClose}
            variant="ghost"
            size="lg"
            colorScheme="brand"
            textTransform="initial"
            borderRadius="10px"
            mt="25px"
            ml="10px"
          >
            Cancel
          </Button>
        </Box>
      </form>
    </>
  );
};
export default PayPalInputs;
