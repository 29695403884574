import React, { Component } from 'react';
import PropTypes from 'prop-types';

//import "../lib/stickyHeader.js";
import Header from './Header';
import Footer from './Footer';
import { MainLayout, MainContent, MainContainer } from './styles';
import Navigation from './HeaderNavigation';

class LayoutMain extends Component {
  render() {
    const { children } = this.props;
    return (
      <MainLayout>
        <Header />
        <MainContent>
          <Navigation />
          <MainContainer>{children}</MainContainer>
        </MainContent>
        <Footer />
      </MainLayout>
    );
  }
}

LayoutMain.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
  pathname: PropTypes.string,
};

export default LayoutMain;
