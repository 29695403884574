import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { Heading, Container, Box, Link, useToast, Spinner } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { HttpContext } from '../context/HttpContext';
import InvoiceEditForm from '../components/invoice/InvoiceEdit';
import { AuthContext } from '../context/AuthContext';
import { getMessage, getMessageStatus } from '../util';
import ScrollToTop from '../util/scrollToTop';
import findIndex from 'lodash/findIndex';

export default function InvoiceEdit() {
  const { id } = useParams();
  const toast = useToast();
  const history = useHistory();
  const [state, setState] = useState({
    updatedOrder: null,
    orderNumber: null,
    activityLog: null,
    loading: true,
    error: null,
    pending: false,
    invoice: null,
    invoiceSupplier: null,
    referenceNumberNew: null,
    suppliers: null,
  });
  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await authAxios.get(`/invoices/${id}`);
        if (
          data.purchaseOrder.status !== 'invoice_pending_approval' &&
          data.purchaseOrder.status !== 'invoice_pending_repair'
        ) {
          toast({
            position: 'top',
            description: "Can't edit invoice in this status.",
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/orders');
          }, 3000);
        } else {
          const response = await authAxios.get(
            `/users/${data.purchaseOrder.supplier.user.id}/suppliers`
          );
          const userSuppliers = response.data;
          const recordIndex = findIndex(
            userSuppliers,
            (x) => x.id === data.purchaseOrder.supplier.id
          );

          let invoiceSupplier = null;
          if (recordIndex > -1) {
            invoiceSupplier = userSuppliers[recordIndex];
          }

          setState({
            ...state,
            invoice: data,
            invoiceSupplier,
            pending: false,
            loading: false,
          });
        }
      } catch (onError) {
        setState({
          ...state,
          error: 'Something went wrong',
          loading: false,
          pending: false,
        });
        const status = getMessageStatus(onError);
        if (status === 403) {
          toast({
            position: 'top',
            description: 'You are not authorized to view this page',
            status: 'error',
            duration: 2900,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/orders');
          }, 3000);
        }
        console.log(onError);
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top',
            description: message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        }
      }
    };

    getData();
  }, [authAxios]);

  function updateActivityLog(activityLog) {
    setState({
      ...state,
      activityLog,
    });
    toast({
      position: 'top',
      description: `You have successfully sent your comment. You will be notified when the ${
        auth.isSupplier() ? `goat ` : `campaign`
      } team responds.`,
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  }

  const { invoice, loading, invoiceSupplier } = state;

  return (
    <Container w="100%" maxWidth="100%" pt={4} pr={10} pl={10} pb={14}>
      {!invoice || loading ? (
        <>
          <Box p={12} d="flex" alignItems="center" justifyContent="center" h="400px">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="xl"
            />
          </Box>
        </>
      ) : (
        <>
          <ScrollToTop />
          <Link
            color="brand.500"
            as={RouterLink}
            to={`/invoices/${invoice.id}`}
            fontSize="14px"
          >
            <ChevronLeftIcon /> Invoice Details
          </Link>
          <Box>
            <Heading
              as="h2"
              size="lg"
              textAlign="left"
              fontWeight="700"
              pt={4}
              mb={1}
              d="inline-block"
            >
              Edit invoice
            </Heading>
          </Box>
          <InvoiceEditForm
            invoice={invoice}
            updateActivityLog={updateActivityLog}
            invoiceSupplier={invoiceSupplier}
          />
        </>
      )}
    </Container>
  );
}
