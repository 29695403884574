import React from 'react';
export const PaymentMethodIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    {...props}
  >
    <path
      d="M46.93,9.61c-6.4,0-12.05,3.63-14.93,8.85c-2.99-5.23-8.53-8.85-14.93-8.85C7.68,9.61,0,17.29,0,26.67
	s7.68,17.07,17.07,17.07c6.4,0,11.95-3.63,14.93-8.85c2.88,5.23,8.53,8.85,14.93,8.85c9.39,0,17.07-7.68,17.07-17.07
	S56.32,9.61,46.93,9.61z M17.07,41.61c-8.21,0-14.93-6.72-14.93-14.93s6.72-14.93,14.93-14.93c6.19,0,11.52,3.84,13.76,9.28
	c-0.64,1.81-0.96,3.63-0.96,5.65s0.32,3.84,0.96,5.65C28.59,37.77,23.25,41.61,17.07,41.61z M46.93,41.61
	C38.72,41.61,32,34.89,32,26.67s6.72-14.93,14.93-14.93s14.93,6.72,14.93,14.93S55.15,41.61,46.93,41.61z"
    />
    <path
      d="M27.73,22.41v-2.13l-10.67-5.33L6.4,20.27v2.13h2.13v7.47H7.47v2.13H6.4v2.13h21.33v-2.13h-1.07v-2.13H25.6v-7.47H27.73z
	 M19.2,29.87h-4.27v-7.47h4.27V29.87z M10.67,22.41h2.13v7.47h-2.13V22.41z M23.47,29.87h-2.13v-7.47h2.13V29.87z"
    />
    <path
      d="M53.33,23.47v-1.07c0-2.35-1.92-4.27-4.27-4.27H48v-2.13h-2.13v2.13H44.8c-2.35,0-4.27,1.92-4.27,4.27v1.07
	c0,2.35,1.92,4.27,4.27,4.27h1.07v5.33H44.8c-1.17,0-2.13-0.96-2.13-2.13v-1.07h-2.13v1.07c0,2.35,1.92,4.27,4.27,4.27h1.07v2.13H48
	v-2.13h1.07c2.35,0,4.27-1.92,4.27-4.27v-1.07c0-2.35-1.92-4.27-4.27-4.27H48v-5.33h1.07c1.17,0,2.13,0.96,2.13,2.13v1.07H53.33z
	 M49.07,27.74c1.17,0,2.13,0.96,2.13,2.13v1.07c0,1.17-0.96,2.13-2.13,2.13H48v-5.33H49.07z M45.87,25.61H44.8
	c-1.17,0-2.13-0.96-2.13-2.13v-1.07c0-1.17,0.96-2.13,2.13-2.13h1.07V25.61z"
    />
    <path
      d="M48.96,7.47L45.12-0.1l-1.81,2.67C39.79,0.86,35.95,0.01,32,0.01c-6.61,0-12.91,2.45-17.81,6.83l1.39,1.6
	C20.16,4.38,25.92,2.14,32,2.14c3.52,0,7.04,0.75,10.13,2.24l-1.71,2.67L48.96,7.47z"
    />
    <path
      d="M49.6,46.73l-1.39-1.6c-4.48,3.95-10.24,6.08-16.21,6.08c-3.52,0-7.04-0.75-10.13-2.24l1.71-2.67l-8.53-0.53l3.84,7.57
	l1.81-2.67c3.52,1.71,7.47,2.56,11.31,2.56C38.51,53.34,44.8,50.99,49.6,46.73z"
    />
  </svg>
);
