import styled from 'styled-components';
import { customTheme } from '../theme/customChakraTheme.js';
// import bgLogin1 from "../../assets/images/login1.jpeg";

export const MainContent = styled.div``;
export const MainContainer = styled.div``;
export const HeaderStyles = styled.div``;
export const MainNavigation = styled.div``;

export const AuthContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

export const AuthContainerMain = styled.div`
  min-width: 0;
  height: 100vh;
  position: sticky;
  top: 0;
  display: block;
  background-color: ${customTheme.colors.brand[500]};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const AuthContainerAction = styled.div`
  min-height: 100vh;
  display: grid;
  grid-gap: 24px;
  grid-template-rows: 1fr auto;
  min-width: 0;
  width: 600px;
`;

export const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  ${MainContent} {
    display: flex;
    padding-top: 62px;
  }
  ${MainNavigation} {
    position: sticky;
    top: 66px;
    width: 180px;
    height: calc(100vh - 8.125rem);
    overflow-y: auto;
    flex-shrink: 0;
    display: block;
    overscroll-behavior: contain;
    box-shadow: 1px 0 5px -2px rgba(29, 44, 45, 0.06);
    border-right: 1px solid #eeefef;
    nav {
      display: grid;
      grid-template-rows: auto 1fr;
      align-items: center;
      > div {
        a {
          display: block;
        }
      }
    }
    ul {
      list-style: none;
    }
    li {
      list-style: none;
      margin: 0;

      a {
        position: relative;
        font-size: 14px;
        font-family: ${customTheme.fonts.body};
        color: #a9b1b4;
        height: 64px;
        font-weight: 500;
        padding-left: 17px;
        display: flex;
        align-items: center;
        overflow: hidden;

        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
          left: -33%;
          width: 69%;
          height: 3px;
          border-radius: 50em;
          transition: transform 0.2s ease-in-out;
          background: ${customTheme.colors.brand[500]};
          transform: scaleX(0);
        }

        &:hover {
          background: #f5f6fa;
        }

        &.isActive {
          position: relative;
          background: #f5f6fa;
          color: #333;
          &::after {
            transform: rotate(90deg);
          }
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  ${MainContainer} {
    flex: 1;
    background-color: #f5f6fa;
  }
  ${HeaderStyles} {
    box-shadow: 0 1px 5px rgba(29, 44, 45, 0.06);
    border: 1px solid #eeefef;
    position: fixed;
    width: 100%;
    top: 0;
    background: white;
    z-index: 1;
  }
`;
