import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import map from 'lodash/map';

import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Box,
  useToast,
  SimpleGrid,
  Select,
  Text,
  Button,
} from '@chakra-ui/react';

import { HttpContext } from '../../../context/HttpContext';
// import { AuthContext } from "../../../context/AuthContext";
import { getMessage } from '../../../util';
import { CountriesContext } from '../../../context/country/CountriesContext';
import {
  getBankTransferFields,
  getValidationSchemaBTFields,
  getValuesData,
} from '../../../util/country';

const BankTransferInputs = ({
  onClose,
  supplier,
  updateSupplier,
  isChecked,
  // isEdit,
}) => {
  const countriesContext = useContext(CountriesContext);
  const toast = useToast();
  const {
    accountName,
    accountNumber,
    accountNumberOrIban,
    additionalReference,
    bankAddress,
    bankCode,
    bankName,
    bsbCode,
    // canSubmitInvoice,
    // code,
    country: initialCountry,
    iban,
    // id,
    ifscCode,
    routingNumber,
    sortCode,
    // payPalAccount,
    // routingNumber,
    swift,
  } = supplier;

  const [country, setCountry] = React.useState(initialCountry);
  const validationSchema = Yup.object().shape(getValidationSchemaBTFields(country));
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;

  const { authAxios } = useContext(HttpContext);

  const handleChangeType = (e) => {
    const countryNum = parseInt(e.target.value, 10);
    const country = countriesContext.getCountryById(countryNum);
    setCountry(country);
  };

  const onSubmit = async (values) => {
    try {
      let dataToSend = {
        ...getValuesData(values, country),
        type: 'bank',
        country: country.countryId,
      };

      if (isChecked) {
        dataToSend = { ...dataToSend, agreeChangesPendingInvoices: true };
      }

      const { data } = await authAxios.patch(
        `accounts/suppliers/${supplier.id}/payment-details`,
        dataToSend
      );
      if (data) {
        updateSupplier(data);

        toast({
          position: 'top',
          description:
            'Payment details are now sent to Goat team for approval. You will be notified when the process is completed.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });

        onClose();
      }
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };

  const fields = getBankTransferFields(country);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <>
        <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
          <Box>
            <FormControl isInvalid={errors.bankName} mb={[4, 4, 8]} mt="0">
              <FormLabel htmlFor="accountHtmlFor">Bank name</FormLabel>
              <Input
                isInvalid
                name="bankName"
                id="bankName"
                defaultValue={bankName}
                focusBorderColor="black"
                errorBorderColor="error.500"
                placeholder="Enter bank name"
                {...register('bankName')}
              />
              <FormErrorMessage>
                {errors.bankName?.message || errors.bankName}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box>
            <FormControl mb={[4, 4, 8]}>
              <FormLabel htmlFor="emailHtmlFor" fontSize="16px">
                Country where the bank is located
              </FormLabel>
              <Select
                bg="#ebecf0"
                h="48px"
                onChange={handleChangeType}
                value={country?.countryId}
              >
                {map(countriesContext.gatAllCountriesForSelect(), (country) => (
                  <option key={country.countryId} value={country.countryId}>
                    {country.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </SimpleGrid>

        <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
          {/*<Box>*/}
          {/*  <FormControl isInvalid={errors.iban} mb={[4, 4, 8]}>*/}
          {/*    <FormLabel htmlFor="email">IBAN</FormLabel>*/}
          {/*    <Input*/}
          {/*      isInvalid*/}
          {/*      name="iban"*/}
          {/*      id="iban"*/}
          {/*      focusBorderColor="black"*/}
          {/*      errorBorderColor="error.400"*/}
          {/*      defaultValue={iban}*/}
          {/*      placeholder="Enter IBAN"*/}
          {/*      {...register("iban")}*/}
          {/*    />*/}
          {/*    <FormErrorMessage>*/}
          {/*      {errors.iban?.message || errors.iban}*/}
          {/*    </FormErrorMessage>*/}
          {/*  </FormControl>*/}
          {/*</Box>*/}
          {/*<Box>*/}
          {/*  <FormControl isInvalid={errors.swift} mb={[4, 4, 8]}>*/}
          {/*    <FormLabel htmlFor="email">SWIFT code</FormLabel>*/}
          {/*    <Input*/}
          {/*      isInvalid*/}
          {/*      name="swift"*/}
          {/*      id="swift"*/}
          {/*      focusBorderColor="black"*/}
          {/*      errorBorderColor="error.400"*/}
          {/*      defaultValue={swift}*/}
          {/*      placeholder="Enter Swift code"*/}
          {/*      {...register("swift")}*/}
          {/*    />*/}
          {/*    <FormErrorMessage>*/}
          {/*      {errors.swift?.message || errors.swift}*/}
          {/*    </FormErrorMessage>*/}
          {/*  </FormControl>*/}
          {/*</Box>*/}

          <Box>
            <FormControl isInvalid={errors.accountName} mb={[4, 4, 8]}>
              <FormLabel htmlFor="email">Account name</FormLabel>
              <Input
                isInvalid
                name="accountName"
                id="accountName"
                focusBorderColor="black"
                errorBorderColor="error.400"
                defaultValue={accountName}
                placeholder="Enter account name"
                {...register('accountName')}
              />
              <FormErrorMessage>
                {errors.accountName?.message || errors.accountName}
              </FormErrorMessage>
            </FormControl>
          </Box>

          {fields.accountNumber && (
            <Box>
              <FormControl isInvalid={errors.accountNumber} mb={[4, 4, 8]}>
                <FormLabel htmlFor="email">Account number</FormLabel>
                <Input
                  isInvalid
                  name="accountNumber"
                  id="accountNumber"
                  focusBorderColor="black"
                  errorBorderColor="error.400"
                  defaultValue={accountNumber}
                  placeholder="Enter account number"
                  {...register('accountNumber')}
                />
                <FormErrorMessage>
                  {errors.accountNumber?.message || errors.accountNumber}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}
          {fields.accountNumberOrIban && (
            <Box>
              <FormControl isInvalid={errors.accountNumberOrIban} mb={[4, 4, 8]}>
                <FormLabel htmlFor="email">Account number or IBAN</FormLabel>
                <Input
                  isInvalid
                  name="accountNumberOrIban"
                  id="accountNumberOrIban"
                  focusBorderColor="black"
                  errorBorderColor="error.400"
                  defaultValue={accountNumberOrIban}
                  placeholder="Enter account number or IBAN"
                  {...register('accountNumberOrIban')}
                />
                <FormErrorMessage>
                  {errors.accountNumberOrIban?.message || errors.accountNumberOrIban}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}
          {fields.bankCode && (
            <Box>
              <FormControl isInvalid={errors.bankCode} mb={[4, 4, 8]}>
                <FormLabel htmlFor="email">Bank code</FormLabel>
                <Input
                  isInvalid
                  name="bankCode"
                  id="bankCode"
                  focusBorderColor="black"
                  errorBorderColor="error.400"
                  defaultValue={bankCode}
                  placeholder="Enter bank code"
                  {...register('bankCode')}
                />
                <FormErrorMessage>
                  {errors.bankCode?.message || errors.bankCode}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}

          {fields.bsbCode && (
            <Box>
              <FormControl isInvalid={errors.bsbCode} mb={[4, 4, 8]}>
                <FormLabel htmlFor="email">BSB code</FormLabel>
                <Input
                  isInvalid
                  name="bsbCode"
                  id="bsbCode"
                  focusBorderColor="black"
                  errorBorderColor="error.400"
                  defaultValue={bsbCode}
                  placeholder="Enter BSB code"
                  {...register('bsbCode')}
                />
                <FormErrorMessage>
                  {errors.bsbCode?.message || errors.bsbCode}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}
          {fields.iban && (
            <Box>
              <FormControl isInvalid={errors.iban} mb={[4, 4, 8]}>
                <FormLabel htmlFor="email">IBAN</FormLabel>
                <Input
                  isInvalid
                  name="iban"
                  id="iban"
                  focusBorderColor="black"
                  errorBorderColor="error.400"
                  defaultValue={iban}
                  placeholder="Enter IBAN"
                  {...register('iban')}
                />
                <FormErrorMessage>{errors.iban?.message || errors.iban}</FormErrorMessage>
              </FormControl>
            </Box>
          )}
          {fields.ifscCode && (
            <Box>
              <FormControl isInvalid={errors.ifscCode} mb={[4, 4, 8]}>
                <FormLabel htmlFor="email">IFSC code</FormLabel>
                <Input
                  isInvalid
                  name="ifscCode"
                  id="ifscCode"
                  focusBorderColor="black"
                  errorBorderColor="error.400"
                  defaultValue={ifscCode}
                  placeholder="Enter IFSC code"
                  {...register('ifscCode')}
                />
                <FormErrorMessage>
                  {errors.ifscCode?.message || errors.ifscCode}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}
          {fields.routingNumber && (
            <Box>
              <FormControl isInvalid={errors.routingNumber} mb={[4, 4, 8]}>
                <FormLabel htmlFor="email">Routing number</FormLabel>
                <Input
                  isInvalid
                  name="routingNumber"
                  id="routingNumber"
                  focusBorderColor="black"
                  errorBorderColor="error.400"
                  defaultValue={routingNumber}
                  placeholder="Enter routing number"
                  {...register('routingNumber')}
                />
                <FormErrorMessage>
                  {errors.routingNumber?.message || errors.routingNumber}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}
          {fields.sortCode && (
            <Box>
              <FormControl isInvalid={errors.sortCode} mb={[4, 4, 8]}>
                <FormLabel htmlFor="email">SORT code</FormLabel>
                <Input
                  isInvalid
                  name="sortCode"
                  id="sortCode"
                  focusBorderColor="black"
                  errorBorderColor="error.400"
                  defaultValue={sortCode}
                  placeholder="Enter SORT code"
                  {...register('sortCode')}
                />
                <FormErrorMessage>
                  {errors.sortCode?.message || errors.sortCode}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}
          {fields.swift && (
            <Box>
              <FormControl isInvalid={errors.swift} mb={[4, 4, 8]}>
                <FormLabel htmlFor="email">SWIFT code</FormLabel>
                <Input
                  isInvalid
                  name="swift"
                  id="swift"
                  focusBorderColor="black"
                  errorBorderColor="error.400"
                  defaultValue={swift}
                  placeholder="Enter SWIFT code"
                  {...register('swift')}
                />
                <FormErrorMessage>
                  {errors.swift?.message || errors.swift}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}
        </SimpleGrid>
        <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
          <Box>
            <FormControl isInvalid={errors.additionalReference} mb={[4, 4, 8]}>
              <FormLabel htmlFor="email">Additional reference (optional)</FormLabel>
              <Input
                isInvalid
                name="additionalReference"
                id="additionalReference"
                focusBorderColor="black"
                errorBorderColor="error.400"
                defaultValue={additionalReference}
                placeholder="Enter additional reference"
                {...register('additionalReference')}
              />
              <FormErrorMessage>
                {errors.additionalReference?.message || errors.additionalReference}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box>
            <FormControl isInvalid={errors.bankAddress} mb={[4, 4, 8]}>
              <FormLabel htmlFor="email">Bank address (optional)</FormLabel>
              <Input
                isInvalid
                name="bankAddress"
                id="bankAddress"
                focusBorderColor="black"
                errorBorderColor="error.400"
                defaultValue={bankAddress}
                placeholder="Enter bank address"
                {...register('bankAddress')}
              />
              <FormErrorMessage>
                {errors.bankAddress?.message || errors.bankAddress}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </SimpleGrid>
        <Box textAlign="right">
          <Text fontSize="12px">
            You acknowledge that any deduction from the Fee made by your bank or third
            party service provider, including but not limited to PayPal, is outside of
            Goat's control and you shall be liable for paying such deductions and Goat
            shall in no event be liable to pay such deductions. We do not pay via 'Family
            and Friends'.
          </Text>
          <Button
            type="submit"
            size="lg"
            colorScheme="brand"
            disabled={
              !isDirty ||
              !isValid ||
              isSubmitting ||
              country === null ||
              (supplier.numberOfPendingInvoices > 0 && !isChecked)
            }
            textTransform="initial"
            _hover={{ bg: '#000' }}
            borderRadius="10px"
            mt="25px"
          >
            Save
          </Button>
          <Button
            onClick={onClose}
            variant="ghost"
            size="lg"
            colorScheme="brand"
            textTransform="initial"
            borderRadius="10px"
            mt="25px"
            ml="10px"
          >
            Cancel
          </Button>
        </Box>
      </>
    </form>
  );
};
export default BankTransferInputs;
