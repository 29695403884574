import React, { useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Box,
  Text,
  useToast,
} from '@chakra-ui/react';
import AutocompleteTeamInput from './AutocompleteInput';
import { getMessage } from '../../util';
import { HttpContext } from '../../context/HttpContext';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';

const initialRemovedMemberState = {
  removedMember: null,
  removedMemberError: null,
  removedMemberTouched: false,
};

export default function RemoveTeamMember({ member, removeMember }) {
  const auth = useContext(AuthContext);
  const user = auth.getUser();
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const [removedMemberState, setRemovedMemberState] = useState(initialRemovedMemberState);

  function updateRemovedMemberState({
    removedMember,
    removedMemberError,
    removedMemberTouched,
  }) {
    setRemovedMemberState({
      removedMember,
      removedMemberError,
      removedMemberTouched,
    });
  }
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleClose() {
    onClose();
    setRemovedMemberState(initialRemovedMemberState);
  }

  const onSubmit = async () => {
    try {
      await authAxios.delete(
        `users/members/${member.id}?userToTransfer=${removedMemberState.removedMember.id}`
      );

      toast({
        position: 'top',
        description: `You have successfully removed ${member.firstName} ${member.lastName} from the team.`,
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      handleClose();
      removeMember(member);
    } catch (onError) {
      setRemovedMemberState(initialRemovedMemberState);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
      setRemovedMemberState(initialRemovedMemberState);
    }
  };

  return (
    <>
      {member.id !== user.id && (
        <Button
          colorScheme="brand"
          variant="ghost"
          size="xs"
          fontSize="14px"
          onClick={onOpen}
        >
          Remove
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={handleClose} size="xl" minWidth="700px">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pt="50px" textAlign="center" fontSize="22px">
            Remove user from team?
          </ModalHeader>
          <ModalCloseButton color="#d1d2d2" _focus={{ outline: '0' }} size="sm" />
          <ModalBody pt="10px" pr="30px" pl="30px">
            <Box>
              <b>{member.email}</b>{' '}
              <Text as="span" color="#868789">
                will no longer be able to access this account.
              </Text>{' '}
            </Box>
            <Box pt="40px">
              <Text>Transfer ownership:</Text>
              <Text color="#868789" fontSize="14px">
                Select another team member who will take over the communication associated
                with this user.
              </Text>
            </Box>
            <Box>
              <AutocompleteTeamInput
                member={member}
                removedMemberState={removedMemberState}
                updateRemovedMemberState={updateRemovedMemberState}
                onClose={handleClose}
              />
            </Box>
          </ModalBody>

          <ModalFooter
            d="flex"
            alignItems="center"
            justifyContent="center"
            pt="0px"
            pb="40px"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Button
                colorScheme="brand"
                mr={3}
                type="submit"
                disabled={
                  isSubmitting ||
                  member.id === removedMemberState.removedMember?.id ||
                  removedMemberState.removedMember === null
                }
              >
                Yes, remove user
              </Button>
            </form>
            <Button
              variant="outline"
              color="brand.500"
              pt="15px"
              pb="15px"
              onClick={handleClose}
              disabled={isSubmitting}
            >
              No, go back
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
