import first from 'lodash/first';
import * as Yup from 'yup';
import { REGEX_ONLY_NUMBERS } from './regExr';

export const statesUS = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'District Of Columbia',
    code: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    code: 'FM',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Palau',
    code: 'PW',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virgin Islands',
    code: 'VI',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
];

export function firstStateUS() {
  const option = first(statesUS);
  return option.code || null;
}

export function getUSStateCountryName(code) {
  for (let i = 0; i < statesUS.length; i++) {
    if (statesUS[i].code === code) {
      return statesUS[i].name;
    }
  }

  return null;
}

export function getUSStateCountryCode(name) {
  for (let i = 0; i < statesUS.length; i++) {
    if (statesUS[i].name === name) {
      // console.log(statesUS[i].code)
      return statesUS[i].code;
    }
  }

  return null;
}

export function isEU(country) {
  return country?.continentCode === 'EU';
}

export function isUS(country) {
  return country?.code === 'US';
}
export function isGB(country) {
  return country?.code === 'GB';
}
export function isAU(country) {
  return country?.code === 'AU';
}
export function isNZ(country) {
  return country?.code === 'NZ';
}
export function isMY(country) {
  return country?.code === 'MY';
}
export function isIN(country) {
  return country?.code === 'IN';
}
export function isJP(country) {
  return country?.code === 'JP';
}
export function isMX(country) {
  return country?.code === 'MX';
}
export function isCA(country) {
  return country?.code === 'CA';
}

export function getBankTransferFields(country) {
  // const initialFields = {
  //   accountNumber: false,
  //   accountNumberOrIban: false,
  //   bankCode: false,
  //   bsbCode: false,
  //   iban: false,
  //   ifscCode: false,
  //   routingNumber: false,
  //   sortCode: false,
  //   swift: false,
  // };

  const cleanFields = {
    accountNumber: false,
    accountNumberOrIban: false,
    bankCode: false,
    bsbCode: false,
    iban: false,
    ifscCode: false,
    routingNumber: false,
    sortCode: false,
    swift: false,
  };

  if (isGB(country)) {
    return {
      ...cleanFields,
      accountNumber: true,
      sortCode: true,
    };
  }

  if (isUS(country)) {
    return {
      ...cleanFields,
      accountNumber: true,
      routingNumber: true,
    };
  }

  if (isAU(country)) {
    return {
      ...cleanFields,
      accountNumber: true,
      bsbCode: true,
    };
  }
  if (isNZ(country) || isMY(country)) {
    return {
      ...cleanFields,
      accountNumber: true,
      bankCode: true,
    };
  }

  if (isIN(country)) {
    return {
      ...cleanFields,
      ifscCode: true,
      accountNumberOrIban: true,
    };
  }

  if (isJP(country) || isMX(country) || isCA(country)) {
    return {
      ...cleanFields,
      swift: true,
      accountNumberOrIban: true,
    };
  }

  return {
    ...cleanFields,
    iban: true,
    swift: true,
  };
}

export function getValidationSchemaBTFields(country) {
  let start = {
    bankName: Yup.string().required('This is mandatory field'),
    bankAddress: Yup.string(),
    additionalReference: Yup.string(),
    accountName: Yup.string().required('This is mandatory field'),
    accountNumber: Yup.string(),
    accountNumberOrIban: Yup.string(),
    bankCode: Yup.string(),
    bsbCode: Yup.string(),
    iban: Yup.string(),
    ifscCode: Yup.string(),
    routingNumber: Yup.string(),
    sortCode: Yup.string(),
    swift: Yup.string(),
  };

  if (isGB(country)) {
    return {
      ...start,
      accountNumber: Yup.string()
        .matches(REGEX_ONLY_NUMBERS, 'The field must contain only numbers')
        .max(8, 'Please enter 8 digit number')
        .min(8, 'Please enter 8 digit number')
        .required('This is mandatory field'),
      sortCode: Yup.string()
        .matches(REGEX_ONLY_NUMBERS, 'The field must contain only numbers')
        .max(6, 'Please enter 6 digit number')
        .min(6, 'Please enter 6 digit number')
        .required('This is mandatory field'),
    };
  }

  if (isUS(country)) {
    return {
      ...start,
      accountNumber: Yup.string().required('This is mandatory field'),
      routingNumber: Yup.string().required('This is mandatory field'),
    };
  }

  if (isAU(country)) {
    return {
      ...start,
      accountNumber: Yup.string().required('This is mandatory field'),
      bsbCode: Yup.string().required('This is mandatory field'),
    };
  }
  if (isNZ(country) || isMY(country)) {
    return {
      ...start,
      accountNumber: Yup.string().required('This is mandatory field'),
      bankCode: Yup.string().required('This is mandatory field'),
    };
  }

  if (isIN(country)) {
    return {
      ...start,
      ifscCode: Yup.string().required('This is mandatory field'),
      accountNumberOrIban: Yup.string().required('This is mandatory field'),
    };
  }

  if (isJP(country) || isMX(country) || isCA(country)) {
    return {
      ...start,
      swift: Yup.string().required('This is mandatory field'),
      accountNumberOrIban: Yup.string().required('This is mandatory field'),
    };
  }

  return {
    ...start,
    iban: Yup.string().required('This is mandatory field'),
    swift: Yup.string().required('This is mandatory field'),
  };
}

export function getValuesData(values, country) {
  const { bankAddress, bankName, additionalReference, accountName } = values;
  let data = { bankAddress, bankName, additionalReference, accountName };

  if (isGB(country)) {
    return {
      ...data,
      accountNumber: values.accountNumber,
      sortCode: values.sortCode,
    };
  }

  if (isUS(country)) {
    return {
      ...data,
      accountNumber: values.accountNumber,
      routingNumber: values.routingNumber,
    };
  }

  if (isAU(country)) {
    return {
      ...data,
      accountNumber: values.accountNumber,
      bsbCode: values.bsbCode,
    };
  }
  if (isNZ(country) || isMY(country)) {
    return {
      ...data,
      accountNumber: values.accountNumber,
      bankCode: values.bankCode,
    };
  }

  if (isIN(country)) {
    return {
      ...data,
      ifscCode: values.ifscCode,
      accountNumberOrIban: values.accountNumberOrIban,
    };
  }

  if (isJP(country) || isMX(country) || isCA(country)) {
    return {
      ...data,
      swift: values.swift,
      accountNumberOrIban: values.accountNumberOrIban,
    };
  }

  return {
    ...data,
    iban: values.iban,
    swift: values.swift,
  };
}
