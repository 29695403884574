import React, { useContext } from 'react';
import { Heading, Container, Box, Button, useToast } from '@chakra-ui/react';
import { HttpContext } from '../context/HttpContext';
import { AuthContext } from '../context/AuthContext';

import AccountForm from '../components/account/accountForm';
import SupplierAccountForm from '../components/account/SupplierAccountForm';
import { CountriesProvider } from '../context/country/CountriesContext';
import UseCountries from '../custom-hooks/useCountries';
import SupplierAccountCompanyForm from '../components/account/SupplierAccountCompanyForm';
import { getUrlSearchParams, getMessage } from '../util';

export default function Account() {
  let query = getUrlSearchParams();
  const auth = useContext(AuthContext);
  const { authAxios } = useContext(HttpContext);
  const toast = useToast();

  const handleConfirmSupplierAccountDetails = async () => {
    try {
      const res = await authAxios.post(
        `accounts/suppliers/me/confirm-account-details-up-to-date`,
        {}
      );
      if (res.data) {
        auth.setAuthUser(res.data);
        toast({
          position: 'top',
          description: 'Confirmation saved.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      }
    } catch (onError) {
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Container
      w="100%"
      maxWidth={auth.shouldSupplierConfirmAccountDetails() ? '1186px' : '100%'}
      pt={4}
      pr={10}
      pl={10}
      pb={14}
    >
      {auth.shouldSupplierConfirmAccountDetails() ? (
        <>
          <Heading
            as="h2"
            size="lg"
            textAlign="center"
            fontWeight="700"
            pt={10}
            mb={9}
            color="#333"
          >
            Is all your information up to date?
          </Heading>
          <Box
            as="p"
            color="#333"
            maxWidth="720px"
            textAlign="center"
            margin="0 auto 46px"
          >
            Please review your profile and confirm below to ensure all details are up to
            date and accurate.
            <br />
            Note: If any changes are required, these will be reflected on future and
            pending invoices. Any currently pending payments will be paid to the
            prevailing account details.
          </Box>
        </>
      ) : (
        <Heading
          as="h2"
          size="lg"
          textAlign="left"
          fontWeight="700"
          pt={4}
          mb={[4, 4, 5]}
        >
          Account
        </Heading>
      )}
      {auth.isSupplierIndividual() ? (
        <CountriesProvider>
          <UseCountries>
            <SupplierAccountForm redirect={query.get('redirect')} />
          </UseCountries>
        </CountriesProvider>
      ) : auth.isSupplierCompany() ? (
        <CountriesProvider>
          <UseCountries>
            <SupplierAccountCompanyForm redirect={query.get('redirect')} />
          </UseCountries>
        </CountriesProvider>
      ) : (
        <AccountForm />
      )}

      {auth.shouldSupplierConfirmAccountDetails() && (
        <Box textAlign="center">
          <Button
            size="lg"
            colorScheme="brand"
            textTransform="initial"
            _hover={{ bg: '#000' }}
            borderRadius="10px"
            mt={12}
            onClick={handleConfirmSupplierAccountDetails}
          >
            I confirm the information above is up to date
          </Button>
        </Box>
      )}
    </Container>
  );
}
