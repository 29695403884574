import React from 'react';
import { Button, useDisclosure, Box } from '@chakra-ui/react';
import DrawerComponentCustom from '../../common/UIcomponents/CustomDrawer';
import RequestChangesCampaignForm from './RequestChangesCampaignForm';
import { CommentIcon } from '../../assets/iconsComponents/commentIcon';

export default function RequestChangesCampaignButton({
  updateActivityLog,
  userType,
  refreshCampaignAndActivity,
  parent,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        type="submit"
        color="brand.500"
        size="sm"
        variant="outline"
        borderColor="gray.300"
        onClick={onOpen}
        pt="10px"
        pb="10px"
        fontSize="14px"
        fontWeight="500"
      >
        <CommentIcon width="14px" />
        <span style={{ paddingLeft: '10px' }}>Request campaign changes</span>
      </Button>

      <DrawerComponentCustom
        title="Request campaign changes"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        comment
      >
        {' '}
        <Box color="#868789" mb="30px">
          {`Something wasn’t correct in the campaign? Please let us know what needs to be changed and you will be notified when we respond.`}
        </Box>
        <RequestChangesCampaignForm
          onClose={onClose}
          updateActivityLog={updateActivityLog}
          type={userType}
          refreshCampaignAndActivity={refreshCampaignAndActivity}
          parent={parent}
        />
      </DrawerComponentCustom>
    </>
  );
}
