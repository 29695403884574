import React, { useState } from 'react';
import { useStep } from 'react-hooks-helper';
import map from 'lodash/map';
import CodeInput from './CodeInputForm';
import { getMissingCurrencies } from '../../../util/supplier/helper';

const CodeStepForm = ({
  onClose,
  updateSupplierCode,
  updateSelectedSupplierId,
  suppliers,
  updateSuppliers,
  updateUser,
}) => {
  const [stepFormState, setStepFormState] = useState({
    status: 'idle',
    error: null,
    currencies: getMissingCurrencies(suppliers),
    currenciesCount: getMissingCurrencies(suppliers)
      ? getMissingCurrencies(suppliers).length
      : 0,
  });

  const { status, currencies, currenciesCount } = stepFormState;

  function updateStepFormState(data) {
    setStepFormState(data);
  }

  const steps = map(currencies, (curr) => ({
    ...curr,
    value: `${curr.id}`,
    label: `${curr.name}`,
    Component: CodeInput,
  }));

  const { step, navigation, index } = useStep({ initialStep: 0, steps });
  const { id, name, value, Component, supplierId } = step;
  const props = { navigation, index };
  return (
    <Component
      {...props}
      steps={steps}
      name={name}
      value={value}
      onClose={onClose}
      updateSupplierCode={updateSupplierCode}
      updateSelectedSupplierId={updateSelectedSupplierId}
      id={id}
      supplierId={supplierId}
      currenciesCount={currenciesCount}
      status={status}
      currencies={currencies}
      updateStepFormState={updateStepFormState}
      stepFormState={stepFormState}
      suppliers={suppliers}
      updateSuppliers={updateSuppliers}
      updateUser={updateUser}
    />
  );
};

export default CodeStepForm;
