import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import { CurrencyContext } from '../context/currency/CurrencyContext';

function UseCurrency({ children }) {
  const { authAxios } = useContext(HttpContext);
  const currencyContext = useContext(CurrencyContext);

  useEffect(() => {
    let ignore = false;
    const getData = async () => {
      try {
        const { data } = await authAxios.get(`taxonomies/currencies`);
        if (!ignore) {
          currencyContext.setCurrenciesState({
            currencies: data,
            isLoaded: true,
            error: null,
          });
        }
      } catch (onError) {
        console.log(onError);
        const error = (onError.response && onError.response.data) || null;
        if (!ignore) {
          currencyContext.setCurrenciesState({ currencies: [], isLoaded: true, error });
        }
      }
    };

    getData();

    return () => {
      ignore = true;
    };
  }, [authAxios]);

  return <>{children}</>;
}

export default UseCurrency;
