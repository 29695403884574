import { getFilenameFromResponse } from '../components/vendors/UploadedFile';

const downloadFile = async (fileType, setFilePreview, authAxios, id) => {
  setFilePreview((prev) => ({ ...prev, downloading: true }));
  try {
    const response = await authAxios.get(`files/download?id=${id}&type=${fileType}`, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', getFilenameFromResponse(response));
    document.body.appendChild(link);
    link.click();
    setFilePreview((prev) => ({
      ...prev,
      url,
      isOpen: true,
      fileType: response.headers['content-type'].split('/')[1],
    }));
  } catch (error) {
    console.error(error);
  } finally {
    setFilePreview((prev) => ({ ...prev, downloading: false }));
  }
};

export default downloadFile;
