import React, { useContext, useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { Link as RouterLink } from 'react-router-dom';
import {
  Input,
  List,
  ListItem,
  Flex,
  Box,
  Link,
  FormControl,
  FormLabel,
  useToast,
  IconButton,
  FormErrorMessage,
} from '@chakra-ui/react';
import { SmallAddIcon, CloseIcon } from '@chakra-ui/icons';
import { useDebounce } from '../../../custom-hooks/useDebounce';
import { HttpContext } from '../../../context/HttpContext';
import map from 'lodash/map';
import { getMessage, getSupplierNameFromUser } from '../../../util';

const ComboboxInput = React.forwardRef(({ ...props }, ref) => {
  return <Input {...props} ref={ref} />;
});

const ComboboxList = React.forwardRef(({ isOpen, ...props }, ref) => {
  return <List display={isOpen ? null : 'none'} py={2} {...props} ref={ref} />;
});

const ComboboxItem = React.forwardRef(
  ({ itemIndex, highlightedIndex, ...props }, ref) => {
    const isActive = itemIndex === highlightedIndex;

    return (
      <ListItem
        transition="background-color 220ms, color 220ms"
        bg={isActive ? '#ebecf0' : null}
        px={4}
        py={1}
        cursor="pointer"
        {...props}
        ref={ref}
      />
    );
  }
);

export default function AutocompleteSupplierInput({
  supplier,
  updateSupplier,
  setSupplierSelectedError,
  supplierSelectedError,
}) {
  const [inputItems, setInputItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [clearAutocomplete, setClearAutocomplete] = useState(true);
  const toast = useToast();

  let initialInputValue = null;
  if (supplier?.code) {
    const name = getSupplierNameFromUser(supplier.user);
    const code = supplier.code;
    const currency = `${supplier.currency.code} - ${supplier.currency.name}`;
    initialInputValue = `${code}, ${name}, ${currency}`;
  }

  const debouncedSearchTerm = useDebounce(searchTerm, 350);
  const { authAxios } = useContext(HttpContext);
  useEffect(() => {
    const getData = async () => {
      if (searchTerm === undefined || searchTerm === '') return;
      try {
        const { data } = await authAxios.get(
          `users/suppliers/search/autocomplete?search=${searchTerm}`
        );
        const items = map(data, (item) => ({
          ...item,
          name: getSupplierNameFromUser(item.user),
          code: item.code,
          currency: `${item.currency.code} - ${item.currency.name}`,
        }));

        setInputItems(items);
      } catch (onError) {
        console.log(onError);
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top-left',
            description: message,
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
      }
    };

    getData();
  }, [authAxios, debouncedSearchTerm]);

  function handleSelected(selectedItem) {
    updateSupplier(selectedItem);
    setClearAutocomplete(true);
    setSupplierSelectedError(false);
  }

  function handleSelectedInputValueChange(inputValue) {
    setSearchTerm(inputValue.trim());
    if (inputValue.trim() === '') {
      updateSupplier(null);
      setSupplierSelectedError(true);
    } else {
      setSupplierSelectedError(false);
    }
  }

  const {
    isOpen,
    // selectedItem,
    // getToggleButtonProps,
    // getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox({
    items: inputItems,
    initialInputValue: initialInputValue,
    itemToString: (item) => (item ? `${item.code}, ${item.name}, ${item.currency}` : ''),
    onInputValueChange: ({ inputValue }) => {
      handleSelectedInputValueChange(inputValue);
    },
    onSelectedItemChange({ selectedItem }) {
      handleSelected(selectedItem);
    },
    onStateChange(changes, stateAndHelpers) {
      if (
        (changes.type === '__function_select_item__' || changes.type === 16) &&
        !changes.selectedItem
      ) {
        setClearAutocomplete(false);
      }
    },
  });
  return (
    <Flex direction="column">
      <Flex {...getComboboxProps()} direction="column" flex="1 1 auto" mb={[4, 4, 8]}>
        <FormControl>
          <FormLabel htmlFor="emailHtmlFor">Supplier</FormLabel>
          <ComboboxInput
            {...getInputProps({ disabled: clearAutocomplete })}
            placeholder="Start typing to find or add a supplier"
            flex="0 0 auto"
            mt={3}
          />
          {clearAutocomplete && (
            <Box position="absolute" top="36px" right="-10px">
              <IconButton
                colorScheme="brand"
                size="xs"
                onClick={() => {
                  selectItem(null);
                }}
                aria-label="clear autocomplete icon"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </FormControl>
        <Box boxShadow="0 1px 13px rgba(29, 44, 45, 0.12)" borderRadius="6px">
          <ComboboxList
            isOpen={isOpen}
            {...getMenuProps()}
            flex={1}
            overflowY="auto"
            mt={0}
          >
            {inputItems.map((supplier, index) => (
              <ComboboxItem
                {...getItemProps({ supplier, index })}
                itemIndex={index}
                highlightedIndex={highlightedIndex}
                key={index}
              >
                {supplier.code}, {supplier.name}, {supplier.currency}
              </ComboboxItem>
            ))}
            {isOpen && (
              <>
                {inputItems.length > 0 && (
                  <Box w="100%" h="1px" bg="#ebecf0" mt="5px" mb="5px" />
                )}
                <Link
                  color="brand.500"
                  as={RouterLink}
                  to="/suppliers"
                  fontSize="16px"
                  py={2}
                  px={2}
                >
                  <SmallAddIcon h={22} w={7} /> Create a new supplier
                </Link>
                {/* <CreateSupplierButton addFromPurchaseOrder/> */}
              </>
            )}
          </ComboboxList>
        </Box>
        {supplierSelectedError ? (
          <Box color="error.500" fontSize="13px">
            This is mandatory field
          </Box>
        ) : null}
      </Flex>
      {/* {selectedItem && (
        <>
          <FormControl mb={[4, 4, 8]}>
            <FormLabel htmlFor="emailHtmlFor">Supplier Code</FormLabel>
            <Input
              isDisabled
              name="supplierCode"
              id="supplierCode"
              defaultValue="1234XY"
              focusBorderColor="black"
              errorBorderColor="error.500"
            />
          </FormControl>
          <FormControl mb={[4, 4, 8]}>
            <FormLabel htmlFor="emailHtmlFor">Currency</FormLabel>
            <Input
              isDisabled
              name="supplierCurrency"
              id="supplierCurrency"
              defaultValue="EU - Euro"
              focusBorderColor="black"
              errorBorderColor="error.500"
            />
          </FormControl>
        </>
      )} */}
    </Flex>
  );
}
