import React from 'react';
import UseCurrency from '../../custom-hooks/useCurrency';
// import POContextProvider from "../../context/PurchaseOrder/FilterPO";
import PaymentContextProvider from '../../context/payment/FilterPayments';
import { CurrencyProvider } from '../../context/currency/CurrencyContext';
import PortalPayments from '../PortalPayments';

function PaymentsPage() {
  return (
    // TODO CLEANUP
    // <POContextProvider>
    <PaymentContextProvider>
      <CurrencyProvider>
        {/* <UseCurrency> */}
        <PortalPayments />
        {/* </UseCurrency> */}
      </CurrencyProvider>
    </PaymentContextProvider>
    // </POContextProvider>
  );
}

export default PaymentsPage;
