import React from 'react';

export const BudgetIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    // viewBox="0 0 24 24"
    viewBox="139.5 166 27.6 29.4"
    {...props}
  >
    <g>
      <path
        class="st0"
        d="M166.5,178h-9.1c-0.9-1.1-2.1-2.1-3.4-2.7v-1.8l1.6-1.2c0.2-0.1,0.3-0.3,0.3-0.5v-3.2c0-0.4-0.3-0.6-0.6-0.6
		h-1.8c-0.2,0-0.3,0.1-0.5,0.2l-0.5,0.5l-0.5-0.5c-0.1-0.1-0.3-0.2-0.5-0.2h-2.7c-0.2,0-0.3,0.1-0.5,0.2l-0.5,0.5l-0.5-0.5
		c-0.1-0.1-0.3-0.2-0.5-0.2h-1.8c-0.4,0-0.6,0.3-0.6,0.6v3.2c0,0.2,0.1,0.4,0.3,0.5l1.6,1.2v1.8c-3.3,1.7-6.8,6.1-6.8,12
		c0,5.3,3.9,8.4,10.6,8.4l0.1,0c1,0,2-0.1,3-0.3c0.1,0.2,0.3,0.3,0.5,0.3h12.7c0.4,0,0.6-0.3,0.6-0.6v-16.4
		C167.1,178.3,166.9,178,166.5,178z M153.1,178.6v1.5v10.6v3.3c-1,0.2-2,0.3-2.9,0.3l-0.1,0c-2.8,0-9.4-0.7-9.4-7.1
		c0-4.3,2.5-9.6,6.8-11.2h5.1c1.1,0.4,2.1,1.1,3,1.9h-1.9C153.4,178,153.1,178.3,153.1,178.6z M154.5,171.5l-1.4,1h-5.9l-1.4-1v-2.2
		h0.9l0.7,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2l0.7-0.7h2.2l0.7,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2
		l0.7-0.7h0.9V171.5z M152.7,173.8v1h-5.1v-1H152.7z M165.9,179.3v2.4h-11.4v-2.4H165.9z M165.9,182.9v11.4h-11.4v-11.4H165.9z"
      />
      <rect x="155.6" y="184.4" class="st0" width="2.3" height="2.3" />
      <rect x="158.8" y="184.4" class="st0" width="2.3" height="2.3" />
      <rect x="162.1" y="184.4" class="st0" width="2.3" height="2.3" />
      <rect x="155.6" y="187.6" class="st0" width="2.3" height="2.3" />
      <rect x="158.8" y="187.6" class="st0" width="2.3" height="2.3" />
      <rect x="155.6" y="191" class="st0" width="2.3" height="2.3" />
      <rect x="158.8" y="191" class="st0" width="2.3" height="2.3" />
      <rect x="162.1" y="187.6" class="st0" width="2.3" height="5.7" />
    </g>
    <g>
      <path
        class="st0"
        d="M149.4,192.3C149.4,192.3,149.4,192.3,149.4,192.3c-2.5-0.3-4.3-1.2-5.3-2.7c-1.3-1.9-0.9-4.1-0.8-4.2
		c0.1-0.3,0.4-0.5,0.7-0.4c0.3,0.1,0.5,0.4,0.4,0.7c0,0-0.4,1.8,0.7,3.3c0.8,1.2,2.3,1.9,4.5,2.2c0.3,0,0.5,0.3,0.5,0.6
		C150,192.1,149.7,192.3,149.4,192.3z"
      />
    </g>
  </svg>
);
