import React, { useContext, useEffect } from 'react';
import {
  Button,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  // Tab,
  TabPanel,
  Box,
  useTab,
  chakra,
  useStyles,
} from '@chakra-ui/react';
import { SmallAddIcon } from '@chakra-ui/icons';
import DrawerComponentCustom from '../../common/UIcomponents/CustomDrawer';
import SupplierIndividualForm from './CreateSupplierIndividualForm';
import SupplierCompanyForm from './CreateSupplierCompanyForm';
import UseCurrency from '../../custom-hooks/useCurrency';
import { CurrencyContext } from '../../context/currency/CurrencyContext';
import { HttpContext } from '../../context/HttpContext';

export default function CreateSupplierButton({
  addFromPurchaseOrder,
  updatePagination,
  addUser,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // 1. Reuse the styles for the Tab
  const StyledTab = chakra('button', { themeKey: 'Tabs.Tab' });

  const CustomTab = React.forwardRef((props, ref) => {
    // 2. Reuse the `useTab` hook
    const tabProps = useTab(props);
    const isSelected = !!tabProps['aria-selected'];

    // 3. Hook into the Tabs `size`, `variant`, props
    const styles = useStyles();

    return (
      <StyledTab __css={styles.tab} {...tabProps}>
        {tabProps.children}
        {isSelected ? (
          <Box h="5px" w="26px" bg="#eb7f24" borderRadius="8px" ml="77px" />
        ) : (
          <Box h="5px" w="26px" bg="transparent" borderRadius="8px" ml="77px" />
        )}
      </StyledTab>
    );
  });
  return (
    <>
      {addFromPurchaseOrder ? (
        <Button
          onClick={onOpen}
          color="brand.500"
          variant="ghost"
          fontSize="16px"
          py={2}
          px={2}
        >
          <SmallAddIcon h={22} w={7} /> Create a new supplier
        </Button>
      ) : (
        <Button
          type="submit"
          colorScheme="brand"
          size="sm"
          onClick={onOpen}
          pt="20px"
          pb="20px"
          // _
          _hover={{ bg: '#000' }}
        >
          <SmallAddIcon h={22} w={7} />
          Create a supplier
        </Button>
      )}

      <DrawerComponentCustom
        title="Create a Supplier"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <Box as="p" fontWeight="bold" mb="20px">
          Select type
        </Box>
        <Tabs variant="unstyled" align="center">
          <TabList>
            <CustomTab
              border="1px solid #e0e1e5"
              borderRadius="8px"
              width="260px"
              color=" #a9b1b4"
              fontWeight="700"
              pt="19px"
              pb="10px"
              mr="46px"
              _selected={{ color: 'white', bg: 'black' }}
              _focus={{ outline: 'none' }}
            >
              Individual
            </CustomTab>
            <CustomTab
              border="1px solid #e0e1e5"
              borderRadius="8px"
              width="250px"
              color=" #a9b1b4"
              fontWeight="700"
              pt="19px"
              pb="10px"
              _selected={{ color: 'white', bg: 'black' }}
              _focus={{ outline: 'none' }}
            >
              Company
            </CustomTab>
          </TabList>
          <TabPanels>
            <TabPanel pt="45px" p="0">
              <SupplierIndividualForm
                onClose={onClose}
                updatePagination={updatePagination}
                addUser={addUser}
              />
            </TabPanel>
            <TabPanel pt="45px" p="0">
              <SupplierCompanyForm onClose={onClose} addUser={addUser} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </DrawerComponentCustom>
    </>
  );
}
