import React, { useState } from 'react';
import { Box, Text, Button } from '@chakra-ui/react';
import totalPaid from '../../assets/icons/total_paid_icon.svg';
import pendingIcon from '../../assets/icons/pending_icon.svg';
import map from 'lodash/map';
import { formatAmount } from '../../util';
import { TOTAL_PAID_PENDING_LOG_LENGTH } from '../../util/consts';
import NotableInfo from '../../common/UIcomponents/NotableInfo';

export default function PaymentsTotalAndPending({ paidPayments, pendingPayments }) {
  const initialState = {
    limitPaid: TOTAL_PAID_PENDING_LOG_LENGTH,
    limitPending: TOTAL_PAID_PENDING_LOG_LENGTH,
    offset: 0,
    loading: true,
    expandedPaid: false,
    expandedPending: false,
  };
  const [state, setState] = useState(initialState);
  const { limitPaid, limitPending, expandedPaid, expandedPending } = state;

  function showHideCommentsPaid() {
    if (expandedPaid) {
      setState({
        ...state,
        expandedPaid: false,
        limitPaid: TOTAL_PAID_PENDING_LOG_LENGTH,
      });
    } else {
      setState({
        ...state,
        expandedPaid: true,
        limitPaid: paidPayments.length,
        scrollToUp: true,
      });
    }
  }
  function showHideCommentsPending() {
    if (expandedPending) {
      setState({
        ...state,
        expandedPending: false,
        limitPending: TOTAL_PAID_PENDING_LOG_LENGTH,
      });
    } else {
      setState({
        ...state,
        expandedPending: true,
        limitPending: pendingPayments.length,
        scrollToUp: true,
      });
    }
  }

  return (
    <div>
      {paidPayments.length > 0 && (
        <>
          <Box d="flex" flexDirection="row" flexWrap="wrap" position="relative" pl="54px">
            <Box
              bg="brand.400"
              borderRadius="8px"
              w="39px"
              h="39px"
              d="flex"
              justifyContent="center"
              mr="15px"
              position="absolute"
              left="0"
            >
              <img src={totalPaid} alt="total" style={{ width: '27px' }} />
            </Box>
            {map(paidPayments.slice(0, limitPaid), (payment, index) => {
              return (
                <NotableInfo
                  key={index}
                  textTop={`Total paid (${payment.code})`}
                  textBottom={`${payment.symbol}${formatAmount(payment.amount)}`}
                />
              );
            })}
          </Box>
          {paidPayments.length > TOTAL_PAID_PENDING_LOG_LENGTH && (
            <Box textAlign="right">
              <Button
                onClick={showHideCommentsPaid}
                variant="ghost"
                size="xs"
                fontSize="12px"
                mt="15px"
                ml="10px"
              >
                Show {!expandedPaid ? 'more' : 'less '}
              </Button>
            </Box>
          )}
          <Box h="1px" width="100%" bg=" #e8e9e9" mt="18px" mb="18px" />
        </>
      )}
      {pendingPayments.length > 0 && (
        <>
          <Box d="flex" flexDirection="row" flexWrap="wrap" position="relative" pl="54px">
            <Box
              bg="brand.400"
              borderRadius="8px"
              w="39px"
              h="39px"
              d="flex"
              justifyContent="center"
              mr="15px"
              position="absolute"
              left="0"
            >
              <img src={pendingIcon} alt="total" style={{ width: '27px' }} />
            </Box>
            {pendingPayments.slice(0, limitPending).map((payment, index) => {
              return (
                <NotableInfo
                  key={index}
                  textTop={`Total pending (${payment.code})`}
                  textBottom={`${payment.symbol}${formatAmount(payment.amount)}`}
                  mr="30px"
                />
              );
            })}
          </Box>
          {pendingPayments.length > TOTAL_PAID_PENDING_LOG_LENGTH && (
            <Box textAlign="right">
              <Button
                onClick={showHideCommentsPending}
                variant="ghost"
                size="xs"
                fontSize="12px"
                mt="15px"
                ml="10px"
              >
                Show {!expandedPending ? 'more' : 'less'}
              </Button>
            </Box>
          )}
          <Box h="1px" width="100%" bg=" #e8e9e9" mt="18px" mb="18px" />
        </>
      )}
    </div>
  );
}
