import React, { createContext, useState } from 'react';

export const FilterSupplierContext = createContext();

const FilterSupplierContextProvider = (props) => {
  const [filterFrom, setFilterFrom] = useState('');
  const [filterTo, setFilterTo] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [filterPaymentDetailsApprovedStatus, setPaymentDetailsApprovedStatus] =
    useState('none');
  return (
    <FilterSupplierContext.Provider
      value={{
        filterFrom,
        setFilterFrom,
        filterTo,
        setFilterTo,
        from,
        setFrom,
        to,
        setTo,
        filterPaymentDetailsApprovedStatus,
        setPaymentDetailsApprovedStatus,
      }}
    >
      {props.children}
    </FilterSupplierContext.Provider>
  );
};

export default FilterSupplierContextProvider;
