import React, { createContext, useState } from 'react';
import first from 'lodash/first';
import { addZerosToDecimalNumber } from '../../util';

const CurrencyContext = createContext();
const { Provider } = CurrencyContext;
// export const KEYS = {
//   currencies: 'goat-currencies'
// };

const CurrencyProvider = ({ children }) => {
  // const currencies = localStorage.getItem(KEYS.currencies);
  const [state, setState] = useState({
    currencies: [],
    isLoaded: false,
    error: null,
  });

  const setCurrenciesData = ({ currencies, isLoaded, error }) => {
    // localStorage.setItem(KEYS.currencies, currencies);
    setState({ currencies, isLoaded, error });
  };

  // const setCurrencies = (currencies) => {
  //   // localStorage.setItem(KEYS.currencies, currencies);
  //   setState({...state, currencies });
  // };

  const getFirstCurrency = () => {
    const { currencies } = state;
    return first(currencies);
  };

  const getFirstCurrencyId = () => {
    const { currencies } = state;
    if (currencies.length > 0) {
      return first(currencies).id;
    }

    return 0;
  };

  const gateAllCurrencies = () => {
    const { currencies } = state;
    return currencies;
  };

  function formatCurrency(num, code) {
    const symbol = getCurrencySymbol(code);
    const numberFormatted = addZerosToDecimalNumber(num);
    return `${symbol}${numberFormatted
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }

  function getCurrencySymbol(code) {
    if (code === undefined) return code;
    const currencies = gateAllCurrencies();
    for (let i = 0; i < currencies.length; i++) {
      if (currencies[i].code === code) {
        return currencies[i].symbol;
      }
    }

    return '';
  }

  return (
    <Provider
      value={{
        state,
        setCurrenciesState: (data) => setCurrenciesData(data),
        formatCurrency: (num, code) => formatCurrency(num, code),
        getCurrencySymbol: (code) => getCurrencySymbol(code),
        // setCurrencies: (currencies) => setCurrencies(currencies),
        getFirstCurrency,
        getFirstCurrencyId,
        gateAllCurrencies,
      }}
    >
      {children}
    </Provider>
  );
};

export { CurrencyContext, CurrencyProvider };
