import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
/* Contexts */
import { AuthProvider } from './context/AuthContext';
import { HttpProvider } from './context/HttpContext';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <AuthProvider>
        <HttpProvider>
          <AppRoutes />
        </HttpProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}
export default App;
