import React from 'react';
import { Select } from '@chakra-ui/react';
import map from 'lodash/map';
import { statusOptionsForEdit } from '../../util';

function EditSelectStatus({ updateStatus, status }) {
  function handleChangeOption(e) {
    updateStatus(e.target.value);
  }
  return (
    <Select bg="#ebecf0" h="48px" onChange={handleChangeOption} defaultValue={status}>
      {map(statusOptionsForEdit(), (st) => (
        <option value={st.value}>{st.label}</option>
      ))}
    </Select>
  );
}

export default EditSelectStatus;
