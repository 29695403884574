import React, { createContext, useState } from 'react';
import first from 'lodash/first';

const PodLeadersContext = createContext();
const { Provider } = PodLeadersContext;

const PodLeadersProvider = ({ children }) => {
  const [state, setState] = useState({
    data: [],
    isLoaded: false,
    error: null,
  });

  const setData = ({ data, isLoaded, error }) => {
    // localStorage.setItem(KEYS.currencies, currencies);
    setState({ data, isLoaded, error });
  };

  // const setCurrencies = (currencies) => {
  //   // localStorage.setItem(KEYS.currencies, currencies);
  //   setState({...state, currencies });
  // };

  const getFirst = () => {
    const { data } = state;
    // console.log({ data });
    return first(data);
  };

  const getFirstId = () => {
    const { data } = state;
    if (data.length > 0) {
      return first(data).id;
    }

    return 0;
  };

  const getAll = () => {
    const { data } = state;
    return data;
  };

  return (
    <Provider
      value={{
        state,
        setDataState: (data) => setData(data),
        getFirst,
        getFirstId,
        getAll,
      }}
    >
      {children}
    </Provider>
  );
};

export { PodLeadersContext, PodLeadersProvider };
