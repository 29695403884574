import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import map from 'lodash/map';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Button,
  Box,
  useToast,
  Textarea,
  Text,
  // IconButton,
  // FormHelperText,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../../context/HttpContext';
import { getBase64, getErrors, getMessage } from '../../../util';

import AutocompleteSupplierInput from './AutocompleteSupplierInput';
import AutocompleteCampaignInput from './AutocompleteCampaignInput';
import PriceInputs from './PriceInputs';
import { getFilenameFromResponse } from '../../vendors/UploadedFile';
import UploadFiles from '../../campaigns/UploadCampaignFile';
import {
  CheckIcon,
  // CloseIcon
} from '@chakra-ui/icons';
import SelectPodLeader from '../../../common/UIcomponents/SelectPodLeader';

const PurchaseEditForm = ({ onClose, updatePurchaseOrders, order }) => {
  const [fileName] = useState(order.file);

  const [file, setFile] = useState(null);
  const [podLeaderId, setPodLeaderId] = React.useState(order?.podLeader?.id || 0);

  const [submitDisabled, setSubmitDisabled] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [newFile, setNewFile] = useState(undefined);
  const [uploadFileError, setUploadFileError] = useState('');
  const [campaign, setCampaign] = useState(order.campaign);
  const [campaignSelectedError, setCampaignSelectedError] = useState(null);
  const [supplier, setSupplier] = useState(order.supplier);
  const [supplierSelectedError, setSupplierSelectedError] = useState(null);
  const [prices, setPrices] = useState(order.purchaseOrderItems);
  const [pricesTouched, setPricesTouched] = useState(false);
  const [pricesErrors, setPricesErrors] = useState(false);
  const [done, setDone] = useState(false);
  const validationSchema = Yup.object().shape({
    descriptionOfWork: Yup.string()
      .required('This is mandatory field')
      .max(1000, 'Please enter 1000 characters or less'),
  });

  const { authAxios } = useContext(HttpContext);
  const toast = useToast();

  const { register, handleSubmit, formState, setError, reset, setValue, watch } = useForm(
    {
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
    }
  );
  const watchDescriptionOfWork = watch('descriptionOfWork');

  const { errors, isSubmitting, isDirty, isValid } = formState;

  useEffect(() => {
    if (supplier === null || campaign === null) {
      setSubmitDisabled(true);
      return;
    }

    setSubmitDisabled(
      ((!pricesTouched && !isDirty) ||
        !isValid ||
        isSubmitting ||
        hasPricesErrors() ||
        campaignSelectedError ||
        supplierSelectedError) &&
        ((campaign && campaign.id === order.campaign.id) || campaignSelectedError) &&
        ((supplier && supplier.id === order.supplier.id) || supplierSelectedError)
    );
  }, [
    pricesTouched,
    isDirty,
    isSubmitting,
    hasPricesErrors(),
    campaignSelectedError,
    supplierSelectedError,
    campaign?.id,
    order.campaign.id,
    supplier?.id,
    order.supplier.id,
    supplierSelectedError,
  ]);

  const updatePodLeaderId = (id) => {
    setPodLeaderId(id);
  };

  const onSubmit = async (values) => {
    try {
      let purchaseOrderItemsArray = map(prices, ({ amount, id, title, vat }) => ({
        amount: amount.toString(),
        id,
        title,
        vat,
      }));

      let data = {
        ...values,
        campaign: campaign.id,
        supplier: supplier.id,
        purchaseOrderItemsArray,
        podLeader: podLeaderId,
      };

      // TODO CLEANUP
      // if (isFileRemoved) {
      //   data = {
      //     ...data,
      //     file: '',
      //   };
      // }
      if (newFile) {
        const base64 = await getBase64(newFile);
        const file = base64.split(',')[1];
        data = {
          ...data,
          file,
        };
      }

      const res = await authAxios.patch(`purchase-orders/${order.id}`, data);
      updatePurchaseOrders(res.data);
      onClose();
      reset();
      setDone(true);
    } catch (onError) {
      const errors = getErrors(onError);
      console.log(onError);
      if (supplier === null) {
        setSupplierSelectedError(true);
      }
      if (campaign === null) {
        setCampaignSelectedError(true);
      }
      if (errors && errors.clientName && errors.clientName.errors) {
        const message = errors.clientName.errors[0];
        setError('clientName', { type: 'server', message });
      } else if (errors && errors.campaignName && errors.campaignName.errors) {
        const message = errors.campaignName.errors[0];
        setError('campaignName', { type: 'server', message });
      } else if (errors && errors.budget && errors.budget.errors) {
        const message = errors.budget.errors[0];
        setError('budget', { type: 'server', message });
      } else if (errors && errors.currency && errors.currency.errors) {
        const message = errors.currency.errors[0];
        setError('currency', { type: 'server', message });
      } else if (errors && errors.file && errors.file.errors) {
        setUploadFileError('This is mandatory field');
      } else {
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top-left',
            description: message,
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
      }
    }
  };

  function updatePriceTouched(value) {
    setPricesTouched(value);
  }

  function updateSupplier(supplier) {
    setSupplier(supplier);
    // TODO CLEANUP
    // if (isFileRemoved) {
    //   setSupplierHelper(true);
    // }
  }

  // function updateSupplierSelectedError(value) {
  //   setSupplierSelectedError(value);
  // }

  function updateCampaign(campaign) {
    setCampaign(campaign);
    // TODO CLEANUP
    // if (isFileRemoved) {
    //   setCampaignHelper(true);
    // }
  }

  // function updateCampaignSelectedError(value) {
  //   setCampaignSelectedError(value);
  // }

  function updatePrices(prices) {
    setPrices(prices);
  }

  function handleOnClose() {
    onClose();
  }

  function updatePricesErrors(errors) {
    setPricesErrors(errors);
  }

  function hasPricesErrors() {
    if (hasPricesEmptyField()) return true;
    if (pricesErrors.length > 0) {
      for (let i = 0; i < pricesErrors.length; i++) {
        if (pricesErrors[i]?.amount !== '' || pricesErrors[i]?.title !== '') {
          return true;
        }
      }
    }
    return false;
  }

  function hasPricesEmptyField() {
    if (prices.length > 0) {
      for (let i = 0; i < prices.length; i++) {
        if (prices[i]?.amount === '' || prices[i]?.title === '') {
          return true;
        }
      }
    }

    return false;
  }

  function updateUploadedFile(file) {
    setValue('descriptionOfWork', watchDescriptionOfWork, {
      shouldDirty: true,
    });
    setNewFile(file);
    // setIsFileRemoved(false);
  }

  // TODO CLEANUP
  // function hasUploadedFileEmpty() {
  //   if (file === null || isFileRemoved) return true;
  //   return false;
  // }

  const getExistingFile = async (e) => {
    e.preventDefault();
    try {
      if (order.file) {
        // prevent download if file is already downloaded
        setIsFileLoading(true);

        const response = await authAxios.get(
          `files/download?id=${order.id}&type=purchaseOrder`,
          { responseType: 'blob' }
        );

        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        const file = new File([blob], getFilenameFromResponse(response), {
          type: blob.type,
        });

        Object.assign(file, { preview: URL.createObjectURL(file) });
        setFile(file);
        setIsFileLoading(false);

        const link = document.createElement('a');
        link.href = file.preview;
        link.target = '_blank';
        //link.rel = 'noopener noreferrer';

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    } catch (onError) {
      console.log(onError);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {order?.approvedAt ? null : (
          <AutocompleteSupplierInput
            close={handleOnClose}
            updateSupplier={updateSupplier}
            supplier={supplier}
            setSupplierSelectedError={setSupplierSelectedError}
            supplierSelectedError={supplierSelectedError}
          />
        )}

        <AutocompleteCampaignInput
          close={handleOnClose}
          updateCampaign={updateCampaign}
          campaign={campaign}
          setCampaignSelectedError={setCampaignSelectedError}
          campaignSelectedError={campaignSelectedError}
        />
        <FormControl isInvalid={errors.descriptionOfWork} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Description of the work</FormLabel>
          <Textarea
            mt="16px"
            isInvalid
            name="descriptionOfWork"
            id="descriptionOfWork"
            defaultValue={order.descriptionOfWork}
            size="lg"
            border="1px solid  transparent"
            bg="#ebecf0"
            _focus={{ border: '1px solid black' }}
            _hover={{ border: '1px solid black' }}
            {...register('descriptionOfWork')}
            placeholder="Enter the details of the work done"
          />
          <FormErrorMessage>
            {errors.descriptionOfWork?.message || errors.descriptionOfWork}
          </FormErrorMessage>
        </FormControl>
        <Text fontWeight="bold" fontSize="16px" mb="18px">
          Price
        </Text>
        <PriceInputs
          prices={prices}
          updatePrices={updatePrices}
          updatePricesErrors={updatePricesErrors}
          updatePriceTouched={updatePriceTouched}
        />
        {newFile === undefined && order.file && (
          <Box
            position="relative"
            p={2}
            border="1px solid #ebecf0"
            borderRadius="4px"
            mt={2}
            mb={4}
          >
            <Box pr="10px">
              <Box key={file?.name}>
                {/* {file.path} - {formatBytes(file.size)} */}
                {/* <Link color="brand.400" ml="5px">File uploaded</Link> */}
                <Box color="brand.400" ml="5px">
                  <CheckIcon color="brand.400" mr={2} />{' '}
                  {/* <a href={file?.preview} target="_blank" rel="noreferrer">
                    {file ? file.name : 'Uploaded file'}
                  </a>{' '} */}
                  <Button
                    onClick={getExistingFile}
                    variant="ghost"
                    isLoading={isFileLoading}
                  >
                    {fileName ? fileName : 'Uploaded file'}
                  </Button>
                </Box>
              </Box>
            </Box>
            {/* TODO CLEANUP */}
            {/* <Box position="absolute" top="-12px" right="-10px">
              <IconButton
                colorScheme="brand"
                size="xs"
                onClick={() => {
                  setIsFileRemoved(true);
                  setCampaignHelper(true);
                  setSupplierHelper(true);
                  setValue('descriptionOfWork', watchDescriptionOfWork, {
                    shouldDirty: true,
                  });
                }}
                aria-label="remove file icon"
              >
                <CloseIcon />
              </IconButton>
            </Box> */}
          </Box>
        )}
        <FormControl isInvalid={uploadFileError}>
          <UploadFiles
            updateUploadedFile={updateUploadedFile}
            // TODO CLEANUP
            // removeFile={() => setIsFileRemoved(true)}
            setUploadFileError={setUploadFileError}
          />
          <FormErrorMessage>{uploadFileError}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.podLeader} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Pod Leader</FormLabel>
          <SelectPodLeader
            updatePodLeaderId={updatePodLeaderId}
            podLeaderId={podLeaderId}
          />
          <FormErrorMessage>
            {errors.podLeader?.message || errors.podLeader}
          </FormErrorMessage>
        </FormControl>
        <>
          <Box d="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              // disabled={
              //   ((!pricesTouched && !isDirty) ||
              //     !isValid ||
              //     isSubmitting ||
              //     hasPricesErrors() ||
              //     campaignSelectedError ||
              //     supplierSelectedError) &&
              //   ((campaign && campaign.id === order.campaign.id) ||
              //     campaignSelectedError) &&
              //   ((supplier && supplier.id === order.supplier.id) || supplierSelectedError)
              // }
              disabled={
                submitDisabled &&
                (podLeaderId === 0 || podLeaderId === order?.podLeader?.id)
              }
              isLoading={isSubmitting && !done}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
            >
              Submit
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={handleOnClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
};
export default PurchaseEditForm;
