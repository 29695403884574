import React from 'react';
//import { TeamIcon } from "../../assets/iconsComponents/teamIcon";
//import { InfluencerIcon } from "../../assets/iconsComponents/influencerIcon";

// import {  Stack } from "@chakra-ui/react";
//import AuthenticationGoatMemberForm from "./login/LoginGoatMemberForm"
import AuthenticationInfluencerForm from './login/LoginInfluencerForm';

// export default function ChooseButtons() {
//   return (
//     <Stack direction="row" spacing={4} mb="40px">
//       <Box
//         as="button"
//         d="flex"
//         alignItems="center"
//         justifyContent="center"
//         width="190px"
//         height="51px"
//         lineHeight="1.2"
//         transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
//         border="1px"
//         px="8px"
//         borderRadius="6px"
//         fontSize="14px"
//         fontWeight="semibold"
//         bg="#000"
//         borderColor="#ccd0d5"
//         color="#fff"
//         //   _hover={{ bg: "#ebedf0" }}
//         _active={{
//           bg: "#dddfe2",
//           transform: "scale(0.98)",
//           borderColor: "#bec3c9",
//         }}
//         _focus={{
//           boxShadow:
//             "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
//         }}
//       >
//         <Box pr="12px">
//           <TeamIcon fill="#fff" />
//           <Box
//             mt="5px"
//             height="5px"
//             width="24px"
//             bg="#eb7f24;"
//             borderRadius="6px"
//           />
//         </Box>
//         Goat Team
//       </Box>
//       <Box
//         as="button"
//         d="flex"
//         alignItems="center"
//         justifyContent="center"
//         width="190px"
//         height="51px"
//         lineHeight="1.2"
//         transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
//         border="1px"
//         px="8px"
//         borderRadius="6px"
//         fontSize="14px"
//         fontWeight="semibold"
//         bg="#ffffff"
//         borderColor="#e0e1e5;"
//         color="#a9b1b4;"
//         _hover={{ bg: "#ebedf0" }}
//         _active={{
//           bg: "#dddfe2",
//           transform: "scale(0.98)",
//           borderColor: "#bec3c9",
//         }}
//         _focus={{
//           boxShadow:
//             "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
//         }}
//       >
//         <Box pr="12px">
//           <InfluencerIcon fill="#fff" />
//           <Box mt="5px" height="5px" width="24px" bg="" borderRadius="6px" />
//         </Box>
//         Influencer
//       </Box>
//     </Stack>
//   );
// }

import {
  // useDisclosure,
  // Tabs,
  // TabList,
  // TabPanels,
  // Tab,
  // TabPanel,
  Box,
  //useTab,
  //chakra,
  //useStyles,
} from '@chakra-ui/react';

export default function ChooseButtons({ location }) {
  // 1. Reuse the styles for the Tab
  // const StyledTab = chakra("button", { themeKey: "Tabs.Tab" });

  // const CustomTab = React.forwardRef((props, ref) => {
  //   // 2. Reuse the `useTab` hook
  //   const tabProps = useTab(props);
  //   const isSelected = !!tabProps["aria-selected"];

  //   // 3. Hook into the Tabs `size`, `variant`, props
  //   const styles = useStyles();

  //   return (
  //     <StyledTab __css={styles.tab} {...tabProps}>
  //       {tabProps.children}
  //       {isSelected ? (
  //         <Box h="5px" w="26px" bg="#eb7f24" borderRadius="8px" ml="22px" />
  //       ) : (
  //         <Box h="5px" w="26px" bg="transparent" borderRadius="8px" ml="22px" />
  //       )}
  //     </StyledTab>
  //   );
  // });
  return (
    <Box>
      {/* <Tabs variant="unstyled" align="center">
          <TabList>
            <CustomTab
              border="1px solid #e0e1e5"
              borderRadius="8px"
              width="190px"
              color=" #a9b1b4"
              fontWeight="700"
              height="51px"
              lineHeight="1.2"
             // pt="13px"
             // pb="10px"
              mr="20px"
              _selected={{ color: "white", bg: "black" }}
              _focus={{ outline: "none" }}
            >
             <Box d="inline-block" mr="10px"><TeamIcon fill="#fff" /></Box>
              Goat Team
            </CustomTab>
            <CustomTab
              border="1px solid #e0e1e5"
              borderRadius="8px"
              width="190px"
              color=" #a9b1b4"
              fontWeight="700"
              //pt="13px"
             // pb="10px"
             height="51px"
             lineHeight="1.2"
              _selected={{ color: "white", bg: "black" }}
              _focus={{ outline: "none" }}
            >
              <Box d="inline-block" mr="10px"><InfluencerIcon fill="#fff" /></Box>
              <Box d="inline-block">Influencer</Box>
            </CustomTab>
          </TabList> */}
      {/* <TabPanels>
            <TabPanel pt="45px" p="0">
              <AuthenticationGoatMemberForm location={location}/>
            </TabPanel>
            <TabPanel pt="45px" p="0"> */}
      <AuthenticationInfluencerForm location={location} />
      {/* </TabPanel>
          </TabPanels>
        </Tabs> */}
    </Box>
  );
}
