import React, { useContext } from 'react';
import { Select } from '@chakra-ui/react';
import map from 'lodash/map';
import { CurrencyContext } from '../../context/currency/CurrencyContext';

function SelectCurrency({ updateCurrencyId }) {
  const currencyContext = useContext(CurrencyContext);
  function handleChangeOption(e) {
    updateCurrencyId(parseInt(e.target.value, 10));
  }

  const currencies = map(currencyContext.gateAllCurrencies(), (curr) => ({
    ...curr,
    title: `${curr.code} - ${curr.name}`,
  }));

  return (
    <Select bg="#ebecf0" h="48px" onChange={handleChangeOption} defaultValue={0}>
      {map(currencies, (curr) => (
        <option key={curr.id} value={curr.id}>
          {curr.title}
        </option>
      ))}
    </Select>
  );
}

export default SelectCurrency;
