import React, { useContext } from 'react';
import { Box, HStack, Badge, Heading, Text, Select } from '@chakra-ui/react';
// import { WarningIcon } from "@chakra-ui/icons";
import { capitalizeTheFirstLetter } from '../../util';
import SupplierActivity from './SupplierActivity';
import map from 'lodash/map';
// import findIndex from "lodash/findIndex";
import ReviewPaymentButton from './payment/ReviewPaymentChangesButton';
// import { CountriesContext } from "../../context/country/CountriesContext";
import { AuthContext } from '../../context/AuthContext';
import WFIle from './WFile';
import PaymentDetailsViewAsFinance from './PaymentDetailsViewAsFinance';
import { hasPaymentsToApprove } from '../../util/supplier/helper';

export default function SupplierDetailsContent({
  user,
  suppliers,
  selectedSupplierId,
  // selectedSupplier,
  updateSelectedSupplierId,
  updateUser,
  updateSuppliers,
  updateSupplierCode,
  supplierId,
  // updateSuppActivity,
  activityNumber,
  // firstName,
  // lastName,
  // officialRegisteredName,
  // email,
  // type,
  // vatRegistered,
  // vatNumber,
  isFinance,
}) {
  const auth = useContext(AuthContext);

  const {
    addressLine1,
    addressLine2,
    city,
    // companyRegistrationNumber,
    country,
    email,
    entityType,
    firstName,
    // havePendingPaymentDetails,
    // id,
    lastName,
    officialRegisteredName,
    // roles,
    state,
    subType,
    // type,
    // supplierCanBeEdited,
    vatNumber,
    vatRegistered,
    zipCode,
  } = user;

  function getFormName(entityType) {
    if (entityType === 'us_corporation') {
      return 'W9 form';
    }
    if (entityType === 'foreign_company') {
      return 'W8-BEN-E form';
    }
    if (entityType === 'us_person') {
      return 'W9 form';
    }
    if (entityType === 'foreign_person') {
      return 'W8-BEN form';
    }
  }

  function getEntityTitle(entityType) {
    if (entityType === 'us_corporation') {
      return ' US Entity (Corporation)';
    }
    if (entityType === 'foreign_company') {
      return 'US Entity (Foreign Company)';
    }

    if (entityType === 'us_person') {
      return 'US Entity (Person)';
    }
    if (entityType === 'foreign_person') {
      return 'US Entity (Foreign Person)';
    }
  }

  function handleChangeOption(e) {
    updateSelectedSupplierId(parseInt(e.target.value, 10));
  }

  const recordIndex = suppliers.findIndex((x) => x.id === selectedSupplierId);
  let selectedSupplier = null;
  if (recordIndex > -1) {
    selectedSupplier = suppliers[recordIndex];
  }

  return (
    <Box borderRadius="md" boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);" mt="3">
      <Box bg="#f8f9fd" h="128px" p="10" pt="25px" pb="30px" borderRadius="md">
        <Text fontSize="14px" color="#868789" mb="5px">
          Supplier code, Currency
        </Text>
        {suppliers?.length !== 1 ? (
          <Select
            bg="#ebecf0"
            h="48px"
            fontWeight="bold"
            // defaultValue={selectedSupplierId}
            value={selectedSupplierId}
            onChange={handleChangeOption}
            w="319px"
          >
            {map(suppliers, (supplier) => (
              <option key={supplier.id} value={supplier.id}>{`${
                supplier.code ? supplier.code : 'pending'
              }, ${supplier.currency.code} - ${supplier.currency.name}`}</option>
            ))}
          </Select>
        ) : (
          <>
            {' '}
            {map(suppliers, (supplier) => (
              <Text
                fontWeight="bold"
                mt="10px"
                fontSize="18px"
                key={supplier.id}
                value={supplier.id}
              >{`${supplier.code ? supplier.code : 'pending'}, ${
                supplier.currency.code
              } - ${supplier.currency.name}`}</Text>
            ))}
          </>
        )}
      </Box>
      <Box h="1px" width="100%" bg="#e8e9e9" />
      <Box bg="#fff" p="10" pt="30px" borderRadius="md">
        <SupplierActivity
          id={selectedSupplierId}
          activityNumber={activityNumber}
          // code={code}
        />
        <Heading as="h4" size="md" mt="30px" fontWeight="bold">
          Basic information
        </Heading>
        <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="30px" />
        <HStack alignItems="flex-start">
          <Box color="#868789" fontSize="14px" mr="35px">
            {subType && <Text mb="25px">Type:</Text>}
            {firstName && <Text mb="5px">First name:</Text>}
            {lastName && <Text mb="5px">Last name:</Text>}
            {officialRegisteredName && <Text mb="5px">Official name:</Text>}
            <Text mb="5px">Email address:</Text>
            <Text mb="5px">VAT registered:</Text>
            <Text mb="5px">VAT number:</Text>
          </Box>
          <Box alignItems="flex-start" fontSize="14px">
            <Box mb="15px">
              {subType === 'individual' ? (
                <Badge
                  pt="1"
                  pb="1"
                  pr="3"
                  pl="3"
                  colorScheme="blue"
                  color="#6f9fb7"
                  textTransform="initial"
                  fontSize="14px"
                  fontWeight="400"
                  borderRadius="8px"
                >
                  Individual
                </Badge>
              ) : (
                <Badge
                  pt="1"
                  pb="1"
                  pr="3"
                  pl="3"
                  colorScheme="green"
                  color="#5b9075"
                  textTransform="initial"
                  fontSize="14px"
                  fontWeight="400"
                  borderRadius="8px"
                >
                  {capitalizeTheFirstLetter(subType)}
                </Badge>
              )}
            </Box>
            {firstName && <Text mb="5px">{firstName}</Text>}
            {lastName && <Text mb="5px">{lastName}</Text>}
            {officialRegisteredName && <Text mb="5px">{officialRegisteredName}</Text>}
            <Text mb="5px">{email}</Text>
            <Text mb="5px">{vatRegistered ? 'Yes' : 'No'}</Text>
            <Text mb="5px">{vatNumber}</Text>
            <Text />
            <Text />
          </Box>
        </HStack>
        <Heading as="h4" size="md" mt="60px" fontWeight="bold">
          Billing address
        </Heading>
        <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="30px" />
        {!addressLine1 && <Text color="#868789">No information yet.</Text>}
        {addressLine1 && (
          <HStack alignItems="flex-start">
            <Box color="#868789" fontSize="14px" mr="35px">
              <Text mb="5px">Address line 1:</Text>
              {addressLine2 && <Text mb="5px">Address line 2:</Text>}
              <Text mb="5px">City:</Text>
              <Text mb="5px">Zip / Postal code:</Text>
              <Text mb="5px">Country:</Text>
              {state && <Text mb="5px">State:</Text>}
              {entityType && <Text mb="5px">Entity type:</Text>}
              {entityType && <Text mb="5px">{getFormName(entityType)}:</Text>}
            </Box>
            <Box alignItems="flex-start" fontSize="14px">
              <Text mb="5px">{addressLine1}</Text>
              {addressLine2 && <Text mb="5px">{addressLine2}</Text>}
              <Text mb="5px">{city}</Text>
              <Text mb="5px">{zipCode}</Text>
              <Text mb="5px">{country?.name}</Text>
              {state && <Text mb="5px">{state}</Text>}
              {entityType && <Text mb="5px">{getEntityTitle(entityType)}</Text>}
              {entityType && <WFIle userId={user.id} />}
            </Box>
          </HStack>
        )}
        {isFinance && selectedSupplier && (
          <PaymentDetailsViewAsFinance supplier={selectedSupplier} />
        )}

        {auth.isFinanceManager() &&
          hasPaymentsToApprove(suppliers) &&
          !selectedSupplier?.paymentDetailsApproved && (
            <ReviewPaymentButton
              withCheckmark
              suppliers={suppliers}
              updateSuppliers={updateSuppliers}
              updateSupplierCode={updateSupplierCode}
              supplierId={supplierId}
              user={user}
              updateUser={updateUser}
              updateSelectedSupplierId={updateSelectedSupplierId}
            />
          )}
      </Box>
    </Box>
  );
}
