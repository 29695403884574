import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import DrawerComponentCustom from '../../common/UIcomponents/CustomDrawer';
import CampaignEditStatusForm from './CampaignEditStatusForm';

export default function EditCampaignStatusButton({ campaign, updateCampaign }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        type="submit"
        colorScheme="brand"
        size="xs"
        onClick={onOpen}
        pt="20px"
        pb="20px"
        variant="ghost"
        fontSize="14px"
      >
        Change status
      </Button>

      <DrawerComponentCustom
        title="Change a Campaign status"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        campaign
      >
        <CampaignEditStatusForm
          onClose={onClose}
          updateCampaign={updateCampaign}
          campaign={campaign}
        />
      </DrawerComponentCustom>
    </>
  );
}
