import { extendTheme } from '@chakra-ui/react';

const breakpoints = ['375px', '768px', '1024px', '1366px'];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

// const myvariant = (props) => {
//   return {
//     tfoot: {
//       tr: {
//         td: {
//           backgroundColor: "red.100",
//         },
//       },
//     },
//   };
// };

export const customTheme = extendTheme({
  styles: {
    global: {
      'html, body': {
        background: 'white',
        'input:-webkit-autofill': {
          boxShadow: 'inset 0 0 0px 1000px #ECECEC',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #ECECEC',
          borderColor: '#ECECEC',
        },
        'input:autofill': {
          boxShadow: 'inset 0 0 0px 1000px #ECECEC',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #ECECEC',
          borderColor: '#ECECEC',
        },

        //-ms-overflow-style: "none", /* for Internet Explorer, Edge */
        scrollbarWidth: 'none' /* for Firefox */,
        // overflowY: "scroll",
        // overflowX: "hidden",

        msOverflowStyle: '-ms-autohiding-scrollbar',

        // body::-webkit-scrollbar {
        //   display: none; /* for Chrome, Safari, and Opera */
        // }
      },
      'body.has-padding': {
        paddingTop: '122px',
      },
    },
  },
  fonts: {
    body: `"Roboto", sans-serif`,
    heading: `"Roboto", sans-serif`,
    mono: `"source-code-pro", Menlo, Monaco, Consolas, "Courier New", monospace;`,
  },
  // #eb7f24
  colors: {
    brand: {
      50: '#ffefde',
      100: '#fdd5b4',
      200: '#f5bb88',
      300: '#f19f5a',
      400: '#ec842d',
      500: '#eb7f24',
      600: '#a5530d',
      700: '#763a08',
      800: '#482300',
      900: '#1d0a00',
    },
    grey: {
      50: '#f0f0f0',
      100: '#DEDFE0',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#8900A3 ',
      800: '#262626',
      900: '#0d0d0d',
    },
    error: {
      50: '#feebef',
      100: '#feced4',
      200: '#ee9c9e',
      300: '#e47679',
      400: '#ef5758',
      500: '#f4473f',
      600: '#e53e3e',
      700: '#d33538',
      800: '#c62e31',
      900: '#b62425',
    },
    warning: {
      // 800
      50: '#f4e4f5',
      100: '#e3bce7',
      200: '#d18ed8',
      300: '#bd60c8',
      400: '#af3bbc',
      500: '#a009b0',
      600: '#9207aa',
      700: '#7f03a3',
      800: '#6d009c',
      900: '#4c0090',
    },
    black: {
      50: '#f2f2f2',
      100: '#d9d9d9',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#262626',
      900: '#00000',
    },
    green: {
      500: '#92ca65',
    },
  },
  breakpoints,
  components: {
    Table: {
      baseStyle: {
        //boxShadow: "0 1px 13px rgba(29, 44, 45, 0.12)",
        //borderRadius: "md",
      },
      variants: {
        simple: {
          bg: 'brand.500',
          boxShadow: '0 0 2px 2px #efdfde',
          borderRadius: 'md',

          td: {
            border: '1 px solid #e8e9e9;',
            fontSize: '14px',
            color: '#333333',
            fontFamily: 'body',
            padding: '26px 0 26px 35px',
          },
        },
        red: {
          bg: 'brand.500',
          boxShadow: '0 0 2px 2px #efdfde',
          borderRadius: 'md',

          td: {
            border: '1 px solid #e8e9e9;',
            fontSize: '14px',
            color: 'red',
            fontFamily: 'body',
            padding: '26px 0 26px 35px',
          },
        },
      },
      defaultProps: {
        size: 'lg',
        variant: 'simple',
      },
    },
    Input: {
      variants: {
        main: {
          field: {
            borderRadius: 'md',
            border: '1px solid transparent',
            bg: '#ebecf0',
            _hover: {
              borderColor: '#000',
            },
            _focus: {
              borderColor: '#000',
              // boxShadow: "0 0 0 1px #000",
            },
          },
        },
      },
      defaultProps: {
        size: 'lg',
        variant: 'main',
      },
    },
    Textarea: {
      variants: {
        main: {
          borderRadius: 'md',
          border: '1px solid #999',
          bg: '#fff',
          _hover: {
            borderColor: '#EC008C',
          },
          _focus: {
            borderColor: '#EC008C',
            boxShadow: '0 0 0 1px #EC008C',
          },
        },
      },
      defaultProps: {
        size: 'lg',
        variant: 'main',
      },
    },
    Checkbox: {
      variants: {
        main: {
          control: {
            border: '2px solid #eb7f24',
            borderColor: '#eb7f24',
            _focus: {
              borderColor: '#eb7f24',
              boxShadow: '0 0 0 1px #eb7f24',
            },
          },
          label: {
            fontSize: 'sm',
          },
        },
      },
      defaultProps: {
        variant: 'main',
        size: 'md',
        colorScheme: 'brand',
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: '4px',
      },
      sizes: {
        xsm: {
          px: '8px',
          py: '5px',
          fontSize: '14px',
        },
        sm: {
          px: '18px',
          py: '12px',
          fontSize: '16px',
        },
        lg: {
          px: '24px',
          py: '16px',
          fontSize: '18px',
        },
      },
      variants: {
        brand: {
          bg: '#EC008C',
          border: '1px solid #EC008C',
          color: 'white',
          //_hover: { borderColor: "#ff7ecc", bg: "#ff7ecc" },
        },
        brandDark: {
          bg: '#8900a3',
          border: '1px solid #8900a3',
          color: 'white',
          //_hover: { borderColor: "#ff7ecc", bg: "#ff7ecc" },
        },
        ghost: {
          bg: 'transparent',
          border: 'none',
          color: '#eb7f24',
          fontWeight: '500',
          fontSize: '18px',
          _hover: { bg: 'rgba(0,0,0,0.05)' },
        },
        white: {
          bg: 'white',
          border: '1px solid #fff',
          color: 'black',
          _hover: { borderColor: 'white', bg: 'rgba(255,255,255,.7)' },
        },
        whiteOutline: {
          bg: '#EC008C',
          border: '1px solid #fff',
          color: 'white',
          _hover: { bg: '#ff7ecc' },
        },
        grey: {
          bg: '#dedfe0',
          border: '1px solid #dedfe0',
          color: '#8900a3',
          _hover: { borderColor: '#f0f0f0', bg: '#f0f0f0' },
        },
      },
      defaultProps: {
        size: 'sm',
        variant: 'solid',
      },
    },
    Tabs: {
      defaultProps: {
        colorScheme: 'brand',
      },
    },

    FormLabel: {
      baseStyle: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000',
        marginBottom: '8px',
      },
    },
    Heading: {
      sizes: {
        mdf: {
          fontSize: '1.5rem',
        },
      },
      baseStyle: {
        fontWeight: 'normal',
        // textTransform: 'uppercase',
      },
    },
  },
});
