import React, { createContext, useState } from 'react';

export const POContext = createContext();

const POContextProvider = (props) => {
  const [filterStatus, setFilterStatus] = useState('none');
  const [filterPodLeader, setFilterPodLeader] = useState('');

  const [filterCurrency, setFilterCurrency] = useState('');
  const [filterStartFrom, setFilterStartFrom] = useState('');
  const [filterStartTo, setFilterStartTo] = useState('');
  const [filterEndFrom, setFilterEndFrom] = useState('');
  const [filterEndTo, setFilterEndTo] = useState('');
  const [filterStartFromAmount, setFilterStartFromAmount] = useState('');
  const [filterStartToAmount, setFilterStartToAmount] = useState('');

  const [filterTo, setFilterTo] = useState('');
  const [filterFrom, setFilterFrom] = useState('');

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const [fromStart, setFromStart] = useState('');
  const [toStart, setToStart] = useState('');

  const [fromEnd, setFromEnd] = useState('');
  const [toEnd, setToEnd] = useState('');

  const [fromAmount, setFromAmount] = useState('');
  const [toAmount, setToAmount] = useState('');

  return (
    <POContext.Provider
      value={{
        filterStatus,
        setFilterStatus,
        filterPodLeader,
        setFilterPodLeader,
        filterCurrency,
        setFilterCurrency,
        filterStartFrom,
        setFilterStartFrom,
        filterStartTo,
        setFilterStartTo,
        filterEndFrom,
        setFilterEndFrom,
        filterEndTo,
        setFilterEndTo,
        filterFrom,
        setFilterFrom,
        filterTo,
        setFilterTo,
        filterStartFromAmount,
        setFilterStartFromAmount,
        filterStartToAmount,
        setFilterStartToAmount,
        fromAmount,
        setFromAmount,
        toAmount,
        setToAmount,
        from,
        setFrom,
        to,
        setTo,
        fromStart,
        setFromStart,
        toStart,
        setToStart,
        fromEnd,
        setFromEnd,
        toEnd,
        setToEnd,
      }}
    >
      {props.children}
    </POContext.Provider>
  );
};

export default POContextProvider;
