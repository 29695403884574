import React from 'react';
export const PaymentsIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    {...props}
  >
    <g>
      <path
        d="M18.5,17.1c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C17.4,16.6,17.9,17.1,18.5,17.1z
		 M18.5,16.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3C18.9,16.1,18.7,16.3,18.5,16.3z"
      />
      <path
        d="M23.5,13.4V7.7c0-1.1-0.9-1.9-1.9-1.9h-3.1V1.4c0-0.8-0.6-1.4-1.4-1.4H4.7c-0.8,0-1.4,0.6-1.4,1.4v4.4H3c-1.7,0-3,1.4-3,3
		v13.3c0,1.1,0.9,1.9,1.9,1.9h17.3c1,0,1.8-0.8,1.9-1.7h0.2c1.2,0,2.2-1,2.2-2.2v-1.7c0.4-0.2,0.6-0.5,0.6-0.9v-3.2
		C24,13.9,23.8,13.6,23.5,13.4z M22.7,18.5v1.6c0,0.8-0.6,1.4-1.4,1.4h-0.1v-3H22.7z M16.3,3.4c-0.7,0-1.3-0.6-1.3-1.3
		c0-0.2-0.2-0.4-0.4-0.4h-1.1V1.4c0-0.2,0-0.4-0.1-0.6H17c0.3,0,0.6,0.3,0.6,0.6V11h-0.9V3.8C16.7,3.6,16.5,3.4,16.3,3.4z M13.4,7.9
		c0.2,0,0.4-0.1,0.5-0.2C14,7.5,14.1,7.3,14,7.1c0-0.4-0.3-0.6-0.7-0.7v-4h0.8C14.3,3.4,15,4,15.9,4.2V11h-0.6
		c-0.4-0.7-1.1-1.2-1.9-1.2V7.9z M14.3,11h-0.9v-0.4C13.7,10.7,14,10.8,14.3,11z M4.7,0.8H12c0.3,0,0.6,0.3,0.6,0.6V11h-0.9V3.8
		c0-0.2-0.2-0.4-0.4-0.4h0c-0.7,0-1.3-0.6-1.3-1.3c0-0.2-0.2-0.4-0.4-0.4H7.1c-0.2,0-0.4,0.2-0.4,0.4c0,0.7-0.6,1.3-1.3,1.3
		C5.2,3.4,5,3.6,5,3.8V11H4.1V1.4C4.1,1,4.3,0.8,4.7,0.8z M7.4,10c-0.4,0.2-0.8,0.5-1,1H5.8V4.2C6.7,4,7.4,3.4,7.5,2.5h1.6
		c0.2,0.9,0.8,1.6,1.7,1.7V11h-0.6c-0.4-0.7-1.1-1.2-1.9-1.2C8,9.8,7.7,9.9,7.4,10z M9.1,10.9c0.1,0,0.1,0.1,0.2,0.1H7.4
		C7.8,10.6,8.5,10.5,9.1,10.9z M19.2,23.2H1.9c-0.6,0-1.1-0.5-1.1-1.1V10.8c0.6,0.6,1.4,1,2.2,1h16c0.4,0,0.8,0.3,0.8,0.8v0.8h-0.4
		l0-0.2c0-0.2-0.2-0.4-0.4-0.4l-0.1,0c-0.1,0-0.2,0.1-0.3,0.2c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v0l-0.4,0
		c-1.4,0-2.5,1.1-2.6,2.5c0,1.4,1.1,2.6,2.5,2.7h0.5v0.4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v-0.4h0.9v3.6
		C20.3,22.7,19.8,23.2,19.2,23.2z M18.3,14.2h4.8c0.1,0,0.2,0.1,0.2,0.2v3.2c0,0.1-0.1,0.2-0.2,0.2h-4.8c-1,0-1.8-0.8-1.8-1.8
		C16.5,15,17.3,14.2,18.3,14.2z M18.4,11V9.5h4.2v3.9h-2.1v-0.8c0-0.9-0.7-1.6-1.6-1.6H18.4z M19,11.2L19,11.2L19,11.2L19,11.2z
		 M3.3,11H3c-0.6,0-1.1-0.2-1.6-0.7C1,9.9,0.8,9.4,0.8,8.8c0-0.6,0.2-1.1,0.6-1.6c0.4-0.4,1-0.6,1.6-0.6h0.3V11z M18.4,8.7V6.6h3.1
		c0.6,0,1.1,0.5,1.1,1.1v1H18.4z"
      />
      <path d="M8.3,6.5c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7C8.7,7.9,9,7.6,9,7.2C9,6.8,8.7,6.5,8.3,6.5z" />
    </g>
  </svg>
);
