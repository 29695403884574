import React, { useContext, useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import {
  // Link as RouterLink,
  useHistory,
} from 'react-router-dom';
import {
  Input,
  List,
  ListItem,
  Flex,
  Box,
  Link,
  FormControl,
  // FormLabel,
  Text,
} from '@chakra-ui/react';
import { SmallAddIcon } from '@chakra-ui/icons';
import { useDebounce } from '../../custom-hooks/useDebounce';
import { HttpContext } from '../../context/HttpContext';
// import map from "lodash/map";
import filter from 'lodash/filter';
import { getFullNameFromUser } from '../../util';

const ComboboxInput = React.forwardRef(({ ...props }, ref) => {
  return <Input {...props} ref={ref} />;
});

const ComboboxList = React.forwardRef(({ isOpen, ...props }, ref) => {
  return <List display={isOpen ? null : 'none'} py={2} {...props} ref={ref} />;
});

const ComboboxItem = React.forwardRef(
  ({ itemIndex, highlightedIndex, ...props }, ref) => {
    const isActive = itemIndex === highlightedIndex;

    return (
      <ListItem
        transition="background-color 220ms, color 220ms"
        bg={isActive ? '#ebecf0' : null}
        px={4}
        py={2}
        cursor="pointer"
        {...props}
        ref={ref}
      />
    );
  }
);

export default function AutocompleteTeamInput({
  member,
  removedMemberState,
  updateRemovedMemberState,
  onClose,
}) {
  const [inputItems, setInputItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const initialInputValue = null; // member.firstName;
  const debouncedSearchTerm = useDebounce(searchTerm, 350);
  const { authAxios } = useContext(HttpContext);
  const history = useHistory();
  useEffect(() => {
    const getData = async () => {
      if (searchTerm === '') return;
      try {
        const { data } = await authAxios.get(
          `users/members/search/autocomplete?search=${searchTerm}&type=${member.type}`
        );
        const items = filter(data, (item) => item.id !== member.id);
        setInputItems(items);
      } catch (onError) {
        console.log(onError);
      }
    };

    getData();
  }, [authAxios, debouncedSearchTerm]);

  function handleSelected(selectedItem) {
    updateRemovedMemberState({
      removedMember: selectedItem,
      removedMemberError: null,
      removedMemberTouched: true,
    });
  }

  function handleSelectedInputValueChange(inputValue) {
    setSearchTerm(inputValue.trim());
    if (inputValue.trim() === '') {
      updateRemovedMemberState({
        removedMember: null,
        removedMemberError: 'This is a mandatory field.',
        removedMemberTouched: true,
      });
    }
  }

  function goto(path) {
    return () => {
      onClose();
      history.push(path);
    };
  }

  const {
    isOpen,
    // selectedItem,
    // getToggleButtonProps,
    // getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    // itemToString,
  } = useCombobox({
    items: inputItems,
    initialInputValue: initialInputValue,
    // itemToString: (item) => (item ? `${item.code}, ${item.campaignName}` : ""),
    itemToString: (item) => (item ? getFullNameFromUser(item) : ''),
    onInputValueChange: ({ inputValue }) => {
      handleSelectedInputValueChange(inputValue);
    },
    onSelectedItemChange({ selectedItem }) {
      handleSelected(selectedItem);
    },
  });
  return (
    <Flex direction="column">
      <Flex {...getComboboxProps()} direction="column" flex="1 1 auto" mb={[4, 4, 8]}>
        <FormControl>
          <ComboboxInput
            {...getInputProps()}
            placeholder="Start typing to find a team member"
            flex="0 0 auto"
            mt={3}
          />
        </FormControl>
        <Box boxShadow="0 1px 13px rgba(29, 44, 45, 0.12)" borderRadius="6px">
          <ComboboxList
            isOpen={isOpen}
            {...getMenuProps()}
            flex={1}
            overflowY="auto"
            mt={0}
          >
            {inputItems.map((user, index) => (
              <ComboboxItem
                {...getItemProps({ user, index })}
                itemIndex={index}
                highlightedIndex={highlightedIndex}
                key={index}
              >
                <Text>{getFullNameFromUser(user)}</Text>
              </ComboboxItem>
            ))}
            {isOpen && (
              <>
                {inputItems.length > 0 && (
                  <Box w="100%" h="1px" bg="#ebecf0" mt="5px" mb="5px" />
                )}
                <Link
                  color="brand.500"
                  // as={RouterLink}
                  // to={`/team?addNewMember=${member.id}`}
                  onClick={goto(`/team?addNewMember=${member.id}`)}
                  fontSize="16px"
                  py={2}
                  px={2}
                >
                  <SmallAddIcon h={22} w={7} /> Add a new member
                </Link>
                {/* <CreateCampaignButton addFromPurchase onClose/> */}
              </>
            )}
          </ComboboxList>
        </Box>
        {removedMemberState.removedMemberError ? (
          <Box color="error.500" fontSize="13px">
            {removedMemberState.removedMemberError}
          </Box>
        ) : null}
      </Flex>
    </Flex>
  );
}
