import React from 'react';
export const BankTransferIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    {...props}
  >
    <g>
      <path
        d="M12.4,0l11,6.9v1.4H0V6.9L11,0H12.4z M22,19.2h-1.4V11H22V9.6h-4.1V11h1.4v8.2h-4.1V11h1.4V9.6h-4.1V11h1.4v8.2H9.6V11H11
		V9.6H6.9V11h1.4v8.2H4.1V11h1.4V9.6H1.4V11h1.4v8.2H1.4v1.4H0V22h23.4v-1.4H22V19.2z"
      />
    </g>
  </svg>
);
