import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Spinner,
  Text,
  Tooltip,
  // IconButton,
} from '@chakra-ui/react';

import map from 'lodash/map';
import Pagination from '../../common/UIcomponents/Pagination';
import ScrollToTop from '../../util/scrollToTop';
import { POIcon } from '../../assets/iconsComponents/poIcon';
import POSortArrows from './POSortArrows';
import POStatusBadge from './POStatusBadge';
import {
  getPOCommentContent,
  getPOCommentAuthor,
  getPOCommentDate,
  formatAmount,
} from '../../util';
import { CurrencyContext } from '../../context/currency/CurrencyContext';
import SyncFailIcon from '../../assets/icons/sync_fail_icon.svg';
import moment from 'moment/moment';

function POList({
  purchaseOrders,
  isSupplier,
  isFinance,
  handlePageSizeChange,
  handlePageChange,
  currentPage,
  pagesQuantity,
  orderBy,
  updateOrderBy,
  loading,
}) {
  const history = useHistory();
  const { getCurrencySymbol } = useContext(CurrencyContext);
  const handleRowClick = (order) => {
    history.push(`/orders/${order.id}`);
  };

  const {
    supplierCode,
    campaignCode,
    totalGross,
    totalNet,
    totalVat,
    number,
    status,
    comment,
    invoiceDate,
    invoiceDueDate,
    invoicePaymentDate,
    podLeader,
    ownership,
  } = orderBy;

  function handleOnClick(e, key, value) {
    updateOrderBy(key, value);
  }

  console.log('POList', purchaseOrders);
  return (
    <>
      <ScrollToTop />
      <Table
        variant="simple"
        bg="#fff"
        borderRadius="md"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
        mt="7"
      >
        {purchaseOrders.length > 0 && !loading && (
          <Thead>
            <Tr key={'head-members'}>
              {!isSupplier && (
                <Th pr="10px">
                  <Box
                    d="flex"
                    flexDirection="row"
                    alignItems="center"
                    color="#bdc1d0"
                    fontSize="12px"
                    fontFamily="Roboto"
                    letterSpacing="0"
                  >
                    Supplier
                    <Box d="flex" flexDirection="column" ml="0px">
                      <POSortArrows
                        i={supplierCode}
                        b="supplierCode"
                        desc="desc"
                        asc="asc"
                        handleOnClick={handleOnClick}
                      />
                    </Box>
                  </Box>
                </Th>
              )}
              <Th pl={`${isSupplier} ? 20px : 10px`} pr="10px">
                <Box
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Campaign
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={campaignCode}
                      b="campaignCode"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>

              <Th pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Gross
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={totalGross}
                      b="totalGross"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Net
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={totalNet}
                      b="totalNet"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  VAT
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={totalVat}
                      b="totalVat"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="120px" pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  po number
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={number}
                      b="number"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Status
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={status}
                      b="status"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="100px" p="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Invoice date
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={invoiceDate}
                      b="invoiceDate"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="100px" p="0px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Due date
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={invoiceDueDate}
                      b="invoiceDueDate"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="110px" p="0px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Payment date
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={invoicePaymentDate}
                      b="invoicePaymentDate"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="160px" p="0px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={comment}
                      b="comment"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                  comment
                </Box>
              </Th>
              <Th minWidth="100px" p="0px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Pod leader
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={podLeader}
                      b="podLeader"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="100px" p="0px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  PO Requester
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={ownership}
                      b="ownership"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
            </Tr>
          </Thead>
        )}
        <>
          {loading && (
            <Box p={12} d="flex" alignItems="center" justifyContent="center" h="400px">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="brand.500"
                size="xl"
              />
            </Box>
          )}
          {purchaseOrders.length > 0 && !loading ? (
            <Tbody>
              <>
                {map(purchaseOrders, (order) => (
                  <Tr
                    key={order.id}
                    onClick={() => handleRowClick(order)}
                    style={{
                      cursor: 'pointer',
                    }}
                    _hover={{ bg: '#f5f6fa' }}
                  >
                    {!isSupplier && (
                      <Td maxWidth="250px" position="relative">
                        {isFinance && order.sageSyncFailed && (
                          <Box position="absolute" top="10px" left="10px" as="span">
                            <Tooltip
                              label="Sync with Sage failed. Restart synchronisation manually."
                              fontSize="xs"
                              maxWidth="180px"
                              color="white"
                              borderRadius="8px"
                              p={2}
                              bg="red"
                              textAlign="center"
                              lineHeight="1"
                            >
                              <img
                                src={SyncFailIcon}
                                style={{ width: '25px' }}
                                alt="syncfailed"
                              />
                            </Tooltip>
                          </Box>
                        )}
                        <Box
                          as="a"
                          fontSize="14px"
                          fontFamily="Roboto"
                          color={isFinance && order.sageSyncFailed && `red`}
                        >
                          {' '}
                          {order.supplier.code}
                          <Box
                            color={isFinance && order.sageSyncFailed ? `red` : '#a9b1b4'}
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="1"
                          >
                            {/* {getSupplierNameFromUser(order.supplier.user)} */}
                            {order.supplierName}
                          </Box>
                        </Box>
                      </Td>
                    )}
                    <Td
                      maxWidth="250px"
                      pl={`${isSupplier} ? 20px : 10px`}
                      color={isFinance && order.sageSyncFailed && `red`}
                      position="relative"
                    >
                      {/* {isSupplier && (
                        <>
                          {order.sageSyncFailed && (
                            <Box position="absolute" top="10px" left="10px" as="span">
                              <Tooltip
                                label="Sync with Sage failed. Restart synchronisation manually."
                                fontSize="xs"
                                maxWidth="180px"
                                color="white"
                                borderRadius="8px"
                                p={2}
                                bg="red"
                                textAlign="center"
                                lineHeight="1"
                              >
                                <img
                                  src={SyncFailIcon}
                                  style={{ width: "25px" }}
                                  alt="syncfailed"
                                />
                              </Tooltip>
                            </Box>
                          )}
                        </>
                      )} */}
                      <Box as="a" fontSize="14px" fontFamily="Roboto">
                        {' '}
                        {order.campaign.campaignCode}
                        <Box
                          color={isFinance && order.sageSyncFailed ? `red` : '#a9b1b4'}
                          fontSize="14px"
                          fontWeight="400"
                          lineHeight="1"
                        >
                          {order.campaign.campaignName}
                        </Box>
                      </Box>
                    </Td>
                    <Td pl="10px" color={isFinance && order.sageSyncFailed && `red`}>
                      <Box fontWeight="bold" fontSize="16px">
                        {getCurrencySymbol(order.supplier.currency.code)}
                        {formatAmount(order.totalGross)}
                      </Box>
                    </Td>
                    <Td pl="10px" color={isFinance && order.sageSyncFailed && `red`}>
                      {formatAmount(order.totalNet)}
                    </Td>

                    <Td pl="10px" color={isFinance && order.sageSyncFailed && `red`}>
                      {formatAmount(order.totalVat)}
                    </Td>
                    <Td pl="10px" color={isFinance && order.sageSyncFailed && `red`}>
                      {order.number ? (
                        order.number
                      ) : (
                        <Box
                          color={isFinance && order.sageSyncFailed ? `red` : '#a9b1b4'}
                        >
                          pending
                        </Box>
                      )}
                    </Td>
                    <Td pl="5px" color={isFinance && order.sageSyncFailed && `red`}>
                      <POStatusBadge status={order.status} />
                    </Td>
                    <Td pl="5px">
                      <Box color={isFinance && order.sageSyncFailed && `red`}>
                        {order?.invoice?.date
                          ? moment(order?.invoice?.date).format('DD MMM y')
                          : ''}
                      </Box>
                    </Td>
                    <Td pl="5px">
                      <Box color={isFinance && order.sageSyncFailed && `red`}>
                        {order?.invoice?.dueDate
                          ? moment(order?.invoice?.dueDate).format('DD MMM y')
                          : null}
                      </Box>
                    </Td>
                    <Td pl="5px">
                      <Box color={isFinance && order.sageSyncFailed && `red`}>
                        {order?.invoice?.paymentDate
                          ? moment(order?.invoice?.paymentDate).format('DD MMM y')
                          : null}
                      </Box>
                    </Td>
                    <Td p="0px" color={isFinance && order.sageSyncFailed && `red`}>
                      <Box fontStyle="italic" color="#333" lineHeight="1" fontSize="12px">
                        {order && order.lastLogComment ? (
                          <Tooltip
                            bg="brand.500"
                            // color="brand.50"
                            // color="white"
                            label={getPOCommentContent(order.lastLogComment)}
                            aria-label="Comment tooltip"
                          >
                            <Text isTruncated maxWidth="120px">
                              {getPOCommentContent(order.lastLogComment)}
                            </Text>
                          </Tooltip>
                        ) : null}
                      </Box>
                      <Box
                        color={isFinance && order.sageSyncFailed ? `red` : `#a9b1b4`}
                        fontSize="12px"
                        lineHeight="1.3"
                      >
                        {order && order.lastLogComment
                          ? getPOCommentAuthor(order.lastLogComment)
                          : null}
                      </Box>
                      <Box
                        color={isFinance && order.sageSyncFailed ? `red` : `#a9b1b4`}
                        fontSize="12px"
                        lineHeight="1"
                      >
                        {order && order.lastLogComment
                          ? getPOCommentDate(order.lastLogComment)
                          : null}
                      </Box>
                    </Td>
                    <Td pl="5px">
                      <Box color={isFinance && order.sageSyncFailed && `red`}>
                        {order?.podLeader?.firstName
                          ? `${order?.podLeader?.firstName} ${order?.podLeader?.lastName}`
                          : null}
                      </Box>
                    </Td>
                    <Td pl="5px" pr="5px">
                      <Box color={isFinance && order.sageSyncFailed && `red`}>
                        {order?.campaign?.ownership?.firstName
                          ? `${order?.campaign?.ownership?.firstName} ${order?.campaign?.ownership?.lastName}`
                          : null}
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </>
            </Tbody>
          ) : purchaseOrders.length === 0 && !loading ? (
            <Box
              d="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              p="80px"
            >
              <POIcon width="60px" height="55px" color="#d1dbdf" />
              <Box fontSize="18px" fontWeight="bold" mt="20px" mb="20px">
                No results.
              </Box>
              <Box color="#a9b1b4" fontSize="14px">
                We couldn’t find any purchase orders that matched your search criteria.
              </Box>
            </Box>
          ) : null}
        </>
      </Table>
      <Box margin="20px" />
      {purchaseOrders.length > 0 && (
        <Pagination
          handlePageSizeChange={handlePageSizeChange}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          pagesQuantity={pagesQuantity}
        />
      )}
    </>
  );
}

export default POList;
