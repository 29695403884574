import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { CheckIcon, SmallAddIcon } from '@chakra-ui/icons';
import DrawerComponentCustom from '../../common/UIcomponents/CustomDrawer';
import CampaignCodeForm from './CreateCampaignCodeForm';
import CreateCampaignContractForm from './CreateCampaignContractForm';

export default function CreateCampaignCodeButton({
  updateCampaignCode,
  text,
  icon,
  code,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        type="submit"
        colorScheme="brand"
        size="sm"
        pt="10px"
        pb="10px"
        fontSize="14px"
        fontWeight="500"
        onClick={onOpen}
        _hover={{ bg: '#000' }}
      >
        {icon === 'check' ? <CheckIcon mr="10px" /> : <SmallAddIcon h={22} w={7} />}

        {text ? text : `Create the campaign code`}
      </Button>

      <DrawerComponentCustom
        title="Create the Campaign code"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        campaign
      >
        {' '}
        {code ? (
          <CreateCampaignContractForm
            onClose={onClose}
            updateCampaignCode={updateCampaignCode}
            code={code}
          />
        ) : (
          <CampaignCodeForm onClose={onClose} updateCampaignCode={updateCampaignCode} />
        )}
      </DrawerComponentCustom>
    </>
  );
}
