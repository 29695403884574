import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  // useDisclosure,
  Container,
  Flex,
  Heading,
} from '@chakra-ui/react';

import { InfluencerIcon } from '../../assets/iconsComponents/influencerIcon';
import { CampaignIcon } from '../../assets/iconsComponents/campaignIcon';
import { POIcon } from '../../assets/iconsComponents/poIcon';
import { CommentIcon } from '../../assets/iconsComponents/commentIcon';
import { PaymentMethodIcon } from '../../assets/iconsComponents/paymentMethodIcon';

const DrawerComponentCustom = ({
  title,
  isOpen,
  onOpen,
  onClose,
  campaign,
  po,
  comment,
  payment,
  mdWidth,
  ...props
}) => {
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="full"
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent style={{ overflow: 'auto' }}>
          <Container maxW={mdWidth ? 'container.md' : 'container.sm'} pt={24}>
            <DrawerCloseButton color="brand.500" _focus={{ outline: '0' }} size="lg" />
            <DrawerHeader>
              <Flex alignItems="center" direction="column">
                {campaign ? (
                  <CampaignIcon
                    fill="#d1dbdf"
                    color="#d1dbdf"
                    height="90px"
                    width="70px"
                  />
                ) : po ? (
                  <POIcon fill="#d1dbdf" color="#d1dbdf" height="90px" width="70px" />
                ) : comment ? (
                  <CommentIcon
                    fill="#d1dbdf"
                    color="#d1dbdf"
                    height="90px"
                    width="64px"
                  />
                ) : payment ? (
                  <PaymentMethodIcon
                    fill="#d1dbdf"
                    color="#d1dbdf"
                    height="64px"
                    width="72px"
                  />
                ) : (
                  <InfluencerIcon
                    fill="#d1dbdf"
                    color="#d1dbdf"
                    height="50px"
                    width="70px"
                  />
                )}
                <Heading
                  as="h2"
                  size="lg"
                  textAlign="center"
                  fontWeight="700"
                  mb={[4, 4, 8]}
                  mt={4}
                >
                  {title}
                </Heading>
              </Flex>
            </DrawerHeader>
            <DrawerBody style={{ overflow: 'auto' }}>
              <>{props.children}</>
            </DrawerBody>
          </Container>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default DrawerComponentCustom;
