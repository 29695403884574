import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { Heading, Container, Box, Link, useToast } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import CreateSupplierCodeButton from '../components/suppliers/CreateSupplierCode';
import ReviewPaymentButton from '../components/suppliers/payment/ReviewPaymentChangesButton';
import { HttpContext } from '../context/HttpContext';
import SupplierDetailsContent from '../components/suppliers/SupplierDetailsContent';
// import EditSupplierButton from "../components/suppliers/EditButton";
import { AuthContext } from '../context/AuthContext';
import { getMessage, getMessageStatus, getUserFullName } from '../util';
import { hasPaymentsToApprove, isMissingCode } from '../util/supplier/helper';
import EditSupplierButton from '../components/suppliers/EditButton';
import last from 'lodash/last';
import MarkAsInactive from '../components/suppliers/MarkAsInactive';

export default function SupplierDetails() {
  const params = useParams();
  const { userId, id } = params;

  const toast = useToast();
  const history = useHistory();
  const [supplierState, setSupplierState] = useState({
    user: null,
    suppliers: [],
    selectedSupplierId: parseInt(id, 10) || 0,
    activityNumber: 0,
    selectedSupplier: null,
    suppCode: null,
    loading: true,
    error: null,
  });
  // const [addedCurrencies, setAddedCurrencies] = useState(0);
  const [lastCurrency, setLastCurrency] = useState(null);

  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  function updateSupplierCode(supplierCode) {}

  function updateSelectedSupplierId(selectedSupplierId) {
    const increment = supplierState.activityNumber + 1;
    setSupplierState({
      ...supplierState,
      selectedSupplierId,
      activityNumber: increment,
    });
  }

  function updateUser(user) {
    setSupplierState({
      ...supplierState,
      user,
    });
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const responseSuppliers = await authAxios.get(`users/${userId}/suppliers`);
        const suppliers = responseSuppliers?.data ?? [];
        if (lastCurrency?.code) {
          const recordIndex = suppliers.findIndex(
            (x) => x.currency.code === lastCurrency?.code
          );
          if (recordIndex > -1) {
            const selectedSupplier = suppliers.length > 0 ? suppliers[recordIndex] : null;
            const user = selectedSupplier?.user ?? null;
            const selectedSupplierId = suppliers[recordIndex].id;
            setSupplierState({
              ...supplierState,
              selectedSupplier,
              selectedSupplierId,
              user,
              suppliers,
              loading: false,
            });
            return;
          }
        }

        if (supplierState.selectedSupplierId > 0) {
          const recordIndex = suppliers.findIndex(
            (x) => x.id === supplierState.selectedSupplierId
          );

          if (recordIndex > -1) {
            const selectedSupplier = suppliers.length > 0 ? suppliers[recordIndex] : null;
            const user = selectedSupplier?.user ?? null;
            setSupplierState({
              ...supplierState,
              selectedSupplier,
              user,
              suppliers,
              loading: false,
            });
          } else {
            setSupplierState({
              ...supplierState,
              suppliers,
              loading: false,
            });
          }
          return;
        }

        const selectedSupplierId = suppliers.length > 0 ? suppliers[0].id : 0;
        const selectedSupplier = suppliers.length > 0 ? suppliers[0] : null;
        const user = selectedSupplier?.user ?? null;

        setSupplierState({
          ...supplierState,
          selectedSupplierId,
          selectedSupplier,
          user,
          suppliers,
          loading: false,
        });
      } catch (onError) {
        console.log(onError);
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top',
            description: message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/suppliers');
          }, 3000);
        } else {
          const status = getMessageStatus(onError);
          if (status === 403) {
            toast({
              position: 'top',
              description: 'You are not authorized to view this page',
              status: 'error',
              duration: 2900,
              isClosable: true,
            });
            setTimeout(function () {
              history.push('/suppliers');
            }, 3000);
          }
        }
      }
    };

    getData();
  }, [authAxios, userId, id, lastCurrency]);

  function updateSuppliers(suppliers) {
    setSupplierState({ ...supplierState, suppliers });
  }

  const {
    user,
    suppliers,
    selectedSupplierId,
    activityNumber,
    selectedSupplier,
    // loading, error
  } = supplierState;

  function addNewCurrencies(currencies) {
    // setAddedCurrencies(addedCurrencies + 1);
    setLastCurrency(last(currencies));
  }

  const handleMarkAsInactive = async () => {
    try {
      const isActive = selectedSupplier?.active;
      const action = isActive ? 'deactivate' : 'activate';
      await authAxios.post(`/users/suppliers/${selectedSupplierId}/${action}`);

      setSupplierState({
        ...supplierState,
        selectedSupplier: {
          ...supplierState.selectedSupplier,
          active: !isActive,
        },
      });

      toast({
        position: 'top',
        description: 'Changes saved.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Container w="100%" maxWidth="100%" pt={4} pr={10} pl={10} pb={14}>
      {user && (
        <>
          <Link color="brand.500" as={RouterLink} to="/suppliers" fontSize="14px">
            <ChevronLeftIcon /> Suppliers
          </Link>
          <Box>
            <Heading
              as="h2"
              size="lg"
              textAlign="left"
              fontWeight="700"
              pt={4}
              mb={[4, 4, 5]}
              d="inline-block"
            >
              <span>{getUserFullName(user)}</span>
            </Heading>
            {user.supplierCanBeEdited &&
              (auth.isFinanceManager() || auth.isCampaignManager()) && (
                <EditSupplierButton
                  isFinance={auth.isFinanceManager()}
                  isCampaign={auth.isCampaignManager()}
                  user={user}
                  updateUser={updateUser}
                  suppliers={suppliers}
                  addNewCurrencies={addNewCurrencies}
                />
              )}
            {auth.isFinanceManager() && (
              <MarkAsInactive
                handleMarkAsInactive={handleMarkAsInactive}
                supplier={selectedSupplier}
              />
            )}
          </Box>
          {auth.isFinanceManager() && isMissingCode(suppliers) && (
            <>
              <Box as="p" color="#333" mb="20px">
                The campaign team has requested a new supplier code and it is pending your
                approval.
              </Box>
              <Box pb={4}>
                <CreateSupplierCodeButton
                  suppliers={suppliers}
                  updateSuppliers={updateSuppliers}
                  updateSelectedSupplierId={updateSelectedSupplierId}
                  updateSupplierCode={updateSupplierCode}
                  supplierId={selectedSupplierId}
                  user={user}
                  updateUser={updateUser}
                />
              </Box>
            </>
          )}
          {auth.isFinanceManager() && hasPaymentsToApprove(suppliers) && (
            <>
              <Box as="p" color="#333" mb="20px">
                The supplier has submitted new payment data.&nbsp;
                <b>Please review and update the supplier records manually in Sage</b>.
              </Box>
              <Box pb={4}>
                <ReviewPaymentButton
                  suppliers={suppliers}
                  updateSuppliers={updateSuppliers}
                  updateSupplierCode={updateSupplierCode}
                  supplierId={selectedSupplierId}
                  user={user}
                  updateUser={updateUser}
                  updateSelectedSupplierId={updateSelectedSupplierId}
                />
              </Box>
            </>
          )}
        </>
      )}
      {user && (
        <SupplierDetailsContent
          suppliers={suppliers}
          updateSuppliers={updateSuppliers}
          user={user}
          selectedSupplierId={selectedSupplierId}
          updateSelectedSupplierId={updateSelectedSupplierId}
          updateSupplierCode={updateSupplierCode}
          supplierId={selectedSupplierId}
          updateUser={updateUser}
          isFinance={auth.isFinanceManager()}
          activityNumber={activityNumber}
        />
      )}
    </Container>
  );
}
