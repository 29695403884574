import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
  Badge,
  Text,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../context/HttpContext';
import { getErrors, getMessage } from '../../util';

export default function SupplierIndividualEditForm({
  onClose,
  user,
  updateUser,
  suppliers,
}) {
  const { firstName, lastName, email } = user;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('This is mandatory field'),
    lastName: Yup.string().required('This is mandatory field'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };
  const { register, handleSubmit, formState, setError, reset } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  const onSubmit = async (values) => {
    try {
      const res = await authAxios.patch(`users/suppliers/${user.id}`, values);
      onClose();
      reset();
      updateUser(res.data); // user
    } catch (onError) {
      const errors = getErrors(onError);
      if (errors && errors.firstName && errors.firstName.errors) {
        const message = errors.firstName.errors[0];
        setError('firstName', { type: 'server', message });
      } else if (errors && errors.lastName && errors.lastName.errors) {
        const message = errors.lastName.errors[0];
        setError('lastName', { type: 'server', message });
      } else {
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top-left',
            description: message,
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.firstName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">First Name</FormLabel>
          <Input
            isInvalid
            name="firstName"
            id="firstName"
            defaultValue={firstName}
            placeholder="Enter first name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('firstName')}
          />
          <FormErrorMessage>
            {errors.firstName?.message || errors.firstName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.lastName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Last Name</FormLabel>
          <Input
            isInvalid
            name="lastName"
            id="lastName"
            defaultValue={lastName}
            placeholder="Enter last name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('lastName')}
          />
          <FormErrorMessage>
            {errors.lastName?.message || errors.lastName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.email} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Email Address</FormLabel>
          <Input
            isInvalid
            disabled
            color="#c3c1c1"
            name="email"
            id="email"
            defaultValue={email}
            placeholder="Enter email address"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('email')}
          />
        </FormControl>

        <Box>
          <Text fontWeight="bold" mb="8px">
            Currency
          </Text>
          {suppliers.map((supp) => (
            <Badge
              key={supp.id}
              variant="solid"
              bg="transparent"
              pt="1"
              pb="1"
              px="2"
              mr="1"
              mb="1"
              fontSize="13px"
              fontWeight="400"
              color="#2b2b2b"
            >
              <CheckIcon mr={1} /> {supp.currency.code} - {supp.currency.name}
            </Badge>
          ))}
        </Box>
        <>
          <Box d="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              disabled={!isDirty || !isValid || isSubmitting}
              isLoading={isSubmitting}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
            >
              Submit
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              disabled={isSubmitting}
              textTransform="initial"
              borderRadius="10px"
              onClick={onClose}
              // _hover={{ bg: "#000" }}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
}
