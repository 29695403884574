import React from 'react';
import { Button, useDisclosure, Box } from '@chakra-ui/react';
import DrawerComponentCustom from '../../common/UIcomponents/CustomDrawer';
import POChangesForm from './PORequestChangesForm';
import { CommentIcon } from '../../assets/iconsComponents/commentIcon';

export default function RequestPurchaseOrderButton({ updateActivityLog, isSupplier }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        type="submit"
        color="brand.500"
        size="sm"
        variant="outline"
        borderColor="gray.300"
        onClick={onOpen}
        pt="10px"
        pb="10px"
        fontSize="14px"
        fontWeight="500"
      >
        <CommentIcon width="14px" />
        <span style={{ paddingLeft: '10px' }}>Request purchase order changes</span>
      </Button>

      <DrawerComponentCustom
        title="Request purchase order changes"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        comment
      >
        {' '}
        <Box color="#868789" mb="30px">
          {!isSupplier
            ? `Something wasn’t correct in the PO? Please let the campaign team know
          what needs to be changed and you will be notified when they respond.`
            : `Something wasn’t correct in the purchase order? Please let us know what needs to be changed and you will be notified when we respond.`}
        </Box>
        <POChangesForm onClose={onClose} updateActivityLog={updateActivityLog} />
      </DrawerComponentCustom>
    </>
  );
}
