import React, { useState } from 'react';
import { useStep } from 'react-hooks-helper';
import map from 'lodash/map';
import CodeInput from './CodeInputForm2';
import { getPaymentsToApprove } from '../../../../util/supplier/helper';
// import { getMissingCurrencies } from "../../../../util/supplier/helper";

const CodeStepForm = ({
  onClose,
  suppliers,
  updateSelectedSupplierId,
  updateSuppliers,
}) => {
  const [stepFormState, setStepFormState] = useState({
    status: 'idle',
    error: null,
    suppliersForApproving: getPaymentsToApprove(suppliers),
    suppliersForApprovingCount: getPaymentsToApprove(suppliers)
      ? getPaymentsToApprove(suppliers).length
      : 0,
  });

  const { suppliersForApproving } = stepFormState;

  function updateStepFormState(data) {
    setStepFormState(data);
  }

  const steps = map(suppliersForApproving, (supp) => ({
    ...supp,
    value: `${supp.id}`,
    name: supp.code,
    Component: CodeInput,
  }));

  const { step, navigation, index } = useStep({ initialStep: 0, steps });
  const { id, name, value, Component } = step;
  const props = { navigation, index };
  return (
    <Component
      {...props}
      steps={steps}
      name={name}
      value={value}
      onClose={onClose}
      id={id}
      stepFormState={stepFormState}
      updateStepFormState={updateStepFormState}
      updateSelectedSupplierId={updateSelectedSupplierId}
      suppliers={suppliers}
      updateSuppliers={updateSuppliers}
    />
  );
};

export default CodeStepForm;
