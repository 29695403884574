import React from 'react';
import { useParams } from 'react-router-dom';

import ResetPasswordForm from '../components/auth/ResetPassword/resetPasswordForm';

const ResetPassword = () => {
  const { token } = useParams();

  return <ResetPasswordForm token={token} />;
};

export default ResetPassword;
