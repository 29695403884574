import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
  IconButton,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../context/HttpContext';
import { getBase64Promise, getErrors, getMessage } from '../../util';
import EditSelectCurrency from '../../common/UIcomponents/EditSelectCurrency';
import CampaignEditDatePicker from './CampaignEditDatePicker';
import { CampaignContext } from '../../context/campaign/FilterCampaigns';
import moment from 'moment';
import EditSelectStatus from '../../common/UIcomponents/EditSelectStatus';
import UploadFiles from './UploadCampaignFile';
import { getFilenameFromResponse } from '../vendors/UploadedFile';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import SelectPodLeader from '../../common/UIcomponents/SelectPodLeader';

const CampaignEditForm = ({ onClose, campaign, updateCampaign }) => {
  const [fileName] = useState(campaign.file);
  const [fileName2] = useState(campaign.file2);
  const [fileName3] = useState(campaign.file3);
  const [fileName4] = useState(campaign.file4);
  const [fileName5] = useState(campaign.file5);
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const [file5, setFile5] = useState(null);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [isFileRemoved2, setIsFileRemoved2] = useState(false);
  const [isFileRemoved3, setIsFileRemoved3] = useState(false);
  const [isFileRemoved4, setIsFileRemoved4] = useState(false);
  const [isFileRemoved5, setIsFileRemoved5] = useState(false);

  const [newFile, setNewFile] = useState(undefined);
  const [newFile2, setNewFile2] = useState(undefined);
  const [newFile3, setNewFile3] = useState(undefined);
  const [newFile4, setNewFile4] = useState(undefined);
  const [newFile5, setNewFile5] = useState(undefined);
  const [currencyId, setCurrencyId] = React.useState(campaign.currency.id);
  const [startDate, setStartDate] = useState(campaign.startDate);
  const [endDate, setEndDate] = useState(campaign.endDate);
  const [status, setStatus] = useState(campaign.status);
  const [done, setDone] = React.useState(false);
  const [podLeaderId, setPodLeaderId] = React.useState(campaign?.podLeader?.id || 0);

  const { authAxios } = useContext(HttpContext);
  const toast = useToast();

  const validationSchema = Yup.object().shape({
    clientName: Yup.string().required('This is mandatory field'),
    campaignName: Yup.string().required('This is mandatory field'),
    budget: Yup.number()
      .typeError(
        'Only numerical input is allowed, if decimal number is needed please use . to separate numbers (e.g. 3.14).'
      )
      .required('This is mandatory field')
      .min(1, 'Budget must be at least 1'),
    // budget: Yup.number().required('This is mandatory field'),
    // budget: Yup.number().test(
    //   "maxDigitsAfterDecimal",
    //   "Budget field it must be positive and must have two digits after decimal or less",
    //   (number) => Number.isInteger(number * 10 ** 2) && number > 0
    // ),
  });

  useEffect(() => {
    const getData = async () => {
      try {
        if (campaign.file) {
          const response = await authAxios.get(
            `files/download?id=${campaign.id}&type=campaign`,
            { responseType: 'blob' }
          );

          const blob = new Blob([response.data], {
            type: response.headers['content-type'],
          });

          const file = new File([blob], getFilenameFromResponse(response), {
            type: blob.type,
          });
          Object.assign(file, { preview: URL.createObjectURL(file) });
          setFile(file);
        }

        if (campaign.file2) {
          const response2 = await authAxios.get(
            `files/download?id=${campaign.id}&type=campaignFile2`,
            { responseType: 'blob' }
          );

          const blob = new Blob([response2.data], {
            type: response2.headers['content-type'],
          });

          const file2 = new File([blob], getFilenameFromResponse(response2), {
            type: blob.type,
          });
          Object.assign(file2, { preview: URL.createObjectURL(file2) });
          setFile2(file2);
        }

        if (campaign.file3) {
          const response3 = await authAxios.get(
            `files/download?id=${campaign.id}&type=campaignFile3`,
            { responseType: 'blob' }
          );

          const blob = new Blob([response3.data], {
            type: response3.headers['content-type'],
          });

          const file3 = new File([blob], getFilenameFromResponse(response3), {
            type: blob.type,
          });
          Object.assign(file3, { preview: URL.createObjectURL(file3) });
          setFile3(file3);
        }

        if (campaign.file4) {
          const response4 = await authAxios.get(
            `files/download?id=${campaign.id}&type=campaignFile4`,
            { responseType: 'blob' }
          );

          const blob = new Blob([response4.data], {
            type: response4.headers['content-type'],
          });

          const file4 = new File([blob], getFilenameFromResponse(response4), {
            type: blob.type,
          });
          Object.assign(file4, { preview: URL.createObjectURL(file4) });
          setFile4(file4);
        }

        if (campaign.file5) {
          const response5 = await authAxios.get(
            `files/download?id=${campaign.id}&type=campaignFile5`,
            { responseType: 'blob' }
          );

          const blob = new Blob([response5.data], {
            type: response5.headers['content-type'],
          });

          const file5 = new File([blob], getFilenameFromResponse(response5), {
            type: blob.type,
          });
          Object.assign(file5, { preview: URL.createObjectURL(file5) });
          setFile5(file5);
        }
      } catch (onError) {
        console.log(onError);
      }
    };

    getData();
  }, [authAxios]);

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setError,
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { errors, isSubmitting, isDirty, isValid } = formState;

  async function getBase64(file) {
    return await getBase64Promise(file);
  }
  const watchClientName = watch('clientName');

  const onSubmit = async (values) => {
    try {
      if (podLeaderId === 0) {
        setError('podLeader', { type: 'custom', message: 'This is mandatory field' });
        return;
      }
      let data = {
        clientName: values.clientName,
        campaignName: values.campaignName,
        budget: values.budget,
        currency: currencyId,
        startDate,
        endDate,
        podLeader: podLeaderId,
      };

      if (status && status !== 'initial') {
        data = {
          ...data,
          status,
        };
      }

      if (isFileRemoved) {
        data = {
          ...data,
          file: '',
        };
      }
      if (newFile) {
        const base64 = await getBase64(newFile);
        const file = base64.split(',')[1];
        data = {
          ...data,
          file,
        };
      }

      if (isFileRemoved2) {
        data = {
          ...data,
          file2: '',
        };
      }
      if (newFile2) {
        const base642 = await getBase64(newFile2);
        const file2 = base642.split(',')[1];
        data = {
          ...data,
          file2,
        };
      }

      if (isFileRemoved3) {
        data = {
          ...data,
          file3: '',
        };
      }
      if (newFile3) {
        const base643 = await getBase64(newFile2);
        const file3 = base643.split(',')[1];
        data = {
          ...data,
          file3,
        };
      }

      if (isFileRemoved4) {
        data = {
          ...data,
          file4: '',
        };
      }
      if (newFile4) {
        const base644 = await getBase64(newFile2);
        const file4 = base644.split(',')[1];
        data = {
          ...data,
          file4,
        };
      }

      if (isFileRemoved5) {
        data = {
          ...data,
          file5: '',
        };
      }
      if (newFile5) {
        const base645 = await getBase64(newFile2);
        const file5 = base645.split(',')[1];
        data = {
          ...data,
          file5,
        };
      }

      const res = await authAxios.patch(`/campaigns/${campaign.id}`, data);
      updateCampaign({ campaign: res.data.data, meta: res.data.meta });
      onClose();
      reset();
      setDone(true);
      let description = 'You have successfully edited the campaign.';
      if (status !== 'initial') {
        description = 'You have successfully requested campaign changes.';
      }
      toast({
        position: 'top',
        description,
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    } catch (onError) {
      const errors = getErrors(onError);
      if (errors && errors.clientName && errors.clientName.errors) {
        const message = errors.clientName.errors[0];
        setError('clientName', { type: 'server', message });
      } else if (errors && errors.campaignName && errors.campaignName.errors) {
        const message = errors.campaignName.errors[0];
        setError('campaignName', { type: 'server', message });
      } else if (errors && errors.budget && errors.budget.errors) {
        const message = errors.budget.errors[0];
        setError('budget', { type: 'server', message });
      } else if (errors && errors.currency && errors.currency.errors) {
        const message = errors.currency.errors[0];
        setError('currency', { type: 'server', message });
      } else if (errors && errors.podLeader && errors.podLeader.errors) {
        const message = errors.podLeader.errors[0];
        setError('podLeader', { type: 'server', message });
      } else if (errors && errors.status && errors.status.errors) {
        const message = errors.status.errors[0];
        setError('status', { type: 'server', message });
      } else {
        let msgsArr = onError?.response?.data?.errors?.errors || [];
        if (msgsArr.length > 0) {
          toast({
            position: 'top-left',
            description: msgsArr[0],
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        } else {
          const message = getMessage(onError);
          console.log({ message });
          if (message) {
            toast({
              position: 'top-left',
              description: message,
              status: 'error',
              duration: 6000,
              isClosable: true,
            });
          }
        }
      }
    }
  };
  const { setFrom } = useContext(CampaignContext);
  const { setTo } = useContext(CampaignContext);

  function handleOnClose() {
    setFrom('');
    setTo('');
    onClose();
  }

  const updateCurrencyId = (id) => {
    setCurrencyId(id);
  };

  const updateStatus = (status) => {
    setStatus(status);
  };
  function updateStartDate(date) {
    setStartDate(moment(date).format('YYYY-MM-DD'));
  }

  function updateEndDate(date) {
    setEndDate(moment(date).format('YYYY-MM-DD'));
  }

  function isChangedDate() {
    return !(startDate === campaign.startDate && endDate === campaign.endDate);
  }

  function updateUploadedFile(file) {
    setValue('clientName', watchClientName, { shouldDirty: true });
    setNewFile(file);
    setIsFileRemoved(false);
  }

  function updateUploadedFile2(file) {
    setNewFile2(file);
    setIsFileRemoved2(false);
  }

  function updateUploadedFile3(file) {
    setNewFile3(file);
    setIsFileRemoved3(false);
  }
  function updateUploadedFile4(file) {
    setNewFile4(file);
    setIsFileRemoved4(false);
  }
  function updateUploadedFile5(file) {
    setNewFile5(file);
    setIsFileRemoved5(false);
  }

  const updatePodLeaderId = (id) => {
    if (id === '0') {
      setError('podLeader', { type: 'custom', message: 'This is mandatory field' });
    } else {
      clearErrors('podLeader');
    }
    setPodLeaderId(id);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.clientName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Client</FormLabel>
          <Input
            isInvalid
            name="clientName"
            id="clientName"
            defaultValue={campaign.clientName}
            placeholder="Enter client name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('clientName')}
          />
          <FormErrorMessage>
            {errors.clientName?.message || errors.clientName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.campaignName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Campaign name</FormLabel>
          <Input
            isInvalid
            name="campaignName"
            id="campaignName"
            defaultValue={campaign.campaignName}
            placeholder="Enter campaign Name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('campaignName')}
          />
          <FormErrorMessage>
            {errors.campaignName?.message || errors.campaignName}
          </FormErrorMessage>
        </FormControl>
        {campaign?.status !== 'initial' && (
          <FormControl isInvalid={errors.status} mb={[4, 4, 8]}>
            <FormLabel htmlFor="emailHtmlFor">Status</FormLabel>
            <EditSelectStatus status={status} updateStatus={updateStatus} />
            <FormErrorMessage>{errors.status?.message || errors.status}</FormErrorMessage>
          </FormControl>
        )}
        <CampaignEditDatePicker
          startDate={campaign.startDate}
          endDate={campaign.endDate}
          updateStartDate={updateStartDate}
          updateEndDate={updateEndDate}
        />

        <FormControl isInvalid={errors.currency} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Currency</FormLabel>
          <EditSelectCurrency
            currencyId={currencyId}
            updateCurrencyId={updateCurrencyId}
          />
          <FormErrorMessage>
            {errors.currency?.message || errors.currency}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.budget} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Budget</FormLabel>
          <Input
            isInvalid
            name="budget"
            id="budget"
            defaultValue={campaign.budget}
            placeholder="Enter amount"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('budget')}
          />
          <FormErrorMessage>{errors.budget?.message || errors.budget}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.podLeader} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Pod Leader</FormLabel>
          <SelectPodLeader
            updatePodLeaderId={updatePodLeaderId}
            podLeaderId={podLeaderId}
          />
          <FormErrorMessage>
            {errors.podLeader?.message || errors.podLeader}
          </FormErrorMessage>
        </FormControl>

        <FormLabel htmlFor="emailHtmlFor">Attach a documents (optional)</FormLabel>
        <Box color="#868789" fontSize="14px">
          Document 1.
        </Box>
        {newFile === undefined && campaign.file && !isFileRemoved && (
          <Box
            position="relative"
            p={2}
            border="1px solid #ebecf0"
            borderRadius="4px"
            mt={2}
            mb={4}
          >
            <Box pr="10px">
              <Box key={file?.name}>
                {/* {file.path} - {formatBytes(file.size)} */}
                {/* <Link color="brand.400" ml="5px">File uploaded</Link> */}
                <Box color="brand.400" ml="5px">
                  <CheckIcon color="brand.400" mr={2} />{' '}
                  <a href={file?.preview} target="_blank" rel="noreferrer">
                    {fileName ? fileName : 'Uploaded file'}
                  </a>{' '}
                </Box>
              </Box>
            </Box>
            <Box position="absolute" top="-12px" right="-10px">
              <IconButton
                colorScheme="brand"
                size="xs"
                onClick={() => {
                  setIsFileRemoved(true);
                  setValue('clientName', watchClientName, {
                    shouldDirty: true,
                  });
                }}
                aria-label="remove file icon"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        )}
        <UploadFiles
          updateUploadedFile={updateUploadedFile}
          removeFile={() => setIsFileRemoved(true)}
          name={file}
        />

        <Box color="#868789" fontSize="14px">
          Document 2.
        </Box>
        {newFile2 === undefined && campaign.file2 && !isFileRemoved2 && (
          <Box
            position="relative"
            p={2}
            border="1px solid #ebecf0"
            borderRadius="4px"
            mt={2}
            mb={4}
          >
            <Box pr="10px">
              <Box key={file2?.name}>
                {/* {file.path} - {formatBytes(file.size)} */}
                {/* <Link color="brand.400" ml="5px">File uploaded</Link> */}
                <Box color="brand.400" ml="5px">
                  <CheckIcon color="brand.400" mr={2} />{' '}
                  <a href={file2?.preview} target="_blank" rel="noreferrer">
                    {fileName2 ? fileName2 : 'Uploaded file'}
                  </a>{' '}
                </Box>
              </Box>
            </Box>
            <Box position="absolute" top="-12px" right="-10px">
              <IconButton
                colorScheme="brand"
                size="xs"
                onClick={() => {
                  setIsFileRemoved2(true);
                  setValue('clientName', watchClientName, {
                    shouldDirty: true,
                  });
                }}
                aria-label="remove file icon"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        )}
        <UploadFiles
          updateUploadedFile={updateUploadedFile2}
          removeFile={() => setIsFileRemoved2(true)}
          name={file2}
        />
        <Box color="#868789" fontSize="14px">
          Document 3.
        </Box>
        {newFile3 === undefined && campaign.file3 && !isFileRemoved3 && (
          <Box
            position="relative"
            p={2}
            border="1px solid #ebecf0"
            borderRadius="4px"
            mt={2}
            mb={4}
          >
            <Box pr="10px">
              <Box key={file3?.name}>
                {/* {file.path} - {formatBytes(file.size)} */}
                {/* <Link color="brand.400" ml="5px">File uploaded</Link> */}
                <Box color="brand.400" ml="5px">
                  <CheckIcon color="brand.400" mr={2} />{' '}
                  <a href={file3?.preview} target="_blank" rel="noreferrer">
                    {fileName3 ? fileName3 : 'Uploaded file'}
                  </a>{' '}
                </Box>
              </Box>
            </Box>
            <Box position="absolute" top="-12px" right="-10px">
              <IconButton
                colorScheme="brand"
                size="xs"
                onClick={() => {
                  setIsFileRemoved3(true);
                  setValue('clientName', watchClientName, {
                    shouldDirty: true,
                  });
                }}
                aria-label="remove file icon"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        )}
        <UploadFiles
          updateUploadedFile={updateUploadedFile3}
          removeFile={() => setIsFileRemoved3(true)}
          name={file3}
        />
        <Box color="#868789" fontSize="14px">
          Document 4.
        </Box>
        {newFile4 === undefined && campaign.file4 && !isFileRemoved4 && (
          <Box
            position="relative"
            p={2}
            border="1px solid #ebecf0"
            borderRadius="4px"
            mt={2}
            mb={4}
          >
            <Box pr="10px">
              <Box key={file4?.name}>
                {/* {file.path} - {formatBytes(file.size)} */}
                {/* <Link color="brand.400" ml="5px">File uploaded</Link> */}
                <Box color="brand.400" ml="5px">
                  <CheckIcon color="brand.400" mr={2} />{' '}
                  <a href={file4?.preview} target="_blank" rel="noreferrer">
                    {fileName4 ? fileName4 : 'Uploaded file'}
                  </a>{' '}
                </Box>
              </Box>
            </Box>
            <Box position="absolute" top="-12px" right="-10px">
              <IconButton
                colorScheme="brand"
                size="xs"
                onClick={() => {
                  setIsFileRemoved4(true);
                  setValue('clientName', watchClientName, {
                    shouldDirty: true,
                  });
                }}
                aria-label="remove file icon"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        )}
        <UploadFiles
          updateUploadedFile={updateUploadedFile4}
          removeFile={() => setIsFileRemoved4(true)}
          name={file4}
        />
        <Box color="#868789" fontSize="14px">
          Document 5.
        </Box>
        {newFile5 === undefined && campaign.file5 && !isFileRemoved5 && (
          <Box
            position="relative"
            p={2}
            border="1px solid #ebecf0"
            borderRadius="4px"
            mt={2}
            mb={4}
          >
            <Box pr="10px">
              <Box key={file5?.name}>
                {/* {file.path} - {formatBytes(file.size)} */}
                {/* <Link color="brand.400" ml="5px">File uploaded</Link> */}
                <Box color="brand.400" ml="5px">
                  <CheckIcon color="brand.400" mr={2} />{' '}
                  <a href={file5?.preview} target="_blank" rel="noreferrer">
                    {fileName5 ? fileName5 : 'Uploaded file'}
                  </a>{' '}
                </Box>
              </Box>
            </Box>
            <Box position="absolute" top="-12px" right="-10px">
              <IconButton
                colorScheme="brand"
                size="xs"
                onClick={() => {
                  setIsFileRemoved5(true);
                  setValue('clientName', watchClientName, {
                    shouldDirty: true,
                  });
                }}
                aria-label="remove file icon"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        )}
        <UploadFiles
          updateUploadedFile={updateUploadedFile5}
          removeFile={() => setIsFileRemoved5(true)}
          name={file5}
        />

        <>
          <Box display="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              disabled={
                (!isDirty || !isValid || isSubmitting) &&
                currencyId === campaign.currency.id &&
                (podLeaderId === 0 || podLeaderId === campaign?.podLeader?.id) &&
                status === campaign.status &&
                !isChangedDate()
              }
              isLoading={isSubmitting && !done}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
            >
              Submit
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={handleOnClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
};
export default CampaignEditForm;
