import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import { PodLeadersContext } from '../context/podLeader/PodleadersContext';
// import { AuthContext } from '../context/AuthContext';

export default function UsePodLeaders({ children }) {
  const { authAxios } = useContext(HttpContext);
  // const auth = useContext(AuthContext);
  const ctx = useContext(PodLeadersContext);

  useEffect(() => {
    let ignore = false;
    const getData = async () => {
      try {
        // if (!auth.isCampaignManager()) return;
        const { data } = await authAxios.get(`/users/pod-leaders/search/autocomplete`);
        // console.log({data })
        if (!ignore) {
          ctx.setDataState({
            data,
            isLoaded: true,
            error: null,
          });
        }
      } catch (onError) {
        console.log(onError);
        const error = (onError.response && onError.response.data) || null;
        if (!ignore) {
          ctx.setDataState({ data: [], isLoaded: true, error });
        }
      }
    };

    getData();

    return () => {
      ignore = true;
    };
  }, [authAxios]);

  return <>{children}</>;
}
