import React from 'react';
import UseCurrency from '../../custom-hooks/useCurrency';
import CampaignContextProvider from '../../context/campaign/FilterCampaigns';
import { CurrencyProvider } from '../../context/currency/CurrencyContext';
import CampaignRequestChanges from '../CampaignRequestChangesPage';

export const SINGLE_CAMPAIGN_REQUEST_CHANGES_PAGE = 'campaigns_request_changes_page';
function CampaignRequestChangesPage() {
  return (
    <CampaignContextProvider>
      <CurrencyProvider>
        <UseCurrency>
          <CampaignRequestChanges />
        </UseCurrency>
      </CurrencyProvider>
    </CampaignContextProvider>
  );
}

export default CampaignRequestChangesPage;
