import React from 'react';
import { Badge } from '@chakra-ui/react';
import { getPOLabel } from '../../util';

export default function POStatusBadge({ status }) {
  return (
    <>
      {status === 'pending_repair' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="yellow"
          color="#b3a633"
          bg="#f3ecac"
          textTransform="initial"
          fontSize="12px"
          fontWeight="400"
          borderRadius="6px"
          w="210px"
          textAlign="center"
        >
          {getPOLabel(status)}
        </Badge>
      ) : status === 'awaiting_invoice' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="blue"
          bg="#d3dbf6"
          color="#6980cf"
          textTransform="initial"
          fontSize="12px"
          fontWeight="400"
          borderRadius="6px"
          w="210px"
          textAlign="center"
        >
          {getPOLabel(status)}
        </Badge>
      ) : status === 'pending_approval' ? (
        <Badge
          pt="1"
          pb="1"
          pr="2"
          pl="2"
          colorScheme="gray"
          bg="#f1f2f6"
          color="#b6b7ba"
          textTransform="initial"
          fontSize="12px"
          fontWeight="400"
          borderRadius="6px"
          w="210px"
          textAlign="center"
        >
          {getPOLabel(status)}
        </Badge>
      ) : status === 'pending_approval_finance' ? (
        <Badge
          pt="1"
          pb="1"
          pr="2"
          pl="2"
          colorScheme="gray"
          bg="#f1f2f6"
          color="#b6b7ba"
          textTransform="initial"
          fontSize="12px"
          fontWeight="400"
          borderRadius="6px"
          w="210px"
          textAlign="center"
        >
          {getPOLabel(status)}
        </Badge>
      ) : status === 'rejected' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="red"
          color="#df2338"
          bg="#ffa4ab"
          textTransform="initial"
          fontSize="12px"
          fontWeight="400"
          borderRadius="6px"
          w="210px"
          textAlign="center"
        >
          {getPOLabel(status)}
        </Badge>
      ) : status === 'invoice_pending_approval' ? (
        <Badge
          pt="1"
          pb="1"
          pr="1"
          pl="1"
          colorScheme="gray"
          bg="#f1f2f6"
          color="#b6b7ba"
          textTransform="initial"
          fontSize="12px"
          fontWeight="400"
          borderRadius="6px"
          w="210px"
          textAlign="center"
        >
          {getPOLabel(status)}
        </Badge>
      ) : status === 'invoice_approved' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="blue"
          bg="#abd3fd"
          color="#4f95de"
          textTransform="initial"
          fontSize="12px"
          fontWeight="400"
          borderRadius="6px"
          w="210px"
          textAlign="center"
        >
          {getPOLabel(status)}
        </Badge>
      ) : status === 'invoice_paid' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="green"
          bg="#82e3b0"
          color="#5b9075"
          textTransform="initial"
          fontSize="12px"
          fontWeight="400"
          borderRadius="6px"
          w="210px"
          textAlign="center"
        >
          {getPOLabel(status)}
        </Badge>
      ) : status === 'invoice_pending_repair' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="yellow"
          color="#b3a633"
          bg="#f3ecac"
          textTransform="initial"
          fontSize="12px"
          fontWeight="400"
          borderRadius="6px"
          w="210px"
          textAlign="center"
        >
          {getPOLabel(status)}
        </Badge>
      ) : status === 'invoice_rejected' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="red"
          color="#df2338"
          bg="#ffa4ab"
          textTransform="initial"
          fontSize="12px"
          fontWeight="400"
          borderRadius="6px"
          w="210px"
          textAlign="center"
        >
          {getPOLabel(status)}
        </Badge>
      ) : null}
    </>
  );
}
