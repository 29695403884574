import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
// import { useToast } from '@chakra-ui/react';
import { getMessage } from '../util';

const AuthContext = createContext();
const { Provider } = AuthContext;

function parseJwt(jwt) {
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const publicAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const KEYS = {
  token: process.env.REACT_APP_TOKEN,
  exp: process.env.REACT_APP_EXP,
  user: process.env.REACT_APP_USER,
  refreshToken: process.env.REACT_APP_REFRESH_TOKEN,
  refreshTokenTTL: process.env.REACT_APP_REFRESH_TOKEN_TTL_SEC,
};

function getRefreshTokenExpInSeconds() {
  try {
    return (
      Math.floor(new Date().getTime() / 1000) +
      parseInt(process.env.REACT_APP_REFRESH_TOKEN_TTL_SEC, 10)
    );
  } catch (onError) {
    console.log(onError);
    return 0;
  }
}

function getExpiresAt(token) {
  const payload = parseJwt(token);
  let { exp } = payload;
  const refreshTokenExp = getRefreshTokenExpInSeconds();
  if (exp < refreshTokenExp) {
    exp = refreshTokenExp;
  }

  return exp;
}

const AuthProvider = ({ children }) => {
  const history = useHistory();
  // const toast = useToast();

  const token = localStorage.getItem(KEYS.token);
  const exp = localStorage.getItem(KEYS.exp);
  const user = localStorage.getItem(KEYS.user);
  const refreshToken = localStorage.getItem(KEYS.refreshToken);

  const [authState, setAuthState] = useState({
    token,
    exp,
    user: user ? JSON.parse(user) : {},
    refreshToken,
  });

  const setAuthInfo = ({ token, user, refreshToken }) => {
    const exp = getExpiresAt(token);
    localStorage.setItem(KEYS.token, token);
    localStorage.setItem(KEYS.user, JSON.stringify(user));
    localStorage.setItem(KEYS.exp, exp);
    localStorage.setItem(KEYS.refreshToken, refreshToken);

    setAuthState({ token, user, exp, refreshToken });
  };

  const setUser = (user) => {
    localStorage.setItem(KEYS.user, JSON.stringify(user));
    setAuthState({
      ...authState,
      user,
    });
  };

  const setTokens = ({ token, refreshToken }) => {
    const exp = getExpiresAt(token);
    localStorage.setItem(KEYS.token, token);
    localStorage.setItem(KEYS.exp, exp);
    localStorage.setItem(KEYS.refreshToken, refreshToken);

    setAuthState({ token, exp, refreshToken });
  };
  const logout = async () => {
    try {
      localStorage.removeItem(KEYS.token);
      localStorage.removeItem(KEYS.exp);
      localStorage.removeItem(KEYS.user);
      localStorage.removeItem(KEYS.refreshToken);
      setAuthState({
        token: null,
        exp: null,
        user: null,
        refreshToken: null,
      });
      if (history) {
        history.push('/login');
      }
    } catch (onError) {
      console.log(onError);
    }
  };

  const isFinance = () => {
    const user = getUser();

    return !!(
      user &&
      (user.type === 'finance' || user.type === 'finance_user') &&
      isAuthenticated()
    );
  };
  const isFinanceManager = () => {
    const user = getUser();

    return !!(user && user.type === 'finance' && isAuthenticated());
  };

  const isFinanceUser = () => {
    const user = getUser();

    return !!(user && user.type === 'finance_user' && isAuthenticated());
  };

  const isAdmin = () => {
    const user = getUser();

    return !!(user && user.type === 'admin' && isAuthenticated());
  };

  const isPodLeader = () => {
    const user = getUser();

    return !!(user && user.type === 'pod_leader' && isAuthenticated());
  };

  const isCampaignManager = () => {
    const user = getUser();

    return !!(user && user.type === 'campaign' && isAuthenticated());
  };

  const isSupplier = () => {
    const user = getUser();
    return !!(user && user.type === 'supplier' && isAuthenticated());
  };

  const isSupplierIndividual = () => {
    const user = getUser();
    return !!(
      user &&
      user.type === 'supplier' &&
      user.subType === 'individual' &&
      isAuthenticated()
    );
  };

  const isSupplierCompany = () => {
    const user = getUser();
    return !!(
      user &&
      user.type === 'supplier' &&
      user.subType === 'company' &&
      isAuthenticated()
    );
  };
  const canViewMembersList = () => {
    const user = getUser();
    return !!(user && user.canViewMembersList && isAuthenticated());
  };
  const canViewPOList = () => {
    const user = getUser();
    return !!(user && user.canViewPOList && isAuthenticated());
  };
  const canViewPaymentsList = () => {
    const user = getUser();
    return !!(user && user.canViewPaymentsList && isAuthenticated());
  };
  const canViewSuppliersList = () => {
    const user = getUser();
    return !!(user && user.canViewSuppliersList && isAuthenticated());
  };

  const shouldSupplierConfirmAccountDetails = () => {
    const user = getUser();
    return !!(
      user &&
      user.type === 'supplier' &&
      isAuthenticated() &&
      user.roles &&
      user.roles.includes('ROLE_UNCONFIRMED_INFORMATIONS')
    );
  };

  const isAuthenticated = () => {
    return !((!getAccessToken() && !getRefreshToken()) || !getUser());
  };

  const getAccessToken = () => {
    return localStorage.getItem(KEYS.token);
  };

  const getRefreshToken = () => {
    return localStorage.getItem(KEYS.refreshToken);
  };

  const getNewToken = async () => {
    try {
      const { token, refresh_token } = await publicAxios.post('/token/refresh', {
        refresh_token: getRefreshToken(),
      });
      setTokens({ token, refreshToken: refresh_token });
    } catch (onError) {
      console.log(onError);
      return onError;
    }
  };

  const getUser = () => {
    const user = localStorage.getItem(KEYS.user);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  };

  const getNewTokenForRequest = async (failedRequest) => {
    console.log(failedRequest);
    try {
      const { data } = await publicAxios.post('/token/refresh', {
        refresh_token: getRefreshToken(),
      });

      const { token, refresh_token } = data;

      const payload = parseJwt(token);
      const { exp } = payload;
      localStorage.setItem(KEYS.token, token);
      localStorage.setItem(KEYS.refreshToken, refresh_token);
      localStorage.setItem(KEYS.exp, exp);

      // failedRequest.response.config.headers['Authorization'] = `Bearer ${data.token}`;
      failedRequest.response.config.headers[`X-Auth-Token`] = token;
      setTokens({ token, refreshToken: refresh_token });

      return Promise.resolve();
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);

      if (message) {
        // toast({
        //   position: 'top-left',
        //   description: message,
        //   status: 'error',
        //   duration: 6000,
        //   isClosable: true,
        // });
        logout();
      }
    }
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        setAuthTokens: (tokens) => setTokens(tokens),
        setAuthUser: (user) => setUser(user),
        getUser,
        logout,
        isAuthenticated,
        isFinanceManager,
        isFinanceUser,
        isFinance,
        isPodLeader,
        isAdmin,
        isCampaignManager,
        isSupplier,
        isSupplierIndividual,
        isSupplierCompany,
        getAccessToken,
        getNewToken,
        getNewTokenForRequest,
        shouldSupplierConfirmAccountDetails,
        canViewMembersList,
        canViewPOList,
        canViewPaymentsList,
        canViewSuppliersList,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
