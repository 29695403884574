import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Text,
  HStack,
  useToast,
} from '@chakra-ui/react';

import AddPaymentDetails from './PaymentDetailsForm/AddPaymentDetailsButton';
import { getCurrencyTitle, getMessage } from '../../util';
import { HttpContext } from '../../context/HttpContext';
import { hasBankAccount, hasPayPal } from '../../util/supplier/helper';
export default function PaymentDetails({ user }) {
  const toast = useToast();
  const history = useHistory();
  const [state, setState] = useState({
    updatedOrder: null,
    orderNumber: null,
    activityLog: null,
    loading: true,
    error: null,
    pending: false,
    suppliers: [],
  });

  const { authAxios } = useContext(HttpContext);
  const { orderNumber, activityLog, updatedOrder, suppliers } = state;

  useEffect(() => {
    const getData = async () => {
      try {
        setState({
          ...state,
          pending: true,
        });

        const dataSuppliers = await authAxios.get(`/users/${user.id}/suppliers`);

        setState({
          ...state,
          suppliers: Object.values(dataSuppliers.data),
          loading: false,
          pending: false,
        });
      } catch (onError) {
        setState({
          ...state,
          error: 'Something went wrong',
          loading: false,
          pending: false,
        });
        console.log(onError);
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top',
            description: message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/orders');
          }, 3000);
        }
      }
    };

    getData();
  }, [authAxios, orderNumber, activityLog, updatedOrder]);

  function needPaymentDetails(supplier) {
    const { bankName, payPalAccount } = supplier;
    return bankName === null && payPalAccount === null;
  }

  function updateSupplier(supplier) {
    const { suppliers } = state;
    const recordIndex = suppliers.findIndex((x) => x.id === supplier.id);
    if (recordIndex > -1) {
      suppliers[recordIndex] = supplier;
      setState({
        ...state,
        suppliers,
      });
    }
  }

  function getBoxOpacity(supplier) {
    return supplier?.paymentDetailsApproved ? '1' : '0.3';
  }

  function getTd(supplier) {
    return (
      <>
        {supplier.paymentDetailsApproved ? (
          <AddPaymentDetails edit supplier={supplier} updateSupplier={updateSupplier} />
        ) : (
          <Text fontStyle="italic" fontSize="16px">
            Processing
          </Text>
        )}
      </>
    );
  }

  return (
    <Table variant="simple" type="top">
      <Thead bg="#eeeff3">
        <Tr>
          <Th color="#bdc1d0" pt={1} pb={1} fontSize="12px">
            Currency
          </Th>
          <Th color="#bdc1d0" pt={1} pb={1} fontSize="12px">
            payment method
          </Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {suppliers?.map((supplier) => {
          if (needPaymentDetails(supplier)) {
            return (
              <Tr type="top" style={{ verticalAlign: 'top' }} key={supplier.id}>
                <Td>{getCurrencyTitle(supplier.currency)}</Td>
                <Td>
                  <AddPaymentDetails
                    supplier={supplier}
                    updateSupplier={updateSupplier}
                  />
                </Td>
                <Td />
              </Tr>
            );
          } else if (hasPayPal(supplier)) {
            return (
              <Tr type="top" style={{ verticalAlign: 'top' }} key={supplier.id}>
                <Td>{getCurrencyTitle(supplier.currency)}</Td>
                <Td>
                  {' '}
                  <Box opacity={getBoxOpacity(supplier)}>
                    <Text fontSize="16px">PayPal</Text>
                    <HStack alignItems="flex-start">
                      <Box color="#868789" fontSize="14px" mr="35px">
                        <Text mb="5px">Email address:</Text>
                      </Box>

                      <Box alignItems="flex-start" fontSize="14px">
                        <Text mb="5px">{supplier.payPalAccount}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </Td>
                <Td>{getTd(supplier)}</Td>
              </Tr>
            );
          } else if (hasBankAccount(supplier)) {
            const {
              accountName,
              accountNumber,
              accountNumberOrIban,
              additionalReference,
              bankAddress,
              bankCode,
              bankName,
              bsbCode,
              country,
              iban,
              ifscCode,
              routingNumber,
              sortCode,
              swift,
            } = supplier;
            return (
              <Tr type="top" style={{ verticalAlign: 'top' }} key={supplier.id}>
                <Td>{getCurrencyTitle(supplier.currency)}</Td>
                <Td>
                  {' '}
                  <Box opacity={getBoxOpacity(supplier)}>
                    <Text fontSize="16px">Bank transfer</Text>
                    <HStack alignItems="flex-start">
                      <Box color="#868789" fontSize="14px" mr="35px">
                        <Text mb="5px">Bank name:</Text>
                        {bankAddress && <Text mb="5px">Bank address:</Text>}
                        <Text mb="5px">Country:</Text>
                        {accountName && <Text mb="5px">Account name:</Text>}
                        {accountNumber && <Text mb="5px">Account number:</Text>}
                        {accountNumberOrIban && (
                          <Text mb="5px">Account number or IBAN:</Text>
                        )}
                        {bankCode && <Text mb="5px">Bank code:</Text>}
                        {bsbCode && <Text mb="5px">BSB code:</Text>}
                        {iban && <Text mb="5px">IBAN:</Text>}
                        {ifscCode && <Text mb="5px">IFSC code:</Text>}
                        {routingNumber && <Text mb="5px">Routing number:</Text>}
                        {sortCode && <Text mb="5px">Sort code:</Text>}
                        {swift && <Text mb="5px">SWIFT code:</Text>}
                        {additionalReference && (
                          <Text mb="5px">Additional reference:</Text>
                        )}
                      </Box>
                      <Box alignItems="flex-start" fontSize="14px" mt="20px">
                        <Text mb="5px">{bankName}</Text>
                        {bankAddress && <Text mb="5px">{bankAddress}</Text>}
                        <Text mb="5px">{country?.name}</Text>
                        {accountName && <Text mb="5px">{accountName}</Text>}
                        {accountNumber && <Text mb="5px">{accountNumber}</Text>}
                        {accountNumberOrIban && (
                          <Text mb="5px">{accountNumberOrIban}</Text>
                        )}
                        {bankCode && <Text mb="5px">{bankCode}</Text>}
                        {bsbCode && <Text mb="5px">{bsbCode}</Text>}
                        {iban && <Text mb="5px">{iban}</Text>}
                        {ifscCode && <Text mb="5px">{ifscCode}</Text>}
                        {routingNumber && <Text mb="5px">{routingNumber}</Text>}
                        {sortCode && <Text mb="5px">{sortCode}</Text>}
                        {swift && <Text mb="5px">{swift}</Text>}
                        {additionalReference && (
                          <Text mb="5px">{additionalReference}</Text>
                        )}
                      </Box>
                    </HStack>
                  </Box>
                </Td>
                <Td>{getTd(supplier)}</Td>
              </Tr>
            );
          } else {
            return null;
          }
        })}
      </Tbody>
    </Table>
  );
}
