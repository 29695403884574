import React, { useContext } from 'react';
import { Select } from '@chakra-ui/react';
import map from 'lodash/map';
import { CurrencyContext } from '../../context/currency/CurrencyContext';

function EditSelectCurrency({ updateCurrencyId, currencyId, disabled }) {
  const currencyContext = useContext(CurrencyContext);
  function handleChangeOption(e) {
    updateCurrencyId(parseInt(e.target.value, 10));
  }
  return (
    <Select
      bg="#ebecf0"
      h="48px"
      onChange={handleChangeOption}
      defaultValue={currencyId}
      disabled={disabled}
    >
      {map(currencyContext.gateAllCurrencies(), (curr) => (
        <option key={curr.id} value={curr.id}>{`${curr.code} - ${curr.name}`}</option>
      ))}
    </Select>
  );
}

export default EditSelectCurrency;
