import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';

import { HttpContext } from '../../context/HttpContext';
import { getErrors, getMessage } from '../../util';
import UploadFiles from './UploadCampaignFile';
import { getBase64Promise } from '../../util';

export default function CampaignCampaignContractForm({
  onClose,
  updateCampaignCode,
  code,
}) {
  const { id } = useParams();
  const [done, setDone] = React.useState(false);
  const [uploadedFile, setUploadedFile] = useState(undefined);

  const { register, handleSubmit, formState, reset, setError } = useForm({
    mode: 'onChange',
  });

  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const history = useHistory();

  async function getBase64(file) {
    return await getBase64Promise(file);
  }

  const onSubmit = async (values) => {
    try {
      let submittedData = values;
      if (uploadedFile) {
        const base64 = await getBase64(uploadedFile);
        const contractFile = base64.split(',')[1];
        submittedData = {
          ...values,
          campaignCode: code,
          contractFile,
        };
      }

      const res = await authAxios.post(`campaigns/${id}/approve`, submittedData);
      onClose();
      updateCampaignCode(res.data.campaignCode);
      reset();
      setDone(true);
      history.push(`/campaigns/${id}`);
      toast({
        position: 'top',
        description: 'You have successfully created the campaign code.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    } catch (onError) {
      console.log(onError);
      const errors = getErrors(onError);
      if (errors && errors.campaignCode && errors.campaignCode.errors) {
        const message = errors.campaignCode.errors[0];
        setError('campaignCode', { type: 'server', message });
      }
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          // "This code is already in use. Please choose a different code.",
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };
  function updateUploadedFile(file) {
    setUploadedFile(file);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.campaignCode} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Campaign code</FormLabel>
          <Input
            defaultValue={code}
            value={code}
            disabled
            isInvalid
            color="#c3c1c1"
            name="CampaignCode"
            id="campaignCode"
            placeholder="Enter campaign code"
            textTransform="uppercase"
            _placeholder={{ textTransform: 'initial' }}
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('campaignCode')}
          />
          <FormErrorMessage>
            {errors.campaignCode?.message || errors.campaignCode}
          </FormErrorMessage>
        </FormControl>
        <FormLabel htmlFor="emailHtmlFor">Attach a document</FormLabel>
        <UploadFiles updateUploadedFile={updateUploadedFile} />
        <>
          <Box d="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              disabled={isSubmitting || !uploadedFile}
              isLoading={isSubmitting && !done}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
            >
              Approve
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
}
