import React from 'react';
// import { Link as RouterLink } from "react-router-dom";
import {
  // Link, Text,
  Select,
  Box,
  // Icon,
} from '@chakra-ui/react';
//import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
//import { IoIosArrowBack, IoIosArrowForward } from "react-icons/IoIosArrowForward";
import {
  Paginator,
  // Container as PaginatorContainer,
  Previous,
  Next,
  PageGroup,
} from 'chakra-paginator';

import { PaginationContainer, PaginationList, PaginationTotal } from './styles';
import { INITIAL_PAGE_SIZE } from '../../util/consts';

const Pagination = ({
  handlePageSizeChange,
  currentPage,
  pagesQuantity,
  handlePageChange,
}) => {
  // styles
  const baseStyles = {
    w: 6,
    fontSize: '14px',
  };

  const normalStyles = {
    ...baseStyles,
    _hover: {
      bg: 'transparent',
      color: '#333',
    },
    _focus: {
      outline: 'none',
    },
    bg: 'transparent',
    color: '#bdc1d0;',
  };

  const activeStyles = {
    ...baseStyles,
    _hover: {
      bg: 'transparent',
    },
    _focus: {
      outline: 'none',
    },
    bg: 'transparent',
    color: '#333',
  };

  const separatorStyles = {
    w: 7,
    bg: 'transparent',
  };
  return (
    <PaginationContainer>
      <PaginationTotal>
        <Box
          as="p"
          color="#333333"
          textAlign="center"
          fontSize="13px"
          fontWeight="700"
          mr="5px"
        >
          View
        </Box>

        <Select
          borderColor="#e8e9e9"
          color="#333333"
          // placeholder="10/page"
          fontSize="13px"
          size="sm"
          borderRadius="8px"
          fontFamily="Roboto"
          onChange={handlePageSizeChange}
        >
          <option value={INITIAL_PAGE_SIZE}>{`${INITIAL_PAGE_SIZE}/page`}</option>
          <option value="100">100/page</option>
          <option value="200">200/page</option>
        </Select>
      </PaginationTotal>
      <PaginationList>
        <Paginator
          activeStyles={activeStyles}
          innerLimit={2}
          currentPage={currentPage}
          outerLimit={2}
          normalStyles={normalStyles}
          separatorStyles={separatorStyles}
          pagesQuantity={pagesQuantity}
          onPageChange={handlePageChange}
        >
          <Previous bg="transparent" _focus={{ outline: 'none' }}>
            <BiChevronLeft color="#bdc1d0" />
          </Previous>
          <PageGroup isInline align="center" />
          <Next bg="transparent" _focus={{ outline: 'none' }}>
            <BiChevronRight color="#bdc1d0" />
          </Next>
        </Paginator>
      </PaginationList>

      {/*<PaginationList>*/}
      {/*  <li>*/}
      {/*    <Link as={RouterLink} to="/">*/}
      {/*      /!* left arrow not be visible if is the first page active *!/*/}
      {/*       <ChevronLeftIcon color="#bdc1d0" />*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link as={RouterLink} to="/" className="isActive">*/}
      {/*      1*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link as={RouterLink} to="/">*/}
      {/*      2*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link as={RouterLink} to="/">*/}
      {/*      3*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link as={RouterLink} to="/" className="isActive">*/}
      {/*      <ChevronRightIcon color="#bdc1d0" />*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*</PaginationList>*/}
    </PaginationContainer>
  );
};

export default Pagination;
