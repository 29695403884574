import React from 'react';
export const POIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M8.388,9.029l0.03-0.001l3.854,0c0.206,0,0.372-0.164,0.374-0.365c0-0.097-0.035-0.19-0.104-0.261
	c-0.07-0.071-0.163-0.111-0.261-0.112H8.406L8.383,8.289H8.378L8.35,8.29c-0.1,0.009-0.189,0.057-0.252,0.133
	C8.035,8.5,8.004,8.596,8.015,8.694C8.033,8.885,8.19,9.029,8.382,9.029H8.388z"
    />
    <path
      d="M8.206,7.103h0.007l0.028-0.001h5.304c0.205,0,0.371-0.163,0.373-0.364c0.002-0.098-0.033-0.191-0.104-0.263
	c-0.068-0.071-0.16-0.11-0.26-0.112H8.229L8.207,6.362H8.202L8.173,6.363c-0.101,0.01-0.19,0.058-0.253,0.134
	C7.857,6.574,7.828,6.669,7.838,6.768C7.856,6.958,8.014,7.103,8.206,7.103z"
    />
    <path
      d="M13.234,20.631h0.008l0.027-0.002h5.305c0.205,0,0.371-0.163,0.373-0.364c0.002-0.098-0.035-0.191-0.104-0.263
	c-0.07-0.07-0.162-0.11-0.262-0.111h-5.324l-0.023-0.002H13.23l-0.029,0.002c-0.1,0.011-0.191,0.059-0.252,0.134
	c-0.063,0.076-0.092,0.172-0.082,0.271C12.885,20.485,13.043,20.631,13.234,20.631z"
    />
    <path
      d="M18.695,11.076H8.224c-0.196,0.013-0.333,0.175-0.333,0.369v5.801c0,0.203,0.149,0.37,0.333,0.37h10.472
	c0.186,0,0.336-0.167,0.336-0.37v-5.801C19.031,11.241,18.881,11.076,18.695,11.076z M18.363,14.961v1.99h-2.605v-1.99H18.363z
	 M18.363,11.814v2.297h-2.605v-2.297H18.363z M15.092,14.961v1.99H8.559v-1.99H15.092z M15.092,11.814v2.297H8.559v-2.297H15.092z"
    />
    <path
      d="M21.387,2.959c0,0-0.08,0.001-0.176,0.001H5.744L5.721,2.959H5.714l-0.03,0.002c-0.188,0.035-0.314,0.19-0.311,0.371v20.299
	c0,0.203,0.167,0.369,0.37,0.369h15.469c0.203,0,0.369-0.166,0.369-0.369V3.221C21.586,3.127,21.537,2.959,21.387,2.959z
	 M6.114,3.699h14.722l0.006,4.35v15.213H6.114V3.699z"
    />
    <path
      d="M4.024,20.438H2.856V0.876h14.722l0.002,1.126h0.744V0.398c0.002-0.094-0.045-0.262-0.197-0.262
	c0,0-0.078,0.001-0.174,0.001H2.486L2.463,0.136H2.457l-0.03,0.002c-0.188,0.035-0.314,0.19-0.311,0.372v20.298
	c0,0.204,0.167,0.369,0.37,0.369h1.538V20.438z"
    />
  </svg>
);
