import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

    html {
        box-sizing: border-box;
        -ms-overflow-style: -ms-autohiding-scrollbar;
          
    }
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    html,
    body,
    div,
    span,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    abbr,
    address,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    samp,
    small,
    strong,
    sub,
    sup,
    var,
    b,
    i,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        text-align: left;
    }
body{overflow-x: hidden;},
    html,
    body {
        margin: 0;
        padding: 0;
        //-ms-overflow-style: -ms-autohiding-scrollbar;
        -ms-overflow-style: none;
        scrollbar-width: none; /* for Firefox */
        
    }
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
    // ::-webkit-scrollbar {
    //     width: 8px;
    //     background:lightgray;
    //   }
      
    //   ::-webkit-scrollbar-track {
    //     -webkit-box-shadow: inset 0 0 6px rgb(195, 195, 195); 
    //     border-radius: 15px;
    //   }
    textarea {
        resize: vertical;
    }
    textarea[contenteditable] {
        -webkit-appearance: none;
    }

    code {
        line-height: 1;
        font-family: Monaco, Consolas, "Bitstream Vera Sans Mono", "Andale Mono",
            "DejaVu Sans Mono", "Lucida Console", monospace;
        font-size: 0.9em;
    }

    img,
    svg {
        display: inline-block;
        max-width: 100%;
        height: auto;
        vertical-align: middle;
        font-style: italic;
    }
    a {
        max-width: 100%;
    }
    img[alt=""],
    img:not([alt]) {
        /* border: 5px dashed red; */
    }

    svg {
        fill: currentcolor;
    }

    /* google maps images */
    .gm-style img {
        max-width: none;
    }
    /*
    img[width],
    img[height]{max-width: none;}
    */
    figure {
        max-width: 100% !important;
        margin: 0 0 1em;
    }
    figure > img {
        display: block;
    }

    /* ----------- */
    object,
    embed,
    video {
        max-width: 100%;
    }

    /* ----------- */
    textarea:active,
    textarea:focus {
        cursor: text;
        outline: none;
    }

    abbr[title],
    dfn[title] {
        cursor: help;
    }
    u,
    ins {
        text-decoration: none;
    }

    /*---------------------------------------
        autofill
    ---------------------------------------*/
    input:-webkit-autofill {
        box-shadow: inset 0 0 0px 9999px rgb(255, 255, 255);
        -webkit-box-shadow: inset 0 0 0px 9999px rgb(255, 255, 255);
    }

    /*---------------------------------------
        Utilities
    ---------------------------------------*/
    .lines-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .lines-3 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    /*
    * Has comma
    */
    .has-comma:after {
        content: "," " ";
    }
    .has-comma:last-child:after {
        content: "";
    }

    /*---------------------------------------
			Search cancel button
    ---------------------------------------*/
    input[type="search"]::-webkit-search-cancel-button {
			-webkit-appearance: none;
			height: 20px;
			width: 20px;
			margin-left: .4em;
			background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba(235,127,36,1)'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
			cursor: pointer;
		}
`;
