import React from 'react';
import { Badge } from '@chakra-ui/react';

export default function CampaignStatusBadge({ status }) {
  // console.log(status);
  return (
    <>
      {status === 'initial' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="gray"
          color="#a9b1b4"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="6px"
          w="89px"
          textAlign="center"
        >
          {status}
        </Badge>
      ) : status === 'active' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="blue"
          color="#6f9fb7"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="6px"
          w="89px"
          textAlign="center"
        >
          {status}
        </Badge>
      ) : status === 'completed' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="green"
          color="#5b9075"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="6px"
          w="89px"
          textAlign="center"
        >
          {status}
        </Badge>
      ) : status === 'snag' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="yellow"
          bg="#fde9ae"
          color="#d18533"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          w="89px"
          textAlign="center"
        >
          {' '}
          {status}
        </Badge>
      ) : status === 'suspend' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="red"
          color="#c75c5c"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          w="89px"
          textAlign="center"
        >
          {' '}
          {status}
        </Badge>
      ) : status === 'rejected' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="red"
          color="#c75c5c"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          w="89px"
          textAlign="center"
        >
          {' '}
          {status}
        </Badge>
      ) : status === 'finance_pending_approval' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="gray"
          color="#a9b1b4"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          //w="89px"
          textAlign="center"
        >
          {' '}
          Finance pending approval
        </Badge>
      ) : status === 'pod_leader_rejected' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="red"
          color="#c75c5c"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          //w="89px"
          textAlign="center"
        >
          {' '}
          Pod Leader rejected
        </Badge>
      ) : status === 'pod_leader_repair' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="yellow"
          bg="#fde9ae"
          color="#d18533"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          //w="89px"
          textAlign="center"
        >
          {' '}
          Pod Leader repair
        </Badge>
      ) : status === 'finance_rejected' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="red"
          color="#c75c5c"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          //w="89px"
          textAlign="center"
        >
          {' '}
          Finance rejected
        </Badge>
      ) : status === 'finance_repair' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="yellow"
          bg="#fde9ae"
          color="#d18533"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          //w="89px"
          textAlign="center"
        >
          {' '}
          Finance repair
        </Badge>
      ) : null}
    </>
  );
}
