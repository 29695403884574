import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../../context/HttpContext';

const ForgotPasswordForm = () => {
  const toast = useToast();
  const [done, setDone] = React.useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('This is mandatory field'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };
  const {
    register,
    handleSubmit,
    reset,
    formState,
    // setError
  } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;
  const { publicAxios } = useContext(HttpContext);

  function onSubmit(values) {
    return new Promise((resolve) => {
      const data = {
        email: values.email,
      };

      publicAxios
        .post(`users/password/forgot`, data)
        .catch((onError) => {
          console.log(onError);
        })
        .finally(() => {
          setDone(true);
          reset();
          toast({
            position: 'top-left',
            description:
              "If you have an account, you'll receive an email with a reset link. Please check your inbox.",
            status: 'success',
            duration: 6000,
            isClosable: true,
          });
        });
      resolve();
    });
  }

  return (
    <>
      <Box as="p" color="#333333" pb={10} pt={2}>
        Enter your email and we'll send you an email with the option to reset your
        password.
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.email} mb={[4, 4, 8]}>
          <FormLabel htmlFor="email">Email address</FormLabel>
          <Input
            isInvalid
            name="email"
            id="email"
            focusBorderColor="black"
            errorBorderColor="error.400"
            {...register('email')}
          />
          <FormErrorMessage>{errors.email?.message || errors.email}</FormErrorMessage>
        </FormControl>
        <>
          <Button
            type="submit"
            size="lg"
            colorScheme="brand"
            isFullWidth
            isLoading={isSubmitting && !done}
            disabled={!isDirty || !isValid || isSubmitting}
            textTransform="initial"
            mt={6}
            _hover={{ bg: '#000' }}
            //disabled={disableForm}
          >
            Send reset instructions
          </Button>
        </>
      </form>
    </>
  );
};
export default ForgotPasswordForm;
