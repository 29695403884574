import DrawerComponentCustom from '../../common/UIcomponents/CustomDrawer';
import { Box, Checkbox, Text } from '@chakra-ui/react';
import { getCurrencyTitle } from '../../util';
import { WarningIcon } from '@chakra-ui/icons';
import AddPaymentDetailsForm from '../account/PaymentDetailsForm/AddPaymentDetailsForm';
import React from 'react';
// import * as PropTypes from "prop-types";

export default function PaymentDetailsDrawer(props) {
  return (
    <DrawerComponentCustom
      title={props.edit ? 'Edit payment details' : 'Add payment details'}
      isOpen={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      payment
      mdWidth
    >
      <Box fontSize="16px">
        <Text fontWeight="bold">Currency</Text>
        {props.supplier && <Text>{getCurrencyTitle(props.currency)}</Text>}
      </Box>
      {props.numberOfPendingInvoices > 0 && (
        <Box bg="#f7c7ad" border="1px solid #ff5a00" p="20px" mb="25px" mt="25px">
          <Box d="flex" flexDirection="row">
            <Box w="16px" minWidth="16px">
              <WarningIcon color="brand.400" w="16px" />
            </Box>
            <Box pl="10px">
              <Text>
                You have {props.numberOfPendingInvoices1} invoice(s) that are not paid
                yet. Once your payment details change request is confirmed by the Goat
                team, your new payment details will be applied to all pending and future
                invoices.
              </Text>
            </Box>
          </Box>
          <Checkbox
            size="lg"
            colorScheme="orange"
            isChecked={props.checked}
            onChange={props.onChange}
          >
            <Box fontSize="16px" pt="20px">
              I acknowledge that my new payment details will be applied to both future
              invoices and invoices that are pending at the moment of payment details
              review.
            </Box>
          </Checkbox>
        </Box>
      )}
      <Box as="p" fontWeight="bold" mt="35px" mb="20px">
        Select how you would like to receive the payments
      </Box>
      <AddPaymentDetailsForm
        onClose={props.onClose}
        supplier={props.supplier}
        updateSupplier={props.updateSupplier}
        isChecked={props.checked}
        isEdit={Boolean(props.edit)}
      />
    </DrawerComponentCustom>
  );
}

// PaymentDetailsForm.propTypes = {
//   edit: PropTypes.any,
//   open: PropTypes.bool,
//   onOpen: PropTypes.func,
//   onClose: PropTypes.func,
//   supplier: PropTypes.any,
//   currency: PropTypes.any,
//   numberOfPendingInvoices: PropTypes.any,
//   numberOfPendingInvoices1: PropTypes.any,
//   checked: PropTypes.bool,
//   onChange: PropTypes.func,
//   updateSupplier: PropTypes.any,
// };
