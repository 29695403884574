import React, { useState, useContext, useEffect } from 'react';
import { HttpContext } from '../../context/HttpContext';
import { getMessage } from '../../util';
import { Box, useToast, Button } from '@chakra-ui/react';
import { AuthContext } from '../../context/AuthContext';

export default function SageRestartSync() {
  const [state, setState] = useState({
    syncStatus: false,
    syncRestarted: 'idle',
  });

  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  const handleSync = async () => {
    try {
      setState({
        ...state,
        syncRestarted: 'pending',
      });
      const res = await authAxios.post(`/resync/start`);
      setState({
        ...state,
        syncStatus: false,
        syncRestarted: 'success',
      });
      toast({
        position: 'top',
        description:
          'You have restarted the synchronisation with Sage, but please note that it might take a few minutes. If the problem persist, contact your development team.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    } catch (onError) {
      setState({
        ...state,
        syncRestarted: 'rejected',
      });
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await authAxios.get(`/resync/status`);
        setState({
          ...state,
          syncStatus: data.data.status,
          syncRestarted: 'ready',
        });
      } catch (onError) {
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top',
            description: message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        }
      }
    };

    getData();
  }, [authAxios]);

  return (
    <>
      {state.syncStatus && (
        <>
          <Box as="p" color="#333" mb="17px" mt="20px">
            Sync with Sage failed. Restart synchronisation below, but please note it might
            take a few minutes.
            <br />
            If the problem persists, contact your development team.
          </Box>
          <Button
            colorScheme="brand"
            size="sm"
            pt="10px"
            pb="10px"
            fontSize="14px"
            fontWeight="500"
            _hover={{ bg: '#000' }}
            mb="15px"
            onClick={handleSync}
            disabled={state.syncRestarted === 'idle' || state.syncRestarted === 'pending'}
          >
            Restart synchronisation
          </Button>
        </>
      )}
    </>
  );
}
