import { useContext } from 'react';
import { Select } from '@chakra-ui/react';
import map from 'lodash/map';

import { PodLeadersContext } from '../../context/podLeader/PodleadersContext';

function SelectPodLeader({ updatePodLeaderId, podLeaderId, disabled }) {
  const ctx = useContext(PodLeadersContext);
  if (!ctx) {
    return null;
  }

  let items = map(ctx.getAll(), (item) => ({
    ...item,
    itemTitle: `${item.firstName} ${item.lastName}`,
  }));

  if (podLeaderId === 0) {
    items = [{ id: podLeaderId, itemTitle: 'Select pod leader' }, ...items];
  }

  function handleChangeOption(e) {
    updatePodLeaderId(parseInt(e.target.value, 10));
  }

  return (
    <Select
      bg="#ebecf0"
      h="48px"
      defaultValue={podLeaderId || 0}
      onChange={handleChangeOption}
      disabled={disabled}
    >
      {map(items, (item) => (
        <option key={item.id} value={item.id}>
          {item.itemTitle}
        </option>
      ))}
    </Select>
  );
}

export default SelectPodLeader;
