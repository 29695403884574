import React, { createContext, useState } from 'react';
import { getTimeStamp } from '../../util/times';
import { useToast } from '@chakra-ui/react';
// import {useParams} from "react-router-dom"

const SupplierContext = createContext();
const { Provider } = SupplierContext;

const startAt = getTimeStamp();

const SupplierProvider = ({ children }) => {
  const toast = useToast();

  const [suppState, setSuppState] = useState({
    items: [],
    total: 0,
    // selectedItem: null,
    // selectedItemId: 0,
    loading: true,
    error: null,
    startAt,
    isAdded: false,
    addedAt: null,
    updatedAt: null,
    currentPage: 1,
    isUpdated: true,
  });

  const setSuppInfo = ({ items, total, loading, error }) => {
    setSuppState({
      ...suppState,
      items,
      total,
      loading,
      error,
    });
  };

  const setIsAdded = (isAdded) => {
    setSuppState({
      ...suppState,
      isAdded,
    });
    toast({
      position: 'top',
      description:
        'You have successfully requested the supplier code. You will be notified when the finance team activates it.',
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  };

  const setDateAdded = (time) => {
    setSuppState({
      ...suppState,
      currentPage: 1,
      addedAt: time,
    });
    toast({
      position: 'top',
      description:
        'You have successfully requested the supplier code. You will be notified when the finance team activates it.',
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  };

  function getCurrentPage() {
    return suppState.currentPage;
  }

  const setIsUpdated = (isUpdated) => {
    setSuppState({
      ...suppState,
      isUpdated,
    });
    toast({
      position: 'top',
      description: 'You have successfully updated a supplier.',
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  };

  const setSuppliersItems = (items) => {
    setSuppState({
      ...suppState,
      items,
    });
  };

  const setSelectedSupplierItem = (selectedItem) => {
    setSuppState({
      ...suppState,
      selectedItem,
      selectedItemId: selectedItem.id,
    });
  };

  return (
    <Provider
      value={{
        suppState,
        setSuppState: (info) => setSuppInfo(info),
        setSuppliers: (items) => setSuppliersItems(items),
        setSelectedSupplier: (item) => setSelectedSupplierItem(item),
        setIsAdded: (isAdded) => setIsAdded(isAdded),
        setDateAdded: (time) => setDateAdded(time),
        setIsUpdated: (isUpdated) => setIsUpdated(isUpdated),
        getCurrentPage,
      }}
    >
      {children}
    </Provider>
  );
};

export { SupplierContext, SupplierProvider };
