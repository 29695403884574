import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Box, Text, Tooltip, IconButton } from '@chakra-ui/react';
import { AttachmentIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
// import {FIFTY_MB} from '../../util/consts';
import first from 'lodash/first';
import isFunction from 'lodash/isFunction';

function UploadCampaignFile({
  updateUploadedFile,
  removeFile,
  disabled,
  // setUploadFileError
}) {
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
    multiple: false,
    maxFiles: 1,
    // maxSize: HUNDRED_MB,
    accept: [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    validator: fileSizeValidator,
    onDrop: (acceptedFiles) => {
      updateFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const files = acceptedFiles.map((file) => {
    // setUploadFileError('');
    return (
      <Box key={file.path}>
        {/* {file.path} - {formatBytes(file.size)} */}
        {/* <Link color="brand.400" ml="5px">File uploaded</Link> */}
        <Box color="brand.400" ml="5px">
          <CheckIcon color="brand.400" mr={2} />{' '}
          <a href={file.preview} target="_blank" rel="noreferrer">
            {file.path}
          </a>{' '}
        </Box>
      </Box>
    );
  });

  function updateFiles(files) {
    const uploadedFile = first(files);
    updateUploadedFile(uploadedFile);
  }

  function fileSizeValidator(file) {
    if (file.size > 20971520) {
      return {
        code: 'file-too-large',
        message: `Please upload a file not larger than 20 MB.`,
      };
    }

    return null;
  }

  const removeAllFiles = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    // inputRef.current.value = ''
    updateUploadedFile(undefined);
    if (isFunction(removeFile)) {
      removeFile();
    }
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={file.path}>
      {/*{file.path} -  {formatBytes(file.size)}*/}

      {errors.map((e) => (
        <Box color="error.500" fontSize="13px" key={e.code}>
          <Tooltip
            bg="error.500"
            color="brand.50"
            // color="white"
            label={e.code}
            aria-label={`Err ${e.code} tooltip`}
          >
            <Text isTruncated>{e.message}</Text>
          </Tooltip>
        </Box>
      ))}
    </div>
  ));

  return (
    <>
      <section className="container">
        {!!files.length && (
          <aside>
            <Box
              position="relative"
              p={2}
              border="1px solid #ebecf0"
              borderRadius="4px"
              mt={2}
              mb={4}
            >
              <Box pr="10px">{files}</Box>
              <Box position="absolute" top="-12px" right="-10px">
                <IconButton
                  colorScheme="brand"
                  size="xs"
                  onClick={removeAllFiles}
                  aria-label="remove file icon"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </aside>
        )}
        <Box
          display="flex"
          alignItems="center"
          mt={3}
          {...getRootProps({ className: 'dropzone' })}
        >
          <input {...getInputProps()} disabled={disabled} />
          <Button
            colorScheme="brand"
            textTransform="initial"
            //_hover={{ bg: "#000" }}
            fontWeight="400"
            mt="0"
            mr="20px"
            fontSize="14px"
            disabled={disabled}
          >
            <AttachmentIcon mr="5px" />
            Upload file
          </Button>
          <Box display="inline-block" color="#a9b1b4" fontSize="12px">
            <Text mb="0" lineHeight="1">
              File format: PDF, DOC, JPG, PNG
            </Text>{' '}
            <Text mt="1" lineHeight="1">
              Max file size: 20 MB
            </Text>
          </Box>
        </Box>
        <aside>
          <Box pr="10px" mt={fileRejectionItems && 2}>
            {fileRejectionItems}
          </Box>
        </aside>
      </section>
    </>
  );
}

export default UploadCampaignFile;
