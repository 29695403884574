import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Container,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { SmallAddIcon } from '@chakra-ui/icons';
import AddMemberForm from '../../components/team/TeamForm';
import { TeamIconAddForm } from '../../assets/iconsComponents/teamIconAddForm';
import { useHistory } from 'react-router-dom';

const DrawerComponent = ({ addMember, addNewMember }) => {
  const history = useHistory();
  let { isOpen, onOpen, onClose } = useDisclosure();

  if (addNewMember) {
    isOpen = true;
  }

  function handleClose() {
    onClose();
    if (addNewMember) {
      history.push('/team');
    }
  }

  return (
    <>
      <Button
        type="submit"
        colorScheme="brand"
        size="sm"
        onClick={onOpen}
        pt="20px"
        pb="20px"
        // _
        _hover={{ bg: '#000' }}
      >
        <SmallAddIcon h={22} w={7} />
        Add a member
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={handleClose}
        size="full"
        // isFullHeight
        blockScrollOnMount={false}
        //   finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent style={{ overflow: 'auto' }}>
          <Container maxW="container.sm" pt={24}>
            <DrawerCloseButton color="brand.500" _focus={{ outline: '0' }} size="lg" />
            <DrawerHeader>
              <Flex alignItems="center" direction="column">
                <TeamIconAddForm
                  fill="#d1dbdf"
                  color="#d1dbdf"
                  height="50px"
                  width="70px"
                />
                <Heading
                  as="h2"
                  size="lg"
                  textAlign="center"
                  fontWeight="700"
                  mb={[4, 4, 8]}
                  mt={4}
                >
                  Add a Member
                </Heading>
              </Flex>
            </DrawerHeader>
            <DrawerBody style={{ overflow: 'auto' }}>
              <>
                <AddMemberForm
                  onClose={handleClose}
                  addMember={addMember}
                  addNewMember={addNewMember}
                />
              </>
            </DrawerBody>
          </Container>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default DrawerComponent;
