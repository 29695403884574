import React from 'react';
export const InvoiceIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 15 15"
    {...props}
  >
    <g>
      <path
        d="M12.99,0.47c0.18,0,0.35,0.03,0.51,0.1s0.3,0.16,0.42,0.28c0.12,0.12,0.21,0.26,0.28,0.42s0.1,0.33,0.1,0.51v11.38
       c0,0.18-0.03,0.35-0.1,0.51s-0.16,0.3-0.28,0.42c-0.12,0.12-0.26,0.21-0.42,0.28s-0.33,0.1-0.51,0.1H2.49
       c-0.18,0-0.35-0.03-0.51-0.1s-0.3-0.16-0.42-0.28c-0.12-0.12-0.21-0.26-0.28-0.42s-0.1-0.33-0.1-0.51V1.78
       c0-0.18,0.03-0.35,0.1-0.51s0.16-0.3,0.28-0.42c0.12-0.12,0.26-0.21,0.42-0.28s0.33-0.1,0.51-0.1H12.99z M12.55,12.72V2.22H2.92
       v10.5H12.55z M4.67,5.72h6.12V4.85H4.67V5.72z M4.67,7.47h6.12V6.6H4.67V7.47z M4.67,9.22h6.12V8.35H4.67V9.22z M4.67,10.97h6.12
       V10.1H4.67V10.97z"
      />
    </g>
  </svg>
);
