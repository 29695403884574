import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  // FormErrorMessage,
  FormLabel,
  FormControl,
  // Input,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';

import { HttpContext } from '../../../context/HttpContext';
import { getErrors, getMessage } from '../../../util';
import { useHistory } from 'react-router-dom';
import { useDebounce } from '../../../custom-hooks/useDebounce';
import { alreadyExistSupplierCode } from '../../../util/supplier/helper';
import isString from 'lodash/isString';
import AutocompleteSupplierCodeInput from './AutocompleteSupplierCodeInput';

const CodeInput = (props) => {
  const initialAutocompleteState = {
    code: null,
    error: null,
    touched: false,
  };
  const [autocompleteState, setAutocompleteState] = React.useState(
    initialAutocompleteState
  );
  const {
    navigation,
    name,
    value,
    onClose,
    steps,
    index,
    // id,
    supplierId,
    updateStepFormState,
    stepFormState,
    suppliers,
    updateSuppliers,
    updateSelectedSupplierId,
    updateUser,
  } = props;
  const history = useHistory();

  const { status, currencies, currenciesCount } = stepFormState;
  const {
    next,
    // go,
    previous,
  } = navigation;

  const {
    formState,
    reset,
    setError,
    watch,
    // register, handleSubmit,
  } = useForm();

  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  const watchValue = watch('code');
  let debouncedValue = useDebounce(watchValue, 350);
  if (isString(debouncedValue)) {
    debouncedValue = debouncedValue.trim();
  }

  useEffect(() => {
    const getData = async () => {
      try {
        if (debouncedValue && !autocompleteState.error) {
          const { data } = await authAxios.get(
            `users/suppliers/search/autocomplete?type=code&search=${debouncedValue}`
          );
          const existed = alreadyExistSupplierCode(debouncedValue, data);
          if (existed) {
            const message =
              'This code is already in use. Please choose a different code.';
            // setError("code", { type: "server", message });
            updateAutocompleteState({
              ...autocompleteState,
              error: message,
            });
          }
        }
      } catch (onError) {
        console.log(onError);
      }
    };

    getData();
  }, [debouncedValue]);

  const onSubmit = async () => {
    try {
      updateStepFormState({
        ...stepFormState,
        status: 'pending',
      });
      const res = await authAxios.post(`users/suppliers/${supplierId}/approve`, {
        code: autocompleteState.code,
      });
      updateAutocompleteState(initialAutocompleteState);
      reset();
      const supplier = res.data;
      const recordIndex = suppliers.findIndex((x) => x.id === supplier.id);
      updateUser(res.data.user);
      if (recordIndex > -1) {
        suppliers[recordIndex] = { ...supplier };
        updateSuppliers(suppliers);
        updateSelectedSupplierId(supplier.id);
      }
      toast({
        position: 'top',
        description: 'You have successfully created the supplier code.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });

      const newCount = currenciesCount > 0 ? currenciesCount - 1 : 0;
      if (newCount === 0) {
        history.push(`/suppliers/${supplier.user.id}/${supplier.id}`);
        // setTimeout(function () {
        //   history.push(`/suppliers/${supplier.user.id}/${supplier.id}`)
        // }, 10);
        onClose();
      } else {
        if (index === currenciesCount - 1) {
          previous();
        }
        const newCurrencies = currencies.filter((curr) => curr.supplierId !== supplierId);

        updateStepFormState({
          ...stepFormState,
          currencies: newCurrencies,
          currenciesCount: newCount,
          status: 'resolved',
        });
        history.push(`/suppliers/${supplier.user.id}/${supplier.id}`);
      }
    } catch (onError) {
      console.log(onError);
      updateStepFormState({
        ...stepFormState,
        status: 'rejected',
      });
      const errors = getErrors(onError);
      if (errors && errors.code && errors.code.errors) {
        let message = errors.code.errors[0];
        if (message) {
          updateStepFormState({
            ...stepFormState,
            status: 'rejected',
            error: message,
          });
          updateAutocompleteState({
            ...autocompleteState,
            error: message,
          });
        }
        setError('code', { type: 'server', message });
      } else {
        const message = getMessage(onError);
        if (message) {
          updateAutocompleteState({
            ...autocompleteState,
            error: message,
          });
          updateStepFormState({
            ...stepFormState,
            status: 'rejected',
            error: message,
          });
          toast({
            position: 'top',
            description: message,
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
      }
    }
  };

  function updateAutocompleteState({ code, error, touched }) {
    setAutocompleteState({
      code,
      error,
      touched,
    });
  }

  return (
    <>
      <Box d="flex" justifyContent="center" mt="-12px" mb="10px" zIndex="1000">
        {index + 1}
        {' of '}
        {currenciesCount}
        {' currencies'}
      </Box>
      <FormControl isInvalid={errors.code} mb={[4, 4, 8]}>
        <FormLabel htmlFor="emailHtmlFor">
          Supplier code for {value} - {name}
        </FormLabel>
        {/*<Input*/}
        {/*  isInvalid*/}
        {/*  name="SupplierCode"*/}
        {/*  id="code"*/}
        {/*  textTransform="uppercase"*/}
        {/*  placeholder="Enter supplier code"*/}
        {/*  _placeholder={{ textTransform: "initial" }}*/}
        {/*  focusBorderColor="black"*/}
        {/*  errorBorderColor="error.500"*/}
        {/*  {...register("code")}*/}
        {/*/>*/}
        {/*<FormErrorMessage>*/}
        {/*  {errors.code?.message || errors.code}*/}
        {/*</FormErrorMessage>*/}
        <AutocompleteSupplierCodeInput
          autocompleteState={autocompleteState}
          updateAutocompleteState={updateAutocompleteState}
          inputValue={autocompleteState.code}
        />
      </FormControl>
      <>
        <Box d="flex" justifyContent="space-between" mt={10} mb={20}>
          <Box>
            {index !== 0 && (
              <Button
                variant="outline"
                size="lg"
                color="brand.400"
                //colorScheme="brand"
                textTransform="initial"
                borderRadius="10px"
                onClick={previous}
                disabled={status === 'pending'}
              >
                Go back
              </Button>
            )}
          </Box>
          <Box>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              disabled={
                isSubmitting ||
                autocompleteState.touched === false ||
                autocompleteState.error !== null ||
                status === 'pending'
              }
              isLoading={isSubmitting}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
              onClick={onSubmit}
              //disabled={disableForm}
            >
              Submit
            </Button>
            {steps.length - 1 !== index && (
              <Button
                variant="ghost"
                size="lg"
                colorScheme="brand"
                textTransform="initial"
                borderRadius="10px"
                onClick={next}
                disabled={status === 'pending'}
              >
                Skip
              </Button>
            )}
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={onClose}
              disabled={status === 'pending'}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </>
    </>
  );
};
export default CodeInput;
