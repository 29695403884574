import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  InputGroup,
  InputRightElement,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../../context/HttpContext';
import { AuthContext } from '../../../context/AuthContext';

const PasswordResetForm = ({ token }) => {
  const [show, setShow] = useState(false);
  const [done, setDone] = React.useState(false);
  const handlePwdClick = () => setShow(!show);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('This is mandatory field')
      .min(6, 'Password must be at least 6 characters')
      .matches(/[a-z]/, 'Password must contain lower case (a-z)')
      .matches(/[A-Z]/, 'Password must contain uppercase (A-Z) letters')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(
        // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-7])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-7])(?=.*?[^\w\s]).{6,}$/,
        'Password must contain at least one symbol'
      ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };
  const {
    register,
    handleSubmit,
    reset,
    formState,
    // setError
  } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;
  const { publicAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  function onSubmit(values) {
    return new Promise((resolve) => {
      const data = {
        plainPassword: values.password,
        token,
      };

      publicAxios
        .post(`users/members/activate`, data)
        .then((res) => {
          const { token, user, refresh_token } = res.data;
          if (token) {
            auth.setAuthState({ token, user, refreshToken: refresh_token });
            reset();
          }
        })
        .catch((onError) => {
          console.log(onError);
        })
        .finally(setDone(true));
      resolve();
    });
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.password} mb={4} pt={4}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <InputGroup size="lg">
            <Input
              pr="4.5rem"
              type={show ? 'text' : 'password'}
              name="password"
              id="password"
              {...register('password')}
            />
            <InputRightElement
              children={
                <>
                  {show ? (
                    <ViewOffIcon
                      color="brand.500"
                      onClick={handlePwdClick}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <ViewIcon
                      color="grey.200"
                      onClick={handlePwdClick}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </>
              }
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.password?.message || errors.password}
          </FormErrorMessage>
        </FormControl>
        <>
          <Box fontWeight="bold" color="#333333" fontSize="13px">
            Create password that:
          </Box>

          <UnorderedList>
            <ListItem color="#eb7f24">
              <Box color="#333333" fontSize="13px">
                contains at least 6 characters
              </Box>
              {/* 
               This is if condition is complete
              <Box
                color="#cdd3d4"
                fontSize="13px"
                textDecoration="line-through"
              >
                contains at least 6 characters
              </Box>
          */}
            </ListItem>
            <ListItem color="#eb7f24">
              <Box color="#333333" fontSize="13px">
                contains both lower (a-z) and uppercase (A-Z) letters
              </Box>
              {/* 
               This is if condition is complete
              <Box
                color="#cdd3d4"
                fontSize="13px"
                textDecoration="line-through"
              >
                contains both lower (a-z) and uppercase (A-Z) letters
              </Box>
          */}
            </ListItem>
            <ListItem color="#eb7f24">
              <Box color="#333333" fontSize="13px">
                contains at least one number
              </Box>
              {/* 
               This is if condition is complete
              <Box
                color="#cdd3d4"
                fontSize="13px"
                textDecoration="line-through"
              >
                contains at least one number
              </Box>
          */}
            </ListItem>
            <ListItem color="#eb7f24">
              <Box color="#333333" fontSize="13px">
                contains at least one symbol
              </Box>
              {/* 
               This is if condition is complete
              <Box
                color="#cdd3d4"
                fontSize="13px"
                textDecoration="line-through"
              >
                contains at least one symbol
              </Box>
          */}
            </ListItem>
          </UnorderedList>

          <Button
            type="submit"
            size="lg"
            colorScheme="brand"
            isFullWidth
            isLoading={isSubmitting && !done}
            disabled={!isDirty || !isValid || isSubmitting}
            textTransform="initial"
            mt={10}
            _hover={{ bg: '#000' }}
            //disabled={disableForm}
          >
            Set password and login
          </Button>
        </>
      </form>
    </>
  );
};
export default PasswordResetForm;
