import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { SmallAddIcon } from '@chakra-ui/icons';
import DrawerComponentCustom from '../../common/UIcomponents/CustomDrawer';
import CodeStepForm from './multiStepCodeForm/CreateSupplierCodeStep';

export default function CreateSupplierCodeButton({
  suppliers,
  updateSuppliers,
  updateSupplierCode,
  updateSelectedSupplierId,
  supplierId,
  user,
  updateUser,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        type="submit"
        colorScheme="brand"
        size="sm"
        onClick={onOpen}
        pt="20px"
        pb="20px"
        // _
        _hover={{ bg: '#000' }}
      >
        <SmallAddIcon h={22} w={7} />
        Create the supplier code
      </Button>

      <DrawerComponentCustom
        title="Create the Supplier code"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        {' '}
        {/* <SupplierCodeForm onClose={onClose} updateSupplierCode={updateSupplierCode}/>  */}
        <CodeStepForm
          suppliers={suppliers}
          updateSuppliers={updateSuppliers}
          onClose={onClose}
          updateSupplierCode={updateSupplierCode}
          updateSelectedSupplierId={updateSelectedSupplierId}
          supplierId={supplierId}
          user={user}
          updateUser={updateUser}
        />
      </DrawerComponentCustom>
    </>
  );
}
