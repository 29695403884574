import React, { useContext, useState } from 'react';
import map from 'lodash/map';
import moment from 'moment';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  useToast,
} from '@chakra-ui/react';
import { formatAmount } from '../../util';
import logo from '../../assets/images/Goat-logo-emails.png';
import { hasInvoiceBankAccount, hasInvoicePayPal } from '../../util/invoice/helper';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { HttpContext } from '../../context/HttpContext';
import AddPaymentDetails from '../account/PaymentDetailsForm/AddPaymentDetailsButton';
import { getDueDate } from '../../util';

export default function InvoiceEditForm({ invoice, invoiceSupplier }) {
  const [payment, setPayment] = useState(invoiceSupplier);
  function updateSupplier(supplier) {
    setPayment(supplier);
  }

  const currencySymbol = invoice?.purchaseOrder.supplier.currency.symbol;
  const toast = useToast();
  const history = useHistory();
  const { authAxios } = useContext(HttpContext);

  const validationSchema = Yup.object().shape({
    referenceNumber: Yup.string().max(8, 'Please enter 8 characters or less'),
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { errors, isSubmitting, isValid } = formState;

  const onSubmit = async (values) => {
    try {
      const { data } = await authAxios.patch(`/invoices/${invoice.id}`, values);
      if (data) {
        history.push(`/invoices/${invoice.id}`);
        toast({
          position: 'top',
          description:
            'You have successfully made changes to the invoice. You will be notified when the Goat team approves it.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      }
    } catch (onError) {
      console.log(onError);
    }
  };

  return (
    <>
      <Box
        bg="#fff"
        borderRadius="md"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
        mt="7"
        p="10"
        pt="8"
      >
        <HStack justifyContent="space-between" alignItems="center" pb="10px">
          <Box d="flex" alignItems="center">
            <img style={{ height: '76px', marginRight: '20px' }} src={logo} alt="" />

            <Box>
              <Text fontSize="40px" fontWeight="bold" mb="0">
                Invoice
              </Text>
              {/*<Text color=" #868789" fontSize="14px">*/}
              {/*  Invoice number{" "}*/}
              {/*  <span style={{ color: "#333" }}>{invoice?.number}</span>*/}
              {/*</Text>*/}
            </Box>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box d="flex" alignItems="center">
              {' '}
              <FormControl isInvalid={errors.referenceNumber} mb={0}>
                <FormLabel htmlFor="invoiceEditHtmlFor">
                  Reference number (optional)
                </FormLabel>
                <Input
                  isInvalid
                  name="referenceNumber"
                  id="referenceNumber"
                  defaultValue={invoice.referenceNumber}
                  focusBorderColor="black"
                  errorBorderColor="error.500"
                  placeholder="Type here"
                  {...register('referenceNumber')}
                />
                <FormErrorMessage>
                  {errors.referenceNumber?.message || errors.referenceNumber}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </form>
        </HStack>
        <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="30px" />
        <HStack spacing={10} mb={14} alignItems="flex-start">
          <Box width="550px">
            <Text fontSize="20px" mb="10px" fontWeight="bold">
              To
            </Text>
            <HStack alignItems="flex-start">
              <Box fontSize="14px">
                <Text mb="5px">{invoice.invoiceTo.name}</Text>
                <Text mb="5px">{invoice.invoiceTo.address}</Text>
                <Text mb="5px">VAT no: {invoice.invoiceTo.vat}</Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <Text fontSize="20px" mb="10px" fontWeight="bold">
              From
            </Text>
            <HStack alignItems="flex-start">
              <Box fontSize="14px" mr="35px">
                <Text mb="5px">{invoice.fromName}</Text>
                <Text mb="5px">{invoice.fromAddress}</Text>
                <Text mb="5px">
                  VAT no:{' '}
                  {invoice?.fromVat ? (
                    <Text as="span">{invoice?.fromVat}</Text>
                  ) : (
                    <Text as="span" color="#868789">
                      No VAT registered
                    </Text>
                  )}
                </Text>
                <Link
                  color="brand.500"
                  as={RouterLink}
                  to={`/account?redirect=edit-invoice/${invoice.id}&edit=personal-information`}
                  fontSize="14px"
                  d="block"
                  mb="6px"
                >
                  Edit
                </Link>
              </Box>
            </HStack>
          </Box>
        </HStack>

        <HStack spacing={10} mb={14} alignItems="flex-start">
          <Box width="550px">
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Description of work
                </Text>
                <Text color="#333" fontSize="14px" maxWidth="100%">
                  {invoice.purchaseOrder.descriptionOfWork}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Invoice date
                </Text>
                <Text color="#868789" fontSize="14px" maxWidth="100%">
                  {moment(invoice.date).format('DD MMM y')}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Due date
                </Text>
                <Text color="#868789" fontSize="14px" maxWidth="100%">
                  {getDueDate(invoice)}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Purchase order number
                </Text>
                <Text color="#868789" fontSize="14px" maxWidth="100%">
                  {invoice.purchaseOrder.number}
                </Text>
              </Box>
            </HStack>
          </Box>
        </HStack>

        <Box>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th minWidth="500px" pl="0" pb="0" pr="0">
                  <Box color="#bdc1d0" fontSize="12px" fontWeight="700">
                    item title
                  </Box>
                </Th>
                <Th pl="0" pb="0">
                  <Box color="#bdc1d0" pb="0" fontSize="12px" fontWeight="700">
                    amount(net)
                  </Box>
                </Th>
                <Th pb="0" pl="0">
                  <Box
                    color="#bdc1d0"
                    pb="0"
                    pl="0"
                    fontSize="12px"
                    fontWeight="700"
                    lineHeight="1"
                    textAlign="left"
                  >
                    vat
                  </Box>
                </Th>
                <Th pl="10px" pb="0" pr="0">
                  <Box
                    color="#bdc1d0"
                    pb="0"
                    fontSize="12px"
                    fontWeight="700"
                    textAlign="left"
                  >
                    amount(gross)
                  </Box>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {invoice.purchaseOrder.purchaseOrderItems.length &&
                map(invoice.purchaseOrder.purchaseOrderItems, (purchase) => {
                  return (
                    <Tr>
                      <Td pl="0" p="10px">
                        {purchase.title}
                      </Td>
                      <Td p="10px" pl="0">
                        <Box>
                          {' '}
                          {currencySymbol}
                          {formatAmount(purchase.amount)}
                        </Box>
                      </Td>
                      <Td isNumeric p="10px" pl="0">
                        <Box>
                          {' '}
                          {currencySymbol}
                          {formatAmount(purchase.vatAmount)}
                        </Box>
                      </Td>
                      <Td isNumeric p="10px">
                        <Box>
                          {currencySymbol}
                          {formatAmount(purchase.grossAmount)}
                        </Box>
                      </Td>
                    </Tr>
                  );
                })}
              <Tr>
                <Td pl="0" p="10px" />
                <Td pl="0" p="10px" />
                <Td p="10px" isNumeric>
                  <Box color="#868789">Sub-total (net) </Box>
                  <Box color="#868789">VAT sub-total</Box>
                  <Box color="#868789" mt="20px">
                    TOTAL (gross){' '}
                  </Box>
                </Td>
                <Td isNumeric p="10px">
                  {' '}
                  <Box textAlign="right">
                    {currencySymbol}
                    {formatAmount(invoice.purchaseOrder.totalNet)}
                  </Box>
                  <Box textAlign="right">
                    {currencySymbol}
                    {formatAmount(invoice.purchaseOrder.totalVat)}
                  </Box>
                  <Box fontSize="20px" fontWeight="bold" textAlign="right" mt="20px">
                    {currencySymbol}
                    {formatAmount(invoice.purchaseOrder.totalGross)}
                  </Box>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        {/* <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="30px" /> */}
        <Box mt="30px">
          <Text fontSize="20px" mb="10px" fontWeight="bold">
            Payment details:
            {!payment?.paymentDetailsApproved && (
              <Text
                fontStyle="italic"
                fontSize="16px"
                color="#868789"
                fontWeight="400"
                display="inline-block"
                ml="3"
              >
                Processing
              </Text>
            )}
          </Text>
          <HStack alignItems="flex-start">
            {payment && hasInvoiceBankAccount(payment) && (
              <>
                <Box color="#868789" fontSize="14px" mr="35px">
                  <Text mb="5px">Payment method:</Text>
                  <Text mb="5px">Bank name:</Text>
                  {payment.bankAddress && <Text mb="5px">Bank address:</Text>}
                  <Text mb="5px">Country:</Text>
                  {payment.accountNumber && <Text mb="5px">Account number:</Text>}
                  {payment.accountNumberOrIban && (
                    <Text mb="5px">Account number or IBAN:</Text>
                  )}
                  {payment.bankCode && <Text mb="5px">Bank code:</Text>}
                  {payment.bsbCode && <Text mb="5px">BSB code:</Text>}
                  {payment.iban && <Text mb="5px">IBAN:</Text>}
                  {payment.ifscCode && <Text mb="5px">IFSC code:</Text>}
                  {payment.routingNumber && <Text mb="5px">Routing number:</Text>}
                  {payment.sortCode && <Text mb="5px">Sort code:</Text>}
                  {payment.swift && <Text mb="5px">SWIFT code:</Text>}
                  {payment.additionalReference && (
                    <Text mb="5px">Additional reference:</Text>
                  )}
                  {/*<Link*/}
                  {/*  color="brand.500"*/}
                  {/*  as={RouterLink}*/}
                  {/*  to={`/account?redirect=edit-invoice/${invoice.id}`}*/}
                  {/*  fontSize="14px"*/}
                  {/*  d="block"*/}
                  {/*  mb="6px"*/}
                  {/*>*/}
                  {/*  Edit*/}
                  {/*</Link>*/}
                  <Box marginLeft="-18px">
                    {payment.paymentDetailsApproved && (
                      <AddPaymentDetails
                        edit
                        supplier={payment}
                        updateSupplier={updateSupplier}
                      />
                    )}
                  </Box>
                </Box>
                <Box alignItems="flex-start" fontSize="14px">
                  <Text mb="5px">Bank transfer</Text>
                  <Text mb="5px">{payment.bankName}</Text>
                  {payment.bankAddress && <Text mb="5px">{payment.bankAddress}</Text>}
                  <Text mb="5px">{payment.country?.name}</Text>
                  {payment.accountNumber && <Text mb="5px">{payment.accountNumber}</Text>}
                  {payment.accountNumberOrIban && (
                    <Text mb="5px">{payment.accountNumberOrIban}</Text>
                  )}
                  {payment.bankCode && <Text mb="5px">{payment.bankCode}</Text>}
                  {payment.bsbCode && <Text mb="5px">{payment.bsbCode}</Text>}
                  {payment.iban && <Text mb="5px">{payment.iban}</Text>}
                  {payment.ifscCode && <Text mb="5px">{payment.ifscCode}</Text>}
                  {payment.routingNumber && <Text mb="5px">{payment.routingNumber}</Text>}
                  {payment.sortCode && <Text mb="5px">{payment.sortCode}</Text>}
                  {payment.swift && <Text mb="5px">{payment.swift}</Text>}
                  {payment.additionalReference && (
                    <Text mb="5px">{payment.additionalReference}</Text>
                  )}
                </Box>
              </>
            )}
            {payment && hasInvoicePayPal(payment) && (
              <>
                <Box color="#868789" fontSize="14px" mr="35px">
                  <Text mb="5px">Payment method:</Text>
                  <Text mb="5px">Email address:</Text>
                  {/*<Link*/}
                  {/*  color="brand.500"*/}
                  {/*  as={RouterLink}*/}
                  {/*  to={`/account?redirect=edit-invoice/${invoice.id}`}*/}
                  {/*  fontSize="14px"*/}
                  {/*  d="block"*/}
                  {/*  mb="6px"*/}
                  {/*>*/}
                  {/*  Edit*/}
                  {/*</Link>*/}
                  <Box marginLeft="-18px">
                    {payment.paymentDetailsApproved && (
                      <AddPaymentDetails
                        edit
                        supplier={payment}
                        updateSupplier={updateSupplier}
                      />
                    )}
                  </Box>
                </Box>
                <Box alignItems="flex-start" fontSize="14px">
                  <Text mb="5px">PayPal</Text>
                  <Text mb="5px">{payment.payPalAccount}</Text>
                </Box>
              </>
            )}
            {payment && !hasInvoiceBankAccount(payment) && !hasInvoicePayPal(payment) && (
              <>
                <Text color="#868789">No information yet.</Text>
              </>
            )}
          </HStack>
        </Box>
      </Box>
      <Box mt="20px" mb="30px" float="right">
        <Button
          type="submit"
          size="lg"
          colorScheme="brand"
          isFullWidth
          isLoading={isSubmitting}
          textTransform="initial"
          _hover={{ bg: '#000' }}
          fontSize="16px"
          fontWeight="500"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Send updated invoice
        </Button>
      </Box>
    </>
  );
}
