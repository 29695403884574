import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, HStack } from '@chakra-ui/react';
import map from 'lodash/map';
import { HttpContext } from '../../context/HttpContext';
import moment from 'moment';
import { ACTIVITY_LOG_LENGTH } from '../../util/consts';
import ScrollToTop from '../../util/scrollToTop';
import { NewlineText } from '../../util';

function getAuthorName(user) {
  return user.officialRegisteredName
    ? user.officialRegisteredName
    : `${user.firstName} ${user.lastName}`;
}

function POActivity({ id, code, order }) {
  const { authAxios } = useContext(HttpContext);
  const initialState = {
    activities: [],
    total: 0,
    limit: ACTIVITY_LOG_LENGTH,
    offset: 0,
    loading: true,
    expanded: false,
    error: null,
    scrollToUp: false,
  };
  const [state, setState] = useState(initialState);

  const {
    limit,
    offset,
    expanded,
    total,
    activities,
    scrollToUp,
    // error, loading,
  } = state;
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await authAxios.get(
          `logs/activity-logs/purchase_order/${id}?limit=${limit}&offset=${offset}`
        );
        const activities = data.data || [];
        const total = data.meta.total || 0;
        setState({
          ...state,
          activities,
          total,
          loading: false,
        });
      } catch (onError) {
        console.log(onError);
        setState({
          ...state,
          loading: false,
          error: 'Something went wrong',
        });
      }
    };

    getData();
  }, [authAxios, id, code, order, limit, offset]);

  function showHideComments() {
    if (expanded) {
      setState({
        ...state,
        expanded: false,
        limit: ACTIVITY_LOG_LENGTH,
        scrollToUp: true,
      });
    } else {
      setState({
        ...state,
        expanded: true,
        limit: total,
      });
    }
  }

  function getActivityDetail(activity) {
    const date = moment(activity.createdAt).format('DD MMM y,  HH:mm');
    const { author } = activity;

    if (author.type !== 'supplier') {
      let type = author.type;
      if (type === 'pod_leader') type = 'pod leader';
      if (type === 'finance_user') type = 'finance user';
      return `${getAuthorName(author)} (${type} team), ${date}`;
    } else return `${getAuthorName(author)} (${author.type}), ${date}`;

    // return `${getAuthorName(author)} (${author.type}  ${team}), ${date}`;
  }

  function renderActivities(activities) {
    const count = activities.length;
    return map(activities, (activity, index) => {
      const headingTxt =
        activity.actionType === 'comment'
          ? 'Purchase order is pending repair'
          : activity.actionType === 'invoice_submitted'
          ? 'Invoice submitted'
          : activity.actionType === 'invoice_edited'
          ? 'Invoice edited'
          : activity.actionType === 'invoice_comment'
          ? 'Invoice is pending repair'
          : activity.actionType === 'invoice_approved'
          ? 'Invoice approved'
          : activity.actionType === 'invoice_rejected'
          ? 'Invoice rejected'
          : activity.actionType === 'invoice_change_request'
          ? 'Invoice edited'
          : activity.actionType === 'change_ownership'
          ? 'Admin changed ownership for the purchase order'
          : activity.actionType === 'change_pod_leader'
          ? 'Admin changed Pod Leader for the purchase order'
          : `Purchase order ${activity.actionType}`;

      return (
        <div key={activity.id}>
          <Box>
            {index === 0 && (
              <Box
                position="relative"
                pb="30px"
                _before={{
                  content: `""`,
                  position: 'absolute',
                  width: '1px',
                  height: '100%',
                  bg: count > 1 ? '#c4c9cc' : 'transparent',
                  top: '5px',
                  left: '6px',
                }}
              >
                <Box d="flex" alignItems="center">
                  <Box borderRadius="50%" bg="#c4c9cc" h="13px" w="13px" mr="10px" />
                  <Box fontSize="14px" fontWeight="700" color="#333">
                    {headingTxt}{' '}
                  </Box>
                </Box>
                <Box pl="24px">
                  {activity.comment && (
                    <Box
                      color="#333"
                      fontSize="14px"
                      fontStyle="italic"
                      pb="3px"
                      maxW="500px"
                    >
                      <NewlineText text={activity.comment} />
                    </Box>
                  )}
                  <Box color="#868789" fontSize="12px" lineHeight="1">
                    {getActivityDetail(activity)}
                  </Box>
                </Box>
              </Box>
            )}
            {index !== 0 && index < count && (
              <Box
                pb="30px"
                position="relative"
                _before={{
                  content: `""`,
                  position: 'absolute',
                  width: '1px',
                  height: '100%',
                  bg: index === count - 1 ? 'transparent' : '#c4c9cc',
                  top: '5px',
                  left: '6px',
                }}
              >
                <Box d="flex" alignItems="center">
                  <Box borderRadius="50%" bg="#c4c9cc" h="13px" w="13px" mr="10px" />
                  <Box fontSize="14px" fontWeight="700" color="#333">
                    {headingTxt}{' '}
                  </Box>
                </Box>
                <Box pl="24px">
                  {activity.comment && (
                    <Box color="#333" fontSize="14px" fontStyle="italic" pb="3px">
                      <NewlineText text={activity.comment} />
                    </Box>
                  )}
                  <Box color="#868789" fontSize="12px" lineHeight="1">
                    {getActivityDetail(activity)}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      );
    });
  }

  if (activities.length > 0) {
    return (
      <>
        {scrollToUp && <ScrollToTop />}
        <Box h="1px" width="100%" bg="#e8e9e9" mt="30px" mb="30px" />
        <HStack align="flex-start">
          <Box display="flex" flexDirection=" column" justifyContent="space-between">
            {renderActivities(activities)}
          </Box>
        </HStack>
        {total > ACTIVITY_LOG_LENGTH && (
          <Button
            onClick={showHideComments}
            variant="ghost"
            size="xs"
            fontSize="12px"
            mt="15px"
            ml="10px"
          >
            Show {!expanded ? 'full' : 'less of '} activity history
          </Button>
        )}
      </>
    );
  }

  return null;
}

export default POActivity;
