import React, { useContext } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Button,
  // FormLabel,
  // FormControl,
  Box,
  // Select,
  HStack,
  MenuGroup,
  useDisclosure,
  Badge,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react';
// import map from "lodash/map";
import {
  filterStatusPaymentDetailsApproved,
  // currenciesOptions,
  // firstCurrencyValue,
  // typeOptions,
  // firstTypeValue,
} from '../../util';
import DatePicker from './DatePicker';
import { FilterIcon } from '../../assets/iconsComponents/filterIcon';
import { FilterSupplierContext } from '../../context/FilterSuppliers';
import map from 'lodash/map';

export default function FilterSupplier({
  clearAllFilters,
  updatePaymentDetailsApprovedStatus,
}) {
  // const [currency, setCurrency] = React.useState(firstCurrencyValue);
  // const [type, setType] = React.useState(firstTypeValue);
  // const [done, setDone] = React.useState(firstCurrencyValue);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { filterFrom, setFilterFrom } = useContext(FilterSupplierContext);
  const { filterTo, setFilterTo } = useContext(FilterSupplierContext);
  const { from, setFrom } = useContext(FilterSupplierContext);
  const { to, setTo } = useContext(FilterSupplierContext);
  const { filterPaymentDetailsApprovedStatus, setPaymentDetailsApprovedStatus } =
    useContext(FilterSupplierContext);

  const numberOfFilters =
    (filterFrom && filterTo ? 1 : 0) + (filterPaymentDetailsApprovedStatus !== 'none');
  // const handleChangeCurrency = (e) => {
  //   setCurrency(e.target.value);
  // };
  // const handleChangeType = (e) => {
  //   setType(e.target.value);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (from) {
      setFilterFrom(from);
    }
    if (to) {
      setFilterTo(to);
    }
    if (filterPaymentDetailsApprovedStatus) {
      if (filterPaymentDetailsApprovedStatus === 'none') {
        updatePaymentDetailsApprovedStatus('');
      } else {
        updatePaymentDetailsApprovedStatus(
          `&paymentDetailsApproved=${filterPaymentDetailsApprovedStatus}`
        );
      }
    }

    onClose();
  };
  const handleClearAll = (e) => {
    e.preventDefault();
    if (from) {
      setFilterFrom(null);
      setFrom(null);
    }
    if (to) {
      setFilterTo(null);
      setTo(null);
    }
    setPaymentDetailsApprovedStatus('none');
    clearAllFilters();
  };

  const handleChangePaymentDetailsApproved = (e) => {
    setPaymentDetailsApprovedStatus(e.target.value);
  };

  return (
    <Menu>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            as={Button}
            bg="white"
            borderRadius="8px"
            fontSize="14px"
            fontWeight="500"
            border="1px solid #e8e9e9"
            pt="18px"
            pb="18px"
            onClick={onOpen}
            _expanded={{ bg: 'black', color: 'white' }}
            _focus={{ boxShadow: 'none' }}
            leftIcon={
              numberOfFilters ? (
                <Badge
                  bg="#eb7f24"
                  borderRadius="50%"
                  color="white"
                  h="23px"
                  w="24px"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {numberOfFilters}
                </Badge>
              ) : (
                <FilterIcon width="17px" color="#eb7f24" height="19px" />
              )
            }
          >
            Filters
          </MenuButton>
          <MenuList
            onClose={onClose}
            minWidth="360px"
            p="23px"
            boxShadow=" 0 2px 10px rgba(29, 44, 45, 0.18)"
            boundary="preventOverflow"
            flip="true"
            matchWidth
          >
            <MenuOptionGroup title="" type="checkbox" matchWidth closeOnSelect={true}>
              <FormControl mb={[4, 4, 8]}>
                <FormLabel htmlFor="emailHtmlFor" fontSize="14px">
                  Payment Details Approved Status
                </FormLabel>
                <Select
                  bg="#ebecf0"
                  h="48px"
                  onChange={handleChangePaymentDetailsApproved}
                  value={filterPaymentDetailsApprovedStatus}
                >
                  {map(filterStatusPaymentDetailsApproved(), (option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {/* <FormControl mb={[4, 4, 8]}>
                <FormLabel htmlFor="emailHtmlFor" fontSize="14px">
                  Type
                </FormLabel>
                <Select bg="#ebecf0" h="48px" onChange={handleChangeType}>
                  {map(typeOptions(), (option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mb={[4, 4, 8]}>
                <FormLabel htmlFor="emailHtmlFor" fontSize="14px">
                  Currency
                </FormLabel>
                <Select bg="#ebecf0" h="48px" onChange={handleChangeCurrency}>
                  {map(currenciesOptions(), (option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl> */}
              <Box fontSize="14px" fontWeight="bold" mb="10px">
                Date approved is between
              </Box>

              <DatePicker />
            </MenuOptionGroup>
            <MenuGroup closeOnSelect={true} onClose>
              <HStack justifyContent="space-between" mt="30px">
                <Button
                  onClick={(event) => {
                    handleClearAll(event);
                    onClose();
                  }}
                  variant="ghost"
                  size="sm"
                  fontSize="14px"
                >
                  Clear all
                </Button>{' '}
                <Button
                  size="sm"
                  fontSize="14px"
                  colorScheme="brand"
                  textTransform="initial"
                  //_hover={{ bg: "#000" }}
                  mr="10px"
                  onClick={(event) => {
                    handleSubmit(event);
                    onClose();
                  }}
                >
                  Apply and close
                </Button>
              </HStack>
            </MenuGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
}
