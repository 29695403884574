import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
  Badge,
  Text,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { HttpContext } from '../../context/HttpContext';
import { getMessage } from '../../util';
import SelectRestMultipleCurrency from './SelectRestMultipleCurrency';

export default function SupplierIndividualCampaignEditForm({
  onClose,
  user,
  updateUser,
  suppliers,
  addNewCurrencies,
}) {
  const { firstName, lastName, email } = user;
  const [currencies, setCurrencies] = React.useState([]);
  function updateCurrencies(currencies) {
    setCurrencies(currencies);
  }

  const { register, handleSubmit, formState, reset } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  const onSubmit = async () => {
    try {
      const selectedCurrencies = currencies.map((currency) => currency.id);
      const data = {
        currencies: selectedCurrencies,
      };

      const res = await authAxios.patch(`users/suppliers/${user.id}`, data);
      onClose();
      reset();
      updateUser(res.data);
      addNewCurrencies(currencies);
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top-left',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.firstName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">First Name</FormLabel>
          <Input
            isInvalid
            disabled
            color="#c3c1c1"
            name="firstName"
            id="firstName"
            defaultValue={firstName}
            placeholder="Enter first name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('firstName')}
          />
          <FormErrorMessage>
            {errors.firstName?.message || errors.firstName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.lastName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Last Name</FormLabel>
          <Input
            isInvalid
            disabled
            color="#c3c1c1"
            name="lastName"
            id="lastName"
            defaultValue={lastName}
            placeholder="Enter last name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('lastName')}
          />
          <FormErrorMessage>
            {errors.lastName?.message || errors.lastName}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.email} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Email Address</FormLabel>
          <Input
            isInvalid
            disabled
            color="#c3c1c1"
            name="email"
            id="email"
            defaultValue={email}
            placeholder="Enter email address"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('email')}
          />
        </FormControl>
        <Box>
          {suppliers?.map((supp) => (
            <Badge
              key={supp.id}
              variant="solid"
              bg="transparent"
              pt="1"
              pb="1"
              px="2"
              mr="1"
              mb="1"
              fontSize="13px"
              fontWeight="400"
              color="#2b2b2b"
            >
              <CheckIcon mr={1} /> {supp.currency.code} - {supp.currency.name}
            </Badge>
          ))}
          <Text fontWeight="bold" mb="px">
            {' '}
          </Text>
        </Box>
        <SelectRestMultipleCurrency
          user={user}
          updateCurrencies={updateCurrencies}
          suppliers={suppliers}
        />
        <>
          <Box d="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              disabled={currencies.length === 0 || isSubmitting}
              isLoading={isSubmitting}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
              //disabled={disableForm}
            >
              Submit
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              // isLoading={isSubmitting}
              textTransform="initial"
              borderRadius="10px"
              onClick={onClose}
              // _hover={{ bg: "#000" }}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
}
