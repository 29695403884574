import React from 'react';
import { Box, Badge, Text, Code } from '@chakra-ui/react';
import { formatCurrency } from '../../util';
// import CampaignStatusBadge from "./CampaignStatusBadge"
import { upperFirst } from 'lodash';
// import { SmallAddIcon } from '@chakra-ui/icons';

export default function CampaignChangeRequestCard({
  campaign,
  current,
  opposite,
  side,
  title,
}) {
  function getPodLeaderName(current) {
    if (current?.podLeader?.firstName) {
      return current?.podLeader?.firstName + ' ' + current?.podLeader.lastName;
    }

    return '';
  }

  function getFileName(current) {
    if (current?.file) {
      return current.file;
    }
    return 'No files.';
  }

  function getFileNameFor(current, count = 1) {
    if (count <= 5) {
      if (count === 1) {
        if (current?.file) {
          return current.file;
        }
      } else if (count === 2) {
        if (current?.file2) {
          return current.file2;
        }
      } else if (count === 3) {
        if (current?.file3) {
          return current.file3;
        }
      } else if (count === 4) {
        if (current?.file4) {
          return current.file4;
        }
      } else if (count === 5) {
        if (current?.file5) {
          return current.file5;
        }
      }
    }

    return 'No files.';
  }

  return (
    <Box borderWidth="1px" borderRadius="md" p={4} key={campaign?.id}>
      {/*<Box bg='teal' w='100%' p={4} color='white' >*/}
      {/*  This is the Box*/}
      {/*</Box>*/}

      <Badge
        borderRadius="full"
        px="2"
        colorScheme={title === 'Last approved campaign' ? 'facebook' : 'whatsapp'}
      >
        {title}
      </Badge>
      <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="15px" />

      <Text fontSize="sm" color="gray.500" mt={2}>
        Client:
      </Text>

      <Code
        mt={2}
        colorScheme={
          current?.clientName === opposite?.clientName
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {current?.clientName}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        Campaign Name:
      </Text>
      <Code
        mt={2}
        colorScheme={
          current?.campaignName === opposite?.campaignName
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {current?.campaignName}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        Campaign Status:
      </Text>
      <Code
        mt={2}
        colorScheme={
          current?.status === opposite?.status
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {upperFirst(current?.status)}
        {/*<CampaignStatusBadge status={current?.status} />*/}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        Start date:
      </Text>
      <Code
        mt={2}
        colorScheme={
          current?.startDate === opposite?.startDate
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {current?.startDate}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        End date:
      </Text>
      <Code
        mt={2}
        colorScheme={
          current?.endDate === opposite?.endDate
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {current?.endDate}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        Currency:
      </Text>
      <Code
        mt={2}
        colorScheme={
          current?.currency?.code === opposite?.currency?.code
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {current?.currency
          ? `${current?.currency?.code} - ${current?.currency?.name}`
          : ''}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        Budget:
      </Text>
      <Code
        mt={2}
        colorScheme={
          formatCurrency(current?.budget, current?.currency.code) ===
          formatCurrency(opposite?.budget, opposite?.currency.code)
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {current?.budget ? formatCurrency(current?.budget, current?.currency.code) : ''}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        Pod Leader:
      </Text>
      <Code
        mt={2}
        colorScheme={
          current?.podLeader?.id === opposite?.podLeader?.id
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {getPodLeaderName(current)}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        File:
      </Text>
      <Code
        mt={2}
        colorScheme={
          getFileName(current) === getFileName(opposite)
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {getFileName(current)}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        File 2:
      </Text>
      <Code
        mt={2}
        colorScheme={
          getFileNameFor(current, 2) === getFileNameFor(opposite, 2)
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {getFileNameFor(current, 2)}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        File 3:
      </Text>
      <Code
        mt={2}
        colorScheme={
          getFileNameFor(current, 3) === getFileNameFor(opposite, 3)
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {getFileNameFor(current, 3)}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        File 4:
      </Text>
      <Code
        mt={2}
        colorScheme={
          getFileNameFor(current, 4) === getFileNameFor(opposite, 4)
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {getFileNameFor(current, 4)}
      </Code>
      <Text fontSize="sm" color="gray.500" mt={2}>
        File 5:
      </Text>
      <Code
        mt={2}
        colorScheme={
          getFileNameFor(current, 5) === getFileNameFor(opposite, 5)
            ? 'facebook'
            : side === 'left'
            ? 'red'
            : 'whatsapp'
        }
      >
        {getFileNameFor(current, 5)}
      </Code>
    </Box>
  );
}
