import React from 'react';

import ChooseButtons from '../components/auth/LoginAsButtons';
import { Redirect } from 'react-router-dom';

function getConfirmationToken(search) {
  const query = new URLSearchParams(search);
  return query.get('confirmationToken');
}

const Login = ({ location }) => {
  const search =
    (location && location.state && location.state.next && location.state.next.search) ||
    '';

  const previous =
    (location && location.state && location.state.next && location.state.next.pathname) ||
    null;
  const confirmationToken = getConfirmationToken(search);
  if (confirmationToken) {
    return (
      <Redirect
        to={{
          pathname: `/password-setup/${confirmationToken}`,
          state: { next: location, previous },
        }}
      />
    );
  }

  return <ChooseButtons location={location} />;
};

export default Login;
