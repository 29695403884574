import React, { useContext } from 'react';
// import { Link as RouterLink, useParams } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { TeamIconPage } from '../../assets/iconsComponents/teamPageIcon';
import { InfluencerIcon } from '../../assets/iconsComponents/influencerIcon';
import { CampaignIcon } from '../../assets/iconsComponents/campaignIcon';
import { POIcon } from '../../assets/iconsComponents/poIcon';
import { PaymentsIcon } from '../../assets/iconsComponents/paymentsIcon';

import { MainNavigation } from './styles';
import { AuthContext } from '../../context/AuthContext';

const Navigation = () => {
  const auth = useContext(AuthContext);

  return (
    !auth.shouldSupplierConfirmAccountDetails() && (
      <MainNavigation>
        <nav>
          <ul>
            {auth.canViewPOList() && (
              <li>
                <NavLink exact activeClassName="isActive" to="/orders">
                  <POIcon style={{ height: '20px', marginRight: '13px' }} />
                  Purchase Orders
                </NavLink>
              </li>
            )}
            {auth.canViewPaymentsList() && (
              <li>
                <NavLink exact activeClassName="isActive" to="/payments">
                  <PaymentsIcon style={{ height: '20px', marginRight: '13px' }} />
                  Payments
                </NavLink>
              </li>
            )}
            {auth.canViewSuppliersList() && (
              <>
                <li>
                  <NavLink exact activeClassName="isActive" to="/suppliers">
                    <InfluencerIcon style={{ height: '20px', marginRight: '13px' }} />
                    Suppliers
                  </NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="isActive" to="/campaigns">
                    <CampaignIcon style={{ height: '20px', marginRight: '13px' }} />
                    Campaigns
                  </NavLink>
                </li>
              </>
            )}
            {auth.canViewMembersList() && (
              <li>
                <NavLink exact activeClassName="isActive" to="/team">
                  <TeamIconPage style={{ height: '20px', marginRight: '13px' }} />
                  Team
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </MainNavigation>
    )
  );
};

export default Navigation;
