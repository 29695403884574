import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Spinner,
  Tooltip,
  // IconButton,
} from '@chakra-ui/react';

import map from 'lodash/map';
import moment from 'moment';
import Pagination from '../../common/UIcomponents/Pagination';
import ScrollToTop from '../../util/scrollToTop';
import { CampaignIcon } from '../../assets/iconsComponents/campaignIcon';
import CampaignSortArrows from './CampaignSortArrows';
import CampaignStatusBadge from './CampaignStatusBadge';
import { CurrencyContext } from '../../context/currency/CurrencyContext';
import SyncFailIcon from '../../assets/icons/sync_fail_icon.svg';
import { AuthContext } from '../../context/AuthContext';
import CampaignApprovalStatusBadge from './CampaignApprovalStatusBadge';

function CampaignList({
  campaigns,
  handlePageSizeChange,
  handlePageChange,
  currentPage,
  pagesQuantity,
  orderBy,
  updateOrderBy,
  loading,
  // isFinance,
}) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const handleRowClick = (campaign) => {
    history.push(`/campaigns/${campaign.id}`);
  };

  const {
    name,
    campaignCode,
    budget,
    currency,
    podLeader,
    status,
    approvalStatus,
    startDate,
    endDate,
    numberOfPos,
  } = orderBy;

  function handleOnClick(e, key, value) {
    updateOrderBy(key, value);
  }
  return (
    <>
      <ScrollToTop />
      <Table
        variant="simple"
        bg="#fff"
        borderRadius="md"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
        mt="7"
      >
        {campaigns.length > 0 && (
          <Thead>
            <Tr key={'head-members'}>
              <Th pr="10px">
                <Box
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  name
                  <Box d="flex" flexDirection="column" ml="0px">
                    <CampaignSortArrows
                      i={name}
                      b="name"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="100px" p="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Campaign code
                  <Box d="flex" flexDirection="column" ml="0px">
                    <CampaignSortArrows
                      i={campaignCode}
                      b="campaignCode"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th p="10px">
                <Box
                  as="p"
                  d="flex"
                  p="0px"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Budget
                  <Box d="flex" flexDirection="column" ml="0px" p="0px">
                    <CampaignSortArrows
                      i={budget}
                      b="budget"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Currency
                  <Box d="flex" flexDirection="column" ml="0px">
                    <CampaignSortArrows
                      i={currency}
                      b="currency"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Status
                  <Box d="flex" flexDirection="column" ml="0px">
                    <CampaignSortArrows
                      i={status}
                      b="status"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Approval Status
                  <Box d="flex" flexDirection="column" ml="0px">
                    <CampaignSortArrows
                      i={approvalStatus}
                      b="approvalStatus"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="100px" pl="0" pr="0px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Start Date
                  <Box d="flex" flexDirection="column" ml="0px">
                    <CampaignSortArrows
                      i={startDate}
                      b="startDate"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>

              <Th minWidth="100px" pr="0" pl="0">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  End Date
                  <Box d="flex" flexDirection="column" ml="0px">
                    <CampaignSortArrows
                      i={endDate}
                      b="endDate"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="50px" p="0">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  POs
                  <Box d="flex" flexDirection="column" ml="0px">
                    <CampaignSortArrows
                      i={numberOfPos}
                      b="numberOfPos"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="50px" pl="0px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Pod Leader
                  <Box d="flex" flexDirection="column" ml="0px">
                    <CampaignSortArrows
                      i={podLeader}
                      b="podLeader"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
            </Tr>
          </Thead>
        )}
        <>
          {loading && (
            <Box p={12} d="flex" alignItems="center" justifyContent="center" h="400px">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="brand.500"
                size="xl"
              />
            </Box>
          )}
          {campaigns.length > 0 && !loading ? (
            <Tbody>
              <>
                {map(campaigns, (campaign) => {
                  // console.log(campaign);
                  const isRed = campaign.sageSyncFailed && auth.isFinance();
                  return (
                    <Tr
                      key={campaign.id}
                      onClick={() => handleRowClick(campaign)}
                      style={{
                        cursor: 'pointer',
                      }}
                      _hover={{ bg: '#f5f6fa' }}
                    >
                      <Td maxWidth="3600px" minWidth="200px" position="relative">
                        {isRed && (
                          <Box position="absolute" top="10px" left="10px" as="span">
                            <Tooltip
                              label="Sync with Sage failed. Restart synchronisation manually."
                              fontSize="sm"
                              maxWidth="180px"
                              color="white"
                              borderRadius="8px"
                              p={2}
                              bg="red"
                              textAlign="center"
                              lineHeight="1"
                            >
                              <img
                                src={SyncFailIcon}
                                style={{ width: '25px' }}
                                alt="syncfailed"
                              />
                            </Tooltip>
                          </Box>
                        )}
                        <Box
                          as="a"
                          fontWeight="700"
                          fontSize="16px"
                          fontFamily="Roboto"
                          color={isRed && `red`}
                        >
                          {campaign.campaignName}
                          <Box
                            color={isRed ? `red` : `#a9b1b4`}
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="1"
                          >
                            {campaign.clientName}
                          </Box>
                        </Box>
                      </Td>
                      <Td maxWidth="100px" pl="10px" color={isRed && `red`}>
                        {campaign.campaignCode ? (
                          campaign.campaignCode
                        ) : (
                          <Box color={isRed ? `red` : `#a9b1b4`}>pending</Box>
                        )}
                      </Td>
                      <Td color={isRed && `red`} pl="10px">
                        {formatCurrency(campaign.budget, campaign.currency.code)}
                      </Td>
                      <Td
                        pl="10px"
                        color={isRed && `red`}
                      >{`${campaign.currency.code} - ${campaign.currency.name}`}</Td>
                      <Td pl="10px" color={isRed && `red`}>
                        <CampaignStatusBadge status={campaign.status} />
                      </Td>

                      <Td pl="10px" color={isRed && `red`}>
                        <CampaignApprovalStatusBadge status={campaign.approvalStatus} />
                      </Td>

                      <Td color={isRed && `red`} pl="10px">
                        {moment(campaign.startDate).format('DD MMM y')}
                      </Td>
                      <Td color={isRed && `red`} pl="10px">
                        {moment(campaign.endDate).format('DD MMM y')}
                      </Td>
                      <Td color={isRed && `red`} pl="0">
                        {campaign.numberOfPurchaseOrders}
                      </Td>
                      <Td pl="10px" color={isRed && `red`}>
                        {campaign.podLeader?.firstName ? (
                          campaign.podLeader.firstName +
                          ' ' +
                          campaign.podLeader?.lastName
                        ) : (
                          <Box color={isRed ? `red` : `#a9b1b4`}>{''}</Box>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </>
            </Tbody>
          ) : campaigns.length === 0 && !loading ? (
            <Box
              d="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              p="80px"
            >
              <CampaignIcon width="60px" height="55px" color="#d1dbdf" />
              <Box fontSize="18px" fontWeight="bold" mt="20px" mb="20px">
                No results.
              </Box>
              <Box color="#a9b1b4" fontSize="14px">
                We couldn’t find any campaigns that matched your search criteria.
              </Box>
            </Box>
          ) : null}
        </>
      </Table>
      <Box margin="20px" />
      {campaigns.length > 0 && (
        <Pagination
          handlePageSizeChange={handlePageSizeChange}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          pagesQuantity={pagesQuantity}
        />
      )}
    </>
  );
}

export default CampaignList;
