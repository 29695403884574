import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../context/HttpContext';
import { getErrors, getMessage } from '../../util';

import Currency from './SelectMultipleCurrency';

const SupplierCompanyForm = ({ onClose, addUser }) => {
  const [currencies, setCurrencies] = React.useState([]);
  const validationSchema = Yup.object().shape({
    officialRegisteredName: Yup.string().required('This is mandatory field'),
    companyEmail: Yup.string()
      .required('This is mandatory field')
      .email('Please enter a valid email'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };
  const { register, handleSubmit, formState, setError, reset } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  const onSubmit = async (values) => {
    try {
      const selectedCurrencies = currencies.map((currency) => currency.id);
      const data = {
        officialRegisteredName: values.officialRegisteredName,
        email: values.companyEmail,
        currencies: selectedCurrencies,
        subType: 'company',
      };

      const res = await authAxios.post(`users/suppliers`, data);
      toast({
        position: 'top',
        description:
          'You have successfully requested the supplier code. You will be notified when the finance team approves it.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      onClose();
      reset();
      addUser(res.data);
    } catch (onError) {
      console.log(onError);
      const errors = getErrors(onError);
      if (errors && errors.email && errors.email.errors) {
        const message = errors.email.errors[0];
        setError('companyEmail', { type: 'server', message });
      } else if (
        errors &&
        errors.officialRegisteredName &&
        errors.officialRegisteredName.errors
      ) {
        const message = errors.officialRegisteredName.errors[0];
        setError('officialRegisteredName', { type: 'server', message });
      } else {
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top-left',
            description: message,
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
      }
    }
  };

  const updateCurrencies = (currencies) => {
    setCurrencies(currencies);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.officialRegisteredName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Official Registered Name</FormLabel>
          <Input
            isInvalid
            name="officialRegisteredName"
            id="officialRegisteredName"
            placeholder="Enter name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('officialRegisteredName')}
          />
          <FormErrorMessage>
            {errors.officialRegisteredName?.message || errors.officialRegisteredName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.companyEmail} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Email Address</FormLabel>
          <Input
            isInvalid
            name="companyEmail"
            id="companyEmail"
            placeholder="Enter email address"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('companyEmail')}
          />
          <FormErrorMessage>
            {errors.companyEmail?.message || errors.companyEmail}
          </FormErrorMessage>
        </FormControl>
        {/* 
        <FormControl isInvalid={errors.currency} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Currency</FormLabel>
          <SelectCurrency updateCurrencyId={updateCurrencyId} />
          <FormErrorMessage>
            {errors.currency?.message || errors.currency}
          </FormErrorMessage>
        </FormControl> */}
        <Currency updateCurrencies={updateCurrencies} />
        <>
          <Box d="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              disabled={!isDirty || !isValid || isSubmitting}
              isLoading={isSubmitting}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
              //disabled={disableForm}
            >
              Submit
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
};
export default SupplierCompanyForm;
