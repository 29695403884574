import React from 'react';
import { Button } from '@chakra-ui/react';

export default function MarkAsInactive({ handleMarkAsInactive, supplier }) {
  const { active } = supplier;
  return (
    <>
      <Button
        type="button"
        colorScheme="brand"
        size="xs"
        // variant="ghost"
        onClick={handleMarkAsInactive}
        pt="20px"
        pb="20px"
        fontSize="14px"
        ml="20px"
      >
        Mark as {active ? 'Inactive' : 'Active'}
      </Button>
    </>
  );
}
