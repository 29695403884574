import React from 'react';
import InvoicePreview from './InvoicePreview';
import { PdfContainer } from '../../common/UIcomponents/styles';

export default function InvoiceView({ invoice }) {
  return (
    <>
      <PdfContainer>
        <InvoicePreview invoice={invoice} forPdf={true} />
      </PdfContainer>
      <InvoicePreview invoice={invoice} />
    </>
  );
}
