import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  Link,
  InputGroup,
  InputRightElement,
  Stack,
  useToast,
} from '@chakra-ui/react';

//import ChooseButtons from "../LoginAsButtons";

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { HttpContext } from '../../../context/HttpContext';
import { AuthContext, KEYS } from '../../../context/AuthContext';
import { getMessage } from '../../../util';

const AuthenticationInfluencerForm = ({ location }) => {
  const history = useHistory();

  const toast = useToast();
  const [show, setShow] = useState(false);
  const handlePwdClick = () => setShow(!show);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('This is mandatory field'),
    // .email('Email is invalid'),
    password: Yup.string().required('This is mandatory field'),
    // .min(6, 'Password must be at least 6 characters'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;

  const { publicAxios, authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  const onSubmit = async (values) => {
    try {
      const { email, password } = values;
      const res = await publicAxios.post(`login`, {
        username: email,
        password,
      });
      const { token, refresh_token } = res.data;
      let shouldRedirect = true;
      if (token) {
        localStorage.setItem(KEYS.token, token);
        const { data } = await authAxios.get(`users/me`);
        if (data) {
          auth.setAuthState({ token, user: data, refreshToken: refresh_token });
          shouldRedirect = !(
            data.roles && data.roles.includes('ROLE_UNCONFIRMED_INFORMATIONS')
          );
        } else {
          auth.setAuthTokens({ token, refreshToken: refresh_token });
        }
        reset();
      }

      const next = (location && location.state && location.state.next) || '/';
      if (next !== '/' && shouldRedirect) {
        history.push(next);
      }
    } catch (onError) {
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top-left',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.email} mb={[4, 4, 8]}>
          <FormLabel htmlFor="email">Email address or Supplier code</FormLabel>
          <Input
            //isInvalid
            name="email"
            id="email"
            //focusBorderColor="black"
            //errorBorderColor="error.400"
            {...register('email')}
          />
          <FormErrorMessage>{errors.email?.message || errors.email}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.password} mb={4}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <InputGroup size="lg">
            <Input
              pr="4.5rem"
              type={show ? 'text' : 'password'}
              name="password"
              id="password"
              {...register('password')}
            />
            <InputRightElement
              children={
                <>
                  {show ? (
                    <ViewOffIcon
                      color="brand.500"
                      onClick={handlePwdClick}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <ViewIcon
                      color="grey.200"
                      onClick={handlePwdClick}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </>
              }
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.password?.message || errors.password}
          </FormErrorMessage>
        </FormControl>
        <>
          <Stack direction="row" justifyContent="space-between" mb="8">
            <Box textAlign="left" mb="4" />
            <Box textAlign="right" mb="4">
              <Link
                fontSize="14px"
                fontWeight="bold"
                color="brand.500"
                as={RouterLink}
                to="/forgot-password"
              >
                Forgot password?
              </Link>
            </Box>
          </Stack>

          <Button
            type="submit"
            size="lg"
            colorScheme="brand"
            isFullWidth
            isLoading={isSubmitting}
            textTransform="initial"
            _hover={{ bg: '#000' }}
            disabled={!isDirty || !isValid || isSubmitting}
          >
            Login
          </Button>
        </>
      </form>
    </>
  );
};
export default AuthenticationInfluencerForm;
