import React from 'react';
import UseCurrency from '../../custom-hooks/useCurrency';
import POContextProvider from '../../context/PurchaseOrder/FilterPO';
import { CurrencyProvider } from '../../context/currency/CurrencyContext';
import InvoiceEdit from '../InvoiceEdit';
import { CountriesProvider } from '../../context/country/CountriesContext';
import UseCountries from '../../custom-hooks/useCountries';

export default function InvoiceEditPage() {
  return (
    <POContextProvider>
      <CurrencyProvider>
        <UseCurrency>
          <CountriesProvider>
            <UseCountries>
              <InvoiceEdit />
            </UseCountries>
          </CountriesProvider>
        </UseCurrency>
      </CurrencyProvider>
    </POContextProvider>
  );
}
