import React from 'react';

import { Box, Text } from '@chakra-ui/react';

const NotableInfo = ({ icon, textTop, textBottom, mr, mb, normalCase }) => {
  return (
    <Box d="inline-block" position="relative" pl={icon && '54px'}>
      {icon && (
        <Box
          bg="brand.400"
          borderRadius="8px"
          w="39px"
          h="39px"
          d="flex"
          justifyContent="center"
          alignItems="center"
          mr="15px"
          position="absolute"
          left="0"
        >
          {icon}
        </Box>
      )}
      <Box mr={mr ? mr : '50px'} mb={mb ? mb : '15px'}>
        <Text
          fontSize="12px"
          color=" #868789"
          lineHeight="normal"
          mb={1}
          textTransform={normalCase ? 'none' : 'uppercase'}
        >
          {textTop}
        </Text>
        <Text fontSize="24px" fontWeight="500" lineHeight="22px">
          {textBottom}
        </Text>
      </Box>
    </Box>
  );
};

export default NotableInfo;
