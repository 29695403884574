import React, { useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Menu, MenuButton, MenuList, MenuItem, Box } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { AuthContext } from '../../context/AuthContext';
import { getName } from '../../util';

export default function HeaderDropdown() {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const user = auth.getUser();
  let name = '';
  if (user) {
    name = getName(user);
  }

  const handleClick = () => {
    history.push('/login');
    auth && auth.logout();
  };
  return (
    <Menu>
      <MenuButton
        px={4}
        py={2}
        transition="all 0.2s"
        //_hover={{ bg: "gray.400" }}
        //_expanded={{ bg: "blue.400" }}
        _focus={{ boxShadow: 'none' }}
      >
        <Box fontWeight="500" fontSize="14px">
          {' '}
          {name} <ChevronDownIcon />
        </Box>
      </MenuButton>
      <MenuList>
        <MenuItem _hover={{ bg: '#d3dcdf' }} as={RouterLink} to={`/account`}>
          {' '}
          <Box fontSize="14px">Account</Box>
        </MenuItem>
        <MenuItem _hover={{ bg: '#d3dcdf' }} onClick={handleClick}>
          <Box fontSize="14px">Log out</Box>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
