import React from 'react';
import UseCurrency from '../../custom-hooks/useCurrency';
import POContextProvider from '../../context/PurchaseOrder/FilterPO';
import { CurrencyProvider } from '../../context/currency/CurrencyContext';
import PortalPurchaseOrders from '../../pages/PortalPurchaseOrder';
import { PodLeadersProvider } from '../../context/podLeader/PodleadersContext';
import UsePodLeaders from '../../custom-hooks/usePodLeaders';

function PurchaseOrdersPage() {
  return (
    <POContextProvider>
      <CurrencyProvider>
        <UseCurrency>
          <PodLeadersProvider>
            <UsePodLeaders>
              <PortalPurchaseOrders />
            </UsePodLeaders>
          </PodLeadersProvider>
        </UseCurrency>
      </CurrencyProvider>
    </POContextProvider>
  );
}

export default PurchaseOrdersPage;
