import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import first from 'lodash/first';
import {
  Heading,
  Container,
  Box,
  Link,
  useToast,
  Button,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import { ChevronLeftIcon, CheckIcon, SmallAddIcon } from '@chakra-ui/icons';
import RequestPurchaseOrderButton from '../components/purchaseOrders/RequestChangesPOButton';
import RejectPOButton from '../components/purchaseOrders/RejectPOButton';
import { HttpContext } from '../context/HttpContext';
import OrderDetailsContent from '../components/purchaseOrders/OrderDetailsContent';
import EditPOButton from '../components/purchaseOrders/EditPOForm/EditPOButton';
import { AuthContext } from '../context/AuthContext';
import { getMessage, getMessageStatus } from '../util';
import ScrollToTop from '../util/scrollToTop';

export default function OrderDetails() {
  const { id } = useParams();
  const toast = useToast();
  const history = useHistory();
  const [state, setState] = useState({
    order: null,
    updatedOrder: null,
    orderNumber: null,
    activityLog: null,
    loading: true,
    error: null,
    pending: false,
    suppliers: null,
  });
  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  const user = auth.getUser();
  //const canSubmitInvoice = approvedSuppliers?.length > 0 ? approvedSuppliers[0].canSubmitInvoice : false;

  const { order, orderNumber, pending, activityLog, updatedOrder, loading, suppliers } =
    state;
  // const sCode = (order && order.orderNumber) || updatedCode;
  //
  // function updateOrder(order) {
  //   setState({
  //     ...state,
  //     order,
  //   });
  // }

  let orderSupplier = order?.supplier ?? null;

  if (orderSupplier) {
    const recordIndex = findIndex(suppliers, (x) => x.id === orderSupplier.id);
    if (recordIndex > -1) {
      orderSupplier = suppliers[recordIndex];
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        setState({
          ...state,
          pending: true,
        });
        const dataOrder = await authAxios.get(`/purchase-orders/${id}`);

        const dataSuppliers = await authAxios.get(
          `/users/${
            user.type === 'supplier' ? user.id : dataOrder.data.supplier.user.id
          }/suppliers`
        );

        setState({
          ...state,
          order: dataOrder.data,
          suppliers: dataSuppliers.data,
          loading: false,
          pending: false,
        });
      } catch (onError) {
        setState({
          ...state,
          error: 'Something went wrong',
          loading: false,
          pending: false,
        });
        console.log(onError);
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top',
            description: message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/orders');
          }, 3000);
        }
        const status = getMessageStatus(onError);
        if (status === 403) {
          toast({
            position: 'top-left',
            description: 'You are not authorized to view this page',
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/orders');
          }, 3000);
        }
      }
    };

    getData();
  }, [authAxios, id, orderNumber, activityLog, updatedOrder]);

  async function updateApproving() {
    try {
      setState({
        ...state,
        loading: true,
        pending: true,
      });
      const { data } = await authAxios.post(`purchase-orders/${id}/approve`);
      setState({
        ...state,
        orderNumber: data.orderNumber,
        loading: false,
        pending: false,
      });
    } catch (onError) {
      console.log(onError);
      setState({
        ...state,
        loading: false,
        pending: false,
      });
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    }
  }

  function handleClickAddInvoice(e) {
    e.preventDefault();
    toast({
      position: 'top',
      description:
        'Please fill in your account information before submitting an invoice.',
      status: 'warning',
      duration: 3000,
      isClosable: true,
    });
    history.push(`/account?redirect=orders/${order.id}`);
  }

  function updateActivityLog(activityLog) {
    setState({
      ...state,
      activityLog,
    });
    toast({
      position: 'top',
      description: `You have successfully sent your comment. You will be notified when the ${
        auth.isSupplier() ? `goat` : `campaign`
      } team responds.`,
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  }

  async function handleApproving(e) {
    try {
      e.preventDefault();
      setState({
        ...state,
        pending: true,
      });
      const { data } = await authAxios.post(`purchase-orders/${id}/approve`);
      setState({
        ...state,
        orderNumber: data.orderNumber,
        loading: false,
        pending: false,
      });

      const description = auth.isPodLeader()
        ? 'You have successfully approved the purchase order.'
        : 'You have successfully approved the purchase order. You will be notified when the supplier submits the invoice.';
      toast({
        position: 'top',
        description,
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
      setState({
        ...state,
        error: 'Something went wrong',
        loading: false,
        pending: false,
      });
    }
  }

  function updatePurchaseOrders(purchaseOrder) {
    setState({
      ...state,
      updatedOrder: purchaseOrder,
    });
    toast({
      position: 'top',
      description: `
      You have successfully made changes to the purchase order. You will be notified when the ${
        auth.isSupplier() ? `Goat` : `finance`
      } team approves it.`,
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  }

  const supplierList = suppliers || null;
  const orderSId = (order && order.supplier.id) || null;
  const suppliersPo = filter(supplierList, (person) => person.id === orderSId);

  const supplierPo = first(suppliersPo);
  const canEditPO =
    auth.isCampaignManager() &&
    (order?.status === 'pending_approval' || order?.status === 'pending_repair');

  let approvingAsPodLeader =
    order &&
    order.status === 'pending_approval' &&
    auth.isPodLeader() &&
    auth.getUser()?.id === order?.podLeader?.id;
  let approvingAsFinance =
    order?.status === 'pending_approval_finance' && auth.isFinance();
  return (
    <Container w="100%" maxWidth="100%" pt={4} pr={10} pl={10} pb={14}>
      <>
        {!order || loading ? (
          <Box p={12} d="flex" alignItems="center" justifyContent="center" h="400px">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="xl"
            />
          </Box>
        ) : (
          <>
            <ScrollToTop />
            <Link color="brand.500" as={RouterLink} to="/orders" fontSize="14px">
              <ChevronLeftIcon /> Purchase orders
            </Link>
            <Box>
              <Heading
                as="h2"
                size="lg"
                textAlign="left"
                fontWeight="700"
                pt={4}
                mb={1}
                d="inline-block"
              >
                Purchase Order details
              </Heading>
              {canEditPO && (
                <EditPOButton order={order} updatePurchaseOrders={updatePurchaseOrders} />
              )}
            </Box>
            {(auth.isFinance() || auth.isPodLeader()) && (
              <>
                {(approvingAsPodLeader || approvingAsFinance) && (
                  <Box as="p" color="#333" mb="20px" mt="20px">
                    Please review the purchase order details and approve it or request
                    changes from the campaign team.
                  </Box>
                )}
                {(approvingAsPodLeader || approvingAsFinance) && (
                  <HStack>
                    <Button
                      type="submit"
                      colorScheme="brand"
                      size="sm"
                      pt="10px"
                      pb="10px"
                      fontSize="14px"
                      fontWeight="500"
                      _hover={{ bg: '#000' }}
                      onClick={handleApproving}
                      disabled={pending}
                    >
                      <CheckIcon mr="10px" />
                      Approve
                    </Button>
                    <span>or</span>
                    <RequestPurchaseOrderButton updateActivityLog={updateActivityLog} />
                    {order?.approvedAt === null && <RejectPOButton order={order} />}
                  </HStack>
                )}
              </>
            )}
            {auth.isSupplier() && (
              <>
                {order?.status === 'awaiting_invoice' && (
                  <Box as="p" color="#333" mb="20px" mt="20px">
                    Please review the purchase order details and add an invoice.
                  </Box>
                )}
                {order?.status === 'awaiting_invoice' && (
                  <HStack>
                    {supplierPo.canSubmitInvoice === true ? (
                      <Button
                        as={RouterLink}
                        colorScheme="brand"
                        size="sm"
                        pt="10px"
                        pb="10px"
                        fontSize="14px"
                        fontWeight="500"
                        _hover={{ bg: '#000' }}
                        to={`/create-invoice/${order.id}`}
                      >
                        <SmallAddIcon h={22} w={7} />
                        Add invoice
                      </Button>
                    ) : (
                      <Button
                        as={RouterLink}
                        colorScheme="brand"
                        size="sm"
                        pt="10px"
                        pb="10px"
                        fontSize="14px"
                        fontWeight="500"
                        _hover={{ bg: '#000' }}
                        onClick={(e) => handleClickAddInvoice(e)}
                      >
                        <SmallAddIcon h={22} w={7} />
                        Add invoice
                      </Button>
                    )}
                    <span>or</span>
                    <RequestPurchaseOrderButton
                      isSupplier
                      updateActivityLog={updateActivityLog}
                    />
                  </HStack>
                )}
              </>
            )}
            <OrderDetailsContent
              updateApproving={updateApproving}
              pending={pending}
              order={order}
              isFinance={auth.isFinance()}
              isSupplier={auth.isSupplier()}
              updateActivityLog={updateActivityLog}
              canSubmitInvoice={orderSupplier?.canSubmitInvoice || false}
              canApprovingAsPodLeader={approvingAsPodLeader}
              canApprovingAsFinance={approvingAsFinance}
            />
          </>
        )}
      </>
    </Container>
  );
}
