import React from 'react';
import AddPaymentDetailsTab from './AddPaymentDetailsTabs';

const AddPaymentDetailsForm = ({
  onClose,
  supplier,
  updateSupplier,
  isChecked,
  isEdit,
}) => {
  return (
    <AddPaymentDetailsTab
      onClose={onClose}
      supplier={supplier}
      updateSupplier={updateSupplier}
      isChecked={isChecked}
      isEdit={isEdit}
    />
  );
};
export default AddPaymentDetailsForm;
