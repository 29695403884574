import React, {
  useContext,
  useEffect,
  useState,
  // Suspense
} from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { Heading, Container, Box, Link, useToast, Spinner } from '@chakra-ui/react';
// import filter from "lodash/filter";
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { HttpContext } from '../context/HttpContext';
import InvoiceCreate from '../components/invoice/InvoiceCreate';
import { AuthContext } from '../context/AuthContext';
import { getMessage, getMessageStatus } from '../util';
import ScrollToTop from '../util/scrollToTop';
import filter from 'lodash/filter';
import first from 'lodash/first';

export default function CreateInvoice() {
  const { id } = useParams();
  const toast = useToast();
  const history = useHistory();
  const [state, setState] = useState({
    order: null,
    updatedOrder: null,
    orderNumber: null,
    activityLog: null,
    loading: true,
    error: null,
    pending: false,
    invoice: null,
    referenceNumberNew: null,
    suppliers: null,
  });
  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);
  const user = auth.getUser();

  useEffect(() => {
    const getData = async () => {
      try {
        const data0rder = await authAxios.get(`/purchase-orders/${id}`);
        const dataSuppliers = await authAxios.get(`/users/${user.id}/suppliers`);
        setState({
          ...state,
          order: data0rder.data,
          suppliers: dataSuppliers.data,
          pending: false,
          loading: false,
        });
      } catch (onError) {
        console.log(onError);
        setState({
          ...state,
          error: 'Something went wrong',
          loading: false,
          pending: false,
        });
        const status = getMessageStatus(onError);
        if (status === 403) {
          toast({
            position: 'top-left',
            description: 'You are not authorized to view this page',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/orders');
          }, 3000);
        }
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top',
            description: message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/orders');
          }, 3000);
        }
      }
    };

    getData();
  }, [authAxios]);

  function updateActivityLog(activityLog) {
    setState({
      ...state,
      activityLog,
    });
    toast({
      position: 'top',
      description: `You have successfully sent your comment. You will be notified when the ${
        auth.isSupplier() ? `goat ` : `campaign`
      } team responds.`,
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  }

  const { order, loading, suppliers } = state;

  const supplierList = suppliers || null;
  const orderSId = (order && order.supplier.id) || null;
  const suppliersPo = filter(supplierList, (person) => person.id === orderSId);

  // supplierList?.filter((person) => person.id === orderSId) ?? [];
  const supplierPo = first(suppliersPo);

  return (
    <Container w="100%" maxWidth="100%" pt={4} pr={10} pl={10} pb={14}>
      {!order || loading ? (
        <>
          <Box p={12} d="flex" alignItems="center" justifyContent="center" h="400px">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="xl"
            />
          </Box>
        </>
      ) : (
        <>
          <ScrollToTop />
          <Link
            color="brand.500"
            as={RouterLink}
            to={`/orders/${order.id}`}
            fontSize="14px"
          >
            <ChevronLeftIcon /> Purchase Orders Details
          </Link>
          <Box>
            <Heading
              as="h2"
              size="lg"
              textAlign="left"
              fontWeight="700"
              pt={4}
              mb={1}
              d="inline-block"
            >
              Create invoice
            </Heading>
          </Box>
          <InvoiceCreate
            order={order}
            me={user}
            supplierPo={supplierPo}
            isFinance={auth.isFinanceManager()}
            isSupplier={auth.isSupplier()}
            updateActivityLog={updateActivityLog}
          />
        </>
      )}
    </Container>
  );
}
