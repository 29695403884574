import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { SmallAddIcon } from '@chakra-ui/icons';
import DrawerComponentCustom from '../../common/UIcomponents/CustomDrawer';
import RequestForm from './RequestPOForm/RequestParchaseOrderForm';

export default function RequestPurchaseOrderButton({
  fromCampaign,
  addPurchaseOrders,
  updatePOList,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {fromCampaign ? (
        <Button
          type="submit"
          colorScheme="brand"
          size="sm"
          onClick={onOpen}
          pt="10px"
          pb="10px"
          pr="10px"
          pl="5px"
          fontSize="14px"
          // _
          _hover={{ bg: '#000' }}
        >
          <SmallAddIcon h={22} w={7} />
          Request a Purchase Order
        </Button>
      ) : (
        <Button
          type="submit"
          colorScheme="brand"
          size="sm"
          onClick={onOpen}
          pt="20px"
          pb="20px"
          // _
          _hover={{ bg: '#000' }}
        >
          <SmallAddIcon h={22} w={7} />
          Request a Purchase Order
        </Button>
      )}

      <DrawerComponentCustom
        title="Request a Purchase Order"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        po
      >
        {' '}
        <RequestForm
          onClose={onClose}
          addPurchaseOrders={addPurchaseOrders}
          updatePOList={updatePOList}
        />
      </DrawerComponentCustom>
    </>
  );
}
