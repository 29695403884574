import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

export function getMissingCurrencies(suppliers) {
  const count = suppliers.length;
  if (count) {
    let currencies = [];
    for (let j = 0; j < count; j++) {
      // if (null === suppliers[j].code) return suppliers[j]
      if (null === suppliers[j].code)
        currencies.push({
          supplierId: suppliers[j].id,
          id: suppliers[j].currency.code,
          name: suppliers[j].currency.name,
          currency: suppliers[j].currency,
        });
    }

    return currencies;
  }

  return null;
}

export function isMissingCode(suppliers) {
  const count = suppliers.length;
  if (count) {
    for (let j = 0; j < count; j++) {
      if (null === suppliers[j].code) return true;
    }
  }

  return false;
}

export function hasPaymentsToApprove(suppliers) {
  const count = suppliers.length;
  if (count) {
    for (let j = 0; j < count; j++) {
      if (false === suppliers[j].paymentDetailsApproved) return true;
    }
  }

  return false;
}

export function getPaymentsToApprove(suppliers) {
  const count = suppliers?.length;
  if (count) {
    let data = [];
    for (let i = 0; i < count; i++) {
      if (false === suppliers[i].paymentDetailsApproved) data.push(suppliers[i]);
    }

    return data;
  }

  return null;
}

export function supplierTypes() {
  return [
    {
      type: 'individual',
      name: 'Individual',
    },
    {
      type: 'company',
      name: 'Company',
    },
  ];
}

export function supplierEntityTypes() {
  return [
    {
      type: 'us_person',
    },
    {
      type: 'foreign_person',
    },
    {
      type: 'us_corporation',
    },
    {
      type: 'foreign_company',
    },
  ];
}

export function hasPayPal(supplier) {
  const { payPalAccount } = supplier;
  if (payPalAccount) {
    return true;
  }

  return false;
}

export function hasBankAccount(supplier) {
  const { bankName } = supplier;
  if (bankName) {
    return true;
  }

  return false;
}

export function alreadyExistSupplierCode(code, suppliers) {
  if (isEmpty(suppliers)) {
    return false;
  }

  const recordIndex = findIndex(
    suppliers,
    (x) => x.code.toUpperCase() === code.toUpperCase()
  );

  return recordIndex > -1;
}
