import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import DrawerComponentCustom from '../../common/UIcomponents/CustomDrawer';
import SupplierIndividualEditForm from './SupplierIndividualEditForm';
import SupplierCompanyEditForm from './SupplierCompanyEditForm';
import SupplierCompanyCampaignEditForm from './SupplierCompanyCampaignEditForm';
import SupplierIndividualCampaignEditForm from './SupplierIndividualCampaignEditForm';

export default function EditSupplierButton({
  user,
  isFinance,
  isCampaign,
  updateUser,
  suppliers,
  addNewCurrencies,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        type="submit"
        colorScheme="brand"
        size="xs"
        onClick={onOpen}
        pt="20px"
        pb="20px"
        variant="ghost"
        fontSize="14px"
      >
        Edit
      </Button>

      <DrawerComponentCustom
        title="Edit a Supplier"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        {isFinance && user.subType === 'individual' && (
          <SupplierIndividualEditForm
            onClose={onClose}
            updateUser={updateUser}
            user={user}
            suppliers={suppliers}
          />
        )}

        {isFinance && user.subType === 'company' && (
          <SupplierCompanyEditForm
            onClose={onClose}
            updateUser={updateUser}
            user={user}
            suppliers={suppliers}
          />
        )}
        {isCampaign && user.subType === 'individual' && (
          <SupplierIndividualCampaignEditForm
            onClose={onClose}
            updateUser={updateUser}
            user={user}
            suppliers={suppliers}
            addNewCurrencies={addNewCurrencies}
          />
        )}

        {isCampaign && user.subType === 'company' && (
          <SupplierCompanyCampaignEditForm
            onClose={onClose}
            updateUser={updateUser}
            user={user}
            suppliers={suppliers}
            addNewCurrencies={addNewCurrencies}
          />
        )}
      </DrawerComponentCustom>
    </>
  );
}
