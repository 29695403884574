import React from 'react';
export const DownloadIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 64 60"
    {...props}
  >
    {/* <style type="text/css">
 .st0{fill:#333333;}
</style> */}
    <g>
      <path
        d="M56,28h8v26c0,0.8-0.2,1.6-0.5,2.3s-0.7,1.4-1.3,1.9c-0.6,0.5-1.2,1-1.9,1.3S58.8,60,58,60H6
   c-0.8,0-1.6-0.2-2.3-0.5s-1.4-0.7-1.9-1.3c-0.5-0.6-1-1.2-1.3-1.9S0,54.8,0,54V28h8v24h48V28z M24,24V0h16v24h14L32,46L10,24H24z"
      />
    </g>
  </svg>
);
