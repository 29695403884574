import React, { useContext } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
  Text,
  Textarea,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import RejectIcon from '../../assets/icons/reject_icon.svg';
import { useForm } from 'react-hook-form';
import { getMessage, getErrors, getErrorsFromArray } from '../../util';
import { HttpContext } from '../../context/HttpContext';
import { useHistory } from 'react-router-dom';

export default function RejectCampaignButton({ campaign, userType }) {
  const { authAxios } = useContext(HttpContext);
  const history = useHistory();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { handleSubmit, register, formState, setError, reset } = useForm();
  const { isSubmitting, errors } = formState;

  const onSubmit = async (data) => {
    try {
      let url = `campaigns/${campaign.id}/reject`;
      let submittedData = { commentByFinance: data.comment };

      if (userType === 'podLeader') {
        url = `campaigns/${campaign.id}/reject-by-pod-leader`;
        submittedData = { commentByPodLeader: data.comment };
      }

      await authAxios.post(url, submittedData);

      toast({
        position: 'top',
        description: 'You have rejected the campaign.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      history.push(`/campaigns`);
    } catch (onError) {
      const errors = getErrors(onError);
      const errorsArr = getErrorsFromArray(onError);
      if (errors && errors.comment && errors.comment.errors) {
        const message = errors.comment.errors[0];
        setError('comment', { type: 'server', message });
      } else if (errorsArr.length !== 0) {
        const message = errorsArr[0];
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      } else {
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top',
            description: message,
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
        if (onError?.response?.status === 403) {
          toast({
            position: 'top',
            description: 'Access denied.',
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
        if (onError?.response?.status === 500) {
          toast({
            position: 'top',
            description: 'Something went wrong',
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
        onClose();
      }
    }
  };

  return (
    <>
      <Button size="sm" fontWeight="500" fontSize="14px" color="#d41d29" onClick={onOpen}>
        <img
          style={{ height: '12px', marginRight: '5px' }}
          src={RejectIcon}
          alt="reject"
        />
        Reject
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader pt="50px" textAlign="center" fontSize="22px">
              Reject Campaign?
            </ModalHeader>
            <ModalCloseButton color="#d1d2d2" _focus={{ outline: '0' }} size="sm" />
            <ModalBody pt="10px" px="45px" color="#1A202C">
              <Text color="#333333" fontWeight="700" mt="27px">
                Why are you rejecting this Campaign?
              </Text>
              <FormControl isInvalid={errors.comment}>
                <Textarea
                  mt="16px"
                  height="150px"
                  border="1px solid transparent"
                  bg="#ebecf0"
                  _focus={{ border: '1px solid black' }}
                  _hover={{ border: '1px solid black' }}
                  placeholder="Enter your comment"
                  {...register('comment')}
                  defaultValue=""
                ></Textarea>
                <FormErrorMessage>{errors?.comment?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter
              d="flex"
              alignItems="center"
              justifyContent="center"
              pt="30px"
              pb="40px"
            >
              <Button
                colorScheme="brand"
                mr={3}
                type="submit"
                disabled={isSubmitting}
                //onClick={onClose}
              >
                Yes, reject
              </Button>
              <Button
                variant="outline"
                color="brand.500"
                pt="15px"
                pb="15px"
                onClick={() => {
                  reset({
                    comment: '',
                  });
                  onClose();
                }}
                disabled={isSubmitting}
              >
                No, go back
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
