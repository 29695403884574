import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../context/HttpContext';
import { getErrors, getMessage } from '../../util';

const EditMemberForm = ({ onClose, updateMember, member }) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, 'Please enter 100 characters or less')
      .required('This is mandatory field'),
    // .min(4, 'First name must be at least 4 characters'),
    lastName: Yup.string()
      .max(100, 'Please enter 100 characters or less')
      .required('This is mandatory field'),
    // .min(4, 'Last name must be at least 4 characters'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };
  const { register, handleSubmit, formState, setError, reset } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  const onSubmit = async (values) => {
    try {
      const res = await authAxios.patch(`users/members/${member.id}`, values);
      updateMember(res.data);
      onClose();
      reset();
    } catch (onError) {
      const errors = getErrors(onError);
      if (errors && errors.email) {
        const message = errors.email.errors[0];
        setError('email', { type: 'server', message });
      } else {
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top-left',
            description: message,
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.firstName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">First Name</FormLabel>
          <Input
            isInvalid
            name="firstName"
            id="firstName"
            defaultValue={member.firstName}
            placeholder="Enter first name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('firstName')}
          />
          <FormErrorMessage>
            {errors.firstName?.message || errors.firstName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.lastName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Last Name</FormLabel>
          <Input
            isInvalid
            name="lastName"
            id="lastName"
            defaultValue={member.lastName}
            placeholder="Enter last name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('lastName')}
          />
          <FormErrorMessage>
            {errors.lastName?.message || errors.lastName}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.email} mb={[4, 4, 8]}>
          <FormLabel htmlFor="email">Email address</FormLabel>
          <Input
            isInvalid
            disabled
            color="#c3c1c1"
            name="email"
            id="email"
            focusBorderColor="black"
            errorBorderColor="error.400"
            defaultValue={member.email}
            {...register('email')}
          />
          <FormErrorMessage>{errors.email?.message || errors.email}</FormErrorMessage>
        </FormControl>

        <>
          <Box d="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              isLoading={isSubmitting}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
              disabled={!isDirty || !isValid || isSubmitting}
            >
              Submit
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={onClose}
              disabled={isSubmitting}
              // _hover={{ bg: "#000" }}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
};
export default EditMemberForm;
