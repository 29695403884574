import React, { useContext, useEffect, useState } from 'react';
import {
  Link as RouterLink,
  // Link as RouterLink,
  useHistory,
  useParams,
} from 'react-router-dom';
// import moment from 'moment';
import CampaignRequestChangeCard from '../components/campaigns/CampaignChangeRequestCard';
import {
  Box,
  Grid,
  GridItem,
  Button,
  HStack,
  useToast,
  Heading,
  Link,
} from '@chakra-ui/react';
import { CheckIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { AuthContext } from '../context/AuthContext';
import { getMessage } from '../util';

import RequestChangesCampaignButton from '../components/campaigns/RequestChangesCampaignButton';
import RejectCampaignButton from '../components/campaigns/CampaignRejectButton';

import { HttpContext } from '../context/HttpContext';
import { SINGLE_CAMPAIGN_REQUEST_CHANGES_PAGE } from './campaign/CampaignRequestChangesPage';
import CreateCampaignCodeButton from '../components/campaigns/CreateCampaignCodeButton';

// import { CurrencyContext } from '../context/currency/CurrencyContext';

// const campaignCurrent = [
//   {
//     campaignOld: true,
//     id: 1,
//     campaignName: 'Coca Cola',
//     name: 'John Doe',
//     startDate: '2023-01-01',
//     currency: 'usd',
//     contractFile: 'file.png',
//     budget: '123usd',
//     file: 'file.png',
//     status: 'initial',
//   },
// ];
// const campaignNew = [
//   {
//     campaignNew: true,
//     id: 2,
//     campaignName: 'Pepsi',
//     name: 'John Doe',
//     startDate: '2023-01-01',
//     currency: 'usd',
//     contractFile: 'file.png',
//     budget: '123usd',
//     file: 'file.png',
//     status: 'completed',
//   },
// ];

export default function CampaignRequestChanges() {
  const { id } = useParams();
  const toast = useToast();
  const history = useHistory();
  const [campaignState, setCampaignState] = useState({
    campaign: null,
    requested: null,
    lastApproved: null,
    campaignCode: null,
    loading: true,
    error: null,
    meta: null,
  });
  const [state, setState] = useState({
    order: null,
    updatedOrder: null,
    orderNumber: null,
    activityLog: null,
    loading: true,
    error: null,
    pending: false,
    suppliers: null,
    refreshActivity: 0,
    refreshCampaign: 0,
  });
  const [updatedCampaignCode] = useState(null);
  const [addPO] = useState(0);
  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);
  // const user = auth.getUser();
  // const { formatCurrency } = useContext(CurrencyContext);
  // const { getUser } = useContext(AuthContext);

  // function updateCampaignCode(campaignCode) {
  //   setUpdatedCampaignCode(campaignCode);
  // }

  // function updateCampaign({ campaign, meta }) {
  //   setCampaignState({
  //     ...campaignState,
  //     campaign,
  //     meta,
  //   });
  // }

  const campaign = campaignState?.campaign;

  let canApproveAsPodLeader =
    campaign?.status !== 'initial' &&
    campaign?.approvalStatus === 'pending_to_pod_leader' &&
    auth.isPodLeader() &&
    auth.getUser()?.id === campaign?.podLeader?.id;
  let canApproveAsFinance =
    campaign?.approvalStatus === 'pending_to_finance' && auth.isFinance();
  const canApprove = canApproveAsPodLeader || canApproveAsFinance;

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await authAxios.get(`/campaigns/${id}`);
        const campaign = data.data;
        const { meta } = data;
        let requested = null;
        let lastApproved = null;
        let length = campaign?.campaignChanges?.length;
        if (length > 0) {
          requested = campaign.campaignChanges[length - 1];
        }
        let lengthLastApproved = campaign?.approvedItems?.length;
        if (lengthLastApproved > 0) {
          lastApproved = campaign.approvedItems[lengthLastApproved - 1];
        }
        setCampaignState({
          ...campaignState,
          campaign,
          requested,
          lastApproved,
          loading: false,
          meta,
        });
      } catch (onError) {
        console.log(onError);
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top',
            description: message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/campaigns');
          }, 3000);
        }
      }
    };

    getData();
  }, [authAxios, id, updatedCampaignCode, addPO, state.refreshCampaign]);

  async function handleApproving(e) {
    try {
      e.preventDefault();
      setState({
        ...state,
        pending: true,
      });

      let url = `/campaigns/${id}/approve-by-pod-leader`;
      if (auth.isFinance()) {
        if (campaignState?.campaign?.campaignCode) {
          url = `/campaigns/${id}/approve-again`;
        } else {
          url = `/campaigns/${id}/approve`;
        }
      }

      const { data } = await authAxios.post(url);

      setState({
        ...state,
        orderNumber: data.orderNumber,
        loading: false,
        pending: false,
      });
      setCampaignState({
        ...campaignState,
        campaign: data,
        loading: false,
      });

      toast({
        position: 'top',
        description: `You have successfully approved the campaign.`,
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      setTimeout(function () {
        history.push(`/campaigns/${id}`);
      }, 1000);
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
      setState({
        ...state,
        error: 'Something went wrong',
        loading: false,
        pending: false,
      });
    }
  }

  function updateCampaign(campaignCode) {
    let campaign = campaignState.campaign;
    campaign.campaignCode = campaignCode;
    setCampaignState({
      ...campaignState,
      campaign,
      loading: false,
    });

    // toast({
    //   position: 'top',
    //   description: `You have successfully approved the campaign.`,
    //   status: 'success',
    //   duration: 6000,
    //   isClosable: true,
    // });
    setTimeout(function () {
      history.push(`/campaigns/${id}`);
    }, 1000);
  }

  function handleUpdateCampaignCode(campaignCode) {
    setTimeout(function () {
      history.push(`/campaigns/${id}`);
    }, 500);
  }

  let edited = campaignState.campaign;
  let lastApproved = campaignState.lastApproved;
  return (
    <Box p={8}>
      <Link color="brand.500" as={RouterLink} to={`/campaigns/${id}`} fontSize="14px">
        <ChevronLeftIcon /> Campaign
      </Link>
      <Heading p={5}>Campaign Request Change</Heading>
      <Grid templateColumns="repeat(2, 1fr)" gap={8}>
        <GridItem colSpan={1}>
          <CampaignRequestChangeCard
            current={edited}
            opposite={lastApproved}
            side="right"
            title={'Campaign for approving'}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <CampaignRequestChangeCard
            current={lastApproved}
            opposite={edited}
            side="left"
            title="Last approved campaign"
          />
        </GridItem>
      </Grid>
      {canApprove && (
        <HStack mt={10}>
          {((canApproveAsFinance && campaign.contractFile && campaign.campaignCode) ||
            canApproveAsPodLeader) && (
            <Button
              type="submit"
              colorScheme="brand"
              size="sm"
              pt="10px"
              pb="10px"
              fontSize="14px"
              fontWeight="500"
              _hover={{ bg: '#000' }}
              onClick={handleApproving}
              disabled={state.pending}
            >
              <CheckIcon mr="10px" />
              Approve
            </Button>
          )}
          {canApproveAsFinance && !campaign.contractFile && campaign.campaignCode && (
            <CreateCampaignCodeButton
              code={campaign.campaignCode}
              text={'Approve'}
              icon="check"
              updateCampaignCode={updateCampaign}
            />
          )}
          {/*not happen*/}
          {canApproveAsFinance && !campaign.campaignCode && (
            <CreateCampaignCodeButton
              updateCampaignCode={handleUpdateCampaignCode}
              text="Approve"
              icon="check"
            />
          )}
          <span>or</span>
          <RequestChangesCampaignButton
            userType={
              auth.isFinance() ? 'finance' : auth.isPodLeader() ? 'podLeader' : null
            }
            parent={SINGLE_CAMPAIGN_REQUEST_CHANGES_PAGE}
          />
          <RejectCampaignButton
            userType={
              auth.isFinance() ? 'finance' : auth.isPodLeader() ? 'podLeader' : null
            }
            campaign={campaignState?.campaign}
          />
        </HStack>
      )}
    </Box>
  );
}
