import React, { useContext, useEffect, useState } from 'react';
import { Box, HStack, Button } from '@chakra-ui/react';
import map from 'lodash/map';
import { HttpContext } from '../../context/HttpContext';
import moment from 'moment';
import { ACTIVITY_LOG_LENGTH } from '../../util/consts';
import ScrollToTop from '../../util/scrollToTop';

function getAuthorName(user) {
  return user.officialRegisteredName
    ? user.officialRegisteredName
    : `${user.firstName} ${user.lastName}`;

  // return name
}

function SupplierActivity({ id, activityNumber }) {
  const { authAxios } = useContext(HttpContext);
  const initialState = {
    activities: [],
    total: 0,
    limit: ACTIVITY_LOG_LENGTH,
    offset: 0,
    loading: true,
    expanded: false,
    error: null,
    scrollToUp: false,
  };

  // console.log("supplierActivity", id);

  const [state, setState] = useState(initialState);

  const {
    limit,
    offset,
    expanded,
    total,
    activities,
    scrollToUp,
    loading,
    // error
  } = state;

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await authAxios.get(
          `logs/activity-logs/supplier/${id}?limit=${limit}&offset=${offset}`
        );

        const activities = data.data || [];
        const total = data.meta.total || 0;
        setState({
          ...state,
          activities,
          total,
          loading: false,
        });
      } catch (onError) {
        console.log(onError);
        setState({
          ...state,
          loading: false,
          error: 'Something went wrong',
        });
      }
    };

    getData();
  }, [authAxios, id, limit, offset, activityNumber]);

  function showHideComments() {
    if (expanded) {
      setState({
        ...state,
        expanded: false,
        limit: ACTIVITY_LOG_LENGTH,
        scrollToUp: true,
      });
    } else {
      setState({
        ...state,
        expanded: true,
        limit: total,
      });
    }
  }

  function getActivityDetail(activity) {
    const { actionType } = activity;
    const date = moment(activity.createdAt).format('DD MMM y,  HH:mm');
    const { author } = activity;
    if (actionType === 'edited_payment_details') {
      return `${getAuthorName(author)} (${author.type}), ${date}`;
    }
    return `${getAuthorName(author)} (${author.type} team), ${date}`;
  }

  function getActivityTypeName(activity) {
    const { actionType } = activity;
    if (actionType === 'created') return 'requested';
    if (actionType === 'edited_payment_details') return 'edited payment details';
    if (actionType === 'approved_payment_details') return 'approved payment details';
    return actionType;
  }

  function getActivityTitle(activity) {
    const { actionType } = activity;
    if (actionType === 'edited_payment_details') {
      return `Payment data changes (${activity.supplier.currency.code} - ${activity.supplier.currency.name}) requested`;
    }
    if (actionType === 'approved_payment_details') {
      return `Payment data changes (${activity.supplier.currency.code} - ${activity.supplier.currency.name}) approved`;
    }
    if (actionType === 'set_supplier_as_active') {
      return `Supplier (${activity.supplier.currency.code} - ${activity.supplier.currency.name}) is activated`;
    }
    if (actionType === 'set_supplier_as_inactive') {
      return `Supplier (${activity.supplier.currency.code} - ${activity.supplier.currency.name}) is deactivated`;
    }
    return `Supplier code (${activity.supplier.currency.code} - ${
      activity.supplier.currency.name
    }) ${getActivityTypeName(activity)} `;
  }

  function renderDotsAndLines(activities) {
    const count = activities.length;
    return map(activities, (activity, index) => {
      return (
        <div key={activity.id}>
          {index === 0 && <Box borderRadius="50%" bg="#c4c9cc" h="13px" w="13px" />}
          {index !== 0 && index < count && (
            <>
              <Box h="50px" w="1px" bg="#c4c9cc" ml="5px" />
              <Box borderRadius="50%" bg="#c4c9cc" h="13px" w="13px" />
            </>
          )}
        </div>
      );
    });
  }

  function renderActivities(activities) {
    const count = activities.length;
    return map(activities, (activity, index) => {
      return (
        <div key={activity.id}>
          <Box>
            {index === 0 && (
              <Box>
                <Box fontSize="14px" fontWeight="700" color="#333">
                  {getActivityTitle(activity)}
                </Box>
                <Box color="#868789" fontSize="12px" lineHeight="1">
                  {getActivityDetail(activity)}
                </Box>
              </Box>
            )}
            {index !== 0 && index < count && (
              <Box mt="30px">
                <Box fontSize="14px" fontWeight="700" color="#333">
                  {getActivityTitle(activity)}
                </Box>
                <Box color="#868789" fontSize="12px" lineHeight="1">
                  {getActivityDetail(activity)}
                </Box>
              </Box>
            )}
          </Box>
        </div>
      );
    });
  }

  if (!loading && activities.length > 0) {
    return (
      <Box pb="30px">
        {scrollToUp && <ScrollToTop />}
        <HStack align="flex-start">
          {/* <Box>{renderDotsAndLines(activities)}</Box> */}
          <Box mt="1">{renderDotsAndLines(activities)}</Box>
          <Box
            display="flex"
            flexDirection=" column"
            justifyContent="space-between"
            // h=" 96px"
          >
            {renderActivities(activities)}
          </Box>
        </HStack>
        {total > ACTIVITY_LOG_LENGTH && (
          <Button
            onClick={showHideComments}
            variant="ghost"
            size="xs"
            fontSize="12px"
            mt="15px"
            ml="10px"
          >
            Show {!expanded ? 'full' : 'less of '} activity history
          </Button>
        )}
      </Box>
    );
  }

  return null;
}

export default SupplierActivity;
