import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Box,
  Link,
  useToast,
  Spinner,
  Button,
  HStack,
  Text,
} from '@chakra-ui/react';
import { ChevronLeftIcon, WarningIcon, CheckIcon } from '@chakra-ui/icons';
import { HttpContext } from '../context/HttpContext';
import InvoiceView from '../components/invoice/InvoiceView';
import { AuthContext } from '../context/AuthContext';
import { getMessage, getMessageStatus } from '../util';
import ScrollToTop from '../util/scrollToTop';
import RequestInvoiceChangeButton from '../components/invoice/RequestChangesInvoiceButton';
import InvoiceReject from '../components/invoice/InvoiceReject';

export default function Invoice() {
  const { id } = useParams();
  const toast = useToast();
  const history = useHistory();
  const [state, setState] = useState({
    invoice: null,
    updatedOrder: null,
    orderNumber: null,
    activityLog: null,
    loading: true,
    error: null,
    pending: false,
  });
  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  const { invoice, orderNumber, pending, activityLog, updatedOrder, loading } = state;

  useEffect(() => {
    const getData = async () => {
      try {
        setState({
          ...state,
          pending: true,
        });

        const { data } = await authAxios.get(`/invoices/${id}`);
        setState({
          ...state,
          invoice: data,
          loading: false,
          pending: false,
        });
      } catch (onError) {
        console.log(onError);
        setState({
          ...state,
          error: 'Something went wrong',
          loading: false,
          pending: false,
        });
        const message = getMessage(onError);
        const status = getMessageStatus(onError);
        if (status === 403) {
          toast({
            position: 'top-left',
            description: 'You are not authorized to view this page',
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/orders');
          }, 3000);
        }
        if (message) {
          toast({
            position: 'top',
            description: message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
          setTimeout(function () {
            history.push('/orders');
          }, 3000);
        }
      }
    };

    getData();
  }, [authAxios, id, orderNumber, activityLog, updatedOrder]);

  async function handleApproving(e) {
    e.preventDefault();

    try {
      setState({
        ...state,
        pending: true,
      });

      const { data } = await authAxios.post(`invoices/${invoice.id}/approve`);
      setState({
        ...state,
        invoice: data,
        pending: false,
      });
      toast({
        position: 'top',
        description: 'Invoice approved.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
      setState({
        ...state,
        error: message,
        pending: false,
      });
    }
  }

  const fromSupplier = invoice?.purchaseOrder.supplier;
  const fromSupplierPaymentDetailsApproved = fromSupplier?.paymentDetailsApproved ?? true;

  return (
    <Container w="100%" maxWidth="100%" pt={4} pr={10} pl={10} pb={14}>
      <>
        <ScrollToTop />
        <Link
          color="brand.500"
          as={RouterLink}
          to={`/orders/${invoice?.purchaseOrder.id}`}
          fontSize="14px"
        >
          <ChevronLeftIcon /> Purchase Order Details
        </Link>
        {/*<Box>*/}
        {/*  <Heading*/}
        {/*    as="h2"*/}
        {/*    size="lg"*/}
        {/*    textAlign="left"*/}
        {/*    pt={4}*/}
        {/*    mb={1}*/}
        {/*    d="inline-block"*/}
        {/*  >*/}
        {/*    Invoice details*/}
        {/*    {canEditInvoice && <Link*/}
        {/*      color="brand.500"*/}
        {/*      as={RouterLink}*/}
        {/*      to={`/edit-invoice/${invoice?.id}`}*/}
        {/*      fontSize="14px"*/}
        {/*      pl="10px"*/}
        {/*    >*/}
        {/*      Edit*/}
        {/*    </Link>}*/}
        {/*  </Heading>*/}
        {/*</Box>*/}
        {!fromSupplierPaymentDetailsApproved && auth.isFinance() && (
          <Box
            bg="#f7c7ad"
            border="1px solid #ff5a00"
            p="20px"
            d="flex"
            flexDirection="row"
            mb="25px"
            mt="20px"
          >
            <Box>
              <WarningIcon color="brand.400" w="16px" mr="5px" />
            </Box>
            <Box ml="5px">
              <Box>
                {`The supplier (${invoice?.fromName}, ${fromSupplier?.code}) has requested the payment
              details changes for ${fromSupplier?.currency?.code} - ${fromSupplier?.currency?.name} account. Please update
              the supplier records before approving the invoice.`}{' '}
                <Link
                  color="#333"
                  as={RouterLink}
                  to={`/suppliers/${fromSupplier?.user.id}/${fromSupplier?.id}`}
                  fontSize="16px"
                  mb="6px"
                  // textDecoration="underline"
                  variant="underline"
                  textDecoration="underline"
                >
                  Review the supplier payment data
                </Link>
              </Box>
            </Box>
          </Box>
        )}
        {invoice &&
        invoice.purchaseOrder.status === 'invoice_pending_approval' &&
        auth.isFinance() ? (
          <>
            <Text mb="15px" mt="15px">
              Please review the invoice and respond or request changes from the supplier.
            </Text>
            <HStack>
              <Button
                type="submit"
                colorScheme="brand"
                size="sm"
                pt="10px"
                pb="10px"
                fontSize="14px"
                fontWeight="500"
                _hover={{ bg: '#000' }}
                onClick={handleApproving}
                disabled={
                  pending ||
                  // !fromSupplierPaymentDetailsApproved ||
                  !auth.isFinance()
                }
              >
                <CheckIcon mr="10px" />
                Approve
              </Button>
              <RequestInvoiceChangeButton invoice={invoice} />
              <InvoiceReject invoice={invoice} />
            </HStack>
          </>
        ) : null}
      </>
      {loading ? (
        <Box p={12} d="flex" alignItems="center" justifyContent="center" h="400px">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="brand.500"
            size="xl"
          />
        </Box>
      ) : invoice ? (
        <InvoiceView
          invoice={invoice}
          isFinance={auth.isFinance()}
          isSupplier={auth.isSupplier()}
        />
      ) : null}
    </Container>
  );
}
