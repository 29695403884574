import React, { createContext, useState } from 'react';
import first from 'lodash/first';

const CountriesContext = createContext();
const { Provider } = CountriesContext;

const CountriesProvider = ({ children }) => {
  const [state, setState] = useState({
    items: [],
    loading: true,
    status: 'idle',
    error: null,
  });

  const setCountriesData = ({ items, loading, error, status }) => {
    setState({ items, loading, error, status });
  };

  const getFirstCountry = () => {
    const { items } = state;
    return first(items);
  };

  const getFirstCountryId = () => {
    const { items } = state;
    if (items.length > 0) {
      return first(items).id;
    }

    return 0;
  };

  const gatAllCountries = () => {
    const { items } = state;
    return items;
  };

  const gatAllCountriesForSelect = () => {
    const { items } = state;
    return [
      {
        code: null, //"N/A",
        continentCode: null,
        countryId: 0,
        fullName: 'Please select county',
        name: 'Select country',
      },
      ...items,
    ];
  };

  const getCountyIdForUS = () => {
    const { items } = state;
    if (items.length > 0) {
      let recordIndex = items.findIndex((x) => x.code === 'US');
      if (recordIndex > -1) {
        return items[recordIndex].countryId;
      }
    }

    return null;
  };

  const getCountryById = (id) => {
    const { items } = state;
    for (let i = 0; i < items.length; i++) {
      if (items[i].countryId === id) {
        return items[i];
      }
    }

    return null;
  };

  return (
    <Provider
      value={{
        state,
        setCountriesState: (data) => setCountriesData(data),
        getFirstCountry,
        getFirstCountryId,
        gatAllCountries,
        gatAllCountriesForSelect,
        getCountyIdForUS,
        getCountryById,
      }}
    >
      {children}
    </Provider>
  );
};

export { CountriesContext, CountriesProvider };
