import React, { useContext } from 'react';
import map from 'lodash/map';
import moment from 'moment';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Button,
} from '@chakra-ui/react';
import { formatAmount } from '../../util';
import logo from '../../assets/images/Goat-logo-emails.png';
import { hasInvoiceBankAccount, hasInvoicePayPal } from '../../util/invoice/helper';
import { AuthContext } from '../../context/AuthContext';
import { Link as RouterLink } from 'react-router-dom';
import ReactToPdf from 'react-to-pdf';
import { DownloadIcon } from '@chakra-ui/icons';
import { getDueDate } from '../../util';

export default function InvoicePreview({ invoice, forPdf }) {
  const currencySymbol = invoice?.purchaseOrder.supplier.currency.symbol;

  const auth = useContext(AuthContext);
  const canEditInvoice =
    (invoice?.purchaseOrder.status === 'invoice_pending_approval' ||
      invoice?.purchaseOrder.status === 'invoice_pending_repair') &&
    auth.isSupplier();

  const ref = React.createRef();
  const options = {
    orientation: 'p',
    unit: 'mm',
  };

  return (
    <>
      {forPdf && (
        <Box d="flex" alignItems="right" justifyContent="right">
          <ReactToPdf
            targetRef={ref}
            filename="invoice.pdf"
            options={options}
            x={0}
            y={0}
            scale={0.8}
          >
            {({ toPdf }) => (
              <Button size="sm" fontSize="14px" colorScheme="brand" onClick={toPdf}>
                <DownloadIcon mr="5px" /> Download invoice
              </Button>
            )}
          </ReactToPdf>
        </Box>
      )}
      <Box
        bg="#fff"
        borderRadius="md"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
        mt="7"
        p="10"
        pt="8"
        ref={forPdf ? ref : null}
        className={forPdf ? 'PdfContent' : ' '}
      >
        <HStack justifyContent="space-between" alignItems="center" pb="10px">
          <Box d="flex" alignItems="center">
            <img style={{ height: '76px', marginRight: '20px' }} src={logo} alt="" />

            <Box>
              <Text fontSize="40px" fontWeight="bold" mb="0">
                Invoice
                {canEditInvoice && !forPdf && (
                  <Link
                    color="brand.500"
                    as={RouterLink}
                    to={`/edit-invoice/${invoice?.id}`}
                    fontSize="14px"
                    pl="10px"
                  >
                    Edit
                  </Link>
                )}
              </Text>
              {/*<Text color=" #868789" fontSize="14px">*/}
              {/*  Invoice number{" "}*/}
              {/*  <span style={{ color: "#333" }}>{invoice?.number}</span>*/}
              {/*</Text>*/}
            </Box>
          </Box>
          {invoice.referenceNumber && (
            <Box d="flex" alignItems="center">
              <Text color=" #868789" fontSize="14px">
                Reference number
                <br />
                <span style={{ color: '#333' }}>{invoice.referenceNumber}</span>
              </Text>
            </Box>
          )}
        </HStack>
        <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="30px" />
        <HStack spacing={10} mb={forPdf ? 3 : 14} alignItems="flex-start">
          <Box width="550px">
            <Text fontSize="20px" mb="10px" fontWeight="bold">
              To
            </Text>
            <HStack alignItems="flex-start">
              <Box fontSize="14px">
                <Text mb="5px">{invoice.invoiceTo.name}</Text>
                <Text mb="5px">{invoice.invoiceTo.address}</Text>
                <Text mb="5px">VAT no: {invoice.invoiceTo.vat}</Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <Text fontSize="20px" mb="10px" fontWeight="bold">
              From
            </Text>
            <HStack alignItems="flex-start">
              <Box fontSize="14px" mr="35px">
                <Text mb="5px">{invoice.fromName}</Text>
                <Text mb="5px">{invoice.fromAddress}</Text>
                <Text mb="5px">
                  VAT no:{' '}
                  {invoice?.fromVat ? (
                    <Text as="span">{invoice?.fromVat}</Text>
                  ) : (
                    <Text as="span" color="#868789">
                      No VAT registered
                    </Text>
                  )}
                </Text>
              </Box>
            </HStack>
          </Box>
        </HStack>

        <HStack spacing={10} mb={forPdf ? 5 : 14} alignItems="flex-start">
          <Box width="550px">
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Description of work
                </Text>
                <Text color="#333" fontSize="14px" maxWidth="100%">
                  {invoice.purchaseOrder.descriptionOfWork}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box flexShrink="0">
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Invoice date
                </Text>
                <Text color="#868789" fontSize="14px" maxWidth="100%">
                  {moment(invoice.date).format('DD MMM y')}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box flexShrink="0">
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Due date
                </Text>
                <Text color="#868789" fontSize="14px" maxWidth="100%">
                  {getDueDate(invoice)}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack alignItems="flex-start">
              <Box mt="30px">
                <Text fontSize="14px" fontWeight="700" mb="0px" color="#333">
                  Purchase order number
                </Text>
                <Text color="#868789" fontSize="14px" maxWidth="100%">
                  {invoice.purchaseOrder.number}
                </Text>
              </Box>
            </HStack>
          </Box>
        </HStack>

        <Box>
          <Table variant="simple" className="PdfTable">
            <Thead>
              <Tr>
                <Th minWidth="500px" pl="0" pb="0" pr="0">
                  <Box color="#bdc1d0" fontSize="12px" fontWeight="700">
                    item title
                  </Box>
                </Th>
                <Th pl="0" pb="0">
                  <Box color="#bdc1d0" pb="0" fontSize="12px" fontWeight="700">
                    amount(net)
                  </Box>
                </Th>
                <Th pb="0" pl="0">
                  <Box
                    color="#bdc1d0"
                    pb="0"
                    pl="0"
                    fontSize="12px"
                    fontWeight="700"
                    lineHeight="1"
                    textAlign="left"
                  >
                    vat
                  </Box>
                </Th>
                <Th pl="10px" pb="0" pr="0">
                  <Box
                    color="#bdc1d0"
                    pb="0"
                    fontSize="12px"
                    fontWeight="700"
                    textAlign="left"
                  >
                    amount(gross)
                  </Box>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {invoice.purchaseOrder.purchaseOrderItems.length &&
                map(invoice.purchaseOrder.purchaseOrderItems, (purchase) => {
                  return (
                    <Tr>
                      <Td pl="0" p="10px">
                        {purchase.title}
                      </Td>
                      <Td p="10px" textAlign="left" pl="0">
                        <Box>
                          {currencySymbol}
                          {formatAmount(purchase.amount)}
                        </Box>
                      </Td>
                      <Td isNumeric p="10px" textAlign="left" pl="0">
                        <Box>
                          {purchase.vatAmount ? (
                            <>
                              {' '}
                              {currencySymbol}
                              {formatAmount(purchase.vatAmount)}
                            </>
                          ) : (
                            <>{`${currencySymbol}0`}</>
                          )}
                        </Box>
                      </Td>
                      <Td isNumeric p="10px" textAlign="left">
                        <Box>
                          {' '}
                          {currencySymbol}
                          {formatAmount(purchase.grossAmount)}
                        </Box>
                      </Td>
                    </Tr>
                  );
                })}
              <Tr>
                <Td pl="0" p="10px" />
                <Td pl="0" p="10px" />
                <Td p="10px" isNumeric>
                  <Box color="#868789">Sub-total (net) </Box>
                  <Box color="#868789">VAT sub-total</Box>
                  <Box color="#868789" mt="20px">
                    TOTAL (gross){' '}
                  </Box>
                </Td>
                <Td isNumeric p="10px">
                  {' '}
                  <Box textAlign="right">
                    {currencySymbol}
                    {formatAmount(invoice.purchaseOrder.totalNet)}
                  </Box>
                  <Box textAlign="right">
                    {currencySymbol}
                    {formatAmount(invoice.purchaseOrder.totalVat)}
                  </Box>
                  <Box
                    fontSize={forPdf ? '14px' : '20px'}
                    fontWeight={forPdf ? ' ' : 'bold'}
                    textAlign="right"
                    mt="20px"
                  >
                    {currencySymbol}
                    {formatAmount(invoice.purchaseOrder.totalGross)}
                  </Box>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        {/* <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="30px" /> */}
        <Box mt="30px">
          <Text fontSize="20px" mb="10px" fontWeight="bold">
            Payment details:
          </Text>
          <HStack alignItems="flex-start">
            {invoice && hasInvoiceBankAccount(invoice) && (
              <>
                <Box color="#868789" fontSize="14px" mr="35px">
                  <Text mb="5px">Payment method:</Text>
                  <Text mb="5px">Bank name:</Text>
                  {invoice.bankAddress && <Text mb="5px">Bank address:</Text>}
                  <Text mb="5px">Country:</Text>
                  {invoice.accountName && <Text mb="5px">Account name:</Text>}
                  {invoice.accountNumber && <Text mb="5px">Account number:</Text>}
                  {invoice.accountNumberOrIban && (
                    <Text mb="5px">Account number or IBAN:</Text>
                  )}
                  {invoice.bankCode && <Text mb="5px">Bank code:</Text>}
                  {invoice.bsbCode && <Text mb="5px">BSB code:</Text>}
                  {invoice.iban && <Text mb="5px">IBAN:</Text>}
                  {invoice.ifscCode && <Text mb="5px">IFSC code:</Text>}
                  {invoice.routingNumber && <Text mb="5px">Routing number:</Text>}
                  {invoice.sortCode && <Text mb="5px">Sort code:</Text>}
                  {invoice.swift && <Text mb="5px">SWIFT code:</Text>}
                  {invoice.additionalReference && (
                    <Text mb="5px">Additional reference:</Text>
                  )}
                </Box>
                <Box alignItems="flex-start" fontSize="14px">
                  <Text mb="5px">Bank transfer</Text>
                  <Text mb="5px">{invoice.bankName}</Text>
                  {invoice.bankAddress && <Text mb="5px">{invoice.bankAddress}</Text>}
                  <Text mb="5px">{invoice.country?.name}</Text>
                  {invoice.accountName && <Text mb="5px">{invoice.accountName}</Text>}
                  {invoice.accountNumber && <Text mb="5px">{invoice.accountNumber}</Text>}
                  {invoice.accountNumberOrIban && (
                    <Text mb="5px">{invoice.accountNumberOrIban}</Text>
                  )}
                  {invoice.bankCode && <Text mb="5px">{invoice.bankCode}</Text>}
                  {invoice.bsbCode && <Text mb="5px">{invoice.bsbCode}</Text>}
                  {invoice.iban && <Text mb="5px">{invoice.iban}</Text>}
                  {invoice.ifscCode && <Text mb="5px">{invoice.ifscCode}</Text>}
                  {invoice.routingNumber && <Text mb="5px">{invoice.routingNumber}</Text>}
                  {invoice.sortCode && <Text mb="5px">{invoice.sortCode}</Text>}
                  {invoice.swift && <Text mb="5px">{invoice.swift}</Text>}
                  {invoice.additionalReference && (
                    <Text mb="5px">{invoice.additionalReference}</Text>
                  )}
                </Box>
              </>
            )}
            {invoice && hasInvoicePayPal(invoice) && (
              <>
                <Box color="#868789" fontSize="14px" mr="35px">
                  <Text mb="5px">Payment method:</Text>
                  <Text mb="5px">Email address:</Text>
                </Box>
                <Box alignItems="flex-start" fontSize="14px">
                  <Text mb="5px">PayPal</Text>
                  <Text mb="5px">{invoice.payPalAccount}</Text>
                </Box>
              </>
            )}
            {invoice && !hasInvoiceBankAccount(invoice) && !hasInvoicePayPal(invoice) && (
              <>
                <Text color="#868789">No information yet.</Text>
              </>
            )}
          </HStack>
        </Box>
      </Box>
    </>
  );
}
