import React from 'react';
import UseCurrency from '../../custom-hooks/useCurrency';
import CampaignContextProvider from '../../context/campaign/FilterCampaigns';
import { CurrencyProvider } from '../../context/currency/CurrencyContext';
import InfluencerPortalCampaigns from '../../pages/influencerPortalCampaign';
import { PodLeadersProvider } from '../../context/podLeader/PodleadersContext';
import UsePodLeaders from '../../custom-hooks/usePodLeaders';

function CampaignPage() {
  return (
    <CampaignContextProvider>
      <CurrencyProvider>
        <UseCurrency>
          <PodLeadersProvider>
            <UsePodLeaders>
              <InfluencerPortalCampaigns />
            </UsePodLeaders>
          </PodLeadersProvider>
        </UseCurrency>
      </CurrencyProvider>
    </CampaignContextProvider>
  );
}

export default CampaignPage;
