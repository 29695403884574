import React from 'react';
// import UseCurrency from '../../custom-hooks/useCurrency';
import FilterSupplierContextProvider from '../../context/FilterSuppliers';
import { CurrencyProvider } from '../../context/currency/CurrencyContext';
import InfluencerPortalSuppliers from '../InfluencerPortalSuppliers';
import { SupplierProvider } from '../../context/supplier/SupplierContext';

function SuppliersPage() {
  return (
    <FilterSupplierContextProvider>
      <SupplierProvider>
        <CurrencyProvider>
          {/*<UseCurrency>*/}
          <InfluencerPortalSuppliers />
          {/*</UseCurrency>*/}
        </CurrencyProvider>
      </SupplierProvider>
    </FilterSupplierContextProvider>
  );
}

export default SuppliersPage;
