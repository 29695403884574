import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import { CountriesContext } from '../context/country/CountriesContext';

function UseCountries({ children }) {
  const { authAxios } = useContext(HttpContext);
  const countryContext = useContext(CountriesContext);

  useEffect(() => {
    const getData = async () => {
      try {
        countryContext.setCountriesState({
          items: [],
          loading: true,
          error: null,
          status: 'pending',
        });
        const { data } = await authAxios.get(`taxonomies/countries`);
        countryContext.setCountriesState({
          items: data,
          loading: false,
          error: null,
          status: 'resolved',
        });
      } catch (onError) {
        console.log(onError);
        const error = (onError.response && onError.response.data) || null;
        countryContext.setCountriesState({
          items: [],
          loading: false,
          error,
          status: 'rejected',
        });
      }
    };

    getData();
  }, [authAxios]);

  return <>{children}</>;
}

export default UseCountries;
