import React, { useState } from 'react';
import {
  // FormErrorMessage,
  FormControl,
  Input,
  Button,
  Box,
  Grid,
  Checkbox,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { SmallAddIcon, CloseIcon } from '@chakra-ui/icons';

function PriceInputs({ updatePrices, updatePricesErrors, updatePriceTouched }) {
  const [inputList, setInputList] = useState([{ amount: '', title: '', vat: true }]);
  const [values, setValues] = useState([]);
  const [errors, setErrors] = useState([]);

  const validate = (fieldValues = values, index) => {
    const list = [...errors];
    if (!(index in list)) {
      list[index] = {};
    }

    // const regExp1 = /[0-9]|\./;
    // const regExp2 = /^[a-zA-Z.,;:|\\\/~!@#$%^&*_-{}\[\]()`"'<>?\s]+$/;
    // const regExp3 = /^\s*|[0-9][0-9]*/;
    // const regExp4 = /^\d+\.\d{0,2}$/;
    // const regExp5 = /^(\s*|\d+)$/; //positive numbers and allow empty string
    // https://forums.asp.net/t/1909870.aspx?Regular+expression+to+allow+a+number+upto+2+decimal+places
    const regExp6 = /^\d+(\.\d{1,2})?$/; //positive numbers and allow empty string

    if ('title' in fieldValues) {
      if (fieldValues.title === '') {
        list[index].title = 'This is mandatory field';
      } else if (fieldValues.title.length > 255) {
        list[index].title = 'Please enter 250 characters or less';
      } else {
        list[index].title = '';
      }
    }
    if ('amount' in fieldValues)
      list[index].amount = fieldValues.amount ? '' : 'This is mandatory field';
    if ('amount' in fieldValues) {
      if (fieldValues.amount === '') {
        list[index].amount = 'This is mandatory field';
      } else if (!regExp6.test(fieldValues.amount)) {
        list[index].amount =
          'Only numerical input is allowed, if decimal number is needed please use . to separate numbers (e.g. 3.14).';
      } else if (fieldValues.amount === '0') {
        list[index].amount = 'Only input larger than 0 is allowed.';
      } else {
        list[index].amount = '';
      }
    }

    // if ("amount" in fieldValues)
    //   list[index].amount = fieldValues.amount ? "" : "This is mandatory field";

    // if ('mobile' in fieldValues)
    //   temp.mobile = fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required."
    // if ('voiceOverId' in fieldValues)
    //   temp.voiceOverId = fieldValues.voiceOverId.length != 0 ? "" : "This field is required."
    setErrors(list);
    updatePricesErrors(list);

    // if (fieldValues === values)
    //   return Object.values(temp).every((x) => x.toString() === "");
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    updatePrices(list);
    validate({ [name]: value }, index);
    // updatePriceTouched(true);
  };

  const handleCheckboxChange = (e, index) => {
    const list = [...inputList];
    list[index]['vat'] = e.target.checked;
    setInputList(list);
    updatePrices(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    const errList = [...errors];
    errList.splice(index, 1);

    setInputList(list);
    setErrors(errList);
    updatePrices(list);
    updatePricesErrors(errList);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { title: '', amount: '', vat: true }]);
    updatePrices([...inputList, { title: '', amount: '', vat: true }]);
  };

  return (
    <>
      {inputList.map((x, i) => {
        return (
          <Box mb={4}>
            <Box border="1px solid #ebecf0" borderRadius="6px" p={5} position="relative">
              {inputList.length !== 1 && (
                <Box position="absolute" top="-10px" right="-10px">
                  <IconButton
                    colorScheme="brand"
                    size="xs"
                    onClick={() => handleRemoveClick(i)}
                    aria-label="price-input-brand-create"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
              <Grid container templateColumns="repeat(2, 1fr)" gap={6}>
                <Grid item sm={6}>
                  <FormControl mb={0}>
                    <Input
                      isInvalid
                      name="title"
                      id="itemTitle"
                      size="lg"
                      border="1px solid  transparent"
                      bg="#ebecf0"
                      _focus={{ border: '1px solid black' }}
                      _hover={{ border: '1px solid black' }}
                      //   {...register("itemTitle")}
                      placeholder="Enter item title"
                      value={x.title}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                    {errors[i]?.title?.message || errors[i]?.title ? (
                      <Box color="error.500" fontSize="13px">
                        {errors[i].title}
                      </Box>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl mb={0}>
                    <Input
                      isInvalid
                      name="amount"
                      id="amount"
                      size="lg"
                      border="1px solid  transparent"
                      bg="#ebecf0"
                      _focus={{ border: '1px solid black' }}
                      _hover={{ border: '1px solid black' }}
                      //   {...register("itemTitle")}
                      placeholder="Enter amount"
                      value={x.amount}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                    {errors[i]?.amount?.message || errors[i]?.amount ? (
                      <Box color="error.500" fontSize="13px">
                        {errors[i].amount}
                      </Box>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
              <Checkbox
                colorScheme="brand"
                _focus={{ border: '1px solid black' }}
                defaultIsChecked
                mt={4}
                onChange={(e) => handleCheckboxChange(e, i)}
              >
                <Text fontSize="16px" lineHeight="1">
                  Add VAT
                </Text>
              </Checkbox>
            </Box>
            <div>
              {inputList.length - 1 === i && (
                <Button
                  mt={4}
                  colorScheme="brand"
                  variant="ghost"
                  size="xs"
                  p="0px"
                  onClick={handleAddClick}
                  fontSize="14px"
                >
                  <SmallAddIcon h={22} w={7} />
                  Add item
                </Button>
              )}
            </div>
          </Box>
        );
      })}
    </>
  );
}

export default PriceInputs;
