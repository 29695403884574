import React, { useContext } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import RejectIcon from '../../assets/icons/reject_icon.svg';
import { useForm } from 'react-hook-form';
import { getMessage } from '../../util';
import { HttpContext } from '../../context/HttpContext';
import { useHistory } from 'react-router-dom';

export default function InvoiceReject({ invoice }) {
  const { authAxios } = useContext(HttpContext);
  const history = useHistory();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const onSubmit = async () => {
    try {
      const res = await authAxios.post(`invoices/${invoice.id}/reject`);
      toast({
        position: 'top',
        description: 'You have rejected the invoice.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      history.push(`/orders/${invoice.purchaseOrder.id}`);
    } catch (onError) {
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Button size="sm" fontWeight="500" fontSize="14px" color="#d41d29" onClick={onOpen}>
        <img
          style={{ height: '12px', marginRight: '5px' }}
          src={RejectIcon}
          alt="reject"
        />
        Reject
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pt="50px" textAlign="center" fontSize="22px">
            Reject this invoice?
          </ModalHeader>
          <ModalCloseButton color="#d1d2d2" _focus={{ outline: '0' }} size="sm" />
          <ModalBody pt="10px" pr="50px" pl="50px" color="#868789">
            The payment will not be made and the supplier will not be able to create
            another invoice related to this purchase order. If it just needs changes, you
            can request them from the invoice page.
          </ModalBody>

          <ModalFooter
            d="flex"
            alignItems="center"
            justifyContent="center"
            pt="30px"
            pb="40px"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Button colorScheme="brand" mr={3} type="submit" disabled={isSubmitting}>
                Yes, reject
              </Button>
            </form>
            <Button
              variant="outline"
              color="brand.500"
              pt="15px"
              pb="15px"
              onClick={onClose}
              disabled={isSubmitting}
            >
              No, go back
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
