import React, { useContext, useState, forwardRef } from 'react';
import moment from 'moment';
import Helmet from 'react-helmet';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { DateIcon } from '../../assets/iconsComponents/dateIcon';
import { Input, Box, InputGroup, InputLeftElement, HStack } from '@chakra-ui/react';
import { CampaignContext } from '../../context/campaign/FilterCampaigns';

const DatePicker = forwardRef(({ updateStartDate, updateEndDate }, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const ToRef = React.createRef();
  const { from, setFrom } = useContext(CampaignContext);
  const { to, setTo } = useContext(CampaignContext);

  const showFromMonth = () => {
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      to.getDayPicker().showMonth(from);
    }
  };

  const handleFromChange = (from) => {
    // Change the from date and focus the "to" input field
    setFrom(from);
    updateStartDate(from);
  };

  // const handleResetClick = () => {
  //   setFrom(undefined);
  //   setTo(undefined);
  // };

  const handleToChange = (to) => {
    setTo(to, showFromMonth);
    updateEndDate(to, showFromMonth);
  };

  const modifiers = { start: from, end: to };

  return (
    <div className="InputFromTo">
      <HStack spacing="40px" mb="35px">
        <Box width="50%">
          <Box fontSize="16px" fontWeight="700" mb="10px">
            Start date
          </Box>
          <DayPickerInput
            style={{ width: '100%' }}
            component={(props) => (
              <InputGroup isFullWidth {...props}>
                {!from && (
                  <InputLeftElement
                    pointerEvents="none"
                    children={<DateIcon color="#a9b1b4" />}
                  />
                )}
                <Input
                  value={from && moment(from).format('DD MMM YYYY')}
                  placeholder=""
                  fontSize="16px"
                  isFullWidth
                  //{...props}
                />
              </InputGroup>
            )}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { after: to },
              toMonth: to,
              modifiers,
              numberOfMonths: 1,
              onDayClick: () => {
                ToRef.current.focus();
                setIsFocused(true);
              },
            }}
            onDayChange={handleFromChange}
          />{' '}
        </Box>
        <Box width="50%">
          <span className="InputFromTo-to">
            <Box fontSize="16px" fontWeight="700" mb="10px">
              End date
            </Box>
            <DayPickerInput
              style={{ width: '100%' }}
              component={(props) => (
                <InputGroup
                  keepFocus
                  isFullWidth
                  // ref={(el) => (this.to = el)}
                  {...props}
                >
                  {!to && (
                    <InputLeftElement
                      pointerEvents="none"
                      children={<DateIcon color="#a9b1b4" />}
                    />
                  )}
                  <Input
                    ref={ToRef}
                    value={to && moment(to).format('DD MMM YYYY')}
                    placeholder=""
                    //onFocus={isFocused}
                    fontSize="16px"
                    isFullWidth
                    boxShadow={isFocused ? `0px 0px 1px 1px black}` : 'none'}
                  />
                </InputGroup>
              )}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: { before: from },
                modifiers,
                month: from,
                fromMonth: from,
                numberOfMonths: 1,
              }}
              onDayChange={handleToChange}
            />
          </span>
        </Box>
      </HStack>
      {/*{from && to && (*/}
      {/*  <button className="link" onClick={handleResetClick}>*/}
      {/*    Reset*/}
      {/*  </button>*/}
      {/*)}*/}
      <Helmet>
        <style>{`
  .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #eb7f24;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #eb7f24;
}
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #eb7f24;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 270px;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -198px;
  }
`}</style>
      </Helmet>
    </div>
  );
});
export default DatePicker;
