import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import PaymentDetailsDrawer from '../../payments/PaymentDetailsDrawer';

export default function AddPaymentDetails({ edit, supplier, updateSupplier }) {
  const [isChecked, setIsChecked] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleClose() {
    setIsChecked(false);
    onClose();
  }

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  return (
    <>
      {edit ? (
        <Button
          type="submit"
          variant="ghost"
          colorScheme="brand"
          size="sm"
          onClick={onOpen}
          pt="10px"
          pb="10px"
          fontSize="14px"
        >
          Edit
        </Button>
      ) : (
        <Button
          type="submit"
          colorScheme="brand"
          size="sm"
          onClick={onOpen}
          pt="10px"
          pb="10px"
          fontSize="14px"
        >
          {edit ? 'Edit' : 'Add'} payment details
        </Button>
      )}
      <PaymentDetailsDrawer
        edit={edit}
        open={isOpen}
        onOpen={onOpen}
        onClose={handleClose}
        supplier={supplier}
        currency={supplier.currency}
        numberOfPendingInvoices={supplier?.numberOfPendingInvoices}
        numberOfPendingInvoices1={supplier.numberOfPendingInvoices}
        checked={isChecked}
        onChange={(e) => handleCheckboxChange(e)}
        updateSupplier={updateSupplier}
      />
    </>
  );
}
