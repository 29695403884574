import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import DrawerComponentCustom from '../../common/UIcomponents/CustomDrawer';
import CampaignEditForm from './CampaignEditForm';

export default function EditCampaignButton({ campaign, updateCampaign }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        type="submit"
        colorScheme="brand"
        size="xs"
        onClick={onOpen}
        pt="20px"
        pb="20px"
        variant="ghost"
        fontSize="14px"
      >
        Edit
      </Button>

      <DrawerComponentCustom
        title="Edit a Campaign"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        campaign
      >
        <CampaignEditForm
          onClose={onClose}
          updateCampaign={updateCampaign}
          campaign={campaign}
        />
      </DrawerComponentCustom>
    </>
  );
}
