import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  useToast,
  RadioGroup,
  Stack,
  Radio,
  Text,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HttpContext } from '../../context/HttpContext';
import { getErrors, getMessage } from '../../util';
import AutocompleteTeamInput from './AutocompleteInput';

const initialRemovedMemberState = {
  removedMember: null,
  removedMemberError: null,
  removedMemberTouched: false,
};

const TeamEditFormAsAdmin = ({ onClose, updateMember, member }) => {
  const [radioValue, setRadioValue] = React.useState(member.type);
  // const [initialInputValue, setInitialInputValue] = React.useState(null);
  // const [transferOwnership, setTransferOwnership] = React.useState(null);
  // const [ownershipMemberState, setOwnershipMemberState] = useState(
  //   initialOwnershipMemberState
  // );
  const [removedMemberState, setRemovedMemberState] = useState(initialRemovedMemberState);

  function updateRemovedMemberState({
    removedMember,
    removedMemberError,
    removedMemberTouched,
  }) {
    setRemovedMemberState({
      removedMember,
      removedMemberError,
      removedMemberTouched,
    });
  }
  // function updateOwnershipMemberState(dataState) {
  //   // console.log({ dataState })
  //   setOwnershipMemberState({
  //     ownershipMember: dataState.removedMember,
  //     ownershipMemberError: dataState.removedMemberError,
  //     ownershipMemberTouched: dataState.removedMemberTouched,
  //   });
  // }

  function handleClose() {
    onClose();
    setRemovedMemberState(initialRemovedMemberState);
    // setInitialInputValue(null);
  }

  function handleChangeRadio(value) {
    setRadioValue(value);
    setRemovedMemberState(initialRemovedMemberState);
    // setInitialInputValue(null);
  }

  console.log({ removedMemberState });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, 'Please enter 100 characters or less')
      .required('This is mandatory field'),
    // .min(4, 'First name must be at least 4 characters'),
    lastName: Yup.string()
      .max(100, 'Please enter 100 characters or less')
      .required('This is mandatory field'),
    // .min(4, 'Last name must be at least 4 characters'),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };
  const { register, handleSubmit, formState, setError, reset } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       if (member.type === 'pod_leader') {
  //         const { data } = await authAxios.get(`/users/me`);
  //         console.log(data);
  //         setTransferOwnership(data);
  //       }
  //     } catch (onError) {
  //       console.log(onError);
  //     }
  //   };
  //
  //   getData();
  // }, [authAxios]);

  let needTransfer = ['pod_leader', 'campaign'].includes(member.type);
  const showCampaign = ['pod_leader', 'campaign'].includes(member.type);
  const showPodLeader = ['pod_leader', 'campaign'].includes(member.type);
  const showFinanceUser = ['finance', 'finance_user'].includes(member.type);
  const showFinance = ['finance', 'finance_user'].includes(member.type);

  const onSubmit = async (values) => {
    try {
      let submittedData = {
        firstName: values.firstName,
        lastName: values.lastName,
      };

      if (member.type !== radioValue) {
        // if (member.type === 'campaign') {
        //   if (!['pod_leader'].includes(radioValue)) {
        //     toast({
        //       position: 'top-left',
        //       description: 'Campaign manager only can become a Pod Leader',
        //       status: 'error',
        //       duration: 6000,
        //       isClosable: true,
        //     });
        //
        //     return;
        //   }
        // }
        // if (member.type === 'pod_leader') {
        //   if (!['campaign', 'finance_user'].includes(radioValue)) {
        //     toast({
        //       position: 'top-left',
        //       description:
        //         'Pod Leader only can become a Finance User or Campaign Manager',
        //       status: 'error',
        //       duration: 6000,
        //       isClosable: true,
        //     });
        //
        //     return;
        //   }
        // }
        // if (member.type === 'finance_user') {
        //   if (!['finance', 'pod_leader'].includes(radioValue)) {
        //     toast({
        //       position: 'top-left',
        //       description: 'Finance User only can become a Finance Manager or Pod Leader',
        //       status: 'error',
        //       duration: 6000,
        //       isClosable: true,
        //     });
        //
        //     return;
        //   }
        // }
        // if (member.type === 'finance') {
        //   if (!['finance_user'].includes(radioValue)) {
        //     toast({
        //       position: 'top-left',
        //       description: 'Finance Manager only can become a Finance User',
        //       status: 'error',
        //       duration: 6000,
        //       isClosable: true,
        //     });
        //
        //     return;
        //   }
        // }

        submittedData = {
          ...submittedData,
          type: radioValue,
        };

        // if (member.type === 'pod_leader') {
        //   toast({
        //     position: 'top-left',
        //     description: 'Need More info',
        //     status: 'warning',
        //     duration: 6000,
        //     isClosable: true,
        //   });
        //
        //   return;
        // }
      }

      let url = `users/members/${member.id}`;
      if (removedMemberState.removedMember?.id && needTransfer) {
        url = url + `?userToTransferId=${removedMemberState.removedMember?.id}`;
      }
      const res = await authAxios.patch(url, submittedData);
      updateMember(res.data);
      onClose();
      reset();
    } catch (onError) {
      const errors = getErrors(onError);
      if (errors && errors.email) {
        const message = errors.email.errors[0];
        setError('email', { type: 'server', message });
      } else {
        const message = getMessage(onError);
        if (message) {
          toast({
            position: 'top-left',
            description: message,
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        }
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.firstName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">First Name</FormLabel>
          <Input
            isInvalid
            name="firstName"
            id="firstName"
            defaultValue={member.firstName}
            placeholder="Enter first name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('firstName')}
          />
          <FormErrorMessage>
            {errors.firstName?.message || errors.firstName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.lastName} mb={[4, 4, 8]}>
          <FormLabel htmlFor="emailHtmlFor">Last Name</FormLabel>
          <Input
            isInvalid
            name="lastName"
            id="lastName"
            defaultValue={member.lastName}
            placeholder="Enter last name"
            focusBorderColor="black"
            errorBorderColor="error.500"
            {...register('lastName')}
          />
          <FormErrorMessage>
            {errors.lastName?.message || errors.lastName}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.email} mb={[4, 4, 8]}>
          <FormLabel htmlFor="email">Email address</FormLabel>
          <Input
            isInvalid
            disabled
            color="#c3c1c1"
            name="email"
            id="email"
            focusBorderColor="black"
            errorBorderColor="error.400"
            defaultValue={member.email}
            {...register('email')}
          />
          <FormErrorMessage>{errors.email?.message || errors.email}</FormErrorMessage>
        </FormControl>

        <Box fontWeight="700" mb={5}>
          Assign Role
        </Box>
        <RadioGroup onChange={handleChangeRadio} value={radioValue}>
          <Stack direction="column">
            {showCampaign && (
              <Radio
                size="lg"
                name="campaign"
                colorScheme="black"
                _focus={{ outline: '0' }}
                value="campaign"
                alignItems="flex-start"
              >
                <Box pl="23px" fontSize="14px">
                  Campaign Team Member
                  <Box color="#b9c1c3" fontSize="14px">
                    <Box> - request new supplier</Box>
                    <Box>- perform checks and attach evidence on supplier profile</Box>
                    <Box>- request campaign code</Box>
                    <Box>- request purchase order</Box>
                    <Box>- view PO and payment statuses</Box>
                  </Box>
                </Box>
              </Radio>
            )}
            {showPodLeader && (
              <Radio
                size="lg"
                name="pod_leader"
                colorScheme="black"
                _focus={{ outline: '0' }}
                value="pod_leader"
                alignItems="flex-start"
              >
                <Box pl="23px" fontSize="14px">
                  Pod Leader Member
                  <Box color="#b9c1c3" fontSize="14px">
                    <Box>
                      - approve sanctions / reputations checks on supplier profile
                    </Box>
                    <Box>- approve PO requests for campaigns allocated to them</Box>
                    <Box>- approve campaign code requests</Box>
                    <Box>- view PO and payment statuses</Box>
                  </Box>
                </Box>
              </Radio>
            )}
            {showFinanceUser && (
              <Radio
                size="lg"
                name="finance_user"
                colorScheme="black"
                _focus={{ outline: '0' }}
                value="finance_user"
                alignItems="flex-start"
              >
                <Box pl="23px" fontSize="14px">
                  Finance User Member
                  <Box color="#b9c1c3">
                    <Box>- approve initial new supplier request</Box>
                    <Box>- perform checks on supplier profile once submitted</Box>
                    <Box>- submit supplier profile for approval from finance manager</Box>
                    <Box>- approve and send POs</Box>
                    <Box>- approve invoices</Box>
                  </Box>
                </Box>
              </Radio>
            )}
            {showFinance && (
              <Radio
                size="lg"
                name="1"
                colorScheme="black"
                _focus={{ outline: '0' }}
                value="finance"
                alignItems="flex-start"
              >
                <Box pl="23px" fontSize="14px">
                  Finance Manager Member
                  <Box color="#b9c1c3">
                    <Box>
                      - approve new suppliers once profile is complete and checks
                      performed
                    </Box>
                    <Box>- approve and send POs</Box>
                    <Box>- approve invoices</Box>
                  </Box>
                </Box>
              </Radio>
            )}
          </Stack>
        </RadioGroup>

        {radioValue !== member.type && needTransfer && (
          <>
            <Box pt="40px">
              <Text>Transfer ownership:</Text>
              <Text color="#868789" fontSize="14px">
                Select another team member who will take over the communication associated
                with this user.
              </Text>
            </Box>
            <Box>
              <AutocompleteTeamInput
                member={member}
                removedMemberState={removedMemberState}
                updateRemovedMemberState={updateRemovedMemberState}
                onClose={handleClose}
              />
            </Box>
          </>
        )}

        <>
          <Box d="flex" justifyContent="flex-end" mt={20} mb={20}>
            <Button
              type="submit"
              size="lg"
              colorScheme="brand"
              isLoading={isSubmitting}
              textTransform="initial"
              _hover={{ bg: '#000' }}
              borderRadius="10px"
              mr="10px"
              disabled={
                (!isDirty || !isValid || isSubmitting) &&
                (radioValue === member.type ||
                  (needTransfer &&
                    (member.id === removedMemberState.removedMember?.id ||
                      removedMemberState.removedMember === null)))
              }
            >
              Submit
            </Button>
            <Button
              variant="ghost"
              size="lg"
              colorScheme="brand"
              textTransform="initial"
              borderRadius="10px"
              onClick={onClose}
              disabled={isSubmitting}
              // _hover={{ bg: "#000" }}
            >
              Cancel
            </Button>
          </Box>
        </>
      </form>
    </>
  );
};
export default TeamEditFormAsAdmin;
