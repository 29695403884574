import React, { createContext, useContext } from 'react';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { AuthContext } from './AuthContext';

const HttpContext = createContext();
const { Provider } = HttpContext;

const HttpProvider = ({ children }) => {
  const authContext = useContext(AuthContext);

  const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  authAxios.interceptors.request.use(
    (config) => {
      // config.headers.Authorization = `Bearer ${authContext.getAccessToken()}`;
      config.headers.common[`X-Auth-Token`] = `${authContext.getAccessToken()}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 403) {
        if (
          error.response.data.message &&
          error.response.data.message === 'LOGOUT_ACTION'
        ) {
          authContext && authContext.logout();
        }
      }
      return Promise.reject(error);
    }
  );

  createAuthRefreshInterceptor(authAxios, authContext.getNewTokenForRequest, {
    skipWhileRefreshing: false,
  });

  return (
    <Provider
      value={{
        authAxios,
        publicAxios,
      }}
    >
      {children}
    </Provider>
  );
};

export { HttpContext, HttpProvider };
