import styled from 'styled-components';
// import { customTheme } from "../theme/customChakraTheme";

export const PaginationContainer = styled.div`
  position: relative;
  text-align: center;
`;
export const PaginationList = styled.ul`
  margin: 0 auto;
  list-style: none;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  li {
    margin: 0;
    display: inline-block;
    font-size: 24px;

    &:first-child a,
    &:last-child a {
      color: #333;
      background: transparent;
      &.isActive {
        color: #333;
      }

      &:hover {
        background: transparent;
      }
    }
  }
  li {
    display: inline-block;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
  }

  a {
    display: flex;
    overflow: hidden;
    height: 24px;
    min-width: 20px;
    align-items: center;
    justify-content: center;
    color: #bdc1d0;

    &.isActive {
      text-decoration: none;
      color: #333;
    }
    &:hover {
      text-decoration: none;
      color: #333;
    }
  }
`;
export const PaginationTotal = styled.div`
  text-align: center;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
`;
export const PdfContainer = styled.div`
  .PdfContent {
    max-width: 1000px;
    padding: 36px 40px 40px;
    position: absolute;
    left: -5000px;
    z-index: -1;
    pointer-events: none;
    .PdfTable {
      td:first-child {
        width: 540px;
        max-width: 540px;
      }
    }
  }
`;
