import React from 'react';
import { Badge } from '@chakra-ui/react';
import { getTeamLabel } from '../../util';

export default function TeamStatusBadge({ status }) {
  return (
    <>
      {status === true ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="green"
          color="#5b9075"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="6px"
          w="89px"
          textAlign="center"
        >
          {getTeamLabel(status)}
        </Badge>
      ) : (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="gray"
          color="#a9b1b4"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="6px"
          w="89px"
          textAlign="center"
        >
          {getTeamLabel(status)}
        </Badge>
      )}
    </>
  );
}
