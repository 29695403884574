import React, { Component } from 'react';
// import PropTypes from "prop-types";

import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Link,
  Image,
  // Text, Button,
  Heading,
} from '@chakra-ui/react';

import { AuthContainer, AuthContainerMain, AuthContainerAction } from './styles';

import logoWide from '../../assets/vectors/Goat-Portal-logo-login.svg';
import Background1 from '../../assets/images/AuthBackgrounds/Goat.webp';

class LayoutAuth extends Component {
  render() {
    const { children } = this.props;
    const image = `${Background1}`;

    return (
      <AuthContainer>
        <AuthContainerAction>
          <Box p={[4, 8, '100px']}>
            <Box d="flex" justifyContent="left" mb={['16px', '32px', '75px']}>
              <Link as={RouterLink} to="/">
                <Image src={logoWide} alt="Logo" width="227px" align="left" />
              </Link>
            </Box>

            {this.props.withTitle && (
              <Heading as="h1" size="lg" textAlign="left" fontWeight="700" mb={[4, 4, 7]}>
                {this.props.title}
              </Heading>
            )}

            {children}
          </Box>
        </AuthContainerAction>
        <AuthContainerMain
          className={this.props.location.pathname === '/login' && 'login'}
          style={{
            backgroundImage: 'url(' + image + ')',
          }}
        >
          <Box mt={[4, 8, 12]} ml={[4, 8, 24]} mr="1rem">
            <Link as={RouterLink} to="/">
              {/* <Image src={logoWide} alt="Logo" /> */}
            </Link>
          </Box>
        </AuthContainerMain>
      </AuthContainer>
    );
  }
}

LayoutAuth.propTypes = {};

export default LayoutAuth;
