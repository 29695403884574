import React from 'react';
export const CommentIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    {...props}
  >
    <g>
      <g>
        <path
          d="M11.284,24.248l-3.92-4.576H2.647c-0.362,0-0.709-0.07-1.032-0.207C1.3,19.33,1.019,19.141,0.779,18.902
			c-0.241-0.25-0.427-0.529-0.562-0.84c-0.138-0.318-0.208-0.664-0.208-1.029V2.639c0-0.364,0.07-0.711,0.208-1.032
			c0.135-0.316,0.324-0.597,0.563-0.835c0.248-0.24,0.529-0.427,0.84-0.563C1.936,0.07,2.282,0,2.647,0h18.714
			c0.363,0,0.711,0.07,1.032,0.208c0.317,0.136,0.599,0.325,0.835,0.563c0.24,0.247,0.427,0.527,0.563,0.838
			C23.93,1.929,24,2.275,24,2.639v14.395c0,0.363-0.07,0.709-0.209,1.029c-0.136,0.313-0.322,0.592-0.557,0.834
			c-0.258,0.248-0.538,0.436-0.847,0.566c-0.315,0.139-0.662,0.209-1.026,0.209h-6.156L11.284,24.248z M2.647,0.958
			c-0.232,0-0.45,0.043-0.646,0.128C1.795,1.177,1.61,1.301,1.451,1.454C1.305,1.6,1.184,1.78,1.097,1.984
			c-0.087,0.204-0.13,0.418-0.13,0.655v14.395c0,0.236,0.042,0.447,0.129,0.648c0.088,0.203,0.212,0.389,0.367,0.549
			c0.145,0.146,0.325,0.266,0.527,0.354c0.204,0.086,0.424,0.131,0.657,0.131h5.157l3.479,4.061l3.48-4.061h6.598
			c0.231,0,0.448-0.045,0.646-0.131c0.204-0.088,0.389-0.211,0.551-0.365c0.142-0.148,0.265-0.332,0.354-0.537
			c0.088-0.201,0.131-0.414,0.131-0.648V2.639c0-0.234-0.043-0.446-0.131-0.647c-0.089-0.206-0.212-0.39-0.365-0.549
			c-0.147-0.147-0.322-0.265-0.53-0.354c-0.204-0.087-0.418-0.13-0.654-0.13H2.647z"
        />
      </g>
    </g>
    <circle cx="7.097" cy="9.577" r="1.616" />
    <circle cx="11.99" cy="9.577" r="1.617" />
    <circle cx="16.971" cy="9.577" r="1.616" />
  </svg>
);
