import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon } from '@chakra-ui/icons';

function SortArrows({ i, b, desc, asc, handleOnClick }) {
  return (
    <>
      {i === 'desc' && (
        <IconButton
          variant="unstyled"
          h="21px"
          size="xs"
          icon={<ArrowDownIcon />}
          onClick={(e) => handleOnClick(e, `${b}`, `${asc}`)}
          aria-label={`date-${i}-at-down`}
        />
      )}
      {i === 'asc' && (
        <IconButton
          variant="unstyled"
          h="21px"
          size="xs"
          icon={<ArrowUpIcon />}
          onClick={(e) => handleOnClick(e, `${b}`, `${desc}`)}
          aria-label={`date-${i}-at-up`}
        />
      )}
      {i === null && (
        <IconButton
          variant="unstyled"
          h="21px"
          size="xs"
          icon={<ArrowUpDownIcon />}
          onClick={(e) => handleOnClick(e, `${b}`, `${desc}`)}
          aria-label={`date-${i}-at-up-down`}
        />
      )}
    </>
  );
}

export default SortArrows;
