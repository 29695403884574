import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  IconButton,
  Badge,
  Spinner,
  Tooltip,
} from '@chakra-ui/react';
import {
  ArrowDownIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  WarningIcon,
} from '@chakra-ui/icons';
import map from 'lodash/map';
// import filter from "lodash/filter";
import moment from 'moment';
import Pagination from '../../common/UIcomponents/Pagination';
import ScrollToTop from '../../util/scrollToTop';
import {
  capitalizeTheFirstLetter,
  getSupplierEmail,
  getSupplierName,
  getSupplierType,
  getSupplierCurrencyName,
} from '../../util';
import { FilterSupplierContext } from '../../context/FilterSuppliers';
import { InfluencerIcon } from '../../assets/iconsComponents/influencerIcon';
import SyncFailIcon from '../../assets/icons/sync_fail_icon.svg';
import { AuthContext } from '../../context/AuthContext';

function SupplierMembersList({
  suppliers,
  handlePageSizeChange,
  handlePageChange,
  currentPage,
  pagesQuantity,
  // isCampaign,
  orderBy,
  updateOrderBy,
  updateFilterBetween,
  loading,
  // isFinance,
}) {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const handleRowClick = (supplier) => {
    history.push(`/suppliers/${supplier.user.id}/${supplier.id}`);
  };

  const { name, supplierCode, type, currency, dateRequested, dateApproved } = orderBy;

  const { filterTo, filterFrom } = useContext(FilterSupplierContext);

  function handleOnClick(e, key, value) {
    updateOrderBy(key, value);
  }

  if (filterTo && filterFrom) {
    const dateApprovedFromUTC = moment(filterFrom).format('YYYY-MM-DDT00:00:00Z');
    const dateApprovedToUTC = moment(filterTo).format('YYYY-MM-DDT23:59:59Z');
    const uri = `&dateApprovedFrom=${encodeURIComponent(
      dateApprovedFromUTC
    )}&dateApprovedTo=${encodeURIComponent(dateApprovedToUTC)}`;
    updateFilterBetween(uri);
  }

  return (
    <>
      <ScrollToTop />
      <Table
        variant="simple"
        bg="#fff"
        borderRadius="md"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
        mt="7"
      >
        {suppliers?.length > 0 && (
          <Thead>
            <Tr key={'head-members'}>
              <Th>
                <Box
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  name
                  <Box d="flex" flexDirection="column" ml="0px">
                    {name === 'desc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowDownIcon />}
                        onClick={(e) => handleOnClick(e, 'name', 'asc')}
                        aria-label="name-down"
                      />
                    )}
                    {name === 'asc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpIcon />}
                        onClick={(e) => handleOnClick(e, 'name', 'desc')}
                        aria-label="name-up"
                      />
                    )}
                    {name === null && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpDownIcon />}
                        onClick={(e) => handleOnClick(e, 'name', 'desc')}
                        aria-label="name-up-down"
                      />
                    )}
                  </Box>
                </Box>
              </Th>
              <Th minWidth="130px" p="5px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Supplier code
                  <Box d="flex" flexDirection="column" ml="0px">
                    {supplierCode === 'desc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowDownIcon />}
                        onClick={(e) => handleOnClick(e, 'supplierCode', 'asc')}
                        aria-label="supplierCode-down"
                      />
                    )}
                    {supplierCode === 'asc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpIcon />}
                        onClick={(e) => handleOnClick(e, 'supplierCode', 'desc')}
                        aria-label="supplierCode-up"
                      />
                    )}
                    {supplierCode === null && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpDownIcon />}
                        onClick={(e) => handleOnClick(e, 'supplierCode', 'desc')}
                        aria-label="supplierCode-up-down"
                      />
                    )}
                  </Box>
                </Box>
              </Th>
              <Th maxWidth="110px" p="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Type
                  <Box d="flex" flexDirection="column" ml="0px">
                    {type === 'desc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowDownIcon />}
                        onClick={(e) => handleOnClick(e, 'type', 'asc')}
                        aria-label="type-down"
                      />
                    )}
                    {type === 'asc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpIcon />}
                        onClick={(e) => handleOnClick(e, 'type', 'desc')}
                        aria-label="type-up"
                      />
                    )}
                    {type === null && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpDownIcon />}
                        onClick={(e) => handleOnClick(e, 'type', 'desc')}
                        aria-label="type-up-down"
                      />
                    )}
                  </Box>
                </Box>
              </Th>
              <Th>
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Currency
                  <Box d="flex" flexDirection="column" ml="0px">
                    {currency === 'desc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowDownIcon />}
                        onClick={(e) => handleOnClick(e, 'currency', 'asc')}
                        aria-label="currency-down"
                      />
                    )}
                    {currency === 'asc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpIcon />}
                        onClick={(e) => handleOnClick(e, 'currency', 'desc')}
                        aria-label="currency-up"
                      />
                    )}
                    {currency === null && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpDownIcon />}
                        onClick={(e) => handleOnClick(e, 'currency', 'desc')}
                        aria-label="currency-up-down"
                      />
                    )}
                  </Box>
                </Box>
              </Th>
              <Th minWidth="155px" p="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Date requested
                  <Box d="flex" flexDirection="column" ml="0px">
                    {dateRequested === 'desc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowDownIcon />}
                        onClick={(e) => handleOnClick(e, 'dateRequested', 'asc')}
                        aria-label="date-requested-at-down"
                      />
                    )}
                    {dateRequested === 'asc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpIcon />}
                        onClick={(e) => handleOnClick(e, 'dateRequested', 'desc')}
                        aria-label="date-requested-at-up"
                      />
                    )}
                    {dateRequested === null && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpDownIcon />}
                        onClick={(e) => handleOnClick(e, 'dateRequested', 'desc')}
                        aria-label="date-requested-at-up-down"
                      />
                    )}
                  </Box>
                </Box>
              </Th>
              <Th minWidth="155px" p="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Date approved
                  <Box d="flex" flexDirection="column" ml="0px">
                    {dateApproved === 'desc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowDownIcon />}
                        onClick={(e) => handleOnClick(e, 'dateApproved', 'asc')}
                        aria-label="date-approved-at-down"
                      />
                    )}
                    {dateApproved === 'asc' && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpIcon />}
                        onClick={(e) => handleOnClick(e, 'dateApproved', 'desc')}
                        aria-label="date-approved-at-up"
                      />
                    )}
                    {dateApproved === null && (
                      <IconButton
                        variant="unstyled"
                        h="21px"
                        size="xs"
                        icon={<ArrowUpDownIcon />}
                        onClick={(e) => handleOnClick(e, 'dateApproved', 'desc')}
                        aria-label="date-approved-at-up-down"
                      />
                    )}
                  </Box>
                </Box>
              </Th>
              <Th minWidth="30px" pr="0">
                <Box />
              </Th>
            </Tr>
          </Thead>
        )}
        <>
          {loading ? (
            <Box p={12} d="flex" alignItems="center" justifyContent="center" h="400px">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="brand.500"
                size="xl"
              />
            </Box>
          ) : suppliers.length > 0 ? (
            <Tbody>
              <>
                {map(suppliers, (supplier) => {
                  const isRed =
                    auth.isFinance() &&
                    (supplier.sageSyncFailed || supplier.user?.sageSyncFailed);
                  return (
                    // <Tr
                    //   key={supplier.id}
                    //   onClick={() =>
                    //     !isCampaign ? handleRowClick(supplier) : undefined
                    //   }
                    //   style={{
                    //     cursor: !isCampaign ? "pointer" : "initial",
                    //   }}
                    //   _hover={{ bg: !isCampaign ? "#f5f6fa" : "#fff" }}
                    // >
                    <Tr
                      key={supplier.id}
                      onClick={() => handleRowClick(supplier)}
                      style={{
                        cursor: 'pointer',
                      }}
                      _hover={{ bg: '#f5f6fa' }}
                    >
                      <Td position="relative">
                        {isRed && (
                          <Box position="absolute" top="10px" left="10px" as="span">
                            <Tooltip
                              label="Sync with Sage failed. Restart synchronisation manually."
                              fontSize="xs"
                              maxWidth="180px"
                              color="white"
                              borderRadius="8px"
                              p={2}
                              bg="red"
                              textAlign="center"
                              lineHeight="1"
                            >
                              <img
                                src={SyncFailIcon}
                                style={{ width: '25px' }}
                                alt="syncfailed"
                              />
                            </Tooltip>
                          </Box>
                        )}
                        <Box
                          as="a"
                          fontWeight="700"
                          fontSize="16px"
                          fontFamily="Roboto"
                          color={isRed && `red`}
                          // href={`/suppliers/${supplier.id}`}
                        >
                          {getSupplierName(supplier)}
                          <Box
                            color={isRed ? `red` : `#a9b1b4`}
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="1"
                          >
                            {getSupplierEmail(supplier)}
                          </Box>
                        </Box>
                      </Td>
                      <Td maxWidth="120px" pl="10px" color={isRed && `red`}>
                        {supplier.code ? (
                          supplier.code
                        ) : (
                          <Box color={isRed ? `red` : `#a9b1b4`}>pending</Box>
                        )}
                      </Td>
                      <Td maxWidth="110px" pl="10px">
                        {getSupplierType(supplier) === 'individual' ? (
                          <Badge
                            pt="1"
                            pb="1"
                            pr="3"
                            pl="3"
                            colorScheme="blue"
                            color="#6f9fb7"
                            textTransform="initial"
                            fontSize="14px"
                            fontWeight="400"
                            borderRadius="8px"
                          >
                            {capitalizeTheFirstLetter(getSupplierType(supplier))}
                          </Badge>
                        ) : getSupplierType(supplier) === 'company' ? (
                          <Badge
                            pt="1"
                            pb="1"
                            pr="3"
                            pl="3"
                            colorScheme="green"
                            color="#5b9075"
                            textTransform="initial"
                            fontSize="14px"
                            fontWeight="400"
                            borderRadius="8px"
                          >
                            {capitalizeTheFirstLetter(getSupplierType(supplier))}
                          </Badge>
                        ) : null}
                      </Td>
                      <Td color={isRed && `red`}>{getSupplierCurrencyName(supplier)}</Td>
                      <Td color={isRed && `red`}>
                        {moment(supplier?.createdAt).format('DD MMM y')}
                      </Td>
                      <Td color={isRed && `red`}>
                        {supplier?.approvedAt
                          ? moment(supplier.approvedAt).format('DD MMM y')
                          : null}
                      </Td>
                      <Td p="0">
                        {!supplier?.paymentDetailsApproved && (
                          <Tooltip
                            bg="brand.500"
                            // color="brand.50"
                            // color="white"
                            fontSize="12px"
                            w="150px"
                            textAlign="center"
                            p="3px"
                            label="Supplier requested payment details changes"
                            aria-label="Comment tooltip"
                          >
                            <WarningIcon
                              variant="filled"
                              color="brand.400"
                              w="17px"
                              h="16px"
                            />
                          </Tooltip>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </>
            </Tbody>
          ) : (
            <Box
              d="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              p="80px"
            >
              <InfluencerIcon width="60px" height="55px" color="#d1dbdf" />
              <Box fontSize="18px" fontWeight="bold" mt="20px" mb="20px">
                No results.
              </Box>
              <Box color="#a9b1b4" fontSize="14px">
                We couldn’t find any suppliers that matched your search criteria.
              </Box>
            </Box>
          )}
        </>
      </Table>
      <Box margin="20px" />
      {suppliers?.length > 0 && (
        <Pagination
          handlePageSizeChange={handlePageSizeChange}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          pagesQuantity={pagesQuantity}
        />
      )}
    </>
  );
}

export default SupplierMembersList;
