import React, { useContext } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Button,
  FormLabel,
  FormControl,
  Select,
  HStack,
  MenuGroup,
  useDisclosure,
  Badge,
  Box,
} from '@chakra-ui/react';
import map from 'lodash/map';
import {
  approvalStatusOptionsForFilter,
  needToResetFilterApprovalStatus,
  statusOptionsForFilter,
} from '../../util';
import { FilterIcon } from '../../assets/iconsComponents/filterIcon';
import { CampaignContext } from '../../context/campaign/FilterCampaigns';
import FilterStartDate from './CampaignFilterStartDate';
import FilterEndDate from './CampaignFilterEndDate';
import moment from 'moment';
import FilterSelectCurrency from '../../common/UIcomponents/FilterSelectCurrency';
// import SelectPodLeader from '../../common/UIcomponents/SelectPodLeader';
import FilterSelectPodLeader from '../../common/UIcomponents/FilterSelectPodLeader';

export default function FilterCampaigns({
  clearAllFilters,
  updateFilterStatus,
  updateFilterApprovalStatus,
  updateFilterCurrency,
  updateFilterStartBetween,
  updateFilterEndBetween,
  updateFilterPodLeader,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { filterStatus, setFilterStatus } = useContext(CampaignContext);
  const { filterApprovalStatus, setFilterApprovalStatus } = useContext(CampaignContext);
  const { filterCurrency, setFilterCurrency } = useContext(CampaignContext);
  const { filterStartFrom, setFilterStartFrom } = useContext(CampaignContext);
  const { filterStartTo, setFilterStartTo } = useContext(CampaignContext);
  const { filterEndFrom, setFilterEndFrom } = useContext(CampaignContext);
  const { filterEndTo, setFilterEndTo } = useContext(CampaignContext);
  const { filterPodLeader, setFilterPodLeader } = useContext(CampaignContext);

  const numberOfFilters =
    (filterStartFrom && filterStartTo ? 1 : 0) +
    (filterStatus !== 'none') +
    (filterApprovalStatus !== 'none') +
    (filterCurrency && filterCurrency !== 0 ? 1 : 0) +
    (filterPodLeader && filterPodLeader !== 0 ? 1 : 0) +
    (filterEndFrom && filterEndTo ? 1 : 0);

  const handleChangeStatus = (e) => {
    if (needToResetFilterApprovalStatus(filterStatus, e.target.value)) {
      setFilterApprovalStatus('none');
    }
    setFilterStatus(e.target.value);
  };
  const handleChangeApprovalStatus = (e) => {
    setFilterApprovalStatus(e.target.value);
  };
  const updateCurrencyId = (id) => {
    setFilterCurrency(id);
  };
  const updatePodLeaderId = (id) => {
    setFilterPodLeader(id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (filterStartFrom && filterStartTo) {
      const dateStartFrom = moment(filterStartFrom).format('YYYY-MM-DD');
      const dateStartTo = moment(filterStartTo).format('YYYY-MM-DD');
      updateFilterStartBetween(
        `&dateStartFrom=${dateStartFrom}&dateStartTo=${dateStartTo}`
      );
    }

    if (filterEndFrom && filterEndTo) {
      const dateEndFrom = moment(filterEndFrom).format('YYYY-MM-DD');
      const dateEndTo = moment(filterEndTo).format('YYYY-MM-DD');
      updateFilterEndBetween(`&dateEndFrom=${dateEndFrom}&dateEndTo=${dateEndTo}`);
    }

    if (filterStatus) {
      if (filterStatus === 'none') {
        updateFilterStatus('');
      } else {
        updateFilterStatus(`&status=${filterStatus}`);
      }
    }

    if (filterApprovalStatus) {
      if (filterApprovalStatus === 'none') {
        updateFilterApprovalStatus('');
      } else {
        updateFilterApprovalStatus(`&approvalStatus=${filterApprovalStatus}`);
      }
    }

    if (filterCurrency) {
      updateFilterCurrency(`&currency=${filterCurrency}`);
    } else if (filterCurrency === 0) {
      updateFilterCurrency('');
    }

    if (filterPodLeader) {
      updateFilterPodLeader(`&podLeader=${filterPodLeader}`);
    } else if (filterPodLeader === 0) {
      updateFilterPodLeader('');
    }
    onClose();
  };
  const handleClearAll = (e) => {
    e.preventDefault();
    setFilterStatus('none');
    setFilterApprovalStatus('none');
    setFilterCurrency(0);
    setFilterPodLeader(0);
    setFilterStartFrom('');
    setFilterStartTo('');
    setFilterEndFrom('');
    setFilterEndTo('');
    updateFilterStatus('');
    updateFilterCurrency('');
    updateFilterStartBetween('');
    updateFilterEndBetween('');
    clearAllFilters();
  };
  return (
    <Menu>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            as={Button}
            bg="white"
            borderRadius="8px"
            fontSize="14px"
            fontWeight="500"
            border="1px solid #e8e9e9"
            pt="18px"
            pb="18px"
            onClick={onOpen}
            _expanded={{ bg: 'black', color: 'white' }}
            _focus={{ boxShadow: 'none' }}
            leftIcon={
              numberOfFilters ? (
                <Badge
                  bg="#eb7f24"
                  borderRadius="50%"
                  color="white"
                  h="23px"
                  w="24px"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {numberOfFilters}
                </Badge>
              ) : (
                <FilterIcon width="17px" color="#eb7f24" height="19px" />
              )
            }
          >
            Filters
          </MenuButton>
          <MenuList
            onClose={onClose}
            minWidth="360px"
            p="23px"
            boxShadow=" 0 2px 10px rgba(29, 44, 45, 0.18)"
            boundary="preventOverflow"
            flip="true"
            matchWidth
          >
            <MenuOptionGroup title="" type="checkbox" matchWidth closeOnSelect={true}>
              <FormControl mb={[4, 4, 8]}>
                <FormLabel htmlFor="emailHtmlFor" fontSize="14px">
                  Status
                </FormLabel>
                <Select
                  bg="#ebecf0"
                  h="48px"
                  onChange={handleChangeStatus}
                  value={filterStatus}
                >
                  {map(statusOptionsForFilter(), (option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mb={[4, 4, 8]}>
                <FormLabel htmlFor="emailHtmlFor" fontSize="14px">
                  Approval Status
                </FormLabel>
                <Select
                  bg="#ebecf0"
                  h="48px"
                  onChange={handleChangeApprovalStatus}
                  value={filterApprovalStatus}
                >
                  {map(approvalStatusOptionsForFilter(filterStatus), (option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mb={[4, 4, 8]}>
                <FormLabel htmlFor="emailHtmlFor" fontSize="14px">
                  Pod Leader
                </FormLabel>
                <FilterSelectPodLeader
                  updatePodLeaderId={updatePodLeaderId}
                  podLeaderId={filterPodLeader}
                />
              </FormControl>

              <FormControl mb={[4, 4, 8]}>
                <FormLabel htmlFor="emailHtmlFor" fontSize="14px">
                  Currency
                </FormLabel>
                <FilterSelectCurrency updateCurrencyId={updateCurrencyId} />
              </FormControl>
              <Box fontSize="16px" fontWeight="700" mb="10px">
                Start date is between
              </Box>
              <FilterStartDate />
              <Box fontSize="16px" fontWeight="700" mb="10px" mt="30px">
                End date is between
              </Box>
              <FilterEndDate />
            </MenuOptionGroup>
            <MenuGroup closeOnSelect={true} onClose>
              <HStack justifyContent="space-between" mt="30px">
                <Button
                  onClick={(event) => {
                    handleClearAll(event);
                    onClose();
                  }}
                  variant="ghost"
                  size="sm"
                  fontSize="14px"
                >
                  Clear all
                </Button>{' '}
                <Button
                  size="sm"
                  fontSize="14px"
                  colorScheme="brand"
                  textTransform="initial"
                  mr="10px"
                  onClick={(event) => {
                    handleSubmit(event);
                    onClose();
                  }}
                >
                  Apply and close
                </Button>
              </HStack>
            </MenuGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
}
