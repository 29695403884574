import React, { useContext, useEffect, useState } from 'react';
import { Heading, Container, useToast, HStack, Box, Button } from '@chakra-ui/react';
import CreateSupplierButton from '../components/suppliers/CreateSupplierButton';
import { HttpContext } from '../context/HttpContext';
import SuppliersMembersList from '../components/suppliers/SupplierList';
import Search from '../common/UIcomponents/SearchList';
import { usePaginator } from 'chakra-paginator';
import { AuthContext } from '../context/AuthContext';
import FilterSupplier from '../components/suppliers/FilterSupplierDropdown';
import FilterSupplierContextProvider from '../context/FilterSuppliers';
import SageRestartSync from '../components/vendors/SageRestartSync';
import UseCurrency from '../custom-hooks/useCurrency';
import { INITIAL_PAGE_SIZE } from '../util/consts';
import { DownloadIcon } from '../assets/iconsComponents/downloadIcon';

export default function InfluencerPortalSuppliers() {
  const clearedOrderBy = {
    id: null,
    name: null,
    supplierCode: null,
    type: null,
    currency: null,
    dateRequested: null,
    dateApproved: null,
  };
  const initialOrderBy = {
    id: 'desc',
    name: null,
    supplierCode: null,
    type: null,
    currency: null,
    dateRequested: null,
    dateApproved: null,
  };
  const [supplierState, setSupplierState] = useState({
    suppliers: [],
    loading: true,
    total: 0,
    // orderBy: initialOrderBy
  });
  const [order, setOrder] = useState(initialOrderBy);
  const [term, setTerm] = useState('');
  const [filterBetween, setFilterBetween] = useState('');
  const [filterPaymentDetailsApprovedStatus, setPaymentDetailsApprovedStatus] =
    useState('');
  const [added, setAdded] = useState(0);

  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);
  const toast = useToast();

  const {
    pagesQuantity,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    offset, // you may not need this most of the times, but it's returned for you anyway
  } = usePaginator({
    total: supplierState.total,
    initialState: {
      pageSize: INITIAL_PAGE_SIZE,
      currentPage: 1,
      isDisabled: false,
    },
  });

  function getSortingQuery(order) {
    if (order.id) {
      return `&sort=id&direction=${order.id}`;
    }
    if (order.name) {
      return `&sort=name&direction=${order.name}`;
    }
    if (order.supplierCode) {
      return `&sort=code&direction=${order.supplierCode}`;
    }
    if (order.type) {
      return `&sort=subType&direction=${order.type}`;
    }
    if (order.currency) {
      return `&sort=currency&direction=${order.currency}`;
    }
    if (order.dateApproved) {
      return `&sort=approvedAt&direction=${order.dateApproved}`;
    }
    if (order.dateRequested) {
      return `&sort=createdAt&direction=${order.dateRequested}`;
    }

    return '';
  }

  function getSearchQuery(term) {
    const trimmed = term.trim();
    if (trimmed.length > 0) {
      return `&search=${encodeURIComponent(term)}`;
    }
    return '';
  }

  useEffect(() => {
    const getPortalData = async () => {
      try {
        const sort = getSortingQuery(order);
        const search = getSearchQuery(term);
        const { data } = await authAxios.get(
          `/users/suppliers?limit=${pageSize}&offset=${offset}${sort}${search}${filterBetween}${filterPaymentDetailsApprovedStatus}`
        );
        const suppliers = (data && data.data) || [];
        const total = (data && data.meta && data.meta.total) || 0;
        setSupplierState({
          suppliers,
          total,
          loading: false,
        });
      } catch (onError) {
        console.log(onError);
        setSupplierState({
          ...supplierState,
          loading: false,
        });
      }
    };

    getPortalData();
  }, [
    authAxios,
    currentPage,
    pageSize,
    offset,
    order,
    term,
    filterBetween,
    filterPaymentDetailsApprovedStatus,
    added,
  ]);
  function addUser() {
    setAdded(added + 1);
  }

  function updateSupplier(supplier) {
    const newSuppliers = supplierState.suppliers;
    let recordIndex = newSuppliers.findIndex((x) => x.id === supplier.id);
    newSuppliers[recordIndex] = { ...supplier };

    setSupplierState({
      ...supplierState,
      suppliers: newSuppliers,
    });

    toast({
      position: 'top',
      description: 'You have successfully updated a supplier.',
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  }

  function updateOrderBy(key, value) {
    setOrder({ ...clearedOrderBy, [key]: value });
    setCurrentPage(1);
  }

  function updateFilterBetween(between) {
    setFilterBetween(between);
    // setOrder({ ...clearedOrderBy, dateApproved: "asc" });
    setCurrentPage(1);
  }

  function updatePaymentDetailsApprovedStatus(status) {
    if (status === 'paymentDetailsApproved=none') {
      setPaymentDetailsApprovedStatus('');
    } else {
      setPaymentDetailsApprovedStatus(status);
    }
    setCurrentPage(1);
  }

  function clearAllFilters() {
    setFilterBetween('');
    setPaymentDetailsApprovedStatus('');
    // setOrder({ ...clearedOrderBy, dateApproved: "asc" });
    setCurrentPage(1);
  }

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);
  };

  const handlePageSizeChange = (event) => {
    const pageSize = Number(event.target.value);
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  function updateTerm(term) {
    setCurrentPage(1);
    setTerm(term);
  }

  const handleCsvDownload = async () => {
    try {
      const sort = getSortingQuery(order);
      const search = getSearchQuery(term);
      const csvExport = `&export=true`;
      const response = await authAxios.get(
        `/users/suppliers?limit=${pageSize}&offset=${offset}${sort}${search}${filterBetween}${filterPaymentDetailsApprovedStatus}${csvExport}`,
        {
          responseType: 'blob',
        }
      );
      const blob = new Blob([response.data], { type: 'text/csv; charset=UTF-8' });
      const url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = 'suppliers.csv';
      a.click();
    } catch (onError) {
      console.log(onError);
    }
  };

  return (
    <FilterSupplierContextProvider>
      <Container w="100%" maxWidth="100%" pt={4} pr={10} pl={10} pb={14}>
        {auth.isFinance() && auth.getAccessToken() && <SageRestartSync />}
        <Heading
          as="h2"
          size="lg"
          textAlign="left"
          fontWeight="700"
          pt={4}
          mb={[4, 4, 5]}
        >
          Suppliers
        </Heading>
        <HStack justifyContent="space-between">
          <Box>
            {auth.isCampaignManager() && (
              <UseCurrency>
                <CreateSupplierButton addUser={addUser} />
              </UseCurrency>
            )}
          </Box>
          <Box d="flex" flexDirection="row" justifyContent="center" alignItems="center">
            {auth.isFinance() && (
              <Button
                leftIcon={<DownloadIcon width="17px" color="#fff" height="19px" />}
                colorScheme="brand"
                borderRadius="8px"
                fontSize="14px"
                fontWeight="500"
                pt="18px"
                pb="18px"
                _hover={supplierState?.suppliers?.length === 0 ? null : { bg: '#000' }}
                _expanded={{ bg: 'black', color: 'white' }}
                _focus={{ boxShadow: 'none' }}
                onClick={handleCsvDownload}
                disabled={supplierState?.suppliers?.length === 0}
                mr="1rem"
              >
                Download (.csv)
              </Button>
            )}
            <Search updateTerm={updateTerm} />
            <Box width="15px" />
            <FilterSupplier
              clearAllFilters={clearAllFilters}
              updatePaymentDetailsApprovedStatus={updatePaymentDetailsApprovedStatus}
            />
          </Box>
        </HStack>
        <SuppliersMembersList
          suppliers={supplierState.suppliers}
          handlePageSizeChange={handlePageSizeChange}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          pagesQuantity={pagesQuantity}
          updateSupplier={updateSupplier}
          isCampaign={auth.isCampaignManager()}
          isFinance={auth.isFinanceManager()}
          orderBy={order}
          updateOrderBy={updateOrderBy}
          updateFilterBetween={updateFilterBetween}
          loading={supplierState.loading}
        />
      </Container>
    </FilterSupplierContextProvider>
  );
}
