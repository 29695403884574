import React, { useContext, useEffect, useState } from 'react';
import { Heading, Container, useToast, Button, HStack } from '@chakra-ui/react';
import DrawerComponent from '../common/UIcomponents/Drawer';
import { HttpContext } from '../context/HttpContext';
import TeamMembersList from '../components/team/TeamMembersList';
import { usePaginator } from 'chakra-paginator';
// import { useHistory } from "react-router-dom"
import { getUrlSearchParams } from '../util';
import filter from 'lodash/filter';
import { AuthContext } from '../context/AuthContext';
import SageRestartSync from '../components/vendors/SageRestartSync';
import { INITIAL_PAGE_SIZE } from '../util/consts';
import { DownloadIcon } from '../assets/iconsComponents/downloadIcon';

export default function InfluencerPortalTeam() {
  const clearedOrderBy = {
    name: null,
    email: null,
    type: null,
    enabled: null,
  };
  const initialOrderBy = {
    name: null,
    email: null,
    type: null,
    enabled: null,
  };

  // const history = useHistory();
  let query = getUrlSearchParams();
  const addNewMember = query.get('addNewMember');

  const [teamState, setTeamState] = useState({
    members: [],
    loading: true,
    total: 0,
  });
  const [order, setOrder] = useState(initialOrderBy);
  // const [total, setTotal] = useState(0);
  // const [
  //   // loading
  //   ,setLoading
  // ] = useState(true)

  // const [members, setMembers] = useState([]);
  const { authAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);
  const isAdmin = auth.isAdmin();
  const toast = useToast();

  const {
    pagesQuantity,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    offset, // you may not need this most of the times, but it's returned for you anyway
  } = usePaginator({
    total: teamState.total,
    initialState: {
      pageSize: INITIAL_PAGE_SIZE,
      currentPage: 1,
      isDisabled: false,
    },
  });

  function getSortingQuery(order) {
    if (order.name) {
      return `&sort=name&direction=${order.name}`;
    }
    if (order.email) {
      return `&sort=email&direction=${order.email}`;
    }
    if (order.type) {
      return `&sort=type&direction=${order.type}`;
    }
    if (order.enabled) {
      const orderEnabled = order.enabled === 'desc' ? 'asc' : 'desc';
      return `&sort=enabled&direction=${orderEnabled}`;
    }

    return '';
  }

  useEffect(() => {
    const getPortalData = async () => {
      try {
        const sort = getSortingQuery(order);
        const { data } = await authAxios.get(
          `/users/members?limit=${pageSize}&offset=${offset}${sort}`
        );
        const members = (data && data.data) || [];
        const total = (data && data.meta && data.meta.total) || 0;

        setTeamState({
          members,
          total,
          loading: false,
        });
      } catch (onError) {
        console.log(onError);
        setTeamState({
          ...teamState,
          loading: false,
        });
      }
    };

    getPortalData();
  }, [authAxios, currentPage, pageSize, offset, order]);

  function addMember(member) {
    const oldMembers = teamState.members;
    setCurrentPage(1);
    setTeamState({
      ...teamState,
      members: [member, ...oldMembers],
    });
    toast({
      position: 'top',
      description:
        "You have successfully created a member account and the login details are sent to the member's email address.",
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  }

  function updateMember(member) {
    const newMembers = teamState.members;
    let recordIndex = newMembers.findIndex((x) => x.id === member.id);
    newMembers[recordIndex] = { ...member };

    setTeamState({
      ...teamState,
      members: newMembers,
    });

    toast({
      position: 'top',
      description: 'You have successfully updated a member account.',
      status: 'success',
      duration: 6000,
      isClosable: true,
    });
  }

  function removeMember(member) {
    const members = filter(teamState.members, (item) => item.id !== member.id);
    setTeamState({ ...teamState, members });
  }

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);
  };

  const handlePageSizeChange = (event) => {
    const pageSize = Number(event.target.value);
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  function updateOrderBy(key, value) {
    setOrder({ ...clearedOrderBy, [key]: value });
    setCurrentPage(1);
  }

  // if (teamState.loading) {
  //   return (
  //     <Box p={12} d="flex" alignItems="center" justifyContent="center" h="400px">
  //       <Spinner
  //         thickness="4px"
  //         speed="0.65s"
  //         emptyColor="gray.200"
  //         color="brand.500"
  //         size="xl"
  //       />
  //     </Box>
  //   );
  // }

  const handleCsvDownload = async () => {
    try {
      const sort = getSortingQuery(order);
      const csvExport = `&export=true`;
      const response = await authAxios.get(
        `/users/members?limit=${pageSize}&offset=${offset}${sort}${csvExport}`,
        {
          responseType: 'blob',
        }
      );
      const blob = new Blob([response.data], { type: 'text/csv; charset=UTF-8' });
      const url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = 'team.csv';
      a.click();
    } catch (onError) {
      console.log(onError);
    }
  };

  console.log(auth, auth.isFinanceManager());

  return (
    <Container w="100%" maxWidth="100%" pt={4} pr={10} pl={10} pb={14}>
      {auth.isFinance() && auth.getAccessToken() && <SageRestartSync />}
      <HStack justify="space-between" w="full">
        <Heading
          as="h2"
          size="lg"
          textAlign="left"
          fontWeight="700"
          pt={4}
          mb={[4, 4, 5]}
        >
          Team
        </Heading>
        {(auth.isFinanceManager() || auth.isAdmin()) && (
          <Button
            leftIcon={<DownloadIcon width="17px" color="#fff" height="19px" />}
            colorScheme="brand"
            borderRadius="8px"
            fontSize="14px"
            fontWeight="500"
            pt="18px"
            pb="18px"
            _hover={teamState?.members?.length === 0 ? null : { bg: '#000' }}
            _expanded={{ bg: 'black', color: 'white' }}
            _focus={{ boxShadow: 'none' }}
            onClick={handleCsvDownload}
            disabled={teamState?.members?.length === 0}
            mr="1rem"
          >
            Download (.csv)
          </Button>
        )}
      </HStack>
      {isAdmin && <DrawerComponent addMember={addMember} addNewMember={addNewMember} />}
      <TeamMembersList
        members={teamState.members}
        handlePageSizeChange={handlePageSizeChange}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        pagesQuantity={pagesQuantity}
        updateMember={updateMember}
        removeMember={removeMember}
        orderBy={order}
        updateOrderBy={updateOrderBy}
        loading={teamState.loading}
      />
    </Container>
  );
}
