import React from 'react';
export const TeamIconAddForm = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    {...props}
  >
    <path
      d="M17.51,17.947c0,0.031,0.026,0.059,0.059,0.059l0,0c0.031,0,0.059-0.025,0.059-0.059v-0.088c0-0.032-0.025-0.06-0.059-0.06
        l0,0c-0.031,0-0.059,0.026-0.059,0.06V17.947z"
    />
    <path
      d="M1.059,16.754c0,0.745-1.059,0.745-1.059,0c0.108-3.23,2.432-5.716,5.075-5.716h2.221c0.516,0,1.012,0.088,1.479,0.251
        c0.021,0.007-0.423,1.028-0.436,1.023c-0.392-0.142-0.61-0.155-1.043-0.155H5.075C2.961,12.157,1.164,14.145,1.059,16.754
        L1.059,16.754z"
    />
    <path
      d="M22.941,16.754c-0.105-2.609-1.902-4.597-4.016-4.597h-2.222c-0.433,0-0.651,0.013-1.044,0.155
        c-0.013,0.005-0.456-1.016-0.436-1.023c0.467-0.163,0.964-0.251,1.479-0.251h2.222c2.643,0,4.967,2.485,5.074,5.716
        C24,17.499,22.941,17.499,22.941,16.754L22.941,16.754z"
    />
    <path
      d="M6.849,23.268c0,0.744-0.953,0.744-0.953,0c0-3.107,2.22-5.718,4.969-5.718h2.22c2.643,0,4.969,2.61,4.969,5.718
        c0,0.744-0.953,0.744-0.953,0c0-2.485-1.796-4.598-4.016-4.598h-2.22C8.644,18.67,6.849,20.782,6.849,23.268z"
    />
    <path
      d="M5.921,3.706c-1.832,0-3.207,1.617-3.207,3.605c0,1.862,1.375,3.479,3.207,3.479c1.718,0,3.205-1.617,3.205-3.479
        C9.126,5.322,7.639,3.706,5.921,3.706z M5.921,9.67c-1.261,0-2.175-1.117-2.175-2.359c0-1.366,0.914-2.486,2.175-2.486
        c1.145,0,2.174,1.12,2.174,2.486C8.096,8.553,7.066,9.67,5.921,9.67z"
    />
    <path
      d="M15.119,7.311c0,1.862,1.488,3.479,3.205,3.479c1.832,0,3.207-1.617,3.207-3.479c0-1.989-1.375-3.605-3.207-3.605
        C16.607,3.706,15.119,5.322,15.119,7.311z M16.15,7.311c0-1.366,1.03-2.486,2.174-2.486c1.261,0,2.175,1.12,2.175,2.486
        c0,1.242-0.914,2.359-2.175,2.359C17.181,9.67,16.15,8.553,16.15,7.311z"
    />
    <path
      d="M11.93,10.346c-1.718,0-3.207,1.615-3.207,3.477c0,1.988,1.489,3.479,3.207,3.479c1.83,0,3.319-1.49,3.319-3.479
        C15.249,11.961,13.76,10.346,11.93,10.346z M11.93,16.187c-1.146,0-2.175-0.997-2.175-2.363c0-1.242,1.029-2.36,2.175-2.36
        c1.259,0,2.288,1.118,2.288,2.36C14.218,15.189,13.188,16.187,11.93,16.187z"
    />
  </svg>
);
