import React from 'react';
import { Badge } from '@chakra-ui/react';

export default function CampaignApprovalStatusBadge({ status }) {
  return (
    <>
      {status === 'initial' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="gray"
          color="#a9b1b4"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="6px"
          w="89px"
          textAlign="center"
        >
          {status}
        </Badge>
      ) : // ) : status === 'approved' ? (
      //   <Badge
      //     pt="1"
      //     pb="1"
      //     pr="3"
      //     pl="3"
      //     colorScheme="blue"
      //     color="#6f9fb7"
      //     textTransform="capitalize"
      //     fontSize="14px"
      //     fontWeight="400"
      //     borderRadius="6px"
      //     w="89px"
      //     textAlign="center"
      //   >
      //     Approved
      //   </Badge>
      status === 'approved' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="green"
          color="#5b9075"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="6px"
          w="89px"
          textAlign="center"
        >
          {status}
        </Badge>
      ) : status === 'snag' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="yellow"
          bg="#fde9ae"
          color="#d18533"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          w="89px"
          textAlign="center"
        >
          {' '}
          {status}
        </Badge>
      ) : status === 'suspend' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="red"
          color="#c75c5c"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          w="89px"
          textAlign="center"
        >
          {' '}
          {status}
        </Badge>
      ) : status === 'pending_to_finance' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="gray"
          color="#a9b1b4"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          //w="89px"
          textAlign="center"
        >
          {' '}
          Pending Finance
        </Badge>
      ) : status === 'pending_to_pod_leader' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="gray"
          color="#a9b1b4"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          //w="89px"
          textAlign="center"
        >
          {' '}
          Pending Pod Leader
        </Badge>
      ) : status === 'pod_leader_rejected' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="red"
          color="#c75c5c"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          //w="89px"
          textAlign="center"
        >
          {' '}
          Rejected by Pod Leader
        </Badge>
      ) : status === 'pod_leader_pending_repair' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="yellow"
          bg="#fde9ae"
          color="#d18533"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          //w="89px"
          textAlign="center"
        >
          {' '}
          Pending repair (Pod Leader)
        </Badge>
      ) : status === 'finance_rejected' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="red"
          color="#c75c5c"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          //w="89px"
          textAlign="center"
        >
          {' '}
          Rejected by Finance
        </Badge>
      ) : status === 'finance_pending_repair' ? (
        <Badge
          pt="1"
          pb="1"
          pr="3"
          pl="3"
          colorScheme="yellow"
          bg="#fde9ae"
          color="#d18533"
          textTransform="capitalize"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          //w="89px"
          textAlign="center"
        >
          {' '}
          Pending repair (Finance)
        </Badge>
      ) : null}
    </>
  );
}
