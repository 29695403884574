import React, { useContext } from 'react';
import { Box, Text } from '@chakra-ui/react';
import map from 'lodash/map';

import { CUIAutoComplete } from 'chakra-ui-autocomplete';

import { CurrencyContext } from '../../context/currency/CurrencyContext';

export default function Currency({ updateCurrencies }) {
  const currencyContext = useContext(CurrencyContext);
  const currencies = map(currencyContext.gateAllCurrencies(), (curr) => ({
    ...curr,
    title: `${curr.code} - ${curr.name}`,
  }));
  const currencyItem = map(currencies, (curr) => ({
    ...curr,
    value: `${curr.id}`,
    label: `${curr.code} - ${curr.name}`,
  }));

  const [pickerItems, setPickerItems] = React.useState(currencyItem);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const handleCreateItem = (item) => {
    setPickerItems((curr) => [...curr, item]);
    setSelectedItems((curr) => [...curr, item]);
  };

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
      updateCurrencies(selectedItems);
    }
  };

  return (
    <>
      <Text fontSize="16px" fontWeight="bold">
        Currency
      </Text>
      <Text as="span" color="gray.400" mb="0">
        (You can select more than one)
      </Text>
      <CUIAutoComplete
        //label="Currency"
        placeholder="Choose Currency"
        onCreateItem={handleCreateItem}
        items={pickerItems}
        disableCreateItem={true}
        mt="-10px"
        selectedIconProps={{
          // bg:"brand.400",
          color: 'brand.400',
        }}
        // icon={{color:"brand.400"}}
        tagStyleProps={{
          //rounded: "full",
          pt: 1,
          pb: 1,
          px: 2,
          ml: '0 !important',
          mb: '10px !important',
          mr: '5px!important',
          fontSize: '13px',
          fontWeight: '400',
          lineHeight: '1',
          minHeight: '30px',
          maxHeight: '30px',
          bg: ' #a9b1b4',
          color: 'white',
        }}
        toggleButtonStyleProps={{
          height: '47px',
          bg: '#ebecf0',
        }}
        selectedItems={selectedItems}
        onSelectedItemsChange={(changes) =>
          handleSelectedItemsChange(changes.selectedItems)
        }
      />
    </>
  );
}
