import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Tooltip,
  Text,
  // IconButton,
} from '@chakra-ui/react';
import map from 'lodash/map';
import { POIcon } from '../../../assets/iconsComponents/poIcon';
import POSortArrows from './POSortArrows';
import POStatusBadge from '../../purchaseOrders/POStatusBadge';
import {
  formatAmount,
  getPOCommentAuthor,
  getPOCommentContent,
  getPOCommentDate,
  getSupplierNameFromUser,
} from '../../../util';
import { HttpContext } from '../../../context/HttpContext';
import ScrollToTop from '../../../util/scrollToTop';

function POList({ pageSize }) {
  const history = useHistory();
  const { id } = useParams();
  const { authAxios } = useContext(HttpContext);
  const clearedOrderBy = {
    supplierName: null,
    supplierCode: null,
    totalGross: null,
    totalNet: null,
    totalVat: null,
    number: null,
    status: null,
    comment: null,
  };
  const initialOrderBy = {
    supplierName: null,
    supplierCode: null,
    totalGross: null,
    totalNet: null,
    totalVat: null,
    number: null,
    status: null,
    comment: null,
  };
  const offset = 0;
  const [poState, setPoState] = useState({
    purchaseOrders: [],
    loading: true,
    total: 0,
    // orderBy: initialOrderBy
  });
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const handleRowClick = (order) => {
    history.push(`/orders/${order.id}`);
  };

  function updateOrderBy(key, value) {
    setOrderBy({ ...clearedOrderBy, [key]: value });
  }

  function handleOnClick(e, key, value) {
    updateOrderBy(key, value);
  }

  function getSortingQuery(order) {
    if (order.supplierName) {
      return `&sort=supplierName&direction=${order.supplierName}`;
    }
    if (order.supplierCode) {
      return `&sort=supplierCode&direction=${order.supplierCode}`;
    }
    if (order.totalGross) {
      return `&sort=totalGross&direction=${order.totalGross}`;
    }
    if (order.totalNet) {
      return `&sort=totalNet&direction=${order.totalNet}`;
    }
    if (order.totalVat) {
      return `&sort=totalVat&direction=${order.totalVat}`;
    }
    if (order.number) {
      return `&sort=number&direction=${order.number}`;
    }
    if (order.status) {
      return `&sort=status&direction=${order.status}`;
    }
    if (order.comment) {
      return `&sort=comment&direction=${order.comment}`;
    }

    return '';
  }

  useEffect(() => {
    const getPortalData = async () => {
      try {
        const sort = getSortingQuery(orderBy);
        const { data } = await authAxios.get(
          `purchase-orders?limit=${pageSize}&offset=${offset}&campaignId=${id}${sort}`
        );
        const purchaseOrders = (data && data.data) || [];
        const total = (data && data.meta && data.meta.total) || 0;
        setPoState({
          purchaseOrders,
          total,
          loading: false,
        });
      } catch (onError) {
        console.log(onError);
        setPoState({
          ...poState,
          loading: false,
        });
      }
    };

    getPortalData();
  }, [authAxios, pageSize, offset, orderBy, id]);

  const {
    supplierName,
    supplierCode,
    totalGross,
    totalNet,
    totalVat,
    number,
    status,
    comment,
  } = orderBy;

  const { purchaseOrders } = poState;

  return (
    <>
      <ScrollToTop />
      <Table
        variant="simple"
        bg="#fff"
        borderRadius="md"
        boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
        mt="7"
      >
        {purchaseOrders.length > 0 && (
          <Thead>
            <Tr key={'head-members'}>
              <Th pr="10px">
                <Box
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Supplier name
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={supplierName}
                      b="supplierName"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th pl="10px" pr="10px">
                <Box
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Supplier code
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={supplierCode}
                      b="supplierCode"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>

              <Th>
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Gross
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={totalGross}
                      b="totalGross"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th>
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Net
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={totalNet}
                      b="totalNet"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th>
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  VAT
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={totalVat}
                      b="totalVat"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="120px" pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  po number
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={number}
                      b="number"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th pl="10px" pr="10px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  Status
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={status}
                      b="status"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
              <Th minWidth="160px" pr="0px">
                <Box
                  as="p"
                  d="flex"
                  flexDirection="row"
                  alignItems="center"
                  color="#bdc1d0"
                  fontSize="12px"
                  fontFamily="Roboto"
                  letterSpacing="0"
                >
                  comment
                  <Box d="flex" flexDirection="column" ml="0px">
                    <POSortArrows
                      i={comment}
                      b="comment"
                      desc="desc"
                      asc="asc"
                      handleOnClick={handleOnClick}
                    />
                  </Box>
                </Box>
              </Th>
            </Tr>
          </Thead>
        )}
        <>
          {purchaseOrders.length > 0 ? (
            <Tbody>
              <>
                {map(purchaseOrders, (purchaseOrder) => (
                  <Tr
                    key={purchaseOrder.id}
                    onClick={() => handleRowClick(purchaseOrder)}
                    style={{
                      cursor: 'pointer',
                    }}
                    _hover={{ bg: '#f5f6fa' }}
                  >
                    <Td maxWidth="250px">
                      <Box as="a" fontSize="14px" fontFamily="Roboto">
                        {' '}
                        {getSupplierNameFromUser(purchaseOrder.supplier.user)}
                      </Box>
                    </Td>
                    <Td maxWidth="250px" pl="10px">
                      <Box as="a" fontSize="14px" fontFamily="Roboto">
                        {' '}
                        {purchaseOrder.supplier.code}
                      </Box>
                    </Td>
                    <Td>
                      <Box fontWeight="bold" fontSize="16px">
                        {purchaseOrder.supplier.currency.symbol}
                        {formatAmount(purchaseOrder.totalGross)}
                      </Box>
                    </Td>
                    <Td>{formatAmount(purchaseOrder.totalNet)}</Td>

                    <Td>{formatAmount(purchaseOrder.totalVat)}</Td>
                    <Td pl="10px">
                      {purchaseOrder.number ? (
                        purchaseOrder.number
                      ) : (
                        <Box color="#a9b1b4">pending</Box>
                      )}
                    </Td>
                    <Td pl="10px">
                      <POStatusBadge status={purchaseOrder.status} />
                    </Td>
                    <Td>
                      <Box fontStyle="italic" color="#333" lineHeight="1" fontSize="12px">
                        {purchaseOrder && purchaseOrder.lastLogComment ? (
                          <Tooltip
                            bg="brand.500"
                            // color="brand.50"
                            // color="white"
                            label={getPOCommentContent(purchaseOrder.lastLogComment)}
                            aria-label="Comment tooltip"
                          >
                            <Text isTruncated maxWidth="120px">
                              {getPOCommentContent(purchaseOrder.lastLogComment)}
                            </Text>
                          </Tooltip>
                        ) : null}
                      </Box>
                      <Box color="#a9b1b4" fontSize="12px" lineHeight="1.3">
                        {purchaseOrder && purchaseOrder.lastLogComment
                          ? getPOCommentAuthor(purchaseOrder.lastLogComment)
                          : null}
                      </Box>
                      <Box color="#a9b1b4" fontSize="12px" lineHeight="1">
                        {purchaseOrder && purchaseOrder.lastLogComment
                          ? getPOCommentDate(purchaseOrder.lastLogComment)
                          : null}
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </>
            </Tbody>
          ) : (
            <Box
              d="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              p="80px"
            >
              <POIcon width="60px" height="55px" color="#d1dbdf" />
              <Box fontSize="18px" fontWeight="bold" mt="20px" mb="20px">
                No purchase orders yet.
              </Box>
            </Box>
          )}
        </>
      </Table>
      <Box margin="20px" />
    </>
  );
}

export default POList;
