import React, { useState, useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import map from 'lodash/map';
import {
  Box,
  HStack,
  Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Link,
  useToast,
  // Flex,
} from '@chakra-ui/react';
import { InvoiceIcon } from '../../assets/iconsComponents/invoiceIcon';
import { CheckIcon, SmallAddIcon } from '@chakra-ui/icons';
import POActivity from './POActivity';
import POStatusBadge from './POStatusBadge';
import { formatAmount, getMessage } from '../../util';
import RequestPurchaseOrderButton from './RequestChangesPOButton';
import UploadedFile from '../vendors/UploadedFile';
import { HttpContext } from '../../context/HttpContext';
import RejectPOButton from './RejectPOButton';

export default function OrderDetailsContent({
  order,
  isFinance,
  isSupplier,
  updateApproving,
  pending,
  updateActivityLog,
  canSubmitInvoice,
  canApprovingAsFinance,
  canApprovingAsPodLeader,
}) {
  const history = useHistory();
  const toast = useToast();
  const [state, setState] = useState({
    loading: true,
    error: null,
    pending: false,
  });
  const { authAxios } = useContext(HttpContext);
  const orderId = (order && order.id) || null;
  const currency = (order && order && order.supplier.currency) || null;
  const currencyTitle = currency ? `${currency.code} - ${currency.name}` : null;
  const currencySymbol =
    (order && order.supplier.currency && order.supplier.currency.symbol) || null;
  const status = (order && order.status) || null;
  const descriptionOfWork = (order && order.descriptionOfWork) || null;
  const totalGross = (order && order.totalGross) || null;
  const totalNet = (order && order.totalNet) || null;
  const totalVat = (order && order.totalVat) || null;
  const number = (order && order.number) || null;
  const purchaseOrderItems = (order && order.purchaseOrderItems) || [];
  const supplier = (order && order.supplier) || null;
  const supplierName = (order && order.supplierName) || null;
  const campaign = (order && order.campaign) || null;
  const campaignCode = (campaign && campaign.campaignCode) || null;
  const campaignName = (campaign && campaign.campaignName) || null;
  const campaignClientName = (campaign && campaign.clientName) || null;
  const campaignLink =
    campaign && campaign.id ? `/campaigns/${campaign.id}` : '/campaigns';

  async function handleApproving(e) {
    e.preventDefault();
    updateApproving();
  }

  function handleClickAddInvoice(e, canCreateInvoice) {
    e.preventDefault();
    if (canCreateInvoice) {
      history.push(`/create-invoice/${order.id}`);
    } else {
      history.push(`/account?redirect=orders/${order.id}`);
      toast({
        position: 'top',
        description:
          'Please fill in your account information before submitting an invoice.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  async function handleResendPoEmail(e) {
    try {
      e.preventDefault();
      setState({
        ...state,
        pending: true,
      });
      await authAxios.post(`purchase-orders/${orderId}/resend-email-upload-invoice`);
      setState({
        ...state,
        loading: false,
        pending: false,
      });
      toast({
        position: 'top',
        description: `You have successfully re-sent the purchase order. You will be notified when the supplier submits the invoice.`,
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    } catch (onError) {
      console.log(onError);
      const message = getMessage(onError);
      if (message) {
        toast({
          position: 'top',
          description: message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
      setState({
        ...state,
        error: 'Something went wrong',
        loading: false,
        pending: false,
      });
    }
  }

  return (
    <Box
      bg="#fff"
      borderRadius="md"
      boxShadow="0 1px 13px rgba(29, 44, 45, 0.12);"
      mt="7"
      p="10"
    >
      <HStack justifyContent="space-between">
        <Box d="flex" flexDirection="row" alignItems="center">
          <Box pr="5">
            <Box color="#868789" fontSize="14px">
              Gross amount
            </Box>

            <Box h="24px" fontSize="20px" fontWeight="bold" lineHeight="20px">
              {currencySymbol}
              {formatAmount(totalGross)}
            </Box>
          </Box>
          <Box h="45px" w="1px" bg="#e8e9e9" />
          <Box pr="5" pl="5">
            <Box color="#868789" fontSize="14px">
              Net amount
            </Box>

            <Box>
              {currencySymbol}
              {formatAmount(totalNet)}
            </Box>
          </Box>
          <Box h="45px" w="1px" bg="#e8e9e9" />
          <Box pr="5" pl="5">
            <Box color="#868789" fontSize="14px">
              Purchase order number
            </Box>{' '}
            <Box>{number ? number : <Box color="#a9b1b4">pending</Box>}</Box>
          </Box>
          {!isSupplier && (
            <>
              <Box h="45px" w="1px" bg="#e8e9e9" />
              <Box pr="5" pl="5">
                <Box color="#868789" fontSize="14px">
                  Attachment
                </Box>
                {order.file ? (
                  <UploadedFile id={orderId} type="purchaseOrder" />
                ) : (
                  <Box color="#a9b1b4" fontSize="14px" fontWeight="400">
                    No files.
                  </Box>
                )}
              </Box>
            </>
          )}

          <Box h="45px" w="1px" bg="#e8e9e9" />
          <Box pl="5">
            <POStatusBadge status={status} />
          </Box>
        </Box>
        {isFinance && order?.status === 'awaiting_invoice' && (
          <Button
            colorScheme="brand"
            borderRadius="8px"
            fontSize="14px"
            fontWeight="500"
            pt="1"
            pb="1"
            pr="10"
            pl="10"
            _hover={{ bg: '#000' }}
            _expanded={{ bg: 'black', color: 'white' }}
            _focus={{ boxShadow: 'none' }}
            onClick={handleResendPoEmail}
            disabled={state.pending}
          >
            Re-send purchase order to supplier
          </Button>
        )}
        {(isFinance || isSupplier) && order && order.invoice && (
          <Button
            as={RouterLink}
            colorScheme="brand"
            size="sm"
            pt="10px"
            pb="10px"
            fontSize="14px"
            fontWeight="500"
            to={`/invoices/${order.invoice.id}`}
            _hover={{ bg: '#000' }}
          >
            <InvoiceIcon width="14px" height="14px" color="white" />
            <span style={{ paddingLeft: '5px' }}>View invoice</span>
          </Button>
        )}
      </HStack>
      {orderId && <POActivity id={orderId} order={order} code={number} />}
      <Heading as="h4" fontSize="22px" mt="60px" fontWeight="bold">
        Summary
      </Heading>
      <Box h="1px" width="100%" bg="#e8e9e9" mt="15px" mb="30px" />
      <HStack spacing={10} mb={14}>
        <Box width="400px">
          <Text fontSize="20px" mb="18px">
            Supplier
          </Text>
          <HStack alignItems="flex-start">
            <Box color="#868789" fontSize="14px" mr="35px">
              <Text mb="5px">Supplier code</Text>
              <Text mb="5px">Supplier name</Text>
              <Text mb="5px">Currency</Text>
            </Box>
            <Box alignItems="flex-start" fontSize="14px">
              <Text mb="5px">{supplier?.code}</Text>
              {!isSupplier ? (
                <Link
                  color="brand.500"
                  as={RouterLink}
                  to={`/suppliers/${supplier ? supplier.user.id : ''}`}
                  fontSize="14px"
                  d="block"
                  mb="6px"
                >
                  {supplierName}
                </Link>
              ) : (
                <Text mb="5px">{supplierName}</Text>
              )}

              <Text mb="5px">{currencyTitle}</Text>
            </Box>
          </HStack>
        </Box>
        <Box>
          <Text fontSize="20px" mb="18px">
            Campaign
          </Text>
          <HStack alignItems="flex-start">
            <Box color="#868789" fontSize="14px" mr="35px">
              <Text mb="5px">Campaign code</Text>
              <Text mb="5px">Campaign name</Text>
              <Text mb="5px">Client</Text>
            </Box>
            <Box alignItems="flex-start" fontSize="14px">
              <Text mb="5px">{campaignCode}</Text>
              {!isSupplier ? (
                <Link
                  color="brand.500"
                  as={RouterLink}
                  to={campaignLink}
                  fontSize="14px"
                  d="block"
                  mb="6px"
                >
                  {campaignName}
                </Link>
              ) : (
                <Text mb="5px">{campaignName}</Text>
              )}

              <Text mb="5px">{campaignClientName}</Text>
            </Box>
          </HStack>
        </Box>
      </HStack>

      <Text fontSize="20px" mb="10px" color="#333">
        Description of work
      </Text>

      <Text color="#333" fontSize="14px" maxWidth="760px">
        {descriptionOfWork}
      </Text>
      <Box maxWidth="70%">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th minWidth="500px" pl="0" pb="0">
                <Box color="#bdc1d0" fontSize="12px" fontWeight="700">
                  item title
                </Box>
              </Th>
              <Th pl="0" pb="0">
                <Box color="#bdc1d0" pb="0" fontSize="12px" fontWeight="700">
                  amount(net)
                </Box>
              </Th>
              <Th pr="0" pb="0" pl="0">
                <Box
                  color="#bdc1d0"
                  pb="0"
                  fontSize="12px"
                  fontWeight="700"
                  textAlign="right"
                  lineHeight="1"
                >
                  vat
                </Box>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {purchaseOrderItems.length &&
              map(purchaseOrderItems, (purchase) => {
                return (
                  <Tr key={purchase.id}>
                    <Td pl="0" p="10px">
                      {purchase.title}
                    </Td>
                    <Td p="10px">
                      {currencySymbol}
                      {formatAmount(purchase.amount)}
                    </Td>
                    <Td isNumeric p="10px">
                      {currencySymbol}
                      {formatAmount(purchase.vatAmount)}
                    </Td>
                  </Tr>
                );
              })}
            <Tr>
              <Td pl="0" p="10px" />
              <Td p="10px" isNumeric>
                <Box color="#868789">Sub-total (net) </Box>
                <Box color="#868789">VAT sub-total</Box>
              </Td>
              <Td isNumeric p="10px">
                {' '}
                <Box textAlign="right">
                  {currencySymbol}
                  {formatAmount(totalNet)}
                </Box>
                <Box textAlign="right">
                  {currencySymbol}
                  {formatAmount(totalVat)}
                </Box>
              </Td>
            </Tr>
            <Tr>
              <Td pl="0" p="20px" border="0" />
              <Td p="20px" border="0" pr="0">
                <Box color="#868789">TOTAL (gross) </Box>
              </Td>
              <Td isNumeric p="20px" pr="0" border="0">
                {' '}
                <Box fontSize="20px" fontWeight="bold" textAlign="right">
                  {currencySymbol}
                  {formatAmount(totalGross)}
                </Box>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      {(canApprovingAsFinance || canApprovingAsPodLeader) && (
        <HStack mt="40px">
          <Button
            type="submit"
            colorScheme="brand"
            size="sm"
            pt="10px"
            pb="10px"
            fontSize="14px"
            fontWeight="500"
            mr="10px"
            _hover={{ bg: '#000' }}
            onClick={handleApproving}
            disabled={pending}
          >
            <CheckIcon mr="10px" />
            Approve
          </Button>
          <RequestPurchaseOrderButton updateActivityLog={updateActivityLog} />
          {order?.approvedAt === null && <RejectPOButton order={order} />}
        </HStack>
      )}
      {isSupplier && order && order.status === 'awaiting_invoice' && (
        <HStack mt="40px">
          <Button
            as={RouterLink}
            colorScheme="brand"
            size="sm"
            pt="10px"
            pb="10px"
            fontSize="14px"
            fontWeight="500"
            _hover={{ bg: '#000' }}
            to={canSubmitInvoice ? `/create-invoice/${order.id}` : '/account'}
            onClick={(e) => handleClickAddInvoice(e, canSubmitInvoice)}
          >
            <SmallAddIcon h={22} w={7} />
            Add invoice
          </Button>
          <span>or</span>
          <RequestPurchaseOrderButton isSupplier updateActivityLog={updateActivityLog} />
        </HStack>
      )}
      {(isFinance || isSupplier) && order && order.invoice ? (
        <Box mt="20px">
          <Button
            as={RouterLink}
            colorScheme="brand"
            size="sm"
            pt="10px"
            pb="10px"
            fontSize="14px"
            fontWeight="500"
            to={`/invoices/${order.invoice.id}`}
            _hover={{ bg: '#000' }}
          >
            <InvoiceIcon width="14px" height="14px" color="white" />
            <span style={{ paddingLeft: '5px' }}>View invoice</span>
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}
