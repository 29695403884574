import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Container,
  Flex,
  Heading,
} from '@chakra-ui/react';
import EditMemberForm from '../../components/team/TeamEditForm';
import { TeamIconAddForm } from '../../assets/iconsComponents/teamIconAddForm';
import TeamEditFormAsAdmin from './TeamEditFormAsAdmin';

const TeamEditMember = ({ member, updateMember, isAdmin }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        colorScheme="brand"
        variant="ghost"
        size="xs"
        fontSize="14px"
        onClick={onOpen}
      >
        Edit
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="full"
        // isFullHeight
        blockScrollOnMount={false}
        //   finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent style={{ overflow: 'auto' }}>
          <Container maxW="container.sm" pt={24}>
            <DrawerCloseButton color="brand.500" _focus={{ outline: '0' }} size="lg" />
            <DrawerHeader>
              <Flex alignItems="center" direction="column">
                <TeamIconAddForm
                  fill="#d1dbdf"
                  color="#d1dbdf"
                  height="50px"
                  width="70px"
                />
                <Heading
                  as="h2"
                  size="lg"
                  textAlign="center"
                  fontWeight="700"
                  mb={[4, 4, 8]}
                  mt={4}
                >
                  Edit a Member
                </Heading>
              </Flex>
            </DrawerHeader>
            <DrawerBody style={{ overflow: 'auto' }}>
              <>
                {isAdmin ? (
                  <TeamEditFormAsAdmin
                    onClose={onClose}
                    updateMember={updateMember}
                    member={member}
                  />
                ) : (
                  <EditMemberForm
                    onClose={onClose}
                    updateMember={updateMember}
                    member={member}
                  />
                )}
              </>
            </DrawerBody>
          </Container>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default TeamEditMember;
