import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  // FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Box,
  InputGroup,
  InputRightElement,
  ListItem,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { HttpContext } from '../../../context/HttpContext';
import { AuthContext } from '../../../context/AuthContext';
import {
  getErrors,
  hasDigit,
  hasLowerAndUpperCase,
  hasSymbol,
  lengthAtLeast,
} from '../../../util';
import {
  REGEX_HAS_SYMBOL,
  REGEX_HAS_AT_LEAST_ONE_NUMBER,
  REGEX_HAS_CONTAINS_BOTH_LOWER_AND_UPPERCASE_LETTERS,
} from '../../../util/regExr';

const PasswordSetupForm = ({ token, redirect }) => {
  const toast = useToast();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [done, setDone] = React.useState(false);
  const handlePwdClick = () => setShow(!show);

  // https://www.geeksforgeeks.org/check-if-a-string-contains-uppercase-lowercase-special-characters-and-numeric-values/

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('This is mandatory field')
      .min(6, 'Password must be at least 6 characters')
      .matches(
        REGEX_HAS_CONTAINS_BOTH_LOWER_AND_UPPERCASE_LETTERS,
        'Password must contain both lower (a-z) and uppercase (A-Z) letters'
      )
      .matches(REGEX_HAS_AT_LEAST_ONE_NUMBER, 'Password must contain at least one number')
      .matches(REGEX_HAS_SYMBOL, 'Password must contain at least one symbol'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };
  const {
    register,
    handleSubmit,
    reset,
    formState,
    watch,
    // setError
  } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;
  const { publicAxios } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const getTokenStatus = async () => {
      try {
        const { data } = await publicAxios.get(`users/password/${token}/check`);
        if (false === data.status) {
          toast({
            position: 'top-left',
            description: 'The activation link expired.',
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
          history.push('/login');
        }
      } catch (onError) {
        console.log(onError);
        toast({
          position: 'top-left',
          description: 'The activation link expired',
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
        history.push('/login');
      }
    };

    getTokenStatus();
  }, [publicAxios, history, toast, token]);
  const watchValue = watch('password');
  function onSubmit(values) {
    return new Promise((resolve) => {
      const data = {
        plainPassword: values.password,
        token,
      };

      publicAxios
        .post(`users/password/setup`, data)
        .then((res) => {
          const { token, user, refresh_token } = res.data;
          if (token) {
            auth.setAuthState({ token, user, refreshToken: refresh_token });
            reset();
          }
          if (redirect) {
            history.push(redirect);
          }
        })
        .catch((onError) => {
          const errors = getErrors(onError);
          if (errors && errors.token && errors.token.errors) {
            const message = errors.token.errors[0];
            toast({
              position: 'top-left',
              description: message,
              status: 'error',
              duration: 6000,
              isClosable: true,
            });
          }
          if (errors && errors.plainPassword && errors.plainPassword.errors) {
            const message = errors.plainPassword.errors[0];
            toast({
              position: 'top-left',
              description: message,
              status: 'error',
              duration: 6000,
              isClosable: true,
            });
          }
          reset();
          // history.push('/login')
        })
        .finally(setDone(true));
      resolve();
    });
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.password} mb={4} pt={4}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <InputGroup size="lg">
            <Input
              pr="4.5rem"
              type={show ? 'text' : 'password'}
              name="password"
              id="password"
              {...register('password')}
            />
            <InputRightElement
              children={
                <>
                  {show ? (
                    <ViewOffIcon
                      color="brand.500"
                      onClick={handlePwdClick}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <ViewIcon
                      color="grey.200"
                      onClick={handlePwdClick}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </>
              }
            />
          </InputGroup>
          {/*<FormErrorMessage>*/}
          {/*  {errors.password?.message || errors.password}*/}
          {/*</FormErrorMessage>*/}
        </FormControl>
        <>
          <Box fontWeight="bold" color="#333333" fontSize="13px">
            Create password that:
          </Box>

          <UnorderedList>
            <ListItem color="#eb7f24">
              {lengthAtLeast(watchValue, 6) ? (
                <Box color="#cdd3d4" fontSize="13px" textDecoration="line-through">
                  contains at least 6 characters
                </Box>
              ) : (
                <Box color="#333333" fontSize="13px">
                  contains at least 6 characters
                </Box>
              )}
            </ListItem>
            <ListItem color="#eb7f24">
              {hasLowerAndUpperCase(watchValue) ? (
                <Box color="#cdd3d4" fontSize="13px" textDecoration="line-through">
                  contains both lower (a-z) and uppercase (A-Z) letters
                </Box>
              ) : (
                <Box color="#333333" fontSize="13px">
                  contains both lower (a-z) and uppercase (A-Z) letters
                </Box>
              )}
            </ListItem>
            <ListItem color="#eb7f24">
              {hasDigit(watchValue) ? (
                <Box color="#cdd3d4" fontSize="13px" textDecoration="line-through">
                  contains at least one number
                </Box>
              ) : (
                <Box color="#333333" fontSize="13px">
                  contains at least one number
                </Box>
              )}
            </ListItem>
            <ListItem color="#eb7f24">
              {hasSymbol(watchValue) ? (
                <Box color="#cdd3d4" fontSize="13px" textDecoration="line-through">
                  contains at least one symbol
                </Box>
              ) : (
                <Box color="#333333" fontSize="13px">
                  contains at least one symbol
                </Box>
              )}
            </ListItem>
          </UnorderedList>

          <Button
            type="submit"
            size="lg"
            colorScheme="brand"
            isFullWidth
            isLoading={isSubmitting && !done}
            disabled={!isDirty || !isValid || isSubmitting}
            textTransform="initial"
            mt={10}
            _hover={{ bg: '#000' }}
            //disabled={disableForm}
          >
            Set password and login
          </Button>
        </>
      </form>
    </>
  );
};
export default PasswordSetupForm;
